import {all,  put, takeEvery} from 'redux-saga/effects';
import API from '../../../../utils/api';
import {ApiEndpointVersion, errorToaster} from '../../../../utils/helper';
import {
    getRiskScoreChartFailureAction,
    getRiskScoreChartSuccessAction
} from "../../../actions/dentist/wellnessProfile/riskScoreChartAction";
import {GET_RISK_SCORE_CHART_DATA_REQUEST} from "../../../actionTypes/dentist/wellnessProfile/riskScoreChart";


// get risk score chart data
function* getRiskScoreChartRequest(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/wellness-profile/risk-scores/graph/${payload?.payload?.patient_id}`)
        yield put(getRiskScoreChartSuccessAction(response?.data?.detail))

    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(getRiskScoreChartFailureAction({}))
    }
}

function* getRiskScoreChartRequestWatch() {
    yield takeEvery(GET_RISK_SCORE_CHART_DATA_REQUEST, getRiskScoreChartRequest);
}

export default function* riskScoreSaga() {
    yield all([
        getRiskScoreChartRequestWatch(),
    ])
}
