import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../../../utils/api';
import {
    getClinicCacheDataClearRequest,
    getClinicRequestFailure,
    getClinicRequestSuccess,
    verifyPasswordRequestFailure,
    verifyPasswordRequestSuccess
} from '../../../actions/common/clinicSelection/clinicActions';
import {
    GET_CLINIC_REQUEST,
    VERIFY_PASSWORD_REQUEST
} from '../../../actionTypes/common/clinicSelection';
import {ApiEndpointVersion, errorToaster, successToaster} from "../../../../utils/helper";

function* verifyPasswordSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/auth/account/password`, {
            password: payload.payload.password
        });
        yield put(
            verifyPasswordRequestSuccess({
                data: response.data.detail
            })
        );
        successToaster(response?.data?.meta?.message)
        yield call(payload.payload.callback)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            verifyPasswordRequestFailure({
                error: e?.meta?.message
            })
        );
    }
}

function* getClinicRequestSaga(payload: any): any {
    yield put(getClinicCacheDataClearRequest());
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/clinic/account/${payload?.payload?.staff_id}`);
        yield put(
            getClinicRequestSuccess({
                data: response.data.detail
            })
        );
        if (payload?.payload.callback) {
            yield call(payload?.payload.callback, response.data.detail);
        }
    } catch (e: any) {
        yield put(getClinicCacheDataClearRequest());
        yield put(
            getClinicRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* verifyPasswordWatch() {
    yield takeEvery(VERIFY_PASSWORD_REQUEST, verifyPasswordSaga);
}

function* clinicSaga() {
    yield takeEvery(GET_CLINIC_REQUEST, getClinicRequestSaga);
}

export default function* rootSaga() {
    yield all([clinicSaga(), verifyPasswordWatch()]);
}
