import {all, put, takeEvery} from "redux-saga/effects";
import API from "../../../utils/api";
import {GET_ANALYTICS_DATA_REQUEST} from "../../actionTypes/dentist/analytics";
import {updateAnalyticsDataAction} from "../../actions/dentist/analyticsActions";
import {ApiEndpointVersion, checkAPIParams} from "../../../utils/helper";

function* getAnalyticsDataSaga(payload: any): any {
    try {
        const params = [
            {key: 'clinic_id', value: payload.payload.clinic_id},
            {key: 'from_date', value: payload.payload.from_date},
            {key: 'to_date', value: payload.payload.to_date},
        ]

        const url = `/${ApiEndpointVersion}/analytics${checkAPIParams(params)}`
        const response = yield API.get(url)
        yield put(
            updateAnalyticsDataAction(response?.data?.detail)
        );
    } catch (e: any) {
        yield put(
            updateAnalyticsDataAction(null)
        );
    }
}

function* getAnalyticsDataWatcher() {
    yield takeEvery(GET_ANALYTICS_DATA_REQUEST, getAnalyticsDataSaga)
}

export default function* dentistDashboardSaga() {
    yield all([
        getAnalyticsDataWatcher()
    ])
}
