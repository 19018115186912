import React, {useEffect, useMemo} from "react";
import {Box, Button, Divider, Grid} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {
    hardTissue,
    limitedExam,
    medical,
    odontogram,
    periogram,
    softTissue,
} from "../../../../utils/constant/clinicalExamConstant";
import Periogram from "../../ExamSteps/ClinicalExam/Periogram";
import ExamForm from "../../ExamSteps/ClinicalExam/ExamForm";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../redux/reducers/rootReducer";
import softTissueImage from "../../../../assets/images/softTissue.png";
import {
    updateExamAccordionAction,
    updateExamFormSizeAction
} from "../../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction";
import HardTissueImage from "../../../../assets/images/hardTissue.png";
import {
    getHardTissueRequestAction,
    getLimitedExamRequestAction,
    getOdontogramRequestAction,
    getOtherFormFindingRequestAction,
    getPeriogramRequestAction,
    getSoftTissueRequestAction,
    putHardTissueRequestAction,
    putOdontogramRequestAction,
    putPeriogramRequestAction,
    putSoftTissueRequestAction
} from "../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import Odontogram from "../../ExamSteps/ClinicalExam/Odontogram";
import ButtonContained from "../../../common/UIComponents/ButtonContained";
import ChatBox from "../../ExamSteps/ClinicalExam/ChatBox";

const useStyles = makeStyles((theme: any) => ({
    appointmentFlowTabs: {
        position: 'relative',
        zIndex: 500,
        background: theme.palette.common.white,
        "& .MuiTabs-indicator": {
            backgroundColor: theme.palette.v2.primary.main,
            height: "3px",
        },
        "& .MuiTabs-scroller": {
            overflow: 'auto !important',
            width: '100%',
            "&::-webkit-scrollbar": {
                display: " none",
            },
        }
    },
    examContent: {
        background: theme.palette.background.secondaryPaper,
    },
    saveAsDraftButton: {
        width: "134px",
        height: "40px",
        marginRight: "20px",
        textTransform: "none",
        color: theme.palette.v2.primary.main,
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
}));
const WellnessExam = () => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const {
        clinicalExamFilters: {
            examAccordionExpanded,
            examFormSize,
        },
        wellnessProfileFilters: {wellnessProfileStep, wellnessProfileData, wellnessProfileLoader},
    } = useSelector((state: RootState) => state) as any;
    const takeFromUrl = useParams();

    useEffect(() => {
        switch (wellnessProfileStep) {
            case softTissue:
                dispatch(getSoftTissueRequestAction({
                    patient_id: takeFromUrl?.patientId,
                }));
                break;
            case hardTissue:
                dispatch(getHardTissueRequestAction({
                    patient_id: takeFromUrl?.patientId,
                }));
                break;
            case odontogram:
                dispatch(getOdontogramRequestAction({
                    patient_id: takeFromUrl?.patientId,
                }));
                break;
            case periogram:
                dispatch(getPeriogramRequestAction({
                    patient_id: takeFromUrl?.patientId,
                }));
                break;
            case medical:
                dispatch(getOtherFormFindingRequestAction({
                    patient_id: takeFromUrl?.patientId,
                }));
                break;
            case limitedExam:
                dispatch(getLimitedExamRequestAction({
                    patient_id: takeFromUrl?.patientId,
                }));
                break;
            default:
                break;
        }
    }, [wellnessProfileStep,dispatch,takeFromUrl?.patientId]);
    const handleExpanded = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (examFormSize) {
            dispatch(updateExamFormSizeAction(false));
        }
        dispatch(updateExamAccordionAction(!examAccordionExpanded));
    };
    const handleFullSize = (e: any) => {
        e?.preventDefault();
        e?.stopPropagation();
        if (!examAccordionExpanded && !examFormSize) {
            dispatch(updateExamAccordionAction(true));
        }
        dispatch(updateExamFormSizeAction(!examFormSize));
    };
    const showFooter = false
    const showForm = () => {
        return true
    };
    const displayImageFindingTypeWise = () => {
        if (wellnessProfileStep === softTissue) {
            return softTissueImage
        }
        if (wellnessProfileStep === hardTissue) {
            return HardTissueImage
        }

    }
    useEffect(() => {
        if (wellnessProfileStep === softTissue || wellnessProfileStep === hardTissue) {
            dispatch(updateExamFormSizeAction(true));
            dispatch(updateExamAccordionAction(true))
        } else {
            dispatch(updateExamFormSizeAction(false));
        }
    }, [wellnessProfileStep, dispatch])
    const saveExamData = () => {
        if (wellnessProfileStep === softTissue) {
            dispatch(
                putSoftTissueRequestAction({
                    patient_id: takeFromUrl?.patientId,
                    submit: true,
                    callback: () => {
                        dispatch(getSoftTissueRequestAction({
                            patient_id: takeFromUrl?.patientId,
                        }));
                    }
                })
            );
        }
        if (wellnessProfileStep === hardTissue) {
            dispatch(
                putHardTissueRequestAction({
                    patient_id: takeFromUrl?.patientId,
                    submit: true,
                    callback: () => {
                        dispatch(getHardTissueRequestAction({
                            patient_id: takeFromUrl?.patientId,
                        }));
                    }
                })
            );
        }
        if (wellnessProfileStep === odontogram) {
            dispatch(
                putOdontogramRequestAction({
                    patient_id: takeFromUrl?.patientId,
                    submit: true,
                    callback: () => {
                        dispatch(getOdontogramRequestAction({
                            patient_id: takeFromUrl?.patientId,
                        }));
                    }
                })
            );
        }
        if (wellnessProfileStep === periogram) {
            dispatch(
                putPeriogramRequestAction({
                    patient_id: takeFromUrl?.patientId,
                    submit: true,
                    callback: () => {
                        dispatch(getPeriogramRequestAction({
                            patient_id: takeFromUrl?.patientId,
                        }));
                    }
                })
            );
        }
    }
    const btnName = useMemo(() => {
        if (wellnessProfileStep === softTissue) {
            return 'Save Soft Tissue'
        } else if (wellnessProfileStep === hardTissue) {
            return 'Save Hard Tissue'
        } else if (wellnessProfileStep === odontogram) {
            return 'Save Odontogram'
        } else if (wellnessProfileStep === periogram) {
            return 'Save Periogram'
        }
    }, [wellnessProfileStep])

    return (
        <Grid container height={'100%'} pt={'10px'}>
            <Grid
                display={examFormSize && "none"}
                item
                xl={showForm() ? (examAccordionExpanded ? 8.5 : 12) : 12}
                lg={showForm() ? (examAccordionExpanded ? 8.5 : 12) : 12}
                md={showForm() ? (examAccordionExpanded ? 8 : 12) : 12}
                sm={showForm() ? (examAccordionExpanded ? 8 : 12) : 12}
                xs={showForm() ? (examAccordionExpanded ? 8 : 12) : 12}
                width={wellnessProfileStep === periogram ? 1085 : 'auto'} overflow='scroll'
                className="bg-white"
                sx={{minHeight: "calc(100vh - 396px)", height: '100%', padding: '0px'}}
            >
                {wellnessProfileStep === periogram ?
                    <Periogram/>
                    :
                    wellnessProfileStep === odontogram ?
                        <Odontogram/>
                        :
                        ((wellnessProfileStep !== softTissue && wellnessProfileStep !== hardTissue) &&
                            <Box className='h-100 d-flex-all-center'>
                                <Box
                                    className={`d-flex-all-center`}
                                    sx={{
                                        width: `800px !important`,
                                        margin: 'auto'
                                    }}
                                >
                                    <img src={displayImageFindingTypeWise()} alt='exam' style={{
                                        objectPosition: 'center',
                                        height: (wellnessProfileStep === softTissue || wellnessProfileStep === hardTissue) ? '568px' : ''
                                    }}/>
                                </Box>
                            </Box>)
                }
            </Grid>
            <React.Fragment>
                {showForm() && (examAccordionExpanded ?
                        <Grid
                            item
                            xl={examFormSize ? 12 : 3.5}
                            lg={examFormSize ? 12 : 3.5}
                            md={examFormSize ? 12 : 4}
                            sm={examFormSize ? 12 : 4}
                            xs={examFormSize ? 12 : 4}
                            pl={examFormSize ? 0 : 1}
                        >
                            <ExamForm
                                handleExpanded={handleExpanded}
                                handleFullSize={handleFullSize}
                                showFooter={showFooter}
                            />
                        </Grid>
                        :
                        <Grid item>
                            <ExamForm
                                handleExpanded={handleExpanded}
                                handleFullSize={handleFullSize}
                                showFooter={showFooter}
                            />
                        </Grid>
                )}
            </React.Fragment>
            {(!wellnessProfileData?.is_wellness_locked && !wellnessProfileLoader) ?
                <Grid item xs={12} position={'absolute'} width={'100%'} bottom={0}>
                    <React.Fragment>
                        <Divider sx={{width: '100%', borderWidth: 1}}/>
                        <Box
                            className={classes.appointmentFlowTabs}
                            pt={"4px"}
                            px={["20px"]}
                            height={["80px"]}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                        >
                            <Button
                                color="error"
                                variant={"contained"}
                                className={`button fw-regular`}
                                sx={{
                                    width: "190px",
                                    height: "77px",
                                }}
                                onClick={() => saveExamData()}
                            >
                                {btnName}
                            </Button>

                        </Box>
                    </React.Fragment>
                </Grid> :
                <Grid item xs={12} position={'absolute'} width={'100%'} bottom={0}>
                    <React.Fragment>
                        <Divider sx={{width: '100%', borderWidth: 1}}/>
                        <Box
                            className={classes.appointmentFlowTabs}
                            pt={"4px"}
                            px={["20px"]}
                            height={["80px"]}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            alignItems={"center"}
                        >
                            <Link
                                to={`/dentist/appointment/clinical-exam/${wellnessProfileData?.ongoing_appointment_id}/${takeFromUrl?.patientId}`}
                                target={"_blank"}
                                className={`cursor-pointer text-decoration`}
                            >
                                <ButtonContained
                                    btnName={'Complete ongoing exam'}
                                    sx={{minWidth: '210px',}}
                                />
                            </Link>
                        </Box>
                    </React.Fragment>
                </Grid>
            }
            <ChatBox/>

        </Grid>
    )
}

export default WellnessExam;