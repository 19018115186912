import {
    GET_SUMMARIZATION_DATA_REQUEST,
    GET_SUMMARIZATION_DATA_REQUEST_SUCCESS,
    GET_SUMMARIZATION_DATA_REQUEST_FAILURE,
    UPDATE_SUMMARIZATION_DATA_REQUEST,
    UPDATE_SUMMARIZATION_DATA_REQUEST_SUCCESS,
    UPDATE_SUMMARIZATION_DATA_REQUEST_FAILURE,
    UPDATE_SUMMARIZATION_DATA_LOCALLY,
} from '../../actionTypes/dentist/qSummarization'
import { QSummarizationTypeState, SummarizationTypeActions } from '../../types/dentist/qSummarization';

const initialState: QSummarizationTypeState = {
    getSummarizationLoader: false,
    getSummarizationData: null,
    getSummarizationError: null,

    updateSummarizationLoader: false,
    updateSummarizationData: null,
    updateSummarizationError: null,

    updateSummaryDataLocally: {
        userRating: null,
        userFeedback: '',
    }
};

const qSummarizationReducer = (state = initialState, action: SummarizationTypeActions) => {
    switch (action.type) {
        //update summary data locally
        case UPDATE_SUMMARIZATION_DATA_LOCALLY:
            return {
                ...state,
                updateSummaryDataLocally: action?.payload,
            }

        // get summarization data
        case GET_SUMMARIZATION_DATA_REQUEST:
            return {
                ...state,
                getSummarizationLoader: true,
                getSummarizationData: null,
                getSummarizationError: null
            }
        case GET_SUMMARIZATION_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                getSummarizationLoader: false,
                getSummarizationData: action.payload,
                getSummarizationError: null
            }
        case GET_SUMMARIZATION_DATA_REQUEST_FAILURE:
            return {
                ...state,
                getSummarizationLoader: false,
                getSummarizationData: null,
                getSummarizationError: action.payload
            }

        //update summarization data
        case UPDATE_SUMMARIZATION_DATA_REQUEST:
            return {
                ...state,
                updateSummarizationLoader: true,
                updateSummarizationData: null,
                updateSummarizationError: null
            }
        case UPDATE_SUMMARIZATION_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                updateSummarizationLoader: false,
                updateSummarizationData: action.payload,
                updateSummarizationError: null
            }
        case UPDATE_SUMMARIZATION_DATA_REQUEST_FAILURE:
            return {
                ...state,
                updateSummarizationLoader: false,
                updateSummarizationData: null,
                updateSummarizationError: action.payload
            }

        default:
            return {
                ...state
            };
    }
}

export default qSummarizationReducer