export const UPDATE_OTP = 'UPDATE_OTP'
export const UPDATE_OTP_VALIDATION = 'UPDATE_OTP_VALIDATION'

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST"
export const VERIFY_OTP_REQUEST_SUCCESS = "VERIFY_OTP_REQUEST_SUCCESS"
export const VERIFY_OTP_REQUEST_FAILURE = "VERIFY_OTP_REQUEST_FAILURE"


export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST"
export const SEND_OTP_REQUEST_SUCCESS = "SEND_OTP_REQUEST_SUCCESS"
export const SEND_OTP_REQUEST_FAILURE = "SEND_OTP_REQUEST_FAILURE"

