import {
    SendOtpRequest,
    SendOtpRequestPayload,
    UpdateOtpRequest,
    UpdateOtpRequestPayload,
    UpdateOtpValidationRequest,
    UpdateOtpValidationRequestPayload,
    VerifyOtpRequest,
    VerifyOtpRequestPayload,
} from "../../types/common/mobileVerificarionActionTypes";
import {
    SEND_OTP_REQUEST, SEND_OTP_REQUEST_FAILURE, SEND_OTP_REQUEST_SUCCESS,
    UPDATE_OTP,
    UPDATE_OTP_VALIDATION,
    VERIFY_OTP_REQUEST, VERIFY_OTP_REQUEST_FAILURE,
    VERIFY_OTP_REQUEST_SUCCESS
} from "../../actionTypes/common/mobileVerification";


export const updateOtpRequest = (payload: UpdateOtpRequestPayload): UpdateOtpRequest => ({
    type: UPDATE_OTP,
    payload
});
export const updateOtpValidationRequest = (payload: UpdateOtpValidationRequestPayload): UpdateOtpValidationRequest => ({
    type: UPDATE_OTP_VALIDATION,
    payload
});

export const verifyOtpRequest = (payload: VerifyOtpRequestPayload): VerifyOtpRequest => ({
    type: VERIFY_OTP_REQUEST,
    payload,
});
export const verifyOtpRequestSuccess = () => ({
    type: VERIFY_OTP_REQUEST_SUCCESS
});

export const verifyOtpRequestFailure = () => ({
    type: VERIFY_OTP_REQUEST_FAILURE
});


export const sendOtpRequest = (payload: SendOtpRequestPayload): SendOtpRequest => ({
    type: SEND_OTP_REQUEST,
    payload,
});
export const sendOtpRequestSuccess = () => ({
    type: SEND_OTP_REQUEST_SUCCESS
});

export const sendOtpRequestFailure = () => ({
    type: SEND_OTP_REQUEST_FAILURE
});
