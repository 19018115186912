import React from 'react'
import {Typography} from "@mui/material";
import PopupComponent from "../../common/UIComponents/PopupComponent";

type Props = {
    open: boolean
    handleClose: () => void
}

export default function DisclaimerPopUp(props: Props) {
    const {open, handleClose} = props;
    return (
        <React.Fragment>
            <PopupComponent
                open={open}
                heading={'Disclaimer'}
                headerAction={true}
                footerAction={true}
                width={'600px'}
                saveBtnText={'Got It'}
                handleSave={handleClose}
                notDisplayCancelIcon
                notDisplayCancel
            >
                <Typography className={`f-16 lh-25 fw-regular`}>
                    OraQ processes collected data and provides a customized OraQ Wellness risk and recommendation
                    profile that is reviewed by the clinicians.
                    The dentist now has the autonomy to either accept or reject the insights and recommendations,
                    generated by OraQ,
                    thus providing the clinician with direct control of the visit.
                    OraQ does not diagnosis treatment for the patient and the clinician.
                </Typography>
            </PopupComponent>

        </React.Fragment>

    );
}