//patient register form
import {
    PATIENT_REGISTER_FORM_DATA,
    PATIENT_REGISTER_FORM_ERROR,
    SAVE_PATIENT_REGISTER_FAIL,
    SAVE_PATIENT_REGISTER_REQUEST,
    SAVE_PATIENT_REGISTER_SUCCESS,
    VERIFY_PATIENT_USERNAME_FAIL,
    VERIFY_PATIENT_USERNAME_REQUEST,
    VERIFY_PATIENT_USERNAME_SUCCESS,
    GET_PATIENT_DATA_REQUEST,
    GET_PATIENT_DATA_SUCCESS,
    GET_PATIENT_DATA_FAIL
} from "../../actionTypes/patient/patientRegister";
import {
    GetPatientDataFail,
    GetPatientDataFailPayload,
    GetPatientDataRequest,
    GetPatientDataRequestPayload,
    GetPatientDataSuccess,
    GetPatientDataSuccessPayload,
    PatientRegisterFormDataPayload,
    PatientRegisterFormDataRequest,
    PatientRegisterFormErrorPayload,
    PatientRegisterFormErrorRequest,
    SavePatientRegisterRequest,
    SavePatientRegisterRequestPayload,
    VerifyPatientUsernameFail,
    VerifyPatientUsernameFailPayload,
    VerifyPatientUsernameRequest,
    VerifyPatientUsernameRequestPayload,
    VerifyPatientUsernameSuccess,
    VerifyPatientUsernameSuccessPayload
} from "../../types/patient/patientRegisterActionTypes";

export const patientRegisterFormDataRequest = (payload: PatientRegisterFormDataPayload): PatientRegisterFormDataRequest => ({
    type: PATIENT_REGISTER_FORM_DATA,
    payload
});
export const patientRegisterFormErrorRequest = (payload: PatientRegisterFormErrorPayload): PatientRegisterFormErrorRequest => ({
    type: PATIENT_REGISTER_FORM_ERROR,
    payload
});

//register patient
export const savePatientRegisterRequest = (payload: SavePatientRegisterRequestPayload): SavePatientRegisterRequest => ({
    type: SAVE_PATIENT_REGISTER_REQUEST,
    payload
});
export const savePatientRegisterSuccess = () => ({
    type: SAVE_PATIENT_REGISTER_SUCCESS
});

export const savePatientRegisterFailure = () => ({
    type: SAVE_PATIENT_REGISTER_FAIL
});

//verify patient username
export const verifyPatientUsernameRequest = (payload: VerifyPatientUsernameRequestPayload): VerifyPatientUsernameRequest => ({
    type: VERIFY_PATIENT_USERNAME_REQUEST,
    payload
});
export const verifyPatientUsernameSuccess = (payload: VerifyPatientUsernameSuccessPayload): VerifyPatientUsernameSuccess => ({
    type: VERIFY_PATIENT_USERNAME_SUCCESS,
    payload
});

export const verifyPatientUsernameFailure = (payload: VerifyPatientUsernameFailPayload): VerifyPatientUsernameFail => ({
    type: VERIFY_PATIENT_USERNAME_FAIL,
    payload
});

// get patient data
export const getPatientDataRequest = (payload: GetPatientDataRequestPayload): GetPatientDataRequest => ({
    type: GET_PATIENT_DATA_REQUEST,
    payload
});
export const getPatientDataSuccess = (payload: GetPatientDataSuccessPayload): GetPatientDataSuccess => ({
    type: GET_PATIENT_DATA_SUCCESS,
    payload
});

export const getPatientDataFailure = (payload: GetPatientDataFailPayload): GetPatientDataFail => ({
    type: GET_PATIENT_DATA_FAIL,
    payload
});