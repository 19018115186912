import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FMS_API_KEY,
  authDomain: process.env.REACT_APP_FMS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FMS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FMS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FMS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FMS_APP_ID,
  measurementId: process.env.REACT_APP_FMS_MEASUREMENT_ID
};

let firebaseApp;
let messaging = null;
isSupported()?.then(res => {
  if (res) {
    firebaseApp = initializeApp(firebaseConfig);
    messaging = getMessaging(firebaseApp);
  }
});

export const requestForToken = async () => {
  const hasFirebaseMessagingSupport = await isSupported();
  if (hasFirebaseMessagingSupport) {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FMC_VAPID_KEY
    })
      .then(currentToken => {
        if (currentToken) {
          localStorage.setItem('dt', currentToken);
        }
        // else {
        //   errorToaster('No registration token available. Request permission to generate one.');
        // }
      })
      .catch(() => {
        // errorToaster('Please allow notification permission');
      });
  }
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload);
    });
  });
