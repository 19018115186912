import React, {useMemo} from 'react';
import {getCrownSvg} from "../../../Odontogram/OdontogramSvgs/Crown";
import {getLingualSvg} from "../../../Odontogram/OdontogramSvgs/Lingual";

type Props = {
    tooth: number
    isImplant: any
    lingual: any
}

const TeethCrown = React.memo((props: Props) => {
    const {tooth, isImplant, lingual} = props

    const getFinding = useMemo(() => {
        if (isImplant.hasTooth) {
            return 'implant'
        } else {
            return null
        }
    }, [isImplant]);

    const getColor = useMemo(() => {
        if (isImplant.hasTooth) {
            return isImplant?.color
        } else {
            return null
        }
    }, [isImplant,]);

    const getSubType = useMemo(() => {
        if (isImplant.hasTooth) {
            return (isImplant?.implantSubType ? "Fixture and Crown" : 'Fixture')
        }
    }, [isImplant]);

    const lingualStroke = useMemo(() => {
        if (isImplant?.hasTooth) {
            return isImplant?.color;
        } else {
            return null;
        }
    }, [isImplant]);

    const getCrown = useMemo(() => {
        let toothNumber = tooth as any;
        return getCrownSvg[`Crown${toothNumber}`](getFinding, getColor, null, lingualStroke, getSubType);
    }, [tooth, getFinding, getColor, getSubType, lingualStroke]);

    const getLingual = useMemo(() => {
        let toothNumber = tooth as any;
        return getLingualSvg[`Lingual${toothNumber}`](getFinding, getColor, null, lingualStroke, getSubType)
    }, [tooth, getFinding, getColor, getSubType, lingualStroke])
    return (
        <React.Fragment>
            {lingual ? getLingual : getCrown}
        </React.Fragment>
    )
})
export default TeethCrown;