import {
    FORGOT_PASSWORD_V2_REQUEST,
    FORGOT_PASSWORD_REQUEST_FAILURE,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP
} from '../../actionTypes/auth';
import {forgotPasswordInterface, forgotPasswordActions} from '../../types/authActionTypes/forgotPasswordActionTypes';

const initialState: forgotPasswordInterface = {
    forgotPasswordLoader: false,
    isMultipleAccount: false
};

const ForgotPasswordReducer = (state = initialState, action: forgotPasswordActions) => {
    switch (action.type) {
        case FORGOT_PASSWORD_V2_REQUEST:
            return {
                ...state,
                forgotPasswordLoader: true,
            };
        case FORGOT_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                forgotPasswordLoader: false,
            };
        case FORGOT_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                forgotPasswordLoader: false,
            };

        case IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP:
            return {
                ...state,
                isMultipleAccount: action.payload
            }

        default:
            return {
                ...state
            };
    }
};
export default ForgotPasswordReducer;
