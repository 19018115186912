import React, {useEffect, useMemo} from 'react';
import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {implantRoot,} from '../../../../../../assets/images/odontogramFindings/odontogramIcons';
import abfraction from '../../../../../../assets/images/odontogramFindings/abfraction.svg';
import {getCrownSvg} from "../OdontogramSvgs/Crown";
import {watchIcon} from '../OdontogramFindingsIcon';
import {getRootSvg} from '../OdontogramSvgs/ToothRoot';
import OdontogramNcclFinding from './OdontogramNcclFinding/index';
import OdontogramOpenMarginFinding from './OdontogramOpenMarginFinding';
import OdontogramOverHangFinding from './OdontogramOverHangFinding';
import OdontogramOpenContactFinding from './OdontogramOpenContactFinding';
import useOdontogramFinding from "../useOdontogramFinding";

const useStyles = makeStyles(() => ({
    odontogramTeeth: {
        height: '85px',
        flexDirection: 'column',
        zIndex: 1,
    },
    impactedTooth: {
        width: '46.52px',
        height: '90px',
        borderRadius: '50%',
    },
}));

type Props = {
    tooth: number;
    isBottomTeeth?: boolean;
    getFilterData?: any;
};

const OdontogramTeeth = (props: Props) => {
    const {tooth, isBottomTeeth} = props;
    const classes = useStyles();
    const toothNumber = isBottomTeeth ? tooth + 20 : tooth;
    const {isCrown} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isImplant} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isSct} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isRootCanal} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isWatch} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isImpactedTooth} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isPeriapicalAbscess} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isNCCLs} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isOpenMargin} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isOverhang} = useOdontogramFinding(toothNumber, isBottomTeeth);
    const {isOpenContact} = useOdontogramFinding(toothNumber, isBottomTeeth);

    const crownAllCeramicProvisionalSubType = useMemo(() => {
        return isCrown?.crownAllCeramicProvisionalSubType === 'All Ceramic' || isCrown?.crownAllCeramicProvisionalSubType === 'Provisional'
    }, [isCrown]);

    const crownFullMetalSubType = useMemo(() => {
        return isCrown?.crownAllCeramicProvisionalSubType === 'Full Metal'
    }, [isCrown]);

    const crownMetalCeramicSubType = useMemo(() => {
        return isCrown?.crownAllCeramicProvisionalSubType === 'Metal Ceramic'
    }, [isCrown]);

    const crownStroke = useMemo(() => {
        if (isImplant?.hasTooth && isImplant?.implantSubType) {
            return isImplant?.color
        } else if (isCrown?.hasTooth && (crownAllCeramicProvisionalSubType || crownMetalCeramicSubType)) {
            return isCrown?.color
        } else if (isNCCLs?.hasTooth && (isNCCLs?.ncclTypes?.includes('erosion') || isNCCLs?.ncclTypes?.includes('attrition') || isNCCLs?.ncclTypes?.includes('abrasion') || isNCCLs?.ncclTypes?.includes('abfraction'))) {
            return isNCCLs?.color
        } else {
            return null
        }
    }, [isImplant, isCrown, crownAllCeramicProvisionalSubType, crownMetalCeramicSubType, isNCCLs]);

    const crownFill = useMemo(() => {
        if (isCrown?.hasTooth && crownFullMetalSubType) {
            return isCrown?.color
        } else {
            return null
        }
    }, [isCrown, crownFullMetalSubType]);

    const getColor = useMemo(() => {
        if (isImplant.hasTooth) {
            return isImplant?.color
        } else if (isRootCanal?.hasTooth) {
            return isRootCanal?.color
        } else if (isCrown?.hasTooth && crownMetalCeramicSubType) {
            return isCrown?.color
        } else if (isSct?.hasTooth) {
            return isSct?.color
        }
        if (isPeriapicalAbscess.hasTooth) {
            return isPeriapicalAbscess?.color
        } else {
            return null
        }
    }, [isImplant, isRootCanal, isCrown, crownMetalCeramicSubType, isSct, isPeriapicalAbscess]);

    const getSubType = useMemo(() => {
        if (isImplant.hasTooth) {
            return (isImplant?.implantSubType ? "Fixture and Crown" : 'Fixture')
        } else if (isRootCanal?.hasTooth) {
            return (isRootCanal?.rootCanalSubType ? 'Symptomatic' : 'Asymptomatic')
        } else if (isCrown?.hasTooth && crownMetalCeramicSubType) {
            return isCrown?.crownAllCeramicProvisionalSubType
        } else if (isPeriapicalAbscess?.hasTooth) {
            return isPeriapicalAbscess?.periapicalAbscessSubType
        }
    }, [isImplant, isRootCanal, isCrown, crownMetalCeramicSubType, isPeriapicalAbscess]);

    const getFinding = useMemo(() => {
        if (isImplant.hasTooth) {
            return 'implant'
        } else if (isRootCanal?.hasTooth) {
            return "root_canal"
        } else if (isCrown?.hasTooth) {
            return "crown"
        } else if (isSct?.hasTooth) {
            return "sct"
        } else if (isPeriapicalAbscess?.hasTooth) {
            return "periapical_abscess"
        } else {
            return null
        }
    }, [isImplant, isRootCanal, isCrown, isSct, isPeriapicalAbscess]);

    const rootCanalStroke = useMemo(() => {
        if (isRootCanal?.hasTooth) {
            return isRootCanal?.color
        } else if (isPeriapicalAbscess?.hasTooth) {
            return isPeriapicalAbscess?.color
        } else {
            return null
        }
    }, [isRootCanal, isPeriapicalAbscess]);

    const getCrown = () => {
        let toothNumber = isBottomTeeth ? (tooth + 20) : tooth as any
        return getCrownSvg[`Crown${toothNumber}`](getFinding, getColor, crownFill, crownStroke, getSubType,)
    }
    const getRoot = () => {
        let toothNumber = isBottomTeeth ? (tooth + 20) : tooth as any
        return getRootSvg[`Root${toothNumber}`](getFinding, rootCanalStroke, getColor, getSubType)
    }

    useEffect(() => {
        getCrown()
        // eslint-disable-next-line
    }, [isCrown?.crownAllCeramicProvisionalSubType])

    return (
        <Box className={classes.odontogramTeeth + ' d-flex-all-center'}>
            {isWatch?.hasTooth &&
                <Box pb={0.5} className='pos-absolute' sx={{
                    top: isBottomTeeth ? '-9px' : '-5px',
                    transform: isBottomTeeth ? 'rotate(-180deg)' : ''
                }}>{watchIcon(isWatch?.color)}</Box>
            }
            <Box className={isImpactedTooth?.hasTooth ? classes.impactedTooth : ''} sx={{
                border: isImpactedTooth?.hasTooth ? `1px solid ${isImpactedTooth?.color}` : 'none'
            }}>
                {isOpenContact?.hasTooth &&
                    <OdontogramOpenContactFinding toothNumber={toothNumber} isOpenContact={isOpenContact}
                                                  isBottomTeeth={isBottomTeeth}/>}
                {/*Root*/}
                <Box
                    className={'d-flex-all-center w-100'}
                    sx={{
                        height: '46.28px !important',
                        maxHeight: '46.28px !important',
                        minHeight: '46.28px !important',
                        overflow: 'hidden !important',
                        alignItems: !isBottomTeeth ? 'flex-end !important' : 'flex-end !important'
                    }}
                >
                    {isImplant?.hasTooth ?
                        <div className={`d-flex align-flex-end`}
                             dangerouslySetInnerHTML={{__html: implantRoot(isImplant?.color)}}/>
                        : (<Box className='d-flex'
                                sx={{transform: isBottomTeeth ? 'rotate(-180deg)' : ''}}>{getRoot()}</Box>)
                    }
                    {isOpenMargin?.hasTooth && <OdontogramOpenMarginFinding isOpenMargin={isOpenMargin}/>}
                    {isOverhang?.hasTooth &&
                        <OdontogramOverHangFinding toothNumber={toothNumber} isOverhang={isOverhang}
                                                   isBottomTeeth={isBottomTeeth}/>}
                </Box>
                {/*Crown*/}
                <Box className='pos-relative'>
                    <Box className={'d-flex-all-center pos-relative'} sx={{
                        height: '32.5px !important',
                        maxHeight: '32.5px !important',
                        minHeight: '32.5px !important',
                        overflow: 'hidden !important',
                        alignItems: !isBottomTeeth ? 'flex-start !important' : 'flex-end !important'
                    }}>
                        <Box className={`d-flex align-flex-end`} sx={{
                            transform: isBottomTeeth ? 'rotate(-180deg)' : '',
                            alignSelf: isBottomTeeth ? 'flex-start' : '',
                        }}>
                            {isNCCLs?.hasTooth &&
                                <OdontogramNcclFinding isNCCLs={isNCCLs} isBottomTeeth={isBottomTeeth}/>}
                            {getCrown()}
                        </Box>
                    </Box>
                    {isNCCLs?.hasTooth && isNCCLs?.ncclTypes?.includes('Abfraction') &&
                        <Box className='pos-absolute' sx={{
                            top: isBottomTeeth ? '-7px' : '-11px',
                            left: isBottomTeeth ? 31 : 3,
                            transform: isBottomTeeth ? 'rotate(-180deg)' : ''
                        }}>
                            <img src={abfraction} alt="abfraction"/>
                        </Box>}
                </Box>
            </Box>
        </Box>
    );
};

export default OdontogramTeeth;
