import {patientListActions, PatientListInterface} from "../../types/dentist/patientsList";
import {
    GET_PATIENT_LIST_REQUEST,
    GET_PATIENT_LIST_REQUEST_FAILURE,
    GET_PATIENT_LIST_REQUEST_SUCCESS
} from "../../actionTypes/dentist/patientsList";
import {removedDuplicatesData} from "../../../utils/helper";

const initialState: PatientListInterface = {
    getPatientLoader: false,
    getPatientData: [],
    getPatientError: null,
    getPatientMeta: null
}
const patientListReducer = (state = initialState, action: patientListActions) => {
    switch (action.type) {

        case GET_PATIENT_LIST_REQUEST:
            return {
                ...state,
                getPatientLoader: true,
                getPatientError: null,
            }
        case GET_PATIENT_LIST_REQUEST_SUCCESS:
            return {
                ...state,
                getPatientLoader: false,
                getPatientData: removedDuplicatesData(state.getPatientData,action?.payload?.getPatientData,'id'),
                getPatientMeta: action.payload?.getPatientMeta,
                getPatientError: null,
            }
        case GET_PATIENT_LIST_REQUEST_FAILURE:
            return {
                ...state,
                getPatientLoader: false,
                getPatientData: [],
                getPatientError: action.payload.getPatientError,
            }
        default:
            return {
                ...state,
            }
    }
}
export default patientListReducer