import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import OdontogramFindingTooltip from '../OdontogramFindingTooltip';
import { FindingList } from "../../../../../../utils/constant/examConstant";

const useStyles = makeStyles((theme: any) => ({
  findingListWrapper: {
    width: '38px',
    height: '67vh',
    background: theme.palette.grey.GrayBg
  },
  findingTooltip: {
    position: 'absolute',
    width: '200px',
    left: 15,
    background: theme.palette.common.white,
    zIndex: 2
  }
}));

const OdontogramFindingList = () => {
  const classes = useStyles();
  return (
    <Box className={`${classes.findingListWrapper} d-flex align-center flex-column `}>
      {FindingList?.filter((f: any) => f?.group !== 'restro_defect')?.map((res: any, index: number) => (
          <OdontogramFindingTooltip findingInfo={res} key={index} />
        ))
      }
    </Box>
  );
};

export default OdontogramFindingList;
