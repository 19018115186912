import {
    COPY_EXAM_NOTES_REQUEST,
    COPY_EXAM_NOTES_REQUEST_FAILURE,
    COPY_EXAM_NOTES_REQUEST_SUCCESS,
    GET_INSURANCE_DATA_REQUEST,
    GET_INSURANCE_DATA_REQUEST_FAILURE,
    GET_INSURANCE_DATA_REQUEST_SUCCESS,
    GET_PROVIDER_DROPDOWN_REQUEST,
    GET_PROVIDER_DROPDOWN_REQUEST_FAILURE,
    GET_PROVIDER_DROPDOWN_REQUEST_SUCCESS,
    PUT_INSURANCE_DATA_REQUEST,
    PUT_INSURANCE_DATA_REQUEST_FAILURE,
    PUT_INSURANCE_DATA_REQUEST_SUCCESS,
    UPDATE_HYGIENE_REGIME_DATA,
    UPDATE_INSURANCE_TREATMENT_DATA,
    UPDATE_INSURANCE_VALIDATIONS,
    UPDATE_PREAUTH_CHECKLIST_DATA
} from '../../../actionTypes/dentist/clinicalExam/insurance';
import {insuranceInterface} from '../../../types/dentist/clinicalExam/insurance';

const initialState: insuranceInterface = {
    pending: false,
    data: null,
    error: '',
    putInsuranceError: null,
    dropdownPending: false,
    dropdownData: null,
    dropdownError: '',
    preauthChecklist: {
        pa: false,
        bw: false,
        panoramoic_xray: false,
        perio_charting: false,
        photos: false
    },
    copyNotesLoader: false,
    copyNotesData: null,
    copyNotesError: '',
    hygieneRegimeData: {},
    insuranceTreatmentData: [],
    errors: {
        insuranceInfo: {},
    }
};

const insuranceReducer = (state = initialState, action: any) => {
    switch (action?.type) {
        case UPDATE_PREAUTH_CHECKLIST_DATA:
            return {
                ...state,
                preauthChecklist: action?.payload
            };
        case GET_INSURANCE_DATA_REQUEST:
            return {
                ...state,
                pending: true,
                hygieneRegimeData: null,
                insuranceTreatmentData: null,
                preauthChecklist: null,
                referrals: null,
            };
        case GET_INSURANCE_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                hygieneRegimeData: action.payload.hygiene_regime,
                insuranceTreatmentData: action.payload.treatments,
                preauthChecklist: action.payload?.checklist,
                referrals: action.payload?.referrals,
                error: ''
            };
        case GET_INSURANCE_DATA_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action?.payload
            };
        case GET_PROVIDER_DROPDOWN_REQUEST:
            return {
                ...state,
                dropdownPending: true,
                dropdownData: null,
                dropdownError: ''
            };
        case GET_PROVIDER_DROPDOWN_REQUEST_SUCCESS:
            return {
                ...state,
                dropdownPending: false,
                dropdownData: action?.payload,
                dropdownError: ''
            };
        case GET_PROVIDER_DROPDOWN_REQUEST_FAILURE:
            return {
                ...state,
                dropdownPending: false,
                dropdownData: null,
                dropdownError: action?.payload
            };
        case PUT_INSURANCE_DATA_REQUEST:
            return {
                ...state,
                pending: true
            };
        case PUT_INSURANCE_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action?.payload,
                putInsuranceError: ''
            };
        case PUT_INSURANCE_DATA_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                putInsuranceError: action?.payload
            };
        case COPY_EXAM_NOTES_REQUEST:
            return {
                ...state,
                copyNotesLoader: true,
                copyNotesData: null,
                copyNotesError: ''
            };
        case COPY_EXAM_NOTES_REQUEST_SUCCESS:
            return {
                ...state,
                copyNotesLoader: false,
                copyNotesData: action?.payload,
                copyNotesError: ''
            };
        case COPY_EXAM_NOTES_REQUEST_FAILURE:
            return {
                ...state,
                copyNotesLoader: false,
                copyNotesData: null,
                copyNotesError: action?.payload
            };
        case UPDATE_HYGIENE_REGIME_DATA:
            return {
                ...state,
                hygieneRegimeData: action?.payload
            };
        case UPDATE_INSURANCE_TREATMENT_DATA:
            return {
                ...state,
                insuranceTreatmentData: action?.payload
            };
        case UPDATE_INSURANCE_VALIDATIONS:
            return {
                ...state,
                errors: action.payload
            };
        default:
            return {
                ...state
            };
    }
};

export default insuranceReducer;
