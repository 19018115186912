import { UPDATE_CHAT_BOX_VALUE } from "../../actionTypes/master/chatBox";

const initialState: any = {
  chatBoxValue: true,
  keyName: "",
};

const chatBoxReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CHAT_BOX_VALUE:
      return {
        ...state,
        chatBoxValue: action?.payload?.chatBoxValue,
        keyName: action?.payload?.keyName,
      };
    default:
      return {
        ...state,
      };
  }
};

export default chatBoxReducer;
