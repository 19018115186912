import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleUp} from "@fortawesome/free-solid-svg-icons";
import InputHeading from "../../../common/UIComponents/InputHeading";

const useStyles = makeStyles((theme: any) => ({
    accordionRoot: {
        marginBottom: '10px',
        "&:before": {
            backgroundColor: "transparent !important",
        },
    },
    accordionSummary: {
        height: "50px",
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.common.borderGrey}`,
        padding: "0px 12px !important",
        margin: 0,
    },
    accordionDetails: {
        backgroundColor: theme.palette.common.white,
        borderRadius: "0px 0px 8px 8px",
        borderLeft: `1px solid ${theme.palette.common.borderGrey}`,
        borderRight: `1px solid ${theme.palette.common.borderGrey}`,
        borderBottom: `1px solid ${theme.palette.common.borderGrey}`,
        padding: "12px",
    },
    expanded: (titleLength: any) => ({
        "&$expanded": {
            minHeight: 43,
            maxHeight: 43,
            [theme.breakpoints.down(996)]: {
                minHeight: titleLength > 40 ? 60 : 43,
                maxHeight: titleLength > 40 ? 60 : 43,
            },
            [theme.breakpoints.down(866)]: {
                minHeight: 43,
                maxHeight: 43,
            },
            [theme.breakpoints.down(523)]: {
                minHeight: titleLength > 40 ? 60 : 43,
                maxHeight: titleLength > 40 ? 60 : 43,
            },
        },
    }),
    borderRadiusExpanded: {
        margin: 0,
        borderRadius: "8px 8px 0px 0px !important",
    },
    borderRadiusAccordionSummary: {
        borderRadius: "8px !important",
    },
    accordionDetailsBorderExpanded: {
        border: "none !important",
    },
}));

type Props = {
    children: React.ReactNode;
    title: string;
}
const MedicalAlertsCollapsibleBox = (props: Props) => {
    const {title, children} = props;
    const classes = useStyles(title?.length);
    const [expanded, setExpanded] = React.useState<boolean>(false) as any;

    return (
        <Accordion
            className={classes.accordionRoot}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary
                className={`${classes.accordionSummary} ${expanded
                    ? classes.borderRadiusExpanded
                    : classes.borderRadiusAccordionSummary
                }  ${classes.expanded}`}
                expandIcon={<FontAwesomeIcon icon={faAngleUp} className={`f-16`}/>}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <InputHeading
                    titleProps={{sx: {margin: "12px 0px 12px 0px", whiteSpace: {xs: 'break-spaces',}}}}
                    title={title}
                />
            </AccordionSummary>
            <AccordionDetails
                className={`${!expanded && classes.accordionDetailsBorderExpanded} ${classes.accordionDetails}`}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default MedicalAlertsCollapsibleBox