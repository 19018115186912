import {
    MobileVerificationActions,
    MobileVerificationInterface
} from "../../types/common/mobileVerificarionActionTypes";
import {
    SEND_OTP_REQUEST,
    SEND_OTP_REQUEST_FAILURE, SEND_OTP_REQUEST_SUCCESS,
    UPDATE_OTP,
    UPDATE_OTP_VALIDATION, VERIFY_OTP_REQUEST, VERIFY_OTP_REQUEST_FAILURE, VERIFY_OTP_REQUEST_SUCCESS
} from "../../actionTypes/common/mobileVerification";


const initialState: MobileVerificationInterface = {
    otp: null,
    otpError: {},
    pending: false,
};
const mobileVerificationReducer = (state = initialState, action: MobileVerificationActions) => {
    switch (action.type) {
        case UPDATE_OTP:
            return {
                ...state,
                otp: action.payload,
            };
        case UPDATE_OTP_VALIDATION:
            return {
                ...state,
                otpError: action.payload,
            };
        case VERIFY_OTP_REQUEST:
            return {
                ...state,
                pending: true
            };

        case VERIFY_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case VERIFY_OTP_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
            };
        case SEND_OTP_REQUEST:
            return {
                ...state,
                pending: true
            };

        case SEND_OTP_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,

            };
        case SEND_OTP_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
            };
        default:
            return {
                ...state
            };
    }
};
export default mobileVerificationReducer;
