import React from 'react';
import {useSelector} from "react-redux";
import {makeStyles} from "@mui/styles";
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {
    outstandingTreatmentsColumns,
} from "../../../../../utils/constant/wellnessProfileConstant";
import theme from "../../../../../theme";
import {RootState} from "../../../../../redux/reducers/rootReducer";
import NoDataFound from "../../../../common/NoDataFound";

const useStyles = makeStyles((theme: any) => ({
    table: {
        borderCollapse: "separate",
        boxShadow: "none",
        "& .MuiGrid-root": {
            backgroundColor: theme.palette.background.secondaryPaper,
        },
    },
    thead: {
        backgroundColor: theme.palette.primary.greenLight,
        color: theme.palette.common.black50,
        height: '50px',
        border: '1px solid red'
    },
    th: {
        padding: "13px 16px",
        fontSize: "13px",
        lineHeight: "150%",
        color: theme.palette.common.black50,
        overflowX: "auto",
        whiteSpace: "nowrap",
        borderBottom: `1px solid ${theme.palette.v2.primary.main}`
    },
    tr: {
        background: theme.palette.common.white,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    td: {
        height: `40px !important`,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

const WellnessOutstandingTreatments = () => {
    const classes = useStyles()
    const {palette} = theme
    const { plannedTreatmentReducer: {treatmentFeesData} } = useSelector((state: RootState) => state);

    return (
        <React.Fragment>
            {treatmentFeesData?.length > 0 ?
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label='simple table' classes={{root: classes.table}}>
                        <TableHead classes={{root: classes.thead}}>
                            <TableRow>
                                {outstandingTreatmentsColumns?.map((column: any, i: number) => <TableCell
                                    key={i}
                                    className="fw-regular"
                                    classes={{root: classes.th}}
                                    align={'left'}
                                    sx={{minWidth: column.minWidth, maxWidth: column.maxWidth, width: column.width,}}
                                >
                                    <Box
                                        sx={{
                                            borderLeft: `${i === 0 && 'none !important'}`,
                                            paddingLeft: `${i === 0 && '0px !important'}`
                                        }}>
                                        {column.label}
                                    </Box>
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{backgroundColor: palette.common.white}}>
                            {treatmentFeesData?.map((row: any, index: number) =>
                                <TableRow key={index} classes={{root: classes.tr}}>
                                    <TableCell sx={{minWidth: 100, maxWidth: 150}} classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor'
                                               align='left'>
                                        <Typography
                                            className='fw-regular f-w-400'>
                                            {index + 1}
                                        </Typography>

                                    </TableCell>
                                    <TableCell
                                        sx={{minWidth: 400, maxWidth: 500, width: '100%'}}
                                        classes={{root: classes.td}} align='left'>
                                        <Typography textTransform={'capitalize'}
                                                    className={`f-14 fw-regular f-w-400 labelColor`}>
                                            {row?.tooth_number && row?.description
                                                ? `${row?.tooth_number} - ${row?.description}` // Display with `-` when both exist
                                                : row?.tooth_number || row?.description || '-' // Display one if only one exists
                                            }                                        </Typography>

                                    </TableCell>
                                    <TableCell sx={{minWidth: 200, maxWidth: 300,}}
                                               classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                        <Typography
                                            className='fw-regular f-w-400'>{row?.procedure_code || '-'}</Typography>
                                    </TableCell>
                                    <TableCell sx={{minWidth: 200, maxWidth: 300,}} classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                        <Typography className='fw-regular f-w-400'>
                                            {row?.fee_amount !== null ? `$ ${row?.fee_amount}` : '-'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{minWidth: 200, maxWidth: 300,}} classes={{root: classes.td}}
                                               className='f-14 fw-regular f-w-400 labelColor' align='left'>
                                        <Typography className='fw-regular f-w-400'>
                                            {row?.pms_entry_datetime || '-'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer> :
                <Box py={2}>
                    <NoDataFound errorMessage={'Outstanding Treatment'}/>
                </Box>
            }
        </React.Fragment>
    )
}

export default WellnessOutstandingTreatments;