export const SET_EXAM_FINDINGS = 'SET_EXAM_FINDINGS';
export const ADD_EXISTING_FINDING = 'ADD_EXISTING_FINDING';
export const CHECKED_EXISTING_FINDING = 'CHECKED_EXISTING_FINDING';

export const EXAM_GROUP_FINDING_DATA_COUNT = 'EXAM_GROUP_FINDING_DATA_COUNT';

export const GET_SOFT_TISSUE_REQUEST = 'GET_SOFT_TISSUE_REQUEST';
export const PUT_SOFT_TISSUE_REQUEST = 'PUT_SOFT_TISSUE_REQUEST';
export const GET_HARD_TISSUE_REQUEST = 'GET_HARD_TISSUE_REQUEST';
export const PUT_HARD_TISSUE_REQUEST = 'PUT_HARD_TISSUE_REQUEST';
export const GET_ODONTOGRAM_REQUEST = 'GET_ODONTOGRAM_REQUEST';
export const PUT_ODONTOGRAM_REQUEST = 'PUT_ODONTOGRAM_REQUEST';
export const GET_PERIOGRAM_REQUEST = 'GET_PERIOGRAM_REQUEST';
export const PUT_PERIOGRAM_REQUEST = 'PUT_PERIOGRAM_REQUEST';
export const GET_OTHER_FORM_FINDING_REQUEST = 'GET_OTHER_FORM_FINDING_REQUEST';
export const PUT_OTHER_FORM_FINDING_REQUEST = 'PUT_OTHER_FORM_FINDING_REQUEST';
export const UPDATE_EXAM_API_DATA = 'UPDATE_EXAM_API_DATA';
export const SUCCESS_EXAM_API_DATA = 'SUCCESS_EXAM_API_DATA';
export const FAILURE_EXAM_API_DATA = 'FAILURE_EXAM_API_DATA';
export const FAILURE_PUT_EXAM_API_DATA = 'FAILURE_PUT_EXAM_API_DATA';
export const SET_EXAM_DATA_LOADER = 'SET_EXAM_DATA_LOADER';

export const SYNC_PERIOGRAM_PMS_DATA_REQUEST = 'SYNC_PERIOGRAM_PMS_DATA_REQUEST';
export const SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS = 'SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS';
export const SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE = 'SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE';

export const SYNC_ODONTOGRAM_PMS_DATA_REQUEST = 'SYNC_ODONTOGRAM_PMS_DATA_REQUEST';
export const SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS = 'SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS';
export const SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE = 'SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE';

export const GET_LIMITED_EXAM_REQUEST = 'GET_LIMITED_EXAM_REQUEST';
export const PUT_LIMITED_EXAM_REQUEST = 'PUT_LIMITED_EXAM_REQUEST';



export const GET_ODONTOGRAM_OTHER_FINDINGS_REQUEST='GET_ODONTOGRAM_OTHER_FINDINGS_REQUEST';
export const GET_ODONTOGRAM_OTHER_FINDINGS_SUCCESS_REQUEST='GET_ODONTOGRAM_OTHER_FINDINGS_SUCCESS_REQUEST';
export const GET_ODONTOGRAM_OTHER_FINDINGS_FAILURE_REQUEST='GET_ODONTOGRAM_OTHER_FINDINGS_FAILURE_REQUEST';

export const UPDATED_ODONTOGRAM_OTHER_FINDING_DATA='UPDATE_ODONTOGRAM_OTHER_FINDING_DATA';

export const GET_EXAM_REQUEST = 'GET_EXAM_REQUEST';
export const PUT_EXAM_REQUEST = 'PUT_EXAM_REQUEST';