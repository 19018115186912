import {useMemo} from 'react';
import {useSelector} from "react-redux";
import {examConstantInterface, findingData} from "../../../../../../utils/constant/examConstant";
import {procedureColors} from "../../../../../../utils/procedureHelper";

const useOdontogramFinding = (toothNumber: any, isBottomTeeth: any) => {
    const {
        examFindingReducer: {examFindingData,},
        odontogram: {procedureFilters, showRejectedTreatmentsOnly, cameOdontogramFromTreatment},
        clinicalExamFilters: {riskFilters}
    } = useSelector((state: any) => state) as any;

    const riskWiseFilteredData = useMemo(() => {
         /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskFilters?.length
            ? (findingData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData
        // eslint-disable-next-line
    }, [riskFilters, riskFilters?.length, findingData]);

    const displayOnlyPlannedFindingForTreatment = (procedureStatus: any) => {
        return cameOdontogramFromTreatment ? procedureStatus === 'Planned' : true
    }

    const isImplant = useMemo(() => {
        let hasTooth = false;
        let implantSubType = null;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'implant') || {} as any;
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData.examGroup]?.['implant'];
        if (updatedFindingData?.length === 1) {
            const implantData = updatedFindingData[0];
            const isTooth = implantData['tooth_number'];
            const isSubType = implantData['subtype'];
            const procedure_status = implantData['procedure_status'];
            const isRejected = implantData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                implantSubType = isSubType !== 'Fixture';
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData.forEach((implantData: any) => {
                const isTooth = implantData['tooth_number'];
                const isSubType = implantData['subtype'];
                const procedure_status = implantData['procedure_status'];
                const isRejected = implantData['rejected'];
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    implantSubType = isSubType !== 'Fixture';
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            implantSubType,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);
    const isRootCanal = useMemo(() => {
        let hasTooth = false;
        let rootCanalSubType = null;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'root_canal') || {} as any;
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['root_canal'];

        if (updatedFindingData?.length === 1) {
            const rootCanalData = updatedFindingData[0];
            const isTooth = rootCanalData['tooth_number'];
            const isSubType = rootCanalData['subtype'];
            const procedure_status = rootCanalData['procedure_status'];
            const isRejected = rootCanalData['rejected'];

            if (
                // eslint-disable-next-line
                (displayOnlyPlannedFindingForTreatment(procedure_status) && procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                rootCanalSubType = isSubType === 'Symptomatic';
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData.forEach((rootCanalData: any) => {
                const isTooth = rootCanalData['tooth_number'];
                const isSubType = rootCanalData['subtype'];
                const procedure_status = rootCanalData['procedure_status'];
                const isRejected = rootCanalData['rejected'];

                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    rootCanalSubType = isSubType === 'Symptomatic';
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            rootCanalSubType,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isWatch = useMemo(() => {
        let hasTooth = false;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'watch') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['watch'];

        if (updatedFindingData?.length === 1) {
            const watchData = updatedFindingData[0];
            const isTooth = watchData['tooth_number'];
            const procedure_status = watchData['procedure_status'];
            const isRejected = watchData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`)
            ) {
                hasTooth = true;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData.forEach((watchData: any) => {
                const isTooth = watchData['tooth_number'];
                const procedure_status = watchData['procedure_status'];
                const isRejected = watchData['rejected'];
                if (
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) && displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`)
                ) {
                    hasTooth = true;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isCrown = useMemo(() => {
        let hasTooth = false;
        let crownAllCeramicProvisionalSubType = null;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'crown') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['crown'];

        if (updatedFindingData?.length === 1) {
            const crownData = updatedFindingData[0];
            const isTooth = crownData['tooth_number'];
            const isSubType = crownData['subtype'];
            const procedure_status = crownData['procedure_status'];
            const isRejected = crownData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                crownAllCeramicProvisionalSubType = isSubType;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((crownData: any) => {
                const isTooth = crownData['tooth_number'];
                const isSubType = crownData['subtype'];
                const procedure_status = crownData['procedure_status'];
                const isRejected = crownData['rejected'];
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    crownAllCeramicProvisionalSubType = isSubType;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            crownAllCeramicProvisionalSubType,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isSct = useMemo(() => {
        let hasTooth = false;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'sct') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['sct'];

        if (updatedFindingData?.length === 1) {
            const sctData = updatedFindingData[0];
            const isTooth = sctData['tooth_number'];
            const procedure_status = sctData['procedure_status'];
            const isRejected = sctData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`)
            ) {
                hasTooth = true;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((sctData: any) => {
                const isTooth = sctData['tooth_number'];
                const procedure_status = sctData['procedure_status'];
                const isRejected = sctData['rejected'];
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`)
                ) {
                    hasTooth = true;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isImpactedTooth = useMemo(() => {
        let hasTooth = false;
        let impactedToothSubType = null;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'impacted_tooth') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['impacted_tooth'];

        if (updatedFindingData?.length === 1) {
            const impactedToothData = updatedFindingData[0];
            const isTooth = impactedToothData['tooth_number'];
            const isSubType = impactedToothData['subtype'];
            const procedure_status = impactedToothData['procedure_status'];
            const isRejected = impactedToothData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                impactedToothSubType = !!isSubType;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((impactedToothData: any) => {
                const isTooth = impactedToothData['tooth_number'];
                const isSubType = impactedToothData['subtype'];
                const procedure_status = impactedToothData['procedure_status'];
                const isRejected = impactedToothData['rejected'];
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    impactedToothSubType = !!isSubType;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            impactedToothSubType,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isPeriapicalAbscess = useMemo(() => {
        let hasTooth = false;
        let periapicalAbscessSubType = null;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'periapical_abscess') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['periapical_abscess'];

        if (updatedFindingData?.length === 1) {
            const periapicalAbscessData = updatedFindingData[0];
            const isTooth = periapicalAbscessData['tooth_number'];
            const isSubType = periapicalAbscessData['subtype'];
            const procedure_status = periapicalAbscessData['procedure_status'];
            const isRejected = periapicalAbscessData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`) &&
                isSubType
            ) {
                hasTooth = true;
                periapicalAbscessSubType = !!isSubType;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((periapicalAbscessData: any) => {
                const isTooth = periapicalAbscessData['tooth_number'];
                const isSubType = periapicalAbscessData['subtype'];
                const procedure_status = periapicalAbscessData['procedure_status'];
                const isRejected = periapicalAbscessData['rejected'];
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    periapicalAbscessSubType = !!isSubType;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }

        return {
            hasTooth,
            periapicalAbscessSubType,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isNCCLs = useMemo(() => {
        let hasTooth = false;
        let color = null;
        let ncclTypes = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'non_carious_cervical_lesions') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData.examGroup]?.['non_carious_cervical_lesions'];
        if (updatedFindingData?.length === 1) {
            const ncclTypesOption = updatedFindingData[0];
            const isTooth = ncclTypesOption['tooth_number'];
            const procedure_status = ncclTypesOption['procedure_status'];
            const isRejected = ncclTypesOption['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`)
            ) {
                hasTooth = true;
                ncclTypes = ncclTypesOption['ncclTypes']
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData.forEach((ncclTypesOption: any) => {
                const isTooth = ncclTypesOption['tooth_number'];
                const procedure_status = ncclTypesOption['procedure_status'];
                const isRejected = ncclTypesOption['rejected'];

                if (displayOnlyPlannedFindingForTreatment(procedure_status) && (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) && (!showRejectedTreatmentsOnly
                        || isRejected) &&
                    isTooth?.includes(`${toothNumber}`)
                ) {
                    hasTooth = true;
                    ncclTypes = ncclTypesOption['ncclTypes']
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            color,
            ncclTypes,
        };
        // eslint-disable-next-line
    }, [isBottomTeeth, toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isOpenMargin = useMemo(() => {
        let hasTooth = false;
        let color = null;
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'open_margin') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['open_margin'];
        if (updatedFindingData?.length === 1) {
            const openMarginData = updatedFindingData[0];
            const isTooth = openMarginData['tooth_number'];
            const procedure_status = openMarginData['procedure_status'];
            const isRejected = openMarginData['rejected'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`)
            ) {
                hasTooth = true;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((openMarginData: any) => {
                const isTooth = openMarginData['tooth_number'];
                const procedure_status = openMarginData['procedure_status'];
                const isRejected = openMarginData['rejected'];
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`)
                ) {
                    hasTooth = true;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            color,
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);
    const isOverhang = useMemo(() => {
        let hasTooth = false;
        let color = null;
        let toothSurface = null
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'overhang') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['overhang'];
        if (updatedFindingData?.length === 1) {
            const overhangData = updatedFindingData[0];
            const isTooth = overhangData['tooth_number'];
            const procedure_status = overhangData['procedure_status'];
            const isRejected = overhangData['rejected'];
            const isToothSurface = overhangData['tooth_surface']
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`)
            ) {
                hasTooth = true;
                color = procedureColors[procedure_status]?.color;
                toothSurface = isToothSurface
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((overhangData: any) => {
                const isTooth = overhangData['tooth_number'];
                const procedure_status = overhangData['procedure_status'];
                const isRejected = overhangData['rejected'];
                const isToothSurface = overhangData['tooth_surface']
                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`)
                ) {
                    hasTooth = true;
                    color = procedureColors[procedure_status]?.color;
                    toothSurface = isToothSurface
                }
            });
        }
        return {
            hasTooth,
            color,
            toothSurface
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    const isOpenContact = useMemo(() => {
        let hasTooth = false;
        let color = null;
        let toothSite = null
        const currentFindingData = riskWiseFilteredData?.find((f: any) => f?.key === 'open_contact') || {} as any
        const updatedFindingData = currentFindingData && examFindingData?.[currentFindingData?.examGroup]?.['open_contact'];
        if (updatedFindingData?.length === 1) {
            const openContactData = updatedFindingData[0];
            const isTooth = openContactData['tooth_number'];
            const procedure_status = openContactData['procedure_status'];
            const isRejected = openContactData['rejected'];
            const isToothSite = openContactData['tooth_site'];
            if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${toothNumber}`)
            ) {
                hasTooth = true;
                color = procedureColors[procedure_status]?.color;
                toothSite = isToothSite
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((openContactData: any) => {
                const isTooth = openContactData['tooth_number'];
                const procedure_status = openContactData['procedure_status'];
                const isRejected = openContactData['rejected'];
                const isToothSite = openContactData['tooth_site'];

                if (displayOnlyPlannedFindingForTreatment(procedure_status) &&
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${toothNumber}`)
                ) {
                    hasTooth = true;
                    color = procedureColors[procedure_status]?.color;
                    toothSite = isToothSite
                }
            });
        }
        return {
            hasTooth,
            color,
            toothSite
        };
        // eslint-disable-next-line
    }, [toothNumber, examFindingData, procedureFilters, showRejectedTreatmentsOnly, riskWiseFilteredData]);

    return {
        isImplant,
        isRootCanal,
        isWatch,
        isCrown,
        isSct,
        isImpactedTooth,
        isPeriapicalAbscess,
        isNCCLs,
        isOpenMargin,
        isOverhang,
        isOpenContact
    }
}
export default useOdontogramFinding