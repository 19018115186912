import React from 'react';
import {Box, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles"
import {convertValueToRoman} from "../../../../../../../../utils/helper";

const useStyles = makeStyles(() => ({
    isMobilityIconContainer: {
        top: -3,
        right: -2,
        position: 'absolute'
    },
}));

interface Props {
    isFindingPresent: (position: string, type: string) => number;
    lingual?: boolean;
}

const Mobility = React.memo((props: Props) => {
    const {isFindingPresent, lingual} = props
    const classes = useStyles();

    const mobilityValue = isFindingPresent('', 'mobility');

    if (!mobilityValue || lingual) return null; // Only render if there's a mobility value and it's not lingual

    return (
        <Box className={classes.isMobilityIconContainer}>
            <Typography sx={{fontFamily: 'Iowan Old Style !important'}} className='f-16'>
                {convertValueToRoman(`${mobilityValue}`)}
            </Typography>
        </Box>
    );
})
export default Mobility;
