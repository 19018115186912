import {
    LOGIN_REQUEST, LOGIN_REQUEST_FAILURE, LOGIN_REQUEST_SUCCESS, UPDATE_DEVICE_TOKEN,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_REQUEST_SUCCESS,
    GOOGLE_LOGIN_REQUEST_FAILURE
} from '../../actionTypes/auth';
import {loginInterface, loginActions} from '../../types/authActionTypes/loginActionTypes';

const initialState: loginInterface = {
    loginLoader: false,
    deviceToken: null,
    googleLoginLoader: false,
    googleLoginData: null,
    googleLoginError: null

};

const loginReducer = (state = initialState, action: loginActions) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loginLoader: true,
            };
        case LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                loginLoader: false,
            };
        case LOGIN_REQUEST_FAILURE:
            return {
                ...state,
                loginLoader: false,
            };
        case UPDATE_DEVICE_TOKEN:
            return {
                ...state,
                deviceToken: action.payload
            };
        case GOOGLE_LOGIN_REQUEST:
            return {
                ...state,
                googleLoginLoader: true,
                googleLoginData: null,
                googleLoginError: null
            };
        case GOOGLE_LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                googleLoginLoader: false,
                googleLoginData: action.payload,
                googleLoginError: null
            };
        case GOOGLE_LOGIN_REQUEST_FAILURE:
            return {
                ...state,
                googleLoginLoader: false,
                googleLoginData: null,
                googleLoginError: action?.payload?.loginError
            };
        default:
            return {
                ...state
            };
    }
};
export default loginReducer
