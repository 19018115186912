import {call, put, takeEvery, all} from 'redux-saga/effects';
import API from '../../../utils/api';
import {ApiEndpointVersion, checkAPIParams, errorToaster} from '../../../utils/helper';
import {
    getResourceListTypeSuccessAction,
    getResourceListTypeFailureAction,
    getResourceListCacheDataClearRequest
} from '../../actions/dentist/resourceAction';
import {GET_RESOURCE_DATA_REQUEST} from '../../actionTypes/dentist/resource';

// get resources data
function* getResourcesTypeRequest(payload: any): any {
    yield put(getResourceListCacheDataClearRequest())
    try {
        const params = [
            {key: 'portal', value: payload?.payload?.portalName},
        ]
        let url = `${ApiEndpointVersion}/resources${checkAPIParams(params)}`
        const response = yield API.get(url)

        yield put(
            getResourceListTypeSuccessAction({
                resourceData: response?.data?.detail,
                resourceMeta: response?.data?.meta,
            })
        )

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getResourceListTypeFailureAction({
                resourceError: e?.meta?.message,
            })
        )
    }
}

function* getResourcesSaga() {
    yield takeEvery(GET_RESOURCE_DATA_REQUEST, getResourcesTypeRequest)
}

export default function* resourcesSaga() {
    yield all([
        getResourcesSaga()
    ])
}
