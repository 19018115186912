import {
    STAFF_NEW_PROFILE_EDIT_REQUEST,
    STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE,
    STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS,
    STAFF_NEW_PROFILE_REQUEST,
    STAFF_NEW_PROFILE_REQUEST_FAILURE,
    STAFF_NEW_PROFILE_REQUEST_SUCCESS
} from '../../actionTypes/dentist/profileDentist';

import {StaffProfileNewActions, staffProfileNewInterface} from '../../types/dentist/dentistProfile';

const initialState: staffProfileNewInterface = {
    pending: false,
    data: null,
    error: null,
    editProfileLoader: false
};

const staffProfileNewReducer = (state = initialState, action: StaffProfileNewActions) => {
    switch (action.type) {
        case STAFF_NEW_PROFILE_REQUEST:
            return {
                ...state,
                pending: true,
                data:null
            };

        case STAFF_NEW_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };

        case STAFF_NEW_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload
            };
        case STAFF_NEW_PROFILE_EDIT_REQUEST:
            return {
                ...state,
                editProfileLoader: true
            };
        case STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS:
            return {
                ...state,
                editProfileLoader: false,

            };
        case STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE:
            return {
                ...state,
                editProfileLoader: false,

            };
        default:
            return {
                ...state
            };
    }
};
export default staffProfileNewReducer;
