import React from "react";
import {InputAdornment, InputLabel, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import theme from "../../../../theme";
import {AvoidSpace, AvoidSpaceForPassword} from "../../../../utils/helper";
import RequiredStar from "../../RequiredStar";

export const textLengthStyles = makeStyles(() => ({
    textLengthCount: {
        marginRight: '-8px',
        alignItems: 'flex-start',
        fontFamily: "FavoritPro-Regular !important",
        fontSize: '12px',
    },
    multilineTextLengthCount: {
        alignItems: 'flex-start',
        fontFamily: "FavoritPro-Regular !important",
        fontSize: '12px',
        marginRight: '8px',
        marginBottom: '-24px',
    }
}));

type Props = {
    label?: any;
    labelProps?: any;
    inputProps?: any;
    required?: boolean;
    disabled?: boolean;
    placeholder?: any;
    maxLength?: any;
    type?: string;
    inputMode?: any;
    pattern?: any;
    sx?: any;
    inputBackColor?: string;
    resize?: any
    resizeVerticalScreen?: any
    labelSx?: any
    autocomplete?: string
    examFromChart?: boolean
    isPadding?: boolean
    textCapital?: boolean
    isPatient?: boolean
};

const TextBox = (props: Props) => {
    const {
        label,
        labelProps,
        inputProps,
        required,
        disabled,
        placeholder,
        maxLength,
        inputMode,
        pattern,
        sx,
        inputBackColor,
        resize,
        resizeVerticalScreen,
        autocomplete,
        labelSx,
        examFromChart,
        isPadding,
        textCapital,
        isPatient
    } = props as Props;
    const lengthClasses = textLengthStyles()
    const transformedText = (text: string) => {
        const transformedText:string = text?.replace(/(?!mm)\b\w+/g, (match:string) =>
            match?.charAt(0)?.toUpperCase() + match?.slice(1)
        );
        const otherTransformedText:string = text?.charAt(0)?.toUpperCase() + text?.slice(1)
        return text?.includes('mm') ? transformedText : otherTransformedText
    }

    return (
        <React.Fragment>
            {label && (
                examFromChart ?
                    <Typography color={theme.palette.v2.primary.main
                    } className='f-w-500 fw-medium f-14 lh-16' mb={1}>
                        {transformedText(label)} {required && <RequiredStar/>}
                    </Typography> :
                    <InputLabel
                        htmlFor={label}
                        sx={{
                            color: theme.palette.common.black50,
                            mb: "8px", whiteSpace: {
                                xs: 'break-spaces',
                                sm: 'nowrap'
                            },
                            ...labelSx
                        }}
                        className={`labelColor fw-regular f-14 lh-19 f-w-350`}
                        {...labelProps}
                    >
                        {transformedText(label)} {required && <RequiredStar isPatient={isPatient}/>}
                    </InputLabel>

            )}
            <TextField
                disabled={disabled}
                placeholder={placeholder}
                autoComplete={autocomplete}
                onKeyPress={(e: any) => {
                    if (
                        inputProps.name === "current_pwd" ||
                        inputProps.name === "new_pwd" ||
                        inputProps.name === "confirm_pwd"
                    ) {
                        AvoidSpaceForPassword(e);
                    }
                    AvoidSpace(e);
                }}
                InputProps={{
                    classes: {
                        root: `border-radius-10 ${inputProps?.multiline && maxLength ? 'textFieldEndIconPadding textFieldStartIconPadding labelColor bg-white' : ''} `,
                        input: inputProps?.multiline ? maxLength ? 'textFieldMultilineClass' : 'textFieldMultiline' : 'textField',
                    },
                    endAdornment: maxLength ? (
                        <InputAdornment position="end"
                                        className={inputProps?.multiline ? lengthClasses.multilineTextLengthCount : lengthClasses.textLengthCount}>
                            {inputProps?.value?.length || 0} / {maxLength}
                        </InputAdornment>
                    ) : null
                }}

                inputProps={{
                    maxLength: maxLength,
                    inputMode: inputMode,
                    pattern: pattern,

                }}
                {...inputProps}
                sx={{
                    input: {
                        textTransform: textCapital ? 'capitalize' : 'initial',
                        backgroundColor: inputBackColor,
                        color: `${theme.palette.common.black50} !important`, // TODO: inner text color -->> #3333333
                        "&::placeholder": {
                            color: theme.palette.text.darkGrey, //  TODO: placeholder color -- >> #666666
                            opacity: 1,
                        },
                    },
                    "& .Mui-disabled": {
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                border: `1px solid ${theme.palette.text.secondary} !important`,
                            },
                        },
                        color: `${theme.palette.text.secondary} !important`,
                        opacity: 1,
                    },
                    ".Mui-error ": {
                        fontSize: "12px",
                        lineHeight: "14px",
                    },
                    ".MuiFormHelperText-root": {
                        marginLeft: "10px",
                        marginTop: "5px",
                    },
                    '& .MuiOutlinedInput-input': {
                        // resize: resize ? resizeVerticalScreen ? 'vertical' : 'both' : '',
                        minWidth: (resize && inputProps?.multiline) ? resizeVerticalScreen ? '0px' : '600px' : '',
                        padding: (inputProps.type === 'number') && "15px 10px 15px 16px",
                    },
                    //Pass isPadding={true} as props where there are text field UI or height issues.
                    "& .MuiOutlinedInput-root": {
                        color: `${theme.palette.common.black50} !important`,
                        minHeight: resizeVerticalScreen && '50px',
                        padding: isPadding ? '5px 10px 5px 10px !important' : (inputProps?.multiline || resizeVerticalScreen) && "15px 10px 15px 16px",
                        // overflowY: inputProps?.multiline && 'hidden !important',
                        height: isPadding && '50px !important',
                        fontFamily: "FavoritPro-Regular !important",
                        background: "white !important",
                        "& fieldset": {
                            borderColor: isPatient ? theme.palette.secondary.main : theme.palette.text.darkGrey, //  TODO: base border color -- >> #666666
                        },
                        "&:hover fieldset": {
                            borderColor: disabled ? "none" : isPatient ? theme.palette.secondary.main : theme.palette.common.black50, // hover color -- >> TODO: #333333
                        },
                        "&.Mui-focused fieldset": {
                            border: `2px solid ${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main} !important`,
                        },
                    },
                    ...sx,
                }}

            />
        </React.Fragment>
    );
};

export default TextBox;
