import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import PatientPreferences from './PatientPreferences';
import SocialNotes from './SocialNotes';
import ChiefComplaints from "./ChiefComplaints";
import AddPatientPrefAndSocialNotesPopUp from './AddPatientPrefAndSocialNotesPopUp';
import TabsComponent from "../../../common/UIComponents/TabsComponent";
import {PatientPrefAndSocialNotesTabData} from "../../../../utils/constant/wellnessProfileConstant";
import theme from "../../../../theme";
import {RootState} from "../../../../redux/reducers/rootReducer";

const useStyles = makeStyles((theme: any) => ({
    tabContainer: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflowY: 'auto'
    }
}));

const PatientPrefAndSocialNotes = () => {
    const classes = useStyles()
    const [value, setValue] = useState(1);
    const [open, setOpen] = useState(false)
    const {wellnessProfileFilters: {wellnessComplaintsLocal,wellnessProfileData}} = useSelector((state: RootState) => state);
    const isPresentNull =  wellnessComplaintsLocal?.present === null || (value === 1 && wellnessProfileData?.is_wellness_locked)
    const handleClose = (): void => {
        setOpen(false)
    }
    const handleOpen = (): void => {
        setOpen(true)
    }
    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className='cursor-pointer'>
                <TabsComponent tabData={PatientPrefAndSocialNotesTabData} value={value} handleChange={handleChange}/>
                <Typography mr={2}
                            sx={{
                                opacity: isPresentNull ? 0.5 : 1,
                                cursor: isPresentNull ? 'default' : 'pointer'
                            }}
                            onClick={() => isPresentNull ? "" : handleOpen()}
                            className={'fw-500 fw-medium f-16'}>
                    <FontAwesomeIcon
                        icon={faPenToSquare} color={theme.palette.common.black50}/>
                </Typography>
            </Box>
            <Box className={classes.tabContainer} p={2} height={230}>
                {value === 1 && <ChiefComplaints/>}
                {value === 2 && <PatientPreferences/>}
                {value === 3 && <SocialNotes/>}
            </Box>
            <AddPatientPrefAndSocialNotesPopUp open={open} handleClose={handleClose} tabValue={value}/>
        </Box>
    )
}

export default PatientPrefAndSocialNotes