import {
    RECOVER_PASSWORD_REQUEST,
    RECOVER_PASSWORD_REQUEST_FAILURE,
    RECOVER_PASSWORD_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {
    RecoverPasswordRequest,
    RecoverPasswordRequestPayload,
} from '../../types/authActionTypes/resetPasswordActionTypes';

export const recoverPasswordRequest = (payload: RecoverPasswordRequestPayload): RecoverPasswordRequest => ({
    type: RECOVER_PASSWORD_REQUEST,
    payload
});

export const recoverPasswordRequestSuccess = () => ({
    type: RECOVER_PASSWORD_REQUEST_SUCCESS
});

export const recoverPasswordRequestFailure = () => ({
    type: RECOVER_PASSWORD_REQUEST_FAILURE
});
