import React, {useMemo} from 'react';
import {Box, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {isArray} from "lodash";
import {removeRefFromJson} from "../../../../../../utils/helper";
import {procedureColors} from "../../../../../../utils/procedureHelper";
import {updateProcedureFiltersAction} from "../../../../../../redux/actions/dentist/clinicalExam/odontogramAction";

const useStyles = makeStyles((theme: any) => ({
    procedureFilterWrapper: {
        padding: '6px 12px',
        marginRight: '8px',
        borderRadius: '14px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    procedureCountWrapper: {
        display: 'flex',
        background: theme.palette.common.white,
        padding: '3px 4px',
        borderRadius: '50%',
        marginLeft: '10px'
    }
}));

const ProcedureFilters = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const {
        odontogram: { procedureFilters, },
        examFindingReducer: {examFindingData},
    } = useSelector((state: any) => state) as any;
    const handleProcedureFilters = (key: string) => {
        let newProcedureFilters = removeRefFromJson(procedureFilters)
        if (newProcedureFilters.includes(key)) {
            newProcedureFilters = newProcedureFilters?.filter((f: string) => f !== key)
        } else {
            newProcedureFilters.push(key)
        }
        dispatch(updateProcedureFiltersAction(newProcedureFilters))
    }

    const getProcedureCount = useMemo(() => {
        const count = {
            Completed: 0,
            Planned: 0,
            Existing: 0,
        }
        if (examFindingData) {
            if (examFindingData?.occlusion_exam && examFindingData?.occlusion_exam?.missing_tooth_tooth_number && examFindingData?.occlusion_exam?.missing_tooth_tooth_number) {
                if (isArray(examFindingData?.occlusion_exam?.missing_tooth_tooth_number)) {
                    count.Existing += examFindingData?.occlusion_exam?.missing_tooth_tooth_number?.length
                } else {
                    count.Existing += examFindingData?.occlusion_exam?.missing_tooth_tooth_number?.split(',')?.length
                }
            }
            Object.keys(examFindingData)?.forEach((eg: string) => {
                if (examFindingData[eg] && typeof examFindingData[eg] !== 'string') {
                    Object.keys(examFindingData[eg]) && Object.keys(examFindingData[eg])?.forEach((f: string) => {
                        if (examFindingData[eg][f]) {
                            if (isArray(examFindingData[eg][f])) {
                                examFindingData[eg][f]?.map((item: any) => {
                                    if (item?.procedure_status === 'Completed') {
                                        count.Completed++
                                    } else if (item?.procedure_status === 'Planned') {
                                        count.Planned++
                                    } else if (item?.procedure_status === 'Existing') {
                                        count.Existing++
                                    }
                                    return true
                                })
                            }
                        }
                    })
                }
            })
        }
        return count
    }, [examFindingData]) as any
    return (
        <Box className='d-flex pos-absolute' sx={{top: '10px', left: '50px'}}>
            {Object.keys(procedureColors).map((res: any, ind: number) => <Box
                key={ind}
                className={classes.procedureFilterWrapper + ' cursor-pointer fw-regular'}
                sx={{
                    border: `2px solid ${procedureColors[res][procedureFilters.includes(res) ? 'color' : 'bgColor']}`,
                    backgroundColor: procedureColors[res].bgColor,
                    color: procedureColors[res].color,
                }}
                onClick={() => handleProcedureFilters(res)}
            > {res}
                <Typography
                    className={`fw-regular ${classes.procedureCountWrapper}`}>{getProcedureCount[res] < 10 ? '0' : ''}{getProcedureCount[res]}</Typography>
            </Box>)}
        </Box>
    )
}

    export default ProcedureFilters