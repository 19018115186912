
import {
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST,
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS,
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_SUCCESS,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_FAILURE
} from "../../actionTypes/patient/WellnessProfileWithoutAccount";
import {
    WithoutAccountPatientWellnessProfileActions,
    withoutAccountPatientWellnessProfileInterface
} from "../../types/patient/WellnessProfileWithoutAccount";

const initialState: withoutAccountPatientWellnessProfileInterface = {
    withoutAccountGetWellnessProfileLoader: false,
    withoutAccountGetWellnessProfileData: null,
    withoutAccountGetWellnessProfileError: null,

    withoutAccountUpdateWellnessProfileLoader: false,

    downloadPdfWithoutAccountLoader: false,
    downloadPdfWithoutAccountData: null,
    downloadPdfWithoutAccountError: null,
};
const patientWellnessProfileWithoutAccountReducer = (state = initialState, action: WithoutAccountPatientWellnessProfileActions) => {
    switch (action.type) {
        //Get Patient Wellness Profile
        case WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST:
            return {
                ...state,
                withoutAccountGetWellnessProfileLoader: true,
                withoutAccountGetWellnessProfileData: null,
                withoutAccountGetWellnessProfileError: null,
            };
        case WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                withoutAccountGetWellnessProfileLoader: false,
                withoutAccountGetWellnessProfileData: action.payload,
                withoutAccountGetWellnessProfileError: null
            };

        case WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                withoutAccountGetWellnessProfileLoader: false,
                withoutAccountGetWellnessProfileData: null,
                withoutAccountGetWellnessProfileError: action.payload
            };

        // Update Patient Wellness Profile
        case WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST:
            return {
                ...state,
                withoutAccountUpdateWellnessProfileLoader: true,
            };
        case WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                withoutAccountUpdateWellnessProfileLoader: false,

            };

        case WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                withoutAccountUpdateWellnessProfileLoader: false,
            };

        //Download PDF
        case DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST:
            return {
                ...state,
                downloadPdfLoader: true,
                downloadPdfWithoutAccountData: null,
                downloadPdfWithoutAccountError: null,
            };

        case DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_SUCCESS:

            return {
                ...state,
                downloadPdfWithoutAccountLoader: false,
                downloadPdfWithoutAccountData: action?.payload,
                downloadPdfWithoutAccountError: null,
            };
        case DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_FAILURE:
            return {
                ...state,
                downloadPdfWithoutAccountLoader: false,
                downloadPdfWithoutAccountData: null,
                downloadPdfWithoutAccountError: action?.payload,
            };

        default:
            return {
                ...state
            };
    }
};
export default patientWellnessProfileWithoutAccountReducer;
