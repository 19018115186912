import { GET_ANALYTICS_DATA_REQUEST, UPDATE_ANALYTICS_DATA_REQUEST } from '../../actionTypes/dentist/analytics';

const initialState: any = {
  loader: false,
  data: null
};

const analyticsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ANALYTICS_DATA_REQUEST:
      return {
        ...state,
        loader: true
      };
    case UPDATE_ANALYTICS_DATA_REQUEST:
      return {
        ...state,
        loader: false,
        data: action.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default analyticsReducer;
