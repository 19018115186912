
import { call, put, takeEvery, all } from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';
import { recoverPasswordRequestFailure, recoverPasswordRequestSuccess } from '../../actions/authAction/resetPasswordAction';
import { RECOVER_PASSWORD_REQUEST } from '../../actionTypes/auth';


function* recoverPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/${ApiEndpointVersion}/auth/reset/password`, payload.payload)
    if(payload.payload?.callback){
      yield call(payload.payload?.callback);
    }
    yield put(
      recoverPasswordRequestSuccess()
    );
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      recoverPasswordRequestFailure()
    );
  }
}

function* recoverPasswordSaga() {
  yield takeEvery(RECOVER_PASSWORD_REQUEST, recoverPasswordRequestSaga);
}

export default function* rootSaga() {
  yield all([
    recoverPasswordSaga(),
  ])
}