import {
    VERIFY_PASSWORD_REQUEST,
    VERIFY_PASSWORD_REQUEST_SUCCESS,
    VERIFY_PASSWORD_REQUEST_FAILURE
} from '../../../actionTypes/common/clinicSelection'
import {VerifyPasswordActions, verifyPasswordState} from "../../../types/common/clinicSelectionActionTypes";


const initialState: verifyPasswordState = {
    pending: false,
    data: null,
    error: null
};


const verifyPasswordReducer = (state = initialState, action: VerifyPasswordActions) => {
    switch (action.type) {
        case VERIFY_PASSWORD_REQUEST:
            return {
                ...state,
                pending: true,
                data: null,
                error: null
            }
        case VERIFY_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            }
        case VERIFY_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default verifyPasswordReducer