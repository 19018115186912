import {
    DASHBOARD_APPOINTMENT_STAFF_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_SUCCESS,
    DASHBOARD_APPOINTMENT_STAFF_FAILURE,
    DASHBOARD_APPOINTMENT_STAFF_CACHE_DATA_CLEAR_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_SUCCESS,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_FAILURE,
    DASHBOARD_APPOINTMENT_STAFF_COUNT_CACHE_DATA_CLEAR_REQUEST,
    SET_DESCENDING_ASCENDING,
} from '../../actionTypes/dentist/dashboard';
import {
    GetDashboardStaffTypeRequestPayload,
    GetDashboardStaffTypeRequest,
    GetDashboardStaffTypeSuccessPayload,
    GetDashboardStaffTypeSuccess,
    GetDashboardStaffTypeFailurePayload,
    GetDashboardStaffTypeFailure,
    GetDashboardStaffCountTypeRequestPayload,
    GetDashboardStaffCountTypeRequest,
    GetDashboardStaffCountTypeSuccessPayload,
    GetDashboardStaffCountTypeSuccess,
    GetDashboardStaffCountTypeFailurePayload,
    GetDashboardStaffCountTypeFailure,
    SetDescendingAscendingDashboardPayload,
    SetDescendingAscendingDashboard,
} from '../../types/dentist/appointment';

//dashboard appointments staff
export const getDashboardStaffTypeRequest = (payload: GetDashboardStaffTypeRequestPayload): GetDashboardStaffTypeRequest =>
({
    type: DASHBOARD_APPOINTMENT_STAFF_REQUEST,
    payload
} as any);

export const getDashboardStaffTypeSuccess = (payload: GetDashboardStaffTypeSuccessPayload): GetDashboardStaffTypeSuccess =>
({
    type: DASHBOARD_APPOINTMENT_STAFF_SUCCESS,
    payload
} as any);

export const getDashboardStaffTypeFailure = (payload: GetDashboardStaffTypeFailurePayload): GetDashboardStaffTypeFailure =>
({
    type: DASHBOARD_APPOINTMENT_STAFF_FAILURE,
    payload
} as any);

export const getDashboardStaffCacheDataClearRequest = (): any => ({
    type: DASHBOARD_APPOINTMENT_STAFF_CACHE_DATA_CLEAR_REQUEST
});

//dashboard appointments staff count
export const getDashboardStaffCountTypeRequest = (
    payload: GetDashboardStaffCountTypeRequestPayload
): GetDashboardStaffCountTypeRequest =>
({
    type: DASHBOARD_APPOINTMENT_STAFF_COUNT_REQUEST,
    payload
} as any);

export const getDashboardStaffCountTypeSuccess = (
    payload: GetDashboardStaffCountTypeSuccessPayload
): GetDashboardStaffCountTypeSuccess =>
({
    type: DASHBOARD_APPOINTMENT_STAFF_COUNT_SUCCESS,
    payload
} as any);

export const getDashboardStaffCountTypeFailure = (
    payload: GetDashboardStaffCountTypeFailurePayload
): GetDashboardStaffCountTypeFailure =>
({
    type: DASHBOARD_APPOINTMENT_STAFF_COUNT_FAILURE,
    payload
} as any);

export const getDashboardStaffCountCacheDataClearRequest = (): any => ({
    type: DASHBOARD_APPOINTMENT_STAFF_COUNT_CACHE_DATA_CLEAR_REQUEST
});

// set descending ascending
export const setDescendingAscendingAction = (payload: SetDescendingAscendingDashboardPayload): SetDescendingAscendingDashboard => ({
    type: SET_DESCENDING_ASCENDING,
    payload,
})

