import {
  UPDATE_SUB_STATUS_REQUEST,
  UPDATE_SUB_STATUS_SUCCESS,
  UPDATE_SUB_STATUS_FAIL
} from '../../actionTypes/master/updateSubStatusActions';
import { updateSubStatusActions, updateSubStatusRequestInterface } from '../../types/dentist/examStatus';

const initialState: updateSubStatusRequestInterface = {
  appointmentStatusLoader: false,
  statusData: null
};

const updateSubStatusReducer = (state = initialState, action: updateSubStatusActions) => {
  switch (action.type) {
    case UPDATE_SUB_STATUS_REQUEST:
      return {
        ...state,
        appointmentStatusLoader: true
      };
    case UPDATE_SUB_STATUS_SUCCESS:
      return {
        ...state,
        statusData: action.payload,
        appointmentStatusLoader: false
      };
    case UPDATE_SUB_STATUS_FAIL:
      return {
        ...state,
        statusData: null,
        appointmentStatusLoader: false
      };
    default:
      return {
        ...state
      };
  }
};

export default updateSubStatusReducer;
