import {
    GET_CLINIC_REQUEST, GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
    GET_CLINIC_REQUEST_SUCCESS,
    GET_CLINIC_REQUEST_FAILURE,
    VERIFY_PASSWORD_REQUEST,
    VERIFY_PASSWORD_REQUEST_SUCCESS,
    VERIFY_PASSWORD_REQUEST_FAILURE
} from '../../../actionTypes/common/clinicSelection'
import {
    GetClinicRequestPayload,
    GetClinicRequestSuccessPayload,
    GetClinicRequestFailurePayload,
    GetClinicRequest,
    GetClinicRequestSuccess,
    GetClinicRequestFailure,
} from "../../../types/common/clinicSelectionActionTypes";


export const verifyPasswordRequest = (payload: any): any => ({
    type: VERIFY_PASSWORD_REQUEST,
    payload,
});

export const verifyPasswordRequestSuccess = (payload: any): any => ({
    type: VERIFY_PASSWORD_REQUEST_SUCCESS,
    payload,
});

export const verifyPasswordRequestFailure = (payload: any): any => ({
    type: VERIFY_PASSWORD_REQUEST_FAILURE,
    payload,
});

export const getClinicRequest = (payload: GetClinicRequestPayload): GetClinicRequest => ({
    type: GET_CLINIC_REQUEST,
    payload,
});

export const getClinicCacheDataClearRequest = (): any => ({
    type: GET_CLINIC_CACHE_DATA_CLEAR_REQUEST
})

export const getClinicRequestSuccess = (
    payload: GetClinicRequestSuccessPayload
): GetClinicRequestSuccess => ({
    type: GET_CLINIC_REQUEST_SUCCESS,
    payload
});

export const getClinicRequestFailure = (
    payload: GetClinicRequestFailurePayload
): GetClinicRequestFailure => ({
    type: GET_CLINIC_REQUEST_FAILURE,
    payload
});


