import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../utils/api";
import {
    PATIENT_PROFILE_REQUEST_V2,
    PATIENT_PROFILE_EDIT_REQUEST_V2,
} from '../../actionTypes/patient/patientProfile';
import {ApiEndpointVersion, errorToaster, getLocalStorageItem} from '../../../utils/helper';
import {
    patientProfileRequestSuccess,
    patientProfileRequestFailure,
    patientProfileEditRequestFailure,
    patientProfileEditRequestSuccess,
} from '../../actions/patient/patientProfileAction';

//patient Profile Data
function* getPatientProfileRequestSaga(payload: any): any {
    try {
        const response = yield API.get(
            `/${ApiEndpointVersion}/patient/${payload.payload.patient_id}?clinic_id=${getLocalStorageItem('patientClinic')}`
        );
        yield put(
            patientProfileRequestSuccess(response.data?.detail,)
        );
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
    } catch (e: any) {
        yield put(
            patientProfileRequestFailure({
                error: e?.detail,
            })
        );
    }
}

//Edit patient Profile
function* patientProfileEditRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/patient?patient_id=${payload?.payload?.profile_picture?.get('patient_id')}&clinic_id=${getLocalStorageItem('patientClinic')}`, payload.payload.profile_picture)
        yield put(patientProfileEditRequestSuccess());
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
    } catch (e: any) {
        if (payload?.payload?.errorCallback) {
            payload?.payload?.errorCallback()
        }
        errorToaster(e?.meta?.message)
        yield put(patientProfileEditRequestFailure())

    }
}

function* getPatientProfileWatch() {
    yield takeEvery(PATIENT_PROFILE_REQUEST_V2, getPatientProfileRequestSaga);
}

function* patientProfileEditSaga() {
    yield takeEvery(PATIENT_PROFILE_EDIT_REQUEST_V2, patientProfileEditRequestSaga);
}


export default function* patientProfileSagaV2() {
    yield all([
        getPatientProfileWatch(),
        patientProfileEditSaga(),
    ]);
}
