import React, {useEffect} from "react";
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {tooltipData} from "../../../../../utils/constant/tooltipConstant";
import {RootState} from "../../../../../redux/reducers/rootReducer";
import theme from "../../../../../theme";
import {updateChatBoxValueAction} from "../../../../../redux/actions/master/chatBoxActions";
import ImagePopUp from "./ImagePopUp";
import TextBox from "../../../../common/UIComponents/TextBox";
import {staffProfileNewRequest} from "../../../../../redux/actions/dentist/dentistProfileNewAction";
import QLogo from "../../../../../assets/images/QLogo.png";
import {dentistProfileData} from "../../../../../utils/helper";

const useStyles = makeStyles((theme: any) => ({
    mainBox: {
        height: "658px",
        width: "505px",
        background: theme.palette.common.white,
        position: "fixed",
        bottom: `0px`,
        zIndex: 1024,
        maxHeight: "76vh",
        boxShadow: "0px 0px 25px 10px rgb(0 0 0 / 15%)",
    },
    bodyRowBorder: {
        fontSize: "14px",
        color: theme.palette.common.black50,
    },
    profileNameInitial: {
        borderRadius: "50%",
        border: `1px solid ${theme.palette.background.paper}`,
        backgroundColor: "#e9e8f0",
        height: "40px",
        width: "40px",
        opacity: 1,
        marginRight: "10px",
    },
    clinicLogo: {
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        border: `1px solid ${theme.palette.common.white}`,
    },
    profileBox: {
        background: theme.palette.grey["A900"],
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        marginRight: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    profileHeadset: {
        background: theme.palette.primary.otherDarkPurple,
        height: "40px",
        width: "40px",
        borderRadius: "50%",
    },
    imageBg: {
        display: "flex",
        width: "100%",
        height: "100px",
        backgroundPosition: "center",
        backgroundSize: "cover",
        cursor: "pointer",
    },
    scrollbarColor: {
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.otherDarkPurple,
            borderRadius: "5px",
        },
    },
}));

const ChatBox = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState(null);
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const {chatBoxValue, keyName} = useSelector((state: RootState) => state?.chatBox) as any;
    const data = useSelector((state: any) => state?.dentistProfileReducer?.data);

    useEffect(() => {
        dispatch(staffProfileNewRequest({
            staff_id: dentistProfileData?.id,
        }));
    }, [dispatch]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setImageUrl(null)
        setOpen(false)
    };

    const getData = () => {
        if (tooltipData && keyName && tooltipData[keyName]) {
            return tooltipData[keyName];
        }
        return null;
    };

    return (
        <>
            {getData() && chatBoxValue && keyName?.length && (
                <Box className={`${classes.mainBox}`} sx={{
                    left: isWellnessProfile ?"":`60px`,
                    right: isWellnessProfile ?'60px':''
                }}>
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        height="36px"
                        sx={{
                            backgroundColor: theme?.palette?.primary?.otherDarkPurple,
                            padding: "12px 9px",
                        }}
                    >
                        <Box
                            className={`fw-medium`}
                            fontSize={"18px"}
                            color={theme?.palette?.common?.white}
                        >
                            Chat with Q
                        </Box>
                        <FontAwesomeIcon
                            icon={faXmark}
                            className={`f-14 f-w-900 cursor-pointer`}
                            color={theme?.palette?.common?.white}
                            onClick={() =>
                                dispatch(
                                    updateChatBoxValueAction({chatBoxValue: false, keyName: ""})
                                )
                            }
                        />
                    </Box>
                    <Box
                        overflow={"auto"}
                        sx={{height: "calc(100% - 110px)", paddingBottom: "10px"}}
                        className={classes.scrollbarColor}
                    >
                        <Box display={"flex"} justifyContent={"flex-end"}>
                            <Box
                                borderRadius={"12px 12px 0 12px"}
                                mt={"30px"}
                                color={theme?.palette?.common?.white}
                                className={"fw-regular"}
                                sx={{
                                    backgroundColor: theme?.palette?.primary?.otherDarkPurple,
                                    padding: "12px",
                                    width: "fit-content",
                                }}
                            >
                                {getData()?.question}
                            </Box>

                            <Box
                                className={`${classes.bodyRowBorder} text-overflow-ellipsis d-flex fw-regular`}
                                alignItems={"end"}
                                ml={"7px"}
                            >
                                {data?.profile_picture ? (
                                    <Box className={classes.profileBox}>
                                        <img
                                            src={data?.profile_picture}
                                            alt="user"
                                            className={classes.clinicLogo}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        className={`${classes.profileNameInitial} d-flex-all-center `}
                                    >
                                        <Typography
                                            variant={"subtitle1"}
                                            component={"span"}
                                            sx={{
                                                textTransform: "Uppercase",
                                                color: theme?.palette?.primary?.otherDarkPurple,
                                            }}
                                            className="fw-medium"
                                        >
                                            {dentistProfileData?.first_name?.charAt(0)}
                                            {dentistProfileData?.last_name?.charAt(0)}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>

                        <Box display={"flex"} mt={"20px"} alignItems={"flex-end"}>
                            <Box
                                className={classes.profileHeadset}
                                position={"relative"}
                                ml={"12px"}
                                mr={"8px"}
                                alignItems={"end"}
                            >
                                <img
                                    width={25}
                                    height={25}
                                    className={"cursor-pointer"}
                                    src={QLogo}
                                    alt="logo"
                                    style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                    onClick={() =>
                                        dispatch(
                                            updateChatBoxValueAction({
                                                chatBoxValue: false,
                                                keyName: "",
                                            })
                                        )
                                    }
                                />
                            </Box>
                            <Box
                                maxWidth={"270px"}
                                width={"100%"}
                                borderRadius={"12px 12px 12px 0px"}
                                color={theme?.palette?.text?.darkGrey}
                                sx={{
                                    backgroundColor: theme?.palette?.background?.secondaryPaper,
                                    padding: "12px",
                                }}
                            >
                                <Grid container className={"fw-regular"} maxWidth={"100%"}>
                                    {Array.isArray(getData()?.answer) ?
                                        <>
                                            {getData()?.answer && getData()?.answer.map((res: any, index: number) =>
                                                <React.Fragment key={index}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        borderRadius={"8px"}
                                                        sx={{maxWidth: "fit-content"}}
                                                    >
                                                        {res?.heading}:
                                                    </Grid>
                                                    {res?.images && res?.images?.map((imageRes: any, imageResIndex: number) =>
                                                        <Grid
                                                            key={imageResIndex}
                                                            item
                                                            xs={12}
                                                            borderRadius={"8px"}
                                                            sx={{maxWidth: "fit-content"}}
                                                        >
                                                            <Box
                                                                className={classes.imageBg}
                                                                position={"relative"}
                                                                mb={1}
                                                                onClick={() => {
                                                                    handleOpen()
                                                                    setImageUrl(imageRes?.image)
                                                                }
                                                                }
                                                                style={{
                                                                    backgroundImage: `url(${imageRes?.image})`,
                                                                    minWidth: "100%",
                                                                }}
                                                            />
                                                        </Grid>
                                                    )}
                                                </React.Fragment>
                                            )}
                                        </>
                                        :
                                        <>
                                            {getData()?.answer &&
                                                getData()?.answer?.images &&
                                                getData()?.answer?.images.map((res: any, index: number) => (
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={
                                                            (getData()?.answer?.images.length === 3 ||
                                                                getData()?.answer?.images.length === 1) &&
                                                            index === 0
                                                                ? 12
                                                                : 6
                                                        }
                                                        borderRadius={"8px"}
                                                        sx={{maxWidth: "fit-content"}}
                                                    >
                                                        {index < 4 && (
                                                            <Box>
                                                                <Box
                                                                    className={classes.imageBg}
                                                                    position={"relative"}
                                                                    onClick={() => handleOpen()}
                                                                    style={{
                                                                        backgroundImage: `url(${res?.image})`,
                                                                        minWidth: "100%",
                                                                    }}
                                                                >
                                                                    {getData()?.answer?.images.length > 4 &&
                                                                        index === 3 && (
                                                                            <Box
                                                                                className={"fw-medium"}
                                                                                color={theme.palette.common.white}
                                                                                height={"100%"}
                                                                                width={"100%"}
                                                                                display={"flex"}
                                                                                justifyContent={"center"}
                                                                                alignItems={"center"}
                                                                                sx={{
                                                                                    position: "absolute",
                                                                                    top: "50%",
                                                                                    left: "50%",
                                                                                    transform: "translate(-50%, -50%)",
                                                                                    backgroundColor: "rgba(51, 51, 51, 0.75)",
                                                                                }}
                                                                            >
                                                                                {`+ ${getData()?.answer?.images.length - index
                                                                                } More`}
                                                                            </Box>
                                                                        )}
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                ))}
                                        </>
                                    }
                                </Grid>
                                {getData()?.answer?.text && (
                                    <Box mt={"14px"}>
                                        {getData()?.answer &&
                                            getData()?.answer?.text &&
                                            getData()?.answer?.text.map((res: any, index: number) => (
                                                <React.Fragment key={index}>
                                                    {res?.title} <br/>
                                                </React.Fragment>
                                            ))}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>

                    <Box height={"74px"}>
                        <Divider/>
                        <Grid
                            container
                            p={"12px"}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            <Grid item xs={9}>
                                <TextBox
                                    labelProps={{
                                        htmlFor: "input",
                                    }}
                                    disabled
                                    inputBackColor={theme?.palette?.common?.white}
                                    inputProps={{
                                        id: "input",
                                        name: "input",
                                        fullWidth: true,
                                    }}
                                />
                            </Grid>
                            <Button
                                sx={{
                                    padding: "6px 23.5px",
                                    backgroundColor: theme?.palette?.primary?.otherDarkPurple,
                                    height: "40px",
                                    borderRadius: "4px",
                                    "&:hover": {
                                        backgroundColor: theme?.palette?.primary?.otherDarkPurple,
                                    },
                                }}
                                variant={"contained"}
                                className={`fw-regular`}
                                disabled
                            >
                                Send
                            </Button>
                        </Grid>
                    </Box>
                </Box>
            )}
            {open && <ImagePopUp
                open={open}
                handleClose={handleClose}
                imageList={imageUrl === null ? getData()?.answer?.images : imageUrl}
                question={getData()?.question}
            />}
        </>
    );
};

export default ChatBox;
