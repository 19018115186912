import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Button, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import AutorenewIcon from "@mui/icons-material/Autorenew";
import {
    updateAppointmentStepAction,
    updateExamFormSizeAction
} from '../../../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction';
import {
    getPhotosRequestAction
} from '../../../../../redux/actions/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import {
    handleImageXrayFromTreatmentPlanComingAction
} from '../../../../../redux/actions/dentist/clinicalExam/treatment/proposedTreatments/oraqProposedAction';
import {RootState} from '../../../../../redux/reducers/rootReducer';
import theme from '../../../../../theme';
import {photo, xray} from '../../../../../utils/constant/clinicalExamConstant';
import FullScreenLoader from '../../../../common/FullScreenLoader';
import Annotation from '../Annotation';
import PhotosXrayList from './PhotosXrayList';
import ReloadPage from './ReloadPage';
import UploadPhotosXray from './UploadPhotosXray';
import pusher from "../../../../../utils/pusher";
import {spinLoader} from "../../../../../utils/helper";

const PhotoXray = () => {
    const dispatch = useDispatch();
    const takeFromUrl = useParams();
    const isWellnessProfile = window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const {
        uploadPhotoXray: {photosXrayData, photosXrayLoader},
        clinicalExamFilters: {selectedImage, findingType},
        oraqProposedReducer: {cameImageXrayFromTreatmentPlan},
        wellnessProfileFilters: {wellnessProfileStep},
    } = useSelector((state: RootState) => state);

    const [syncingStatus, setSyncingStatus] = useState(false)
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhoto = tabData === photo
    useEffect(() => {
        if ((findingType === photo || findingType === xray) || (wellnessProfileStep === 'photo' || wellnessProfileStep === 'xray')) {
            dispatch(
                getPhotosRequestAction({
                    appointment_id: takeFromUrl?.appointmentId,
                    image_type: (isWellnessProfile ? wellnessProfileStep === 'photo' : findingType === photo) ? 'image' : 'xray',
                    patient_id: takeFromUrl?.patientId,
                })
            );
            dispatch(updateExamFormSizeAction(false));
        }
    }, [findingType, wellnessProfileStep,isWellnessProfile,takeFromUrl?.appointmentId,takeFromUrl?.patientId,dispatch]);

    const backToTreatment = () => {
        const step = 3 as any;
        dispatch(handleImageXrayFromTreatmentPlanComingAction(false));
        dispatch(updateAppointmentStepAction(step));
    }
    // get instant data using pusher
    useEffect(() => {
        if (isWellnessProfile && isPhoto) {
            const channel = pusher.subscribe('image-status');
            channel.bind(takeFromUrl?.patientId || '', (data: any) => {
                setSyncingStatus(data?.status === "loading");
                if(data?.last_result==='successful'){
                    dispatch(
                        getPhotosRequestAction({
                            appointment_id: takeFromUrl?.appointmentId,
                            image_type: 'image',
                            patient_id: takeFromUrl?.patientId,
                        })
                    );
                }
            });
        }
    }, [wellnessProfileStep, isWellnessProfile, isPhoto,takeFromUrl?.appointmentId,takeFromUrl?.patientId,dispatch])

    return <React.Fragment>
        {photosXrayLoader && <FullScreenLoader/>}
        <React.Fragment>
            {syncingStatus && isWellnessProfile && isPhoto &&
                <Box position={'absolute'} left={'30px'} display={'flex'} alignItems={'center'}>
                    <AutorenewIcon sx={{color: theme.palette.error.main, animation: `${spinLoader} 1s infinite ease`}}/>
                    <Typography className={'f-14 fw-regular'} color={theme.palette.error.main}>Currently syncing
                        {isPhoto?' Images':' X-rays'}</Typography>
                </Box>}
            {(photosXrayData && photosXrayData?.length > 0) ?
                <React.Fragment>{selectedImage !== '' ? <Annotation/> : <PhotosXrayList/>}</React.Fragment>
                : <React.Fragment>
                    {cameImageXrayFromTreatmentPlan &&
                        <Box mt={'20px'} ml={'20px'}> <Button onClick={backToTreatment} variant='outlined'
                                                              startIcon={<FontAwesomeIcon icon={faArrowLeft}/>} sx={{
                            color: theme.palette.v2.primary.main,
                            borderColor: theme.palette.v2.primary.main
                        }}>
                            Back to Treatment Planning</Button></Box>}
                    <ReloadPage/>
                </React.Fragment>
            }
            <UploadPhotosXray/>
        </React.Fragment>

    </React.Fragment>;
};

export default PhotoXray;
