
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React from 'react'

const useStyles = makeStyles(() => ({
  lineStyle: {
    height: '65px', borderRadius: '10px'
  }
}));
interface Props {
  isOpenContact: any
  toothNumber: any
  isBottomTeeth: any
}
const OdontogramOpenContactFinding = (props: Props) => {
  const { isOpenContact, toothNumber, isBottomTeeth } = props
  const classes = useStyles()
  const left = isOpenContact?.toothSite?.includes((toothNumber > 30 && toothNumber < 39) || (toothNumber > 20 && toothNumber < 29) ? 'Mesial' : 'Distal')
  const right = isOpenContact?.toothSite?.includes((toothNumber > 30 && toothNumber < 39) || (toothNumber > 20 && toothNumber < 29) ? 'Distal' : 'Mesial')

  return (
    <React.Fragment>
      {(isOpenContact?.hasTooth && left) &&
        <React.Fragment>
          <Typography className={`pos-absolute ${classes.lineStyle}`} sx={{ right: isBottomTeeth ? 4 : '', borderLeft: `2px solid ${isOpenContact?.color}` }}>
          </Typography>
          <Typography className={`pos-absolute ${classes.lineStyle}`} sx={{ right: isBottomTeeth ? '-8px' : '', left: !isBottomTeeth ? '-8px' : '', borderLeft: `2px solid ${isOpenContact?.color}` }}>
          </Typography>
        </React.Fragment>
      }
      {(isOpenContact?.hasTooth && right) &&
        <React.Fragment>
          <Typography className={`pos-absolute ${classes.lineStyle}`} sx={{ right: isBottomTeeth ? 43 : 4, borderRight: `2px solid ${isOpenContact?.color}` }} >
          </Typography>
          <Typography className={`pos-absolute ${classes.lineStyle}`} sx={{ right: isBottomTeeth ? 59 : '-8px', borderRight: `2px solid ${isOpenContact?.color}` }} >
          </Typography>
        </React.Fragment>
      }
    </React.Fragment >
  )
}
export default OdontogramOpenContactFinding