import {call, put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';

import {
    FORGOT_USERNAME_V2_REQUEST,
    PATIENT_CHANGE_PASSWORD_REQUEST,
} from "../../actionTypes/patient/auth";
import {
    forgotUserNameRequestFailure,
    forgotUserNameRequestSuccess,
    multipleNumberPopUp,
    patientChangePasswordRequestFailure,
    patientChangePasswordRequestSuccess,
} from "../../actions/patient/authAction";

function* forgotUserNameRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/auth/forgot-username`, payload.payload.payload)
        yield call(payload.payload.callback, response);
        yield put(
            forgotUserNameRequestSuccess(response?.data?.detail)
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        if (e?.detail?.length) {
            yield put(multipleNumberPopUp({
                multipleNumberPopUp: true,
                multipleNumberData: e?.detail
            }))
        } else {
            errorToaster(e?.meta?.message)
        }
        yield put(
            forgotUserNameRequestFailure(e?.meta?.message)
        );
    }
}

function* changePasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/auth/password`, payload?.payload?.payload)
        yield call(payload?.payload?.callback, response.data);
        yield put(
            patientChangePasswordRequestSuccess({
                data: response?.data?.detail,
            })
        );
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            patientChangePasswordRequestFailure({
                error: e?.meta?.message
            })
        );
    }
}


function* forgotUserNameSaga() {
    yield takeEvery(FORGOT_USERNAME_V2_REQUEST, forgotUserNameRequestSaga);
}

function* changePasswordSaga() {
    yield takeEvery(PATIENT_CHANGE_PASSWORD_REQUEST, changePasswordRequestSaga);
}


export default function* patientAuthSaga() {
    yield all([
        forgotUserNameSaga(),
        changePasswordSaga(),
    ])
}