import {
    RECOVER_PASSWORD_REQUEST,
    RECOVER_PASSWORD_REQUEST_FAILURE,
    RECOVER_PASSWORD_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {recoverPasswordActions, recoverPasswordInterface} from '../../types/authActionTypes/resetPasswordActionTypes';

const initialState: recoverPasswordInterface = {
    recoverPasswordLoader: false,

};

const resetPasswordReducer = (state = initialState, action: recoverPasswordActions) => {
    switch (action.type) {
        case RECOVER_PASSWORD_REQUEST:
            return {
                ...state,
                recoverPasswordLoader: true,

            };
        case RECOVER_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                recoverPasswordLoader: false,
            };
        case RECOVER_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                recoverPasswordLoader: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default resetPasswordReducer;
