import { put, takeEvery, all, call } from "redux-saga/effects";
import API from "../../../utils/api";
import {
    ApiEndpointVersion,
    arrayToString,
    dateFormat,
    errorToaster,
    formatPhoneNumber,
    stringToArray,
    successToaster,
    usDateFormat
} from '../../../utils/helper';
import {
    checkSleep,
    checkSleepLocal,
    getYesNo,
    passTrueFalse
} from '../../../utils/constant/preIntakeConstant/preIntakeConstant';
import {
    updateCommunicationPreferencesAction,
    updateDentalInfoAction,
    updateDentistInsuranceInfoAction,
    updateEmergencyContactInfoAction,
    updateGeneralHealthAction,
    updateLetsGetToKnowYouBetterAction,
    updateMedicalHealthHistoryAction,
    updatePatientInfoAction,
    updateSleepQualityAction
} from '../../actions/dentist/preIntakeActions';
import {
    GET_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    PUT_HEALTH_HISTORY_WITHOUT_AC_REQUEST
} from "../../actionTypes/patient/healthHistoryWithoutAccount";
import {
    getHealthHistoryWithoutAcFailure,
    getHealthHistoryWithoutAcSuccess,
    putHealthHistoryWithoutAcFailure,
    putHealthHistoryWithoutAcSuccess
} from "../../actions/patient/healthHistoryWithoutAccountAction";
import moment from "moment";

function* saveHealthHistoryWithoutAccountRequestSaga(payload: any): any {
    const payloadData = payload?.payload?.getHealthHistoryWithoutAccountData
    const {
        patientInformation,
        emergencyContactInfo,
        letsGetToKnowYouBetter,
        dentistInsuranceInfo,
        generalHealth,
        dentalInfo,
        sleepQuality,
        medicalHealthHistory,
        communicationPreferences,
    } = payloadData;

    const patientDetails = {
        email: patientInformation?.emailAddress,
        first_name: patientInformation?.firstName,
        middle_name: patientInformation?.middleName,
        last_name: patientInformation?.lastName,
        mobile_number: patientInformation?.mobilePhoneNumber,
        address: patientInformation?.address,
        postal_code: patientInformation?.postalCode,
        date_of_birth: patientInformation?.dateOfBirth,
        home_number: patientInformation?.homePhoneNumber,
        country: patientInformation?.country,
        state: patientInformation?.state,
        city: patientInformation?.city,
        gender: patientInformation?.gender === '' ? null : patientInformation?.gender,
        clinic_id: payload?.payload?.clinicID
    }

    const preIntakeData = {
        device: payload?.payload?.device,
        emergency_contact: emergencyContactInfo?.emergencyContactPerson,
        emergency_contact_phone_number:
            emergencyContactInfo?.emergencyContactNumber,
        relationship_to_emergency_contact:
            "Other" === emergencyContactInfo.relationshipToEmergencyContactPerson
                ? emergencyContactInfo.other
                : emergencyContactInfo.relationshipToEmergencyContactPerson,

        //Let’s get to know you better
        occupation: letsGetToKnowYouBetter?.occupation,
        does_your_work_involve_night_shift: passTrueFalse(
            letsGetToKnowYouBetter?.isNightShiftInvolvedWithYourOccupation
        ),
        referred_by: letsGetToKnowYouBetter?.referredBy,
        marital_status: letsGetToKnowYouBetter?.maritalStatus,
        dental_insurance: passTrueFalse(
            letsGetToKnowYouBetter?.doYouHaveDentalInsurance
        ),

        //General Health
        history_of_smoking: generalHealth?.historyOfSmoking,
        alcohol_usage_in_week: generalHealth?.alcoholAverageWeek,
        recreational_drug_use: passTrueFalse(generalHealth?.drugUse),
        recreational_drug_use_other: generalHealth?.drugInputUse,
        height:
            (generalHealth?.heightUnit === "ft" && generalHealth?.heightFT
                ?
                `${generalHealth?.heightFT}.${generalHealth?.heightIN ? generalHealth?.heightIN : 0}`
                : generalHealth?.heightCM || ''),
        height_unit: (generalHealth?.heightFT || generalHealth?.heightCM) ? generalHealth?.heightUnit : '',
        weight: generalHealth?.weight,
        weight_unit: generalHealth?.weight ? generalHealth?.weightUnit : '',

        //Dental Information
        dental_anxiety: dentalInfo?.dentalAnxiety || 0,
        reason_for_scheduling_this_visit:
            dentalInfo?.reasonForSchedulingThisVisit || '',
        previous_dental_office_name: dentalInfo?.nameOfPreviousOffice,
        last_see_dental_hygienist: dateFormat(
            dentalInfo?.lastVisitAtDentistOffice
        ),
        date_of_last_dental_x_ray: dateFormat(
            dentalInfo?.lastTimeYouHadDentalXrayTaken
        ),
        antibiotic_premedication_require_prior_dental_appointments: passTrueFalse(
            dentalInfo?.doesYourPhysicianRecommendAntibiotic
        ),
        antibiotic_premedication: dentalInfo?.antibioticPremeditation,
        dental_health_average: dentalInfo?.feelDentalHealth,
        dental_info: arrayToString(dentalInfo?.checkIfYouHaveEverHadFollowing),
        appearance_smile_scale_to_10: dentalInfo?.rateAppearanceOfYourSmile,
        wish_correct_appearance_smile: passTrueFalse(
            dentalInfo?.correctAppearanceOfYourSmile
        ),
        wish_whiter_smile: passTrueFalse(dentalInfo?.hadWhiterSmile),
        snack_3x_day: passTrueFalse(dentalInfo?.doYouSnackMoreThatDay),
        brand_toothpaste: dentalInfo?.toothpasteBrand,
        tooth_extracted_last_3_year: passTrueFalse(
            dentalInfo?.toothExtractedInLastThreeYear
        ),
        filling_had_in_last_3_year: dentalInfo?.fillingsInLastThreeYear,
        family_history_of_cavities_toothloss: passTrueFalse(
            dentalInfo?.familyHistoryOfCavities
        ),
        homecare_protective_factors: arrayToString(
            dentalInfo?.homeCareProtectiveFactors
        ),

        //Sleep Quality
        diagnosed_sleep_apnea_ever: passTrueFalse(
            sleepQuality?.diagnosedSleepApnea
        ),
        neck_circumference: sleepQuality?.neckCircumferenceMeasured,
        loud_snore: sleepQuality?.snoreLoudly,
        fatigue_daily_bases: passTrueFalse(sleepQuality?.feelDuringDay),
        breathing_stop_sleep: passTrueFalse(sleepQuality?.gaspDuringSleep),
        difficulty_breathing_through_your_nose: passTrueFalse(
            sleepQuality?.difficultyBreathingNose
        ),
        cpap_machine: passTrueFalse(sleepQuality?.useCPAPMachine),
        oral_appliance_sleep: passTrueFalse(sleepQuality?.useOralAppliance),
        sitting_reading_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[0]
        ),
        watching_tv_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[1]
        ),
        sitting_inactive_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[2]
        ),
        being_passenger_car_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[3]
        ),
        lying_down_afternoon_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[4]
        ),
        sitting_talking_someone_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[5]
        ),
        sitting_quietly_lunch_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[6]
        ),
        stopping_fewminutes_driving_epworth_scale: checkSleep(
            sleepQuality?.additionalSleep?.map((res: any) => res)[7]
        ),

        // Medical Health History
        physicians_name: medicalHealthHistory?.physicianName,
        physician_phone_number: medicalHealthHistory?.physicianContactNumber,
        last_visit_to_physician: dateFormat(
            medicalHealthHistory?.dateOfLastVisit
        ),
        serious_illness_or_operation_existance: passTrueFalse(
            medicalHealthHistory?.seriousIllnessesOrOperations
        ),
        serious_illness_or_operation_name:
            medicalHealthHistory?.seriousIllnessesOrOperationsInput,
        currently_taking_any_medication: passTrueFalse(
            medicalHealthHistory?.takingMedicationsOrSupplements
        ),
        medication_list:
            medicalHealthHistory?.takingMedicationsOrSupplementsInput,
        chest_pain_shortness_breath: passTrueFalse(
            medicalHealthHistory?.shortnessBreath
        ),
        nursing: passTrueFalse(medicalHealthHistory?.nursing),
        birth_control: passTrueFalse(medicalHealthHistory?.takingBirthControl),
        pregnant: passTrueFalse(medicalHealthHistory?.pregnant),
        medical_conditions: arrayToString(medicalHealthHistory?.medicalConditions),
        medical_conditions_other: medicalHealthHistory?.medicalConditionsOther || "",
        allergies_list: arrayToString(medicalHealthHistory?.checkboxListTwo),
        allergies_list_other: medicalHealthHistory?.checkboxListTwoOtherInput,

        //Communication Preferences
        appearance_teeth_scale: communicationPreferences?.appearanceOfYourTeeth,
        loose_tooth_feeling_scale:
            communicationPreferences?.feelIfYouWereToLoseTooth,
        chew_importance_scale: communicationPreferences?.yourTeethFunctionWell,
        importancy_of_teeth_health_scale:
            communicationPreferences?.keepYourTeethForRestOfYourLife,
        concerns_appearance_teeth_existance: passTrueFalse(
            communicationPreferences?.concernsWithAppearanceOfYourTeeth
        ),
        concerns_appearance_teeth_existance_explain:
            communicationPreferences?.concernsWithAppearanceOfYourTeethReason,
        teeth_sensitivity: passTrueFalse(
            communicationPreferences?.teethSensitive
        ),
        teeth_sensitivity_answer_yes:
            communicationPreferences?.teethSensitiveReason,
        describe_yourself_1: communicationPreferences?.describeYourselfMore1,
        describe_yourself_2: communicationPreferences?.describeYourselfMore2,
        social_note: communicationPreferences?.socialNotes,
        patient_preferences: communicationPreferences?.patientPreferences,
    } as any;

    if (letsGetToKnowYouBetter?.doYouHaveDentalInsurance === "yes") {
        preIntakeData.insurance = dentistInsuranceInfo?.map((res: any, index: number) => {
            return {
                insurance_provider: res.insuranceProvider,
                insurance_group_number: res.insuranceGroup,
                insurance_id: res.insuranceID,
                plan_member_name: res.planMemberName,
                plan_member_dob: res.planMemberDob,
                position: index
            };
        });
    }

    const updatedPayload = {
        patient_id: payload?.payload?.patient_id,
        patient_details: patientDetails,
        pre_intake: preIntakeData,
        submit_form: payload?.payload?.submit_form,
        is_second_screen: payload?.payload?.is_second_screen,
    } as any;

    if (payload?.payload?.token) {
        updatedPayload.token = payload?.payload?.token;
    }

    try {
        const response = yield API.post(`/${ApiEndpointVersion}/pre-intake/form/profile`, updatedPayload)

        yield put(putHealthHistoryWithoutAcSuccess(response?.data?.detail));

        successToaster(response?.data?.meta?.message)

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }

    } catch (e: any) {
        errorToaster(e?.meta?.message);
        if (payload?.payload?.errorCallback) {
            yield call(payload?.payload?.errorCallback, e?.detail)
        }
        yield put(
            putHealthHistoryWithoutAcFailure(e?.meta?.message)
        );
    }
}

function* getHealthHistoryWithoutAccountRequestSaga(payload: any): any {
    try {
        const response = yield API.get(payload?.payload?.token ?
            `/${ApiEndpointVersion}/pre-intake/form/${payload?.payload?.patient_id}/profile?token=${payload?.payload?.token}`
            :
            `/${ApiEndpointVersion}/pre-intake/form/${payload?.payload?.patient_id}/profile`
        )

        const data = response?.data?.detail;
        const patientPersonalInfo = data?.patient_details
        const patientPreIntake = data?.pre_intake

        //Set data in global redux to use for revert/discard changes (future usages)

        /* * Start ---->  * Update all pre-intake components value based on api response */

        // Patient Information
        yield put(
            updatePatientInfoAction({
                firstName: patientPersonalInfo?.first_name,
                middleName: patientPersonalInfo?.middle_name,
                lastName: patientPersonalInfo?.last_name,
                emailAddress: patientPersonalInfo?.email,
                gender: patientPersonalInfo?.gender,
                genderOther: patientPersonalInfo?.gender_other,
                sex: patientPersonalInfo?.sex,
                race: patientPersonalInfo?.race,
                raceOther: patientPersonalInfo?.race_other,
                mobilePhoneNumber: formatPhoneNumber(patientPersonalInfo?.mobile_number),
                homePhoneNumber: formatPhoneNumber(patientPersonalInfo?.home_number),
                dateOfBirth: moment(patientPersonalInfo?.date_of_birth)?.format(usDateFormat),
                address: patientPersonalInfo?.address,
                city: patientPersonalInfo?.city,
                postalCode: patientPersonalInfo?.postal_code,
                state: patientPersonalInfo?.state,
                country: patientPersonalInfo?.country,
            })
        );

        // Emergency Contact Information
        const relationshipStatus = ["Spouse", "Parent", "Friend", "Family Member"];
        yield put(
            updateEmergencyContactInfoAction({
                emergencyContactPerson: patientPreIntake?.emergency_contact,
                emergencyContactNumber: patientPreIntake?.emergency_contact_phone_number,
                relationshipToEmergencyContactPerson:
                    patientPreIntake?.relationship_to_emergency_contact &&
                    (relationshipStatus?.includes(patientPreIntake?.relationship_to_emergency_contact)
                        ? patientPreIntake?.relationship_to_emergency_contact
                        : "Other"),
                other: relationshipStatus?.includes(
                    patientPreIntake?.relationship_to_emergency_contact
                )
                    ? ""
                    : patientPreIntake?.relationship_to_emergency_contact,
            })
        );

        // Dental Insurance Information
        const dentistInsuranceInfo = [] as any;
        if (patientPreIntake?.insurance?.length) {
            patientPreIntake?.insurance?.map((res: any) => {
                dentistInsuranceInfo.push({
                    insuranceProvider: res.insurance_provider || "",
                    insuranceGroup: res.insurance_group_number || "",
                    insuranceID: res.insurance_id || "",
                    planMemberName: res.plan_member_name || "",
                    planMemberDob: res.plan_member_dob || null,
                    position: res?.position || 0
                });
                return true;
            });
        } else {
            dentistInsuranceInfo.push({
                insuranceProvider: "",
                insuranceGroup: "",
                insuranceID: "",
                planMemberName: "",
                planMemberDob: null,
                position: 0
            });
        }

        yield put(updateDentistInsuranceInfoAction(dentistInsuranceInfo));

        // Let’s get to know you better
        yield put(
            updateLetsGetToKnowYouBetterAction({
                occupation: patientPreIntake?.occupation,
                isNightShiftInvolvedWithYourOccupation: getYesNo(
                    patientPreIntake?.does_your_work_involve_night_shift
                ),
                maritalStatus: patientPreIntake?.marital_status,
                referredBy: patientPreIntake?.referred_by,
                doYouHaveDentalInsurance: getYesNo(patientPreIntake?.dental_insurance),
            })
        );

        // General Health
        yield put(
            updateGeneralHealthAction({
                historyOfSmoking: patientPreIntake?.history_of_smoking,
                alcoholAverageWeek: patientPreIntake?.alcohol_usage_in_week,
                drugUse: getYesNo(patientPreIntake?.recreational_drug_use),
                drugInputUse: patientPreIntake?.recreational_drug_use_other,
                heightUnit: patientPreIntake?.height_unit || "ft",
                heightFT: patientPreIntake?.height_unit === "ft" ? patientPreIntake?.height?.split(".")[0] : "",
                heightIN:
                    patientPreIntake?.height_unit === "ft" ? patientPreIntake?.height?.split(".")[1] || "" : "",
                heightCM: patientPreIntake?.height_unit === "cm" ? patientPreIntake?.height : "",
                weightUnit: patientPreIntake?.weight_unit || "lb",
                weight: patientPreIntake?.weight,
            })
        );

        // Dental Information
        yield put(
            updateDentalInfoAction({
                dentalAnxiety: patientPreIntake?.dental_anxiety,
                reasonForSchedulingThisVisit: patientPreIntake?.reason_for_scheduling_this_visit || '',
                nameOfPreviousOffice: patientPreIntake?.previous_dental_office_name,
                lastVisitAtDentistOffice: patientPreIntake?.last_see_dental_hygienist,
                lastTimeYouHadDentalXrayTaken: patientPreIntake?.date_of_last_dental_x_ray,
                doesYourPhysicianRecommendAntibiotic: getYesNo(
                    patientPreIntake?.antibiotic_premedication_require_prior_dental_appointments
                ),
                antibioticPremeditation: patientPreIntake?.antibiotic_premedication,
                feelDentalHealth: patientPreIntake?.dental_health_average,
                checkIfYouHaveEverHadFollowing: stringToArray(patientPreIntake?.dental_info) || [],
                rateAppearanceOfYourSmile: patientPreIntake?.appearance_smile_scale_to_10,
                correctAppearanceOfYourSmile: getYesNo(
                    patientPreIntake?.wish_correct_appearance_smile
                ),
                hadWhiterSmile: getYesNo(patientPreIntake?.wish_whiter_smile),
                doYouSnackMoreThatDay: getYesNo(patientPreIntake?.snack_3x_day),
                toothpasteBrand: patientPreIntake?.brand_toothpaste,
                toothExtractedInLastThreeYear: getYesNo(
                    patientPreIntake?.tooth_extracted_last_3_year
                ),
                fillingsInLastThreeYear: patientPreIntake?.filling_had_in_last_3_year,
                familyHistoryOfCavities: getYesNo(
                    patientPreIntake?.family_history_of_cavities_toothloss
                ),
                homeCareProtectiveFactors:
                    stringToArray(patientPreIntake?.homecare_protective_factors) || [],
            })
        );

        // Sleep Quality
        yield put(
            updateSleepQualityAction({
                diagnosedSleepApnea: getYesNo(patientPreIntake?.diagnosed_sleep_apnea_ever),
                neckCircumferenceMeasured: patientPreIntake?.neck_circumference,
                snoreLoudly: patientPreIntake?.loud_snore,
                feelDuringDay: getYesNo(patientPreIntake?.fatigue_daily_bases),
                gaspDuringSleep: getYesNo(patientPreIntake?.breathing_stop_sleep),
                difficultyBreathingNose: getYesNo(
                    patientPreIntake?.difficulty_breathing_through_your_nose
                ),
                useCPAPMachine: getYesNo(patientPreIntake?.cpap_machine),
                useOralAppliance: getYesNo(patientPreIntake?.oral_appliance_sleep),
                additionalSleep: [
                    checkSleepLocal(patientPreIntake?.sitting_reading_epworth_scale),
                    checkSleepLocal(patientPreIntake?.watching_tv_epworth_scale),
                    checkSleepLocal(patientPreIntake?.sitting_inactive_epworth_scale),
                    checkSleepLocal(patientPreIntake?.being_passenger_car_epworth_scale),
                    checkSleepLocal(patientPreIntake?.lying_down_afternoon_epworth_scale),
                    checkSleepLocal(patientPreIntake?.sitting_talking_someone_epworth_scale),
                    checkSleepLocal(patientPreIntake?.sitting_quietly_lunch_epworth_scale),
                    checkSleepLocal(patientPreIntake?.stopping_fewminutes_driving_epworth_scale),
                ],
            })
        );

        // Medical Health History
        yield put(
            updateMedicalHealthHistoryAction({
                physicianName: patientPreIntake?.physicians_name,
                physicianContactNumber: patientPreIntake?.physician_phone_number,
                dateOfLastVisit: patientPreIntake?.last_visit_to_physician,
                seriousIllnessesOrOperations: getYesNo(
                    patientPreIntake?.serious_illness_or_operation_existance
                ),
                seriousIllnessesOrOperationsInput:
                    patientPreIntake?.serious_illness_or_operation_name,
                takingMedicationsOrSupplements: getYesNo(
                    patientPreIntake?.currently_taking_any_medication
                ),
                takingMedicationsOrSupplementsInput: patientPreIntake?.medication_list,
                shortnessBreath: getYesNo(patientPreIntake?.chest_pain_shortness_breath),
                nursing: getYesNo(patientPreIntake?.nursing),
                takingBirthControl: getYesNo(patientPreIntake?.birth_control),
                pregnant: getYesNo(patientPreIntake?.pregnant),
                medicalConditions: stringToArray(patientPreIntake?.medical_conditions)?.map((condition: string) => parseInt(condition)) || [],
                medicalConditionsOther: patientPreIntake?.medical_conditions_other || "",
                checkboxListTwo: stringToArray(patientPreIntake?.allergies_list)?.map((allergies: string) => parseInt(allergies)) || [],
                checkboxListTwoOtherInput: patientPreIntake?.allergies_list_other || "",
            })
        );

        // Communication Preferences
        yield put(
            updateCommunicationPreferencesAction({
                appearanceOfYourTeeth: patientPreIntake?.appearance_teeth_scale,
                feelIfYouWereToLoseTooth: patientPreIntake?.loose_tooth_feeling_scale,
                yourTeethFunctionWell: patientPreIntake?.chew_importance_scale,
                keepYourTeethForRestOfYourLife: patientPreIntake?.importancy_of_teeth_health_scale,
                concernsWithAppearanceOfYourTeeth: getYesNo(
                    patientPreIntake?.concerns_appearance_teeth_existance
                ),
                concernsWithAppearanceOfYourTeethReason:
                    patientPreIntake?.concerns_appearance_teeth_existance_explain,
                teethSensitive: getYesNo(patientPreIntake?.teeth_sensitivity),
                teethSensitiveReason: patientPreIntake?.teeth_sensitivity_answer_yes,
                describeYourselfMore1: patientPreIntake?.describe_yourself_1,
                describeYourselfMore2: patientPreIntake?.describe_yourself_2,
            })
        );

        yield put(getHealthHistoryWithoutAcSuccess(data));

    } catch (e: any) {
        errorToaster(e?.meta?.message);
        if (payload?.payload?.errorCallback) {
            yield call(payload?.payload?.errorCallback, e?.detail)
        }
        yield put(getHealthHistoryWithoutAcFailure(e?.meta?.message));
    }
}

function* saveHealthHistoryWithoutAccountRequestWatch() {
    yield takeEvery(PUT_HEALTH_HISTORY_WITHOUT_AC_REQUEST, saveHealthHistoryWithoutAccountRequestSaga);
}

function* getHealthHistoryWithoutAccountRequestWatch() {
    yield takeEvery(GET_HEALTH_HISTORY_WITHOUT_AC_REQUEST, getHealthHistoryWithoutAccountRequestSaga);
}

export default function* healthHistoryWithoutAccountSaga() {
    yield all([
        saveHealthHistoryWithoutAccountRequestWatch(),
        getHealthHistoryWithoutAccountRequestWatch()
    ])
}