import {call, put, takeEvery, all} from 'redux-saga/effects';
import API from '../../../utils/api';
import {ApiEndpointVersion, checkAPIParams, errorToaster} from '../../../utils/helper';
import {
    getNotificationListTypeSuccessAction,
    getNotificationListTypeFailureAction,
    getNotificationListCacheDataClearRequest,
    putNotificationListTypeSuccessAction,
    putNotificationListTypeFailureAction,
    getNotificationCountTypeSuccessAction,
    getNotificationCountTypeFailureAction,
    getNotificationCountCacheDataClearRequest
} from '../../actions/dentist/notificationListingAction';
import {
    GET_NOTIFICATION_REQUEST,
    PUT_NOTIFICATION_REQUEST,
    GET_NOTIFICATION_COUNT_REQUEST
} from '../../actionTypes/dentist/notificationListing';

// get notification listing
function* getNotificationListingTypeRequest(payload: any): any {
    yield put(getNotificationListCacheDataClearRequest())
    try {
        const params = [
            {key: 'page', value: payload?.payload?.page},
            {key: 'size', value: payload?.payload?.size},
            {key: 'show_unread_only', value: payload?.payload?.showUnreadOnly}
        ]

        const url = `${ApiEndpointVersion}/user/notifications/${payload.payload?.clinicID}${checkAPIParams(params)}`
        const response = yield API.get(url)

        yield put(
            putNotificationListTypeSuccessAction({
                notificationData: response?.data?.detail,
                notificationMeta: response?.data?.meta,
            })
        )
        // successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            putNotificationListTypeFailureAction({
                notificationError: e?.meta?.message,
            })
        )
    }
}

// put notification
function* putNotificationStatusTypeRequest(payload: any): any {
    const newPayloadData = payload?.payload?.notificationID && payload?.payload?.notificationID
    try {
        const response = yield API.put(`${ApiEndpointVersion}/user/notification/read/${payload.payload?.clinicID}`, {
            ID: newPayloadData,
        });

        yield put(
            getNotificationListTypeSuccessAction({
                notificationData: response?.data?.detail,
                notificationMeta: response?.data?.meta,
            })
        )

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
        // successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getNotificationListTypeFailureAction({
                notificationError: e?.meta?.message,
            })
        )
    }
}

// get notification count
function* getNotificationCountTypeRequest(payload: any): any {
    yield put(getNotificationCountCacheDataClearRequest())
    try {
        const response = yield API.get(`${ApiEndpointVersion}/user/notifications/count/${payload.payload?.clinicID}`);

        yield put(
            getNotificationCountTypeSuccessAction({
                notificationCount: response?.data?.detail,
            })
        )

        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
        // successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getNotificationCountTypeFailureAction({
                notificationCountError: e?.meta?.message,
            })
        )
    }
}

function* getNotificationListingSaga() {
    yield takeEvery(GET_NOTIFICATION_REQUEST, getNotificationListingTypeRequest)
}

function* putNotificationStatusSaga() {
    yield takeEvery(PUT_NOTIFICATION_REQUEST, putNotificationStatusTypeRequest)
}

function* getNotificationCountSaga() {
    yield takeEvery(GET_NOTIFICATION_COUNT_REQUEST, getNotificationCountTypeRequest)
}

export default function* notificationListingSaga() {
    yield all([
        getNotificationListingSaga(),
        putNotificationStatusSaga(),
        getNotificationCountSaga()
    ])
}
