import React from 'react'
import { Box, List, ListItem, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment/moment";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import theme from '../../../theme';
import { RootState } from '../../../redux/reducers/rootReducer';
import { viewEventLogRequestAction } from '../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction';
import { dentistProfileData } from '../../../utils/helper';

const useStyle = makeStyles((theme: any) => ({
    customPopper: {
        zIndex: 1024,
    },
    arrow: {
        color: `${theme.palette.common.white} !important`,
    },
    feesCustomWidth: {
        backgroundColor: theme.palette.common.white,
        borderRadius: '4px',
        color: theme.palette.common.black50,
        boxShadow: '0px 10px 25px 10px rgba(136, 136, 136, 1)',
        maxWidth: '600px !important',
    },
}));

const OutstandingTreatment = () => {
    const classes = useStyle();
    const { patientId } = useParams();

    const dispatch = useDispatch()
    const {
        plannedTreatmentReducer: { treatmentFeesData },
    } = useSelector((state: RootState) => state);

    const totalFeeAmount = treatmentFeesData?.reduce((total: any, item: any) => {
        return total + item?.fee_amount;
    }, 0);

    const viewEventLog = () => {
        dispatch(viewEventLogRequestAction({
            patient_id: patientId || '',
            event: 'outstanding_treatment_hovers',
            user_id: dentistProfileData?.id
        }))
    }

    return (
        <Tooltip
            arrow
            classes={{
                popper: classes.customPopper,
                tooltip: classes.feesCustomWidth,
                arrow: classes.arrow
            }}
            title={
                treatmentFeesData?.length ?
                    <Box>
                        <Typography
                            p={1}
                            className={`labelColor fw-medium f-18 lh-22 d-flex-all-center`}
                            borderBottom={`1px solid ${theme.palette.divider}`}
                        >
                            {`Outstanding treatment value: $${totalFeeAmount?.toFixed(2)}`}
                        </Typography>

                        <List
                            sx={{
                                listStyle: "circle",
                                pl: 3,
                                maxHeight: '740px',
                                overflowY: 'auto'
                            }}
                        >
                            {treatmentFeesData?.map((item: any, index: number) => {
                                // Check if at least one of the fields exists
                                const shouldDisplay = item?.fee_amount || item?.description || item?.tooth_number;

                                return shouldDisplay ? (
                                    <ListItem
                                        key={index}
                                        className="labelColor fw-regular f-17 lh-25"
                                        sx={{
                                            display: "list-item",
                                            listStyleType: "circle",
                                            padding: '2px'
                                        }}
                                    >
                                        {item?.tooth_number ? `${item?.tooth_number}-` : ''}
                                        {item?.description ? `${item?.description}` : ''}
                                        {item?.pms_entry_datetime ? `-${moment(item?.pms_entry_datetime).format("YYYY/MM/DD")}` : ''}
                                        {` ${
                                            item?.fee_amount != null ? `($${item?.fee_amount?.toFixed(2)})` : ''
                                        }`}
                                    </ListItem>
                                ) : null; // Do not render anything if `shouldDisplay` is false
                            })}
                        </List>

                    </Box>
                    :
                    <Typography
                        p={1}
                        className={`labelColor fw-medium f-14 lh-19 d-flex-all-center`}
                    >
                        Outstanding treatment could not be found for this patient
                    </Typography>
            }
        >
            <Box display={'flex'} gap={1} mr={2.5} alignItems={'center'} className='cursor-pointer' onMouseEnter={viewEventLog}>
                <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    color={theme.palette.v2.primary.main}
                    fontSize={30}
                />
            </Box>
        </Tooltip>
    )
}

export default OutstandingTreatment