import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {useSelector} from "react-redux";
import PeriogramInputBoxes from '../PeriogramInputBoxes';
import {stringToArray} from "../../../../../../utils/helper";

const useStyles = makeStyles(() => ({
    perioFindingBox: {
        height: '14px',
        padding: '2px 0'
    }
}));

type Props = {
    tooth: number;
    lingual?: boolean;
    disabledField: any
    isImpactedTooth: any
};

const PeriogramBoxWrapper = React.memo((props: Props) => {
    const {tooth, lingual, disabledField, isImpactedTooth} = props;
    const classes = useStyles();
    const bottomJaw = tooth > 28;
    const {
        examFindingReducer: {examDataFromAPI},
        wellnessProfileFilters: {wellnessProfileData}
    } = useSelector((state: any) => state) as any;

    const getPODSite = (site: string) => {
        let toothSite: any;
        if (site === 'center') {
            toothSite = lingual ? 'lingual_mm' : 'buccal_mm';
        } else {
            if ((tooth > 10 && tooth < 19) || (tooth > 30 && tooth < 39)) {
                toothSite =
                    site === 'right'
                        ? `mesial_${lingual ? 'lingual' : 'buccal'}_mm`
                        : site === 'left'
                            ? `distal_${lingual ? 'lingual' : 'buccal'}_mm`
                            : '';
            } else {
                toothSite =
                    site === 'right'
                        ? `distal_${lingual ? 'lingual' : 'buccal'}_mm`
                        : site === 'left'
                            ? `mesial_${lingual ? 'lingual' : 'buccal'}_mm`
                            : '';
            }
        }
        return toothSite||'';
    };
    const getSite = (site: string) => {
        let newSite = '' as string;
        if ((tooth > 10 && tooth < 19) || (tooth > 30 && tooth < 39)) {
            newSite = site === 'right' ? 'M' : site === 'left' ? 'D' : '';
        } else {
            newSite = site === 'right' ? 'D' : site === 'left' ? 'M' : '';
        }
        return `${newSite}${lingual ? 'L' : 'B'}`;
    };
    const getDisabledPD = disabledField?.includes("probing_depth")
    const getDisabledBOP = disabledField?.includes("bleed_on_probing")
    const getDisabledPlaque = disabledField?.includes("plaque")
    const isMissingTooth = stringToArray(examDataFromAPI?.occlusion_exam?.missing_tooth_tooth_number || '').includes(`${tooth}`)
    const toothSite = ['left', 'center', 'right']
    return (
        <Box sx={{
            display: isImpactedTooth?.hasTooth ? 'none' : 'flex',
            flexDirection: (bottomJaw && !lingual) || (!bottomJaw && lingual) ? 'column-reverse' : 'column',
        }}
        >
            {!lingual && (<Box className={`${classes.perioFindingBox} `} marginBottom={lingual ? 1.75 : 1.1}>
                {/*Mobility*/}
                <PeriogramInputBoxes value={0} variant={''} tooth={tooth} finding={'mobility'}
                                     disabled={disabledField?.includes("mobility") || isMissingTooth || wellnessProfileData?.is_wellness_locked}/>
            </Box>)}
            <Box
                className={`${classes.perioFindingBox} ${bottomJaw && 'd-flex justify-center'}  `}
                marginBottom={1}
                display={'flex'}>
                {/* Probing Depth*/}
                {toothSite?.map((site: string, index: number) => (
                    <PeriogramInputBoxes
                        value={0}
                        variant={'small'}
                        tooth={tooth}
                        finding={'probing_depth'}
                        toothSite={getPODSite(site)}
                        key={index}
                        disabled={getDisabledPD || isMissingTooth || wellnessProfileData?.is_wellness_locked}
                    />
                ))}
            </Box>
            <Box
                className={`${classes.perioFindingBox} ${bottomJaw && 'd-flex justify-center'}`}
                marginBottom={1}
                display={'flex'}>
                {/*Bleeding on Probing*/}
                {toothSite?.map((site: string, index: number) => (
                    <PeriogramInputBoxes
                        value={0}
                        variant={'small'}
                        tooth={tooth}
                        finding={'bleed_on_probing'}
                        type='image'
                        toothSite={getSite(site)}
                        key={index}
                        disabled={getDisabledBOP || isMissingTooth || wellnessProfileData?.is_wellness_locked}
                    />
                ))}
            </Box>
            <Box
                className={`${classes.perioFindingBox} ${bottomJaw && 'd-flex justify-center'}`}
                marginBottom={!bottomJaw && !lingual ? 0.8 : 1}
                display={'flex'}>
                {/*Plaque*/}
                {toothSite?.map((site: string, index: number) => (
                    <PeriogramInputBoxes
                        value={0}
                        variant={'small'}
                        tooth={tooth}
                        finding={'plaque'}
                        type='image'
                        toothSite={getSite(site)}
                        key={index}
                        disabled={getDisabledPlaque || isMissingTooth || wellnessProfileData?.is_wellness_locked}
                    />
                ))}
            </Box>
            <Box className={`${classes.perioFindingBox}`} marginBottom={!bottomJaw && !lingual ? 0.8 : 1}>
                {/*furcation*/}
                <PeriogramInputBoxes
                    value={0}
                    variant={''}
                    tooth={tooth}
                    finding={'furcation'}
                    type='image'
                    toothSite={lingual ? 'lingual_grade' : 'buccal_grade'}
                    disabled={disabledField?.includes('furcation') || isMissingTooth || wellnessProfileData?.is_wellness_locked}
                />
            </Box>
            <Box className={`${classes.perioFindingBox}`} marginBottom={lingual && bottomJaw ? 1.75 : 1.1}>
                {/*Recession*/}
                <PeriogramInputBoxes
                    value={0}
                    variant={''}
                    tooth={tooth}
                    finding={'recession'}
                    toothSite={lingual ? 'lingual_mm' : 'buccal_mm'}
                    disabled={disabledField?.includes('recession') || isMissingTooth || wellnessProfileData?.is_wellness_locked}
                />
            </Box>
        </Box>
    );
})

export default PeriogramBoxWrapper;
