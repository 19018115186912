import React from 'react'
import {makeStyles} from '@mui/styles';
import {Box, Divider, Typography} from '@mui/material'
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from "react-redux";
import CardComponent from '../../../common/UIComponents/CardComponent'
import theme from '../../../../theme';
import {RootState} from "../../../../redux/reducers/rootReducer";
import NoDataFound from "../../../common/NoDataFound";
import { formatPhoneNumber } from '../../../../utils/helper';

const useStyles = makeStyles(() => ({
    userprofile: {
        borderRadius: '50%',
        position: 'relative',
        width: '104px',
        height: '100%',

    },
    profileBorder: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        padding: '4px',
        border: `1px solid ${theme.palette.v2.primary.main}`,
        borderRadius: '50%',
        width: '104px',
        height: '104px',

        [theme.breakpoints.down(1415)]: {
            width: '90px',
            height: '90px',
        },
        [theme.breakpoints.down(1000)]: {
            width: '80px',
            height: '80px',
        },
    },
    patientName: {
        fontSize: '26px',
        [theme.breakpoints.down(1415)]: {
            fontSize: '20px',
        },
        [theme.breakpoints.down(1009)]: {
            fontSize: '18px',
        },

    },
    profileNameInitial: {
        borderRadius: '50%',
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '104px',
        opacity: 1,
        [theme.breakpoints.down(1415)]: {
            width: '90px',
        }
    },
    nameTextField: {
        color: theme.palette.v2.primary.main,
        fontWeight: 900,
        fontSize: 34,
        textTransform: 'uppercase',
    },
}));

const WellnessPatientInformation = () => {
    const classes = useStyles();
    const {patientId} = useParams();
    const navigate = useNavigate()
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);
    const patientDetails = wellnessProfileData?.patient_details

    const viewAll = () => {
        navigate(`/dentist/appointment/health-history/${patientId}`)
    }

    return (
        <CardComponent bgColor={theme.palette.v2.primary.primary25} color={theme.palette.v2.primary.main}
                       title='Basic Patient Info' rightText='View all' height={230} btnClick={viewAll}>
            {patientDetails ?
                <React.Fragment>
                    <Box width={"100%"} display={'flex'} alignItems={'center'} pt={'6px'} pb={2}>
                        <Box className={classes.profileBorder}>
                            {patientDetails?.profile_picture ? (
                                <img
                                    src={patientDetails?.profile_picture}
                                    alt={"user"}
                                    className={classes.userprofile}
                                />
                            ) : (
                                <Box className={classes.profileNameInitial}>
                                    <Typography
                                        mt={'5px'}
                                        variant={"subtitle1"}
                                        component={"span"}
                                        className={`${classes.nameTextField} fw-medium`}
                                    >
                                        {patientDetails?.first_name?.charAt(0)}
                                        {patientDetails?.last_name?.charAt(0)}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box ml={4} width={'100%'}>
                            <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
                                <Typography textTransform={'capitalize'} pb={1}
                                            className={`${classes.patientName} lh-28 f-w-500 fw-medium`}
                                            color={theme.palette.v2.primary.main}>{`${patientDetails?.first_name || '-'} ${patientDetails?.last_name || '-'}`}
                                </Typography>
                                {/*TODO: Remove the active/inactive chip until the flow of that is confirmed.
                                !*{patientDetails?.status ? <Chip*!/*/}
                                {/*    sx={{*/}
                                {/*        background: patientDetails?.status === 'Active' ? theme.palette.v2.primary.main : theme.palette.error.main,*/}
                                {/*        color: theme.palette.common.white*/}
                                {/*    }}*/}
                                {/*    label={patientDetails?.status}/> : null}*/}
                            </Box>
                            <Typography pb={1} className='f-16 f-w-400 fw-regular lh-19'
                                        color={theme.palette.text.darkGrey}>{patientDetails?.date_of_birth || '-'}</Typography>
                            <Typography pb={1} className='f-16 f-w-400 fw-regular lh-19'
                                        color={theme.palette.text.darkGrey}>{patientDetails?.gender || '-'}</Typography>
                        </Box>
                    </Box>
                    <Divider/>
                    <Box display={'flex'} pt={2} pb={'6px'} flexWrap={'wrap'} sx={{justifyContent: "space-between"}}>
                        <Box>
                            <Typography pb={0.5} color={theme.palette.text.secondary}
                                        className='f-14 f-w-400 fw-regular lh-16'>
                                E-Mail
                            </Typography>
                            <Typography className='f-14 f-w-400 fw-regular labelColor lh-19'>
                                {patientDetails?.email}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography pb={0.5} color={theme.palette.text.secondary}
                                        className='f-14 f-w-400 fw-regular lh-16'>
                                Contact Number
                            </Typography>
                            <Typography className='f-14 f-w-400 fw-regular labelColor lh-19'>
                                {patientDetails?.mobile_number ? '+1 ' : ''}{formatPhoneNumber(patientDetails?.mobile_number)}
                            </Typography>
                        </Box>
                    </Box>
                </React.Fragment>
                : <NoDataFound errorMessage={'data'}/>}
        </CardComponent>
    )
}

export default WellnessPatientInformation