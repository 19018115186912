import {
    DENTIST_LOGOUT_REQUEST,
    DENTIST_LOGOUT_REQUEST_FAILURE,
    DENTIST_LOGOUT_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {logoutActions, logoutInterface} from '../../types/authActionTypes/logOutActionTypes';

const initialState: logoutInterface = {
    logoutLoader: false,

};

const logoutReducer = (state = initialState, action: logoutActions) => {
    switch (action.type) {
        case DENTIST_LOGOUT_REQUEST:
            return {
                ...state,
                logoutLoader: true,
            };

        case DENTIST_LOGOUT_REQUEST_SUCCESS:
            return {
                ...state,
                logoutLoader: false,
            };

        case DENTIST_LOGOUT_REQUEST_FAILURE:
            return {
                ...state,
                logoutLoader: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default logoutReducer;
