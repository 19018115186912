import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../../utils/api';
import {
    getRiskProfileRequestSuccess,
    getRiskProfileRequestFailure,
    getRiskProfileCacheDataClearRequest
} from '../../actions/patient/PatientRiskProfileAction/PatientRiskProfileAction';
import {
    GET_RISK_PROFILE_REQUEST,
} from '../../actionTypes/patient/riskProfile';
import {ApiEndpointVersion} from "../../../utils/helper";

function* getRiskProfileRequestSaga(payload: any): any {
    yield put(getRiskProfileCacheDataClearRequest());
    try {
        const response = yield API.get(
            payload.payload.appointmentID
                ? `${ApiEndpointVersion}/risk/profile/${payload.payload.appointmentID}`
                : `/${ApiEndpointVersion}/risk/profile/patient/${payload.payload.patient_id}`
        );
        if (payload.payload.callback) {

            yield call(payload.payload.callback, response.data?.detail);
        }
        yield put(
            getRiskProfileRequestSuccess({
                data: response.data?.detail
            })
        );
    } catch (e: any) {
        //TODO: update toast according to status code
        // toast.error(e.detail);
        yield put(getRiskProfileCacheDataClearRequest());
        yield put(
            getRiskProfileRequestFailure({
                error: e?.detail
            })
        );
    }
}

function* getPatientRiskProfileSaga() {
    yield takeEvery(GET_RISK_PROFILE_REQUEST, getRiskProfileRequestSaga);
}


export default function* patientRiskProfileSaga() {
    yield all([
        getPatientRiskProfileSaga(),
    ]);
}
