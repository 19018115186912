import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../../utils/api';
import { GET_TREATMENT_FEES_REQUEST } from '../../../../actionTypes/dentist/clinicalExam/treatment/plannedTreatments';
import {
    getTreatmentDataFeesFailAction,
    getTreatmentDataFeesSuccessAction
} from '../../../../actions/dentist/clinicalExam/treatment/plannedTreatments';
import {ApiEndpointVersion} from "../../../../../utils/helper";

function* getTreatmentFeesDataSaga(payload: any): any {
    try {
        const url = payload.payload?.appointmentID ? `/${ApiEndpointVersion}/treatment/tx-value/${payload.payload?.patientID}?appointmnet_id=${payload.payload?.appointmentID}` : `/${ApiEndpointVersion}/treatment/tx-value/${payload.payload?.patientID}`
        const response = yield API.get(url);
        const data = response?.data?.detail
        yield put(getTreatmentDataFeesSuccessAction(data));

    } catch (e: any) {
        yield put(getTreatmentDataFeesFailAction(e));
    }
}

function* getTreatmentFeesDataWatcher() {
    yield takeEvery(GET_TREATMENT_FEES_REQUEST, getTreatmentFeesDataSaga);
}

export default function* plannedTreatmentsSaga() {
    yield all([getTreatmentFeesDataWatcher()]);
}
