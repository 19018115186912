import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
    changeWellnessComplaintsLocalRequestAction,
    changeWellnessComplaintsRequestAction, changeWellnessHygieneRegimeRequestAction,
    changeWellnessPatientPreferencesRequestAction,
    changeWellnessSocialNotesRequestAction,
    createAppointmentFromWellnessRequestFailure,
    createAppointmentFromWellnessRequestSuccess,
    generateRiskWellnessPMSRequestFailureAction,
    generateRiskWellnessPMSRequestSuccessAction,
    getWellnessProfileFailureAction,
    getWellnessProfileSuccessAction,
    updateDentistWellnessComplaintsRequestFailure,
    updateDentistWellnessComplaintsRequestSuccess,
    updateDentistWellnessProfileRequestFailure,
    updateDentistWellnessProfileRequestSuccess,
    updateWellnessHygieneRegimeRequestFailureAction,
    updateWellnessHygieneRegimeRequestSuccessAction
} from '../../../actions/dentist/wellnessProfile/wellnessProfileFiltersAction';
import {
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST,
    GET_WELLNESS_REQUEST,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST, UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST
} from '../../../actionTypes/dentist/wellnessProfile/wellnessProfileFilters';
import API from '../../../../utils/api';
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../../utils/helper';

// get Wellness profile data
function* getWellnessProfileRequest(payload: any): any {
    try {
        const isPatient = payload?.payload?.isPatient
        const response = yield API.get(`/${ApiEndpointVersion}/wellness-profile/${payload?.payload?.patient_id}?clinic_id=${payload?.payload?.clinic_id}`)
        yield put(getWellnessProfileSuccessAction(response?.data?.detail))
        if (!isPatient) {
            const wellnessData = response?.data?.detail
            yield put(changeWellnessComplaintsLocalRequestAction(wellnessData?.chief_complaint))
            yield put(changeWellnessComplaintsRequestAction({ chief_complaint: wellnessData?.chief_complaint?.present?.chief_complaint }))
            yield put(changeWellnessPatientPreferencesRequestAction({ patient_preferences: wellnessData?.patient_preferences }))
            yield put(changeWellnessSocialNotesRequestAction({ social_note: wellnessData?.social_note }))
            yield put(changeWellnessHygieneRegimeRequestAction(wellnessData?.hygiene_regimen))
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(getWellnessProfileFailureAction(e?.meta?.message))
    }
}

//Edit dentist wellness Profile
function* updateDentistWellnessProfileRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/wellness-profile/dentist`, payload.payload)
        yield put(
            updateDentistWellnessProfileRequestSuccess()
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            updateDentistWellnessProfileRequestFailure()
        );
    }
}

// check un-check Complaints
function* updateDentistWellnessComplaintsRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/wellness-profile/complaint`, payload.payload)
        yield put(updateDentistWellnessComplaintsRequestSuccess());
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        // successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(updateDentistWellnessComplaintsRequestFailure());
    }
}

function* createAppointmentFromWellnessRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/wellness-profile/create-appointment`, payload.payload)
        yield put(
            createAppointmentFromWellnessRequestSuccess()
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            createAppointmentFromWellnessRequestFailure()
        );
    }
}


function* updateDentistWellnessProfileHygieneRegimeRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/wellness-profile/hygiene-regime`, payload.payload)
        yield put(
            updateWellnessHygieneRegimeRequestSuccessAction()
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            updateWellnessHygieneRegimeRequestFailureAction()
        );
    }
}

function* generateWellnessRiskScoreDataRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/risk/profile/wellness/${payload.payload?.patient_id}`)
        yield put(
            generateRiskWellnessPMSRequestSuccessAction(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
    } catch (e: any) {
        yield put(
            generateRiskWellnessPMSRequestFailureAction(e?.message)
        );
    }
}

function* getWellnessProfileWatch() {
    yield takeEvery(GET_WELLNESS_REQUEST, getWellnessProfileRequest)
}

function* updateDentistWellnessProfileWatch() {
    yield takeEvery(UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST, updateDentistWellnessProfileRequestSaga);
}

function* updateDentistWellnessComplaintsWatch() {
    yield takeEvery(UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST, updateDentistWellnessComplaintsRequestSaga);
}

function* createAppointmentFromWellnessWatch() {
    yield takeEvery(CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST, createAppointmentFromWellnessRequestSaga);
}

function* updateDentistWellnessProfileHygieneRegimeWatch() {
    yield takeEvery(UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST, updateDentistWellnessProfileHygieneRegimeRequestSaga);
}

function* generateWellnessRiskScoreDataWatch() {
    yield takeEvery(GENERATE_RISK_SCORE_WELLNESS_REQUEST, generateWellnessRiskScoreDataRequestSaga);
}

export default function* WellnessSaga() {
    yield all([
        getWellnessProfileWatch(),
        updateDentistWellnessProfileWatch(),
        updateDentistWellnessComplaintsWatch(),
        createAppointmentFromWellnessWatch(),
        updateDentistWellnessProfileHygieneRegimeWatch(),
        generateWellnessRiskScoreDataWatch()
    ])
}
