import {
  UPDATE_SUB_STATUS_REQUEST,
  UPDATE_SUB_STATUS_SUCCESS,
  UPDATE_SUB_STATUS_FAIL
} from '../../actionTypes/master/updateSubStatusActions';
import {
  updateSubStatusRequest,
  updateSubStatusRequestFailure,
  updateSubStatusRequestFailurePayload,
  updateSubStatusRequestPayload,
  updateSubStatusRequestSuccess,
  updateSubStatusRequestSuccessPayload
} from '../../types/dentist/examStatus';

// Update recommendations_treatment data api actions
export const updateSubStatusAction = (payload: updateSubStatusRequestPayload): updateSubStatusRequest => ({
  type: UPDATE_SUB_STATUS_REQUEST,
  payload
});

export const updateSubStatusSuccessAction = (payload: updateSubStatusRequestSuccessPayload): updateSubStatusRequestSuccess => ({
  type: UPDATE_SUB_STATUS_SUCCESS,
  payload
});

export const updateSubStatusFailAction = (payload: updateSubStatusRequestFailurePayload): updateSubStatusRequestFailure => ({
  type: UPDATE_SUB_STATUS_FAIL,
  payload
});
