import {
  DOWNLOAD_PRE_INTAKE_PDF_FAIL,
  DOWNLOAD_PRE_INTAKE_PDF_REQUEST,
  DOWNLOAD_PRE_INTAKE_PDF_SUCCESS, GET_FIELDS_REQUEST, GET_FIELDS_REQUEST_FAIL, GET_FIELDS_REQUEST_SUCCESS,
  GET_PRE_INTAKE_DATA_FAIL,
  GET_PRE_INTAKE_DATA_REQUEST,
  GET_PRE_INTAKE_DATA_SUCCESS,
  IS_PRE_INTAKE_SUBMIT,
  PUT_PRE_INTAKE_DATA_FAIL,
  PUT_PRE_INTAKE_DATA_REQUEST,
  PUT_PRE_INTAKE_DATA_SUCCESS,
  UPDATE_COMMUNICATION_PREFERENCES,
  UPDATE_DENTAL_INFO,
  UPDATE_DENTIST_INSURANCE_INFO,
  UPDATE_EMERGENCY_CONTACT_INFO,
  UPDATE_GENERAL_HEALTH,
  UPDATE_LETS_GET_TO_KNOW_YOU_BETTER,
  UPDATE_MEDICAL_HEALTH_HISTORY,
  UPDATE_PATIENT_INFO,
  UPDATE_PRE_INTAKE_VALIDATIONS,
  UPDATE_SLEEP_QUALITY
} from '../../actionTypes/dentist/preIntake';

export const updatePatientInfoAction = (payload: any): any => ({
  type: UPDATE_PATIENT_INFO,
  payload
});

export const updateDentistInsuranceInfoAction = (payload: any): any => ({
  type: UPDATE_DENTIST_INSURANCE_INFO,
  payload
});

// Emergency Contact Information
export const updateEmergencyContactInfoAction = (payload: any): any => ({
  type: UPDATE_EMERGENCY_CONTACT_INFO,
  payload
});

export const updateGeneralHealthAction = (payload: any): any => ({
  type: UPDATE_GENERAL_HEALTH,
  payload
});

export const updateSleepQualityAction = (payload: any): any => ({
  type: UPDATE_SLEEP_QUALITY,
  payload
});

export const updateMedicalHealthHistoryAction = (payload: any): any => ({
  type: UPDATE_MEDICAL_HEALTH_HISTORY,
  payload
});

// Let’s get to know you better
export const updateLetsGetToKnowYouBetterAction = (payload: any): any => ({
  type: UPDATE_LETS_GET_TO_KNOW_YOU_BETTER,
  payload
});

// Dental Information
export const updateDentalInfoAction = (payload: any): any => ({
  type: UPDATE_DENTAL_INFO,
  payload
});

// Communication Preferences
export const updateCommunicationPreferencesAction = (payload: any): any => ({
  type: UPDATE_COMMUNICATION_PREFERENCES,
  payload
});

// Get Pre-intake data api actions
export const getPreIntakeDataAction = (payload: any): any => ({
  type: GET_PRE_INTAKE_DATA_REQUEST,
  payload
});

export const getPreIntakeDataSuccessAction = (payload: any): any => ({
  type: GET_PRE_INTAKE_DATA_SUCCESS,
  payload
});

export const getPreIntakeDataFailAction = (): any => ({
  type: GET_PRE_INTAKE_DATA_FAIL
});

// Put Pre-intake data api actions
export const putPreIntakeDataAction = (payload: any): any => ({
  type: PUT_PRE_INTAKE_DATA_REQUEST,
  payload
});

export const putPreIntakeDataSuccessAction = (): any => ({
  type: PUT_PRE_INTAKE_DATA_SUCCESS
});

export const putPreIntakeDataFailAction = (): any => ({
  type: PUT_PRE_INTAKE_DATA_FAIL
});

export const updatePreIntakeValidationsAction = (payload: any): any => ({
  type: UPDATE_PRE_INTAKE_VALIDATIONS,
  payload
});
export const isPreIntakeSubmitAction = (payload: any): any => ({
  type: IS_PRE_INTAKE_SUBMIT,
  payload
});

// Download Pre-intake data api actions
export const downloadPreIntakePdfAction = (payload: any): any => ({
  type: DOWNLOAD_PRE_INTAKE_PDF_REQUEST,
  payload
});

export const downloadPreIntakePdfSuccessAction = (payload: any): any => ({
  type: DOWNLOAD_PRE_INTAKE_PDF_SUCCESS,
  payload
});

export const downloadPreIntakePdfFailAction = (): any => ({
  type: DOWNLOAD_PRE_INTAKE_PDF_FAIL
});

// get field for pre-intake
export const getFieldsAction = (payload: any): any => ({
  type: GET_FIELDS_REQUEST,
  payload
});

export const getFieldsSuccessAction = (payload: any): any => ({
  type: GET_FIELDS_REQUEST_SUCCESS,
  payload
});

export const getFieldsFailAction = (payload: any): any => ({
  type: GET_FIELDS_REQUEST_FAIL,
  payload
});