import React from 'react';
import {Box, Divider,} from '@mui/material';
import {useSelector} from 'react-redux';
import PeriogramLeftLabels from './PeriogramLeftLabels';
import PeriogramSyncPms from './PeriogramSyncPms';
import PeriogramTeeths from './PeriogramTeeths';
import PeriogramDate from "./PeriogramDate";
import theme from '../../../../../theme';

type Props = {
    showFooter?: boolean | undefined
}

const Periogram = React.memo((props: Props) => {
    const {showFooter} = props
    const {palette} = theme
    const {
        appointmentsReducer: {appointmentDetails},
        examFindingReducer: {examDataFromAPI},
        clinicalExamFilters: {examAccordionExpanded, openSpeedometerClinical}
    } = useSelector((state: any) => state) as any;
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const calculatedHeight = isWellnessProfile
        ? 'calc(100vh - 225px)'
        : `calc(100vh - ${examAccordionExpanded
            ? `${openSpeedometerClinical
                ? (showFooter ? "488px" : '405px')
                : (showFooter ? "335px" : '253px')}`
            : `${openSpeedometerClinical ? "490px" : (showFooter ? '335px' : '253px')}`
        })}`;

    return (
        <Box
            height={calculatedHeight}
            overflow='scroll'>
            {(appointmentDetails?.pms_appointment_no !== null || appointmentDetails?.patient?.pms_id !== null) && !isWellnessProfile &&
                <PeriogramSyncPms/>
            }
            {examDataFromAPI?.created_at && <PeriogramDate/>}

            {/*periogram chart start*/}
            <Box className={'d-flex-all-center'} alignItems={'flex-start !important'} mt={2} minWidth={1085}>
                {/*Left Labels section */}
                <PeriogramLeftLabels/>
                {/*Chart section*/}
                <Box>
                    {/*  ----------------------------   11 - 28 Start   -------------------------*/}
                    <Box className='d-flex justify-center align-flex-start'>
                        <PeriogramTeeths/>
                    </Box>
                    {/*  ----------------------------   11 - 28 END   -------------------------*/}
                    <Divider color={palette.common.black50}
                             sx={{width: '854px', height: '4px', margin: '20px 0 15px 0px'}}/>
                    {/*  ----------------------------   31 - 48 Start   -------------------------*/}
                    <Box className='d-flex justify-center align-flex-start' sx={{transform: 'scaleX(-1)'}}>
                        <PeriogramTeeths bottomJaw={true}/>
                    </Box>
                    {/*  ----------------------------   31 - 48 End   -------------------------*/}
                </Box>
            </Box>
            {/*periogram chart end*/}
        </Box>
    );
})

export default Periogram;
