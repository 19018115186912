import {
    communicationInterface,
    PatientCommunicationActions
} from "../../types/patient/auth/communicationsActionType";
import {
    PATIENT_COMMUNICATION_REQUEST, PATIENT_COMMUNICATION_REQUEST_FAILURE,
    PATIENT_COMMUNICATION_REQUEST_SUCCESS
} from "../../actionTypes/patient/patientCommunication";

const initialState: communicationInterface = {
    communicationLoader: false,
    communicationPreferences: false,
    communicationError: null
};
const patientCommunicationReducer = (state = initialState, action: PatientCommunicationActions) => {
    switch (action.type) {
        case PATIENT_COMMUNICATION_REQUEST:
            return {
                ...state,
                communicationLoader: true
            };

        case PATIENT_COMMUNICATION_REQUEST_SUCCESS:
            return {
                ...state,
                communicationLoader: false,
                communicationPreferences: action.payload,
                communicationError: null
            };

        case PATIENT_COMMUNICATION_REQUEST_FAILURE:
            return {
                ...state,
                communicationLoader: false,
                communicationPreferences: null,
                communicationError: action.payload
            };

        default:
            return {
                ...state
            };
    }
};
export default patientCommunicationReducer;
