// @ts-ignore
import {put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {DENTIST_LOGOUT_REQUEST} from "../../actionTypes/auth";
import {ApiEndpointVersion, errorToaster, getLocalStorageItem} from "../../../utils/helper";
import {
    logoutRequestFailure,
    logoutRequestSuccess,
} from "../../actions/authAction/logOutAction";

function* logoutRequestSaga({payload}: any): any {
    try {
        const response = yield API.delete(`/${ApiEndpointVersion}/auth/device/token`, {
            data: {
                device_token: getLocalStorageItem("dt") || "",
            },
        });
        if(response){
            yield put(logoutRequestSuccess());
        }

    } catch (e: any) {
        payload.errorCallback();
        errorToaster(e?.meta?.message);
        yield put(logoutRequestFailure());
    }
}

function* logoutSaga() {
    yield takeEvery(DENTIST_LOGOUT_REQUEST, logoutRequestSaga);
}

export default function* rootSaga() {
    yield all([logoutSaga()]);
}
