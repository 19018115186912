import {
    UPDATE_CHAT_BOX_VALUE
} from '../../actionTypes/master/chatBox';

export const updateChatBoxValueAction = (payload: any): any => ({
    type: UPDATE_CHAT_BOX_VALUE,
    payload
});


