import {
  CAME_ODONTOGRAM_FROM_TREATMENT,
  HANDLE_ODONTOGRAM_FORM_FINDING_SELECTION, OPEN_ODONTOGRAM_GROUP_FINDING_POPUP, PROCEDURE_FILTERS,
  SELECT_ODNTOGRAM_FINDING,
  SELECT_ODNTOGRAM_TOOTH,
  SET_EDIT_ODONTOGRAM_FINDING_DATA, SHOW_REJECTED_TREATMENT_FILTER
} from "../../../../actionTypes/dentist/clinicalExam/exams/odontogram";

const initialState: any = {
  teeth: [],
  finding: '',
  showForm: true,
  editFindingData: null,
  selectedFormFinding: [],
  cameOdontogramFromTreatment: false,
  procedureFilters: [],
  showRejectedTreatmentsOnly: false,
  openOdontogramGroupFindingPopup: null
};

const odontogramReducer = (state = initialState, action: any) => {
  switch (action?.type) {
    case SELECT_ODNTOGRAM_TOOTH:
      return {
        ...state,
        teeth: action?.payload
      };
    case SELECT_ODNTOGRAM_FINDING:
      return {
        ...state,
        finding: action?.payload
      };
    case SET_EDIT_ODONTOGRAM_FINDING_DATA:
      return {
        ...state,
        editFindingData: action?.payload
      };
    case HANDLE_ODONTOGRAM_FORM_FINDING_SELECTION:
      return {
        ...state,
        selectedFormFinding: action?.payload
      };
    case CAME_ODONTOGRAM_FROM_TREATMENT:
      return {
        ...state,
        cameOdontogramFromTreatment: action?.payload
      };

    case PROCEDURE_FILTERS:
      return {
        ...state,
        procedureFilters: action?.payload
      };
    case SHOW_REJECTED_TREATMENT_FILTER:
      return {
        ...state,
        showRejectedTreatmentsOnly: action?.payload
      };
    case OPEN_ODONTOGRAM_GROUP_FINDING_POPUP:
      return {
        ...state,
        openOdontogramGroupFindingPopup: action?.payload
      };
    default:
      return {
        ...state
      };
  }
};

export default odontogramReducer;
