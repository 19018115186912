import axios from "axios";
import {url, getLocalStorageItem} from "./helper";

const api = axios.create({
    baseURL: url,
    headers: {
        'Access-Control-Allow-Origin': process.env.REACT_APP_API_ENDPOINT as string,
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
});

api.interceptors.request.use(
    function (config: any) {
        config.headers.Authorization = window.location?.pathname?.split('/')?.includes('patient') ? `Bearer ${getLocalStorageItem("patientToken")}` : `Bearer ${getLocalStorageItem("dentistToken")}`;
        return config;
    },
    function (error: any) {
        return Promise.reject(error);
    }
);
api.interceptors.response.use(
    (response: any) => {
        // response.headers.accessControlAllowOrigin = '*';
        // response.headers.caseControl = 'private';
        return response;
    },
    function (error: any) {
        if (error?.response?.status === 401) {
            const isDentist = window?.location?.pathname.includes('dentist')
            let temp = window?.location?.pathname?.split('/')
            if (!temp?.includes('login')) {
                if (isDentist) {
                    localStorage.removeItem("dentistToken");
                    localStorage.removeItem("dentistUserData");
                    localStorage.removeItem("clinic");
                    localStorage.removeItem("clinicInfo");
                    localStorage.removeItem("dt");
                    window.location.href = "/dentist/login";
                } else {
                    localStorage.removeItem("patientToken");
                    localStorage.removeItem("patientUserData");
                    localStorage.removeItem("social_login");
                    localStorage.removeItem('patientClinicInfo')
                    localStorage.removeItem('patientClinic')
                    window.location.href = "/patient/login";
                }
            }
            return Promise.reject(error?.response?.data)
        } else {
            return Promise.reject(error?.response?.data);
        }
    }
);

export default api;
