import {
    PATIENT_APPOINTMENT_DETAILS_REQUEST,
    PATIENT_APPOINTMENT_DETAILS_CACHE_DATA_CLEAR_REQUEST,
    PATIENT_APPOINTMENT_DETAILS_REQUEST_FAILURE,
    PATIENT_APPOINTMENT_DETAILS_REQUEST_SUCCESS,
    PATIENT_APPOINTMENT_REQUEST,
    PATIENT_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
    PATIENT_APPOINTMENT_REQUEST_FAILURE,
    PATIENT_APPOINTMENT_REQUEST_SUCCESS,
    GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_SUCCESS,
    GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_FAILURE,
} from '../../../actionTypes/patient/patientAppointment';
import {
    PatientAppointmentDetailsRequest,
    PatientAppointmentDetailsRequestFailure,
    PatientAppointmentDetailsRequestFailurePayload,
    PatientAppointmentDetailsRequestPayload,
    PatientAppointmentDetailsRequestSuccess,
    PatientAppointmentDetailsRequestSuccessPayload,
    PatientAppointmentRequest,
    PatientAppointmentRequestFailure,
    PatientAppointmentRequestFailurePayload,
    PatientAppointmentRequestPayload,
    PatientAppointmentRequestSuccess,
    PatientAppointmentRequestSuccessPayload,
    GetAppointmentNotificationDentistRequestSuccessPayload,
    GetAppointmentNotificationDentistRequestFailurePayload,
    GetAppointmentNotificationDentistRequestSuccess, GetAppointmentNotificationDentistRequestFailure,
} from "../../../types/patient/patientAppointmentActionTypes";

export const patientAppointmentRequest = (payload: PatientAppointmentRequestPayload): PatientAppointmentRequest => ({
    type: PATIENT_APPOINTMENT_REQUEST,
    payload
});

export const patientAppointmentCacheDataClearRequest = (): any => ({
    type: PATIENT_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST
});

export const patientAppointmentRequestSuccess = (
    payload: PatientAppointmentRequestSuccessPayload
): PatientAppointmentRequestSuccess => ({
    type: PATIENT_APPOINTMENT_REQUEST_SUCCESS,
    payload
});

export const patientAppointmentRequestFailure = (
    payload: PatientAppointmentRequestFailurePayload
): PatientAppointmentRequestFailure => ({
    type: PATIENT_APPOINTMENT_REQUEST_FAILURE,
    payload
});

// Appointment Details

export const patientAppointmentDetailsRequest = (
    payload: PatientAppointmentDetailsRequestPayload
): PatientAppointmentDetailsRequest => ({
    type: PATIENT_APPOINTMENT_DETAILS_REQUEST,
    payload
});

export const patientAppointmentDetailCacheDataClearRequest = (): any => ({
    type: PATIENT_APPOINTMENT_DETAILS_CACHE_DATA_CLEAR_REQUEST
});

export const patientAppointmentDetailsRequestSuccess = (
    payload: PatientAppointmentDetailsRequestSuccessPayload
): PatientAppointmentDetailsRequestSuccess => ({
    type: PATIENT_APPOINTMENT_DETAILS_REQUEST_SUCCESS,
    payload
});

export const patientAppointmentDetailsRequestFailure = (
    payload: PatientAppointmentDetailsRequestFailurePayload
): PatientAppointmentDetailsRequestFailure => ({
    type: PATIENT_APPOINTMENT_DETAILS_REQUEST_FAILURE,
    payload
});

// TODO: for future use
// Get Appointment notification
// export const getAppointmentNotificationDentistRequest = (
//     payload: GetAppointmentNotificationDentistRequestPayload
// ): GetAppointmentNotificationDentistRequest => ({
//     type: GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST,
//     payload
// });
export const getAppointmentNotificationDentistRequestSuccess = (
    payload: GetAppointmentNotificationDentistRequestSuccessPayload
): GetAppointmentNotificationDentistRequestSuccess => ({
    type: GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_SUCCESS,
    payload
});
export const getAppointmentNotificationDentistRequestFailure = (
    payload: GetAppointmentNotificationDentistRequestFailurePayload
): GetAppointmentNotificationDentistRequestFailure => ({
    type: GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_FAILURE,
    payload
});

// TODO: for future use
// export const setDateForAppointment = (payload: SetDateForAppointmentPayload): SetDateForAppointment => ({
//     type: SET_DATE_APPOINTMENT,
//     payload
// });
