import React from 'react';
import {Box} from '@mui/material'; // Assuming you're using Material-UI
import Recession from '../../../../../../../../assets/images/periogramFindings/recession.svg';

interface Props {
    isFindingPresent: (position: string, type: string) => number;
    lingual?: boolean;
    bottomJaw: boolean;
}

const RecessionFinding = React.memo((props: Props) => {
    const {isFindingPresent, lingual, bottomJaw} = props
    const recessionValue = isFindingPresent('', 'recession');

    if (recessionValue <= 0) return null; // Only render if there's a recession present
    const transformValue = lingual
        ? bottomJaw ? '' : 'scaleX(-1)'
        : bottomJaw ? 'scaleX(-1)' : '';
    return (
        <Box
            className='pos-absolute d-flex align-flex-end'
            sx={{
                bottom: `${(parseInt(`${recessionValue}`) * 3) - 5}px`,
                zIndex: 1,
                transform: transformValue
            }}
        >
            <img src={Recession} alt={'Recession'}/>
        </Box>
    );
})

export default RecessionFinding;