import {
    GET_RISK_PROFILE_REQUEST,
    GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST,
    GET_RISK_PROFILE_REQUEST_FAILURE,
    GET_RISK_PROFILE_REQUEST_SUCCESS,
} from "../../../actionTypes/patient/riskProfile";
import {
    GetRiskProfileRequest,
    GetRiskProfileRequestFailure,
    GetRiskProfileRequestFailurePayload,
    GetRiskProfileRequestPayload,
    GetRiskProfileRequestSuccess,
    GetRiskProfileRequestSuccessPayload,
} from "../../../types/patient/patientRiskProfileActionTypes";

//Risk Assessment

export const getRiskProfileRequest = (
    payload: GetRiskProfileRequestPayload
): GetRiskProfileRequest => ({
    type: GET_RISK_PROFILE_REQUEST,
    payload,
});
export const getRiskProfileCacheDataClearRequest = (): any => ({
    type: GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST
})

export const getRiskProfileRequestSuccess = (
    payload: GetRiskProfileRequestSuccessPayload
): GetRiskProfileRequestSuccess => ({
    type: GET_RISK_PROFILE_REQUEST_SUCCESS,
    payload,
});

export const getRiskProfileRequestFailure = (
    payload: GetRiskProfileRequestFailurePayload
): GetRiskProfileRequestFailure => ({
    type: GET_RISK_PROFILE_REQUEST_FAILURE,
    payload,
});
