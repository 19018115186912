import {
  SELECT_ADDITIONAL_TREATMENT_DATA,
  GET_ODONTOGRAM_TREATMENT_DATA_REQUEST,
  GET_ODONTOGRAM_TREATMENT_DATA_SUCCESS,
  GET_ODONTOGRAM_TREATMENT_DATA_FAIL,
  SELECTED_CHECKBOX_DATA,
  UPDATE_ODONTOGRAM_TREATMENT_DATA_REQUEST,
  OPEN_PROPOSED_TOOLTIP,
  OPEN_ODONTOGRAM_TOOLTIP,
  CAME_IMAGE_XRAY_FROM_TREATMENT,
  MASTER_PROPOSED_DATA
} from '../../../../../actionTypes/dentist/clinicalExam/treatment/proposedTreatments/oraqProposed';

// search value store
export const selectAdditionalTreatmentAction = (payload: any): any => ({
  type: SELECT_ADDITIONAL_TREATMENT_DATA,
  payload
});

// TODO: for future use
// search value data store
// export const selectNewAdditionalTreatmentAction = (payload: any): any => ({
//   type: SELECT_NEW_ADDITIONAL_TREATMENT_DATA,
//   payload
// });

// Get odontogram data api actions
export const getOdontogramTreatmentDataAction = (payload: any): any => ({
  type: GET_ODONTOGRAM_TREATMENT_DATA_REQUEST,
  payload
});

export const getOdontogramTreatmentDataSuccessAction = (payload: any): any => ({
  type: GET_ODONTOGRAM_TREATMENT_DATA_SUCCESS,
  payload
});

export const getOdontogramTreatmentDataFailAction = (payload: any): any => ({
  type: GET_ODONTOGRAM_TREATMENT_DATA_FAIL,
  payload
});

//checkbox selected data
export const selectedCheckboxDataAction = (payload: any): any => ({
  type: SELECTED_CHECKBOX_DATA,
  payload
});

export const updateOdontogramTreatmentDataAction = (payload: any): any => ({
  type: UPDATE_ODONTOGRAM_TREATMENT_DATA_REQUEST,
  payload
});

export const openProposedTooltipAction = (payload: any): any => ({
  type: OPEN_PROPOSED_TOOLTIP,
  payload
});

export const openOdontogramTooltipAction = (payload: any): any => ({
  type: OPEN_ODONTOGRAM_TOOLTIP,
  payload
});

export const handleImageXrayFromTreatmentPlanComingAction = (payload: boolean): any => ({
  type: CAME_IMAGE_XRAY_FROM_TREATMENT,
  payload
});

export const masterProposedDataAction = (payload: any): any => ({
  type: MASTER_PROPOSED_DATA,
  payload
});
