import {call, put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';
import {
    createPasswordRequestFailure,
    createPasswordRequestSuccess
} from '../../actions/authAction/createPasswordAction';
import {CREATE_PASSWORD_REQUEST} from '../../actionTypes/auth';

function* createPasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/auth/password`, payload.payload)
        yield call(payload.payload.callback, response);
        yield put(createPasswordRequestSuccess());
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(createPasswordRequestFailure());
    }
}

function* createPasswordSaga() {
    yield takeEvery(CREATE_PASSWORD_REQUEST, createPasswordRequestSaga);
}

export default function* rootSaga() {
    yield all([
        createPasswordSaga()
    ])
}