import {ListItemText, MenuItem, Select, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import React from "react";
import theme from "../../../../theme";
import {MenuProps} from "../../../../utils/constant/appointmentConstant";

const useStyles = makeStyles((theme: any) => ({
    selectClass: {
        color: `${theme.palette.common.black50} !important`,
        "& .MuiSelect-select": {
            height: '100% !important',
            display: 'flex',
            alignItems: 'center'
        },
        "& fieldset": {
            borderColor: theme.palette.text.darkGrey, //  TODO: base border color -- >> #666666
        },
        "&:hover fieldset": {
            borderColor: theme.palette.common.black50, // hover color -- >> TODO: #333333
        },
        "&.Mui-focused fieldset": {
            border: `2px solid ${theme.palette.v2.primary.main} !important`,
        },
        "& .Mui-disabled": {
            "& .MuiOutlinedInput-input": {
                fontFamily: "FavoritPro-Regular !important",
                color: `${theme.palette.text.secondary} !important`,
                "& fieldset": {
                    border: `1px solid ${theme.palette.text.secondary} !important`,
                },
            },
            "-webkit-text-fill-color": `${theme.palette.text.secondary} !important`,
            color: `${theme.palette.text.secondary} !important`,
            opacity: 1,
        },
    },
    menuText: {
        "& .MuiTypography-root": {
            fontSize: "14px",
            fontFamily: "FavoritPro-Regular !important",
            fontWeight: 400,
            color: theme.palette?.common.black50,
        },
    },
}));

type Props = {
    selectOptions?: any;
    selectProps?: any;
    disabled?: boolean;
    inputBackColor?: any;
    defaultValue?: any;
    sx?: any;
    prefixLabel?: any;
    fromRiskAssessment?: boolean
};

const DropDown = (props: Props) => {
    const {
        selectOptions,
        selectProps,
        disabled,
        inputBackColor,
        defaultValue,
        sx,
        prefixLabel,
        fromRiskAssessment
    } =
        props as Props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Select
                MenuProps={MenuProps}
                fullWidth
                disabled={disabled}
                defaultValue={defaultValue}
                className={`${classes.selectClass} ${fromRiskAssessment ? "selectRiskAssessment" : "select"} border-radius-8 text-overflow-ellipsis-drop-down`}
                sx={{
                    backgroundColor: inputBackColor,
                    "& .MuiOutlinedInput-input": {
                        padding: "0px !important",
                        marginLeft: "16px",
                        color: `${theme.palette.common.black50} !important`,
                        height: fromRiskAssessment ? "0px !important" : "50px !important",
                        fontFamily: "FavoritPro-Regular !important",
                    }, ...sx
                }}
                displayEmpty
                {...selectProps}
                renderValue={(selected: any) => {
                    if (selected?.length === 0 || selected === null) {
                        return (
                            <span className={`fw-regular f-14 lh-19 f-w-350`}>Select</span>
                        );
                    }
                    let str: string;
                    if (prefixLabel) {
                        str = selected + ' ' + prefixLabel
                    } else {
                        str = selected
                    }
                    return str || '';
                }}
            >
                <MenuItem value={""}>
                    <ListItemText
                        className={`${classes.menuText} text-overflow-ellipsis`}
                        primary={"Select"}
                    />
                </MenuItem>
                {selectOptions &&
                    selectOptions?.map((itm: any, index: number) => (
                        <MenuItem key={index} value={itm.value || itm}>
                            <ListItemText
                                className={`${classes.menuText} text-overflow-ellipsis`}
                                primary={itm.name || itm.label || itm}
                            />
                        </MenuItem>
                    ))}
            </Select>
            {selectProps?.helperText &&
                <Typography component='span' m={'3px 14px 0px'} className="f-12" color={theme.palette.error.main}>
                    {selectProps?.helperText}
                </Typography>
            }
        </React.Fragment>
    );
};

export default DropDown;
