import { all, put, takeEvery } from "redux-saga/effects";
import {
    PATIENT_EMAIL_SEND_OTP_REQUEST,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST,
    PATIENT_QR_SUBMIT_DATA_REQUEST,
} from "../../actionTypes/patient/emailAndQrCodeForm";
import api from "../../../utils/api";
import {
    patientEmailSendOtpRequestFailure,
    patientEmailSendOtpRequestSuccess,
    patientEmailVerifyOtpRequestFailure,
    patientEmailVerifyOtpRequestSuccess,
    patientQrSubmitRequestFailure,
    patientQrSubmitRequestSuccess
} from "../../actions/patient/emailAndQrCodeFormAction";
import {ApiEndpointVersion, errorToaster, successToaster} from "../../../utils/helper";


function* patientEmailSendOtpRequestSaga(payload: any): any {
    try {
        const response = yield api.post(`/${ApiEndpointVersion}/pre-intake/form/send/otp`, payload?.payload)
        yield put(
            patientEmailSendOtpRequestSuccess({
                mobile: response?.mobile
            })
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.callback) {
                payload?.payload?.callback()
            }
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            patientEmailSendOtpRequestFailure({
                otpError: e?.meta?.message
            })
        );
    }
}

function* patientEmailVerifyOtpRequestSaga(payload: any): any {
    try {
        const response = yield api.post(`/${ApiEndpointVersion}/pre-intake/form/verify/otp`, payload.payload)
        yield put(
            patientEmailVerifyOtpRequestSuccess({
                otpData: response?.data?.detail
            })
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.callback) {
                payload?.payload?.callback(response)
            }
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            patientEmailVerifyOtpRequestFailure(e?.meta?.message)
        );
    }
}

function* patientQrSubmitRequestSaga(payload: any): any {
    try {
        const response = yield api.post(`/${ApiEndpointVersion}/auth/access-code`, payload.payload)
        yield put(
            patientQrSubmitRequestSuccess({
                qrSubmitData: response?.data?.detail
            })
        );
        if (response) {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.callback) {
                payload?.payload?.callback(response)
            }
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            patientQrSubmitRequestFailure(e?.meta?.message)
        );
    }
}

function* patientEmailSendOtpSaga() {
    yield takeEvery(PATIENT_EMAIL_SEND_OTP_REQUEST, patientEmailSendOtpRequestSaga);
}

function* patientEmailVerifyOtpSaga() {
    yield takeEvery(PATIENT_EMAIL_VERIFY_OTP_REQUEST, patientEmailVerifyOtpRequestSaga);
}

function* patientQrSubmitSaga() {
    yield takeEvery(PATIENT_QR_SUBMIT_DATA_REQUEST, patientQrSubmitRequestSaga);
}

export default function* emailAndQrCodeFormSaga() {
    yield all([
        patientEmailSendOtpSaga(),
        patientEmailVerifyOtpSaga(),
        patientQrSubmitSaga()
    ])
}