import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '../../../utils/api';
import {ApiEndpointVersion, dentistProfileData, errorToaster} from '../../../utils/helper';
import {
    updateSubStatusSuccessAction,
    updateSubStatusFailAction,
} from "../../actions/master/updateSubStatusActions";
import { UPDATE_SUB_STATUS_REQUEST } from "../../actionTypes/master/updateSubStatusActions";

function* updateSubStatusSaga(payload: any): any {
    try {
        const payloadData = {
            status: payload?.payload.status,
            sub_status: payload?.payload.sub_status,
            profile_id:dentistProfileData?.id
        }
        const response = yield API.put(`${ApiEndpointVersion}/appointments/${payload?.payload?.appointment_id}/status`, payloadData);
        const data = response?.data?.detail

        if (payload?.payload.callback) {
            yield call(payload?.payload?.callback)
        }
        yield put(
            updateSubStatusSuccessAction(data)
        );
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            updateSubStatusFailAction(e)
        );
    }
}

function* updateSubStatusWatcher() {
    yield takeEvery(UPDATE_SUB_STATUS_REQUEST, updateSubStatusSaga);
}

export default function* updateStatusSaga() {
    yield all([updateSubStatusWatcher()]);
}
