import {Box} from '@mui/material';
import {makeStyles} from "@mui/styles";
import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {unstable_batchedUpdates} from "react-dom";
import PeriogramImageInput from "./PeriogramImageInput";
import {setExamFindingsAction} from "../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import {convertValueToRoman, getToothIndex, removeRefFromJson, stringToArray} from '../../../../../../utils/helper';
import {examConstantInterface, findingData, findingTypes} from '../../../../../../utils/constant/examConstant';

type Props = {
    value: number | string
    variant?: string
    tooth: number
    finding: string,
    type?: string
    toothSite?: string
    disabled?: boolean
    bottomJaw?: boolean | undefined
}
const useStyles = makeStyles((theme: any) => ({
    periogramInputBox: {
        display: 'flex',
        justifyContent: 'center',
        m: '1px',
        border: `0.122819px solid ${theme.palette.grey.lightBorderGray}`,
        borderRadius: '3px',
        maxWidth: '48px',
        textAlign: 'center',
        outline: 'none',
    },
    smallVariantBox: {
        width: '14px',
        height: '14px',
        margin: '1px',
        border: `0.09px solid ${theme.palette.grey.lightBorderGray} !important`,
        borderRadius: '3px !important',
        fontSize: '9px'
    },
    mobilityRomenFont: {
        fontFamily: 'slick',
        letterSpacing: '1px',
        height: 17
    }
}));

const PeriogramInputBoxes = React.memo((props: Props) => {
    const {variant, tooth, finding, type, toothSite, disabled} = props
    const bottomJaw = tooth > 28 as Boolean
    const classes = useStyles();
    const dispatch = useDispatch();
    const {examFindingReducer: {examFindingData, examDataFromAPI},
        clinicalExamFilters: {riskFilters, findingType},
    } = useSelector((state: any) => state) as any
    const isMissingTooth = stringToArray(examDataFromAPI?.occlusion_exam?.missing_tooth_tooth_number || '').includes(`${tooth}`)
    const exceptThisSymbols = ["e", "E", "+", "-"];

    const handleMobilityChange = (value: string | undefined) => {
        unstable_batchedUpdates(() => {
            let updatedExamFindingData = removeRefFromJson(examFindingData) as any
            let currentFindingData = updatedExamFindingData?.periodontal_exam && updatedExamFindingData?.periodontal_exam[finding] as any[]
            if (currentFindingData?.length === 1 && currentFindingData[0]?.tooth_number === null) {
                currentFindingData[0] = {
                    grade: value,
                    tooth_number: `${tooth}`,
                }
            } else {
                let index = -1 as number
                currentFindingData?.forEach((res: any, i: number) => {
                    if (res.tooth_number === `${tooth}`) {
                        index = i
                    }
                })
                if (index >= 0) {
                    if (value) {
                        currentFindingData[index].grade = value
                    } else {
                        currentFindingData = currentFindingData?.filter((_: any, i: number) => i !== index)
                    }
                } else {
                    currentFindingData?.push({
                        grade: value,
                        tooth_number: `${tooth}`,
                    })
                }
            }
            if (currentFindingData && updatedExamFindingData?.periodontal_exam) {
                updatedExamFindingData.periodontal_exam[finding] = currentFindingData
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        })
    }

    const handleChange = (value: string | undefined) => {
        unstable_batchedUpdates(() => {
            let updatedExamFindingData = removeRefFromJson(examFindingData) as any
            let currentFindingData = updatedExamFindingData?.periodontal_exam && updatedExamFindingData?.periodontal_exam[finding] as any[]
            if (currentFindingData?.length === 1 && currentFindingData[0]?.tooth_number === null) {
                if (finding === 'probing_depth') {
                    currentFindingData[0] = {
                        buccal_mm: toothSite === 'buccal_mm' ? value : null,
                        mesial_buccal_mm: toothSite === 'mesial_buccal_mm' ? value : null,
                        distal_buccal_mm: toothSite === 'distal_buccal_mm' ? value : null,
                        lingual_mm: toothSite === 'lingual_mm' ? value : null,
                        mesial_lingual_mm: toothSite === 'mesial_lingual_mm' ? value : null,
                        distal_lingual_mm: toothSite === 'distal_lingual_mm' ? value : null,
                        tooth_number: `${tooth}`,
                    }
                } else if (finding === 'recession') {
                    currentFindingData[0] = {
                        buccal_mm: toothSite === 'buccal_mm' ? value : null,
                        lingual_mm: toothSite === 'lingual_mm' ? value : null,
                        tooth_number: `${tooth}`,
                    }
                }
            } else {
                let index = -1 as number
                currentFindingData?.map((res: any, i: number) => {
                    if (res.tooth_number === `${tooth}`) {
                        index = i
                    }
                    return true
                })
                if (index >= 0 && toothSite) {
                    if (value) {
                        // @ts-ignore
                        currentFindingData[index][toothSite] = value
                    } else {
                        if (finding === 'probing_depth') {
                            const toothSiteKey = ['buccal_mm', 'mesial_buccal_mm', 'distal_buccal_mm', 'lingual_mm', 'mesial_lingual_mm', 'distal_lingual_mm']
                            const keysAreNullOrEmpty = toothSiteKey.every(key => {
                                const value = currentFindingData[index][key];
                                return value === null || value === '';
                            });
                            if (keysAreNullOrEmpty) {
                                currentFindingData[index] = currentFindingData?.filter((_: any, i: number) => i !== index)
                            } else {
                                currentFindingData[index][toothSite] = value
                            }
                        } else {
                            currentFindingData[index][toothSite] = value
                        }
                    }
                } else {
                    if (finding === 'probing_depth') {
                        currentFindingData?.push({
                            buccal_mm: toothSite === 'buccal_mm' ? value : null,
                            mesial_buccal_mm: toothSite === 'mesial_buccal_mm' ? value : null,
                            distal_buccal_mm: toothSite === 'distal_buccal_mm' ? value : null,
                            lingual_mm: toothSite === 'lingual_mm' ? value : null,
                            mesial_lingual_mm: toothSite === 'mesial_lingual_mm' ? value : null,
                            distal_lingual_mm: toothSite === 'distal_lingual_mm' ? value : null,
                            tooth_number: `${tooth}`,
                        })
                    } else if (finding === 'recession') {
                        currentFindingData?.push({
                            buccal_mm: toothSite === 'buccal_mm' ? value : null,
                            lingual_mm: toothSite === 'lingual_mm' ? value : null,
                            tooth_number: `${tooth}`,
                        })
                    }
                }
            }
            if (currentFindingData && updatedExamFindingData?.periodontal_exam) {
                updatedExamFindingData.periodontal_exam[finding] = currentFindingData
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        })
    }
    const riskWiseFilteredData = useMemo(() => {
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType])
        ) as any;
        /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskFilters?.length
            ? (findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData
        // eslint-disable-next-line
    }, [riskFilters, riskFilters?.length, findingData, findingType]);

    const getValue = useCallback(() => {
            let value = '' as string
            const filteredFindingData = removeRefFromJson(riskWiseFilteredData) as any
            if (examFindingData && examFindingData?.periodontal_exam && examFindingData?.periodontal_exam[finding] && filteredFindingData) {
                filteredFindingData?.filter((findingKey: any) => findingKey?.key === finding)?.map((fk: any) => {
                    const valueWithTooth = examFindingData?.periodontal_exam[fk?.key]?.filter((f: any) => f?.tooth_number === `${tooth}`) as any
                    if (valueWithTooth?.length) {
                        if (finding === 'mobility') {
                            value = convertValueToRoman(valueWithTooth[0]?.grade)
                        } else {
                            // @ts-ignore
                            value = valueWithTooth[0][toothSite] || ''
                        }
                    }
                    return true
                })
            }
            return value
        },
        // eslint-disable-next-line
        [tooth, examFindingData, finding, examFindingData?.periodontal_exam, toothSite, riskWiseFilteredData],
    );

    const getTabIndex = useMemo(() => {
        if (finding === 'mobility') {
            return 100 + getToothIndex(tooth, tooth > 28);
        }
        if (finding === 'probing_depth') {
            const isLingual = toothSite?.includes('lingual') as boolean;
            const isMesial = toothSite?.includes('mesial') as boolean;
            const isDistal = toothSite?.includes('distal') as boolean;

            let newToothForIndex = 0;
            if ((bottomJaw && tooth > 30 && tooth < 39) || (!bottomJaw && tooth > 20 && tooth < 29)) {
                newToothForIndex = isLingual ? (isMesial ? 3 : isDistal ? 1 : 2) : (isMesial ? 1 : isDistal ? 3 : 2);
            } else {
                newToothForIndex = isLingual ? (isMesial ? 1 : isDistal ? 3 : 2) : (isMesial ? 3 : isDistal ? 1 : 2);
            }
            const baseValue = bottomJaw ? (isLingual ? 3600 : 1200) : (isLingual ? 600 : 200);
            const toothIndex = getToothIndex(tooth, bottomJaw ? !isLingual : isLingual);
            return baseValue + (toothIndex * 3) + newToothForIndex;
        }
        if (finding === 'recession') {
            const baseValue = toothSite?.includes('lingual') ? (bottomJaw ? 4300 : 4100) : (bottomJaw ? 4200 : 4000);
            const isLingual = toothSite?.includes('lingual') as boolean;
            return baseValue + getToothIndex(tooth, bottomJaw ? !isLingual : isLingual);
        }
        return 0;
    }, [finding, tooth, toothSite, bottomJaw]);

    return (
        <Box
            sx={{
                opacity: isMissingTooth ? 0 : 1,
                padding: 0,
                margin: 0,
                transform: bottomJaw ? 'scaleX(-1)' : '',
                display: type === 'image' ? "" : 'flex',
                marginLeft: (variant === 'small' || type === 'image') ? "" : '1px !important',
                marginRight: (variant === 'small' || type === 'image' || !bottomJaw) ? "" : '2px !important'
            }}
        >
            {type === 'image' ?
                <PeriogramImageInput
                    tooth={tooth}
                    finding={finding}
                    variant={variant}
                    toothSite={toothSite}
                    disabled={disabled}
                    bottomJaw={bottomJaw}
                />
                :
                (disabled ?
                    <input
                        style={{
                            opacity: 1,
                        }}
                        disabled={true}
                        className={
                            classes.periogramInputBox +
                            ` ${variant === 'small' ? classes.smallVariantBox : ''} ` +
                            ` ${finding === 'mobility' || finding === 'recession' ? classes.mobilityRomenFont : ''} `
                        }
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        value={getValue()}
                    />
                    : <input
                        tabIndex={getTabIndex}
                        onWheel={(e: any) => e.target.blur()}
                        onKeyDown={(e: any) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                        onChange={(e: any) => {
                            const {value} = e.target;
                            if (finding === 'mobility') {
                                if (value === "1" || value === "2" || value === "3") {
                                    handleMobilityChange(value);
                                } else {
                                    handleMobilityChange('');
                                }
                            } else if (finding === 'probing_depth' || finding === 'recession') {
                                if (e.target.value < 16) {
                                    handleChange(value);
                                }
                            }
                        }}
                        className={
                            classes.periogramInputBox +
                            ` ${variant === 'small' ? classes.smallVariantBox : ''} ` +
                            ` ${finding === 'mobility' || finding === 'recession' ? classes.mobilityRomenFont : ''} `
                        }
                        value={getValue()}
                        type={finding === 'probing_depth' || finding === 'recession' ? 'number' : ''}
                    />)
            }
        </Box>
    );
})

export default PeriogramInputBoxes;
