import { GET_OUTPUT_SCREEN_DATA_REQUEST, GET_OUTPUT_SCREEN_DATA_REQUEST_FAILURE, GET_OUTPUT_SCREEN_DATA_REQUEST_SUCCESS } from "../../../actionTypes/dentist/clinicalExam/outputScreen";
import { outputScreenActions, OutputScreenInterface } from "../../../types/dentist/clinicalExam/outputScreen";


const initialState: OutputScreenInterface = {
    outputScreenLoader: false,
    outputScreenData: null,
    outputScreenError: '',
};

const outputScreenReducer = (state = initialState, action: outputScreenActions) => {
    switch (action.type) {

        case GET_OUTPUT_SCREEN_DATA_REQUEST:
            return {
                ...state,
                outputScreenLoader: true,
                outputScreenData: null,
                outputScreenError: ''
            };
        case GET_OUTPUT_SCREEN_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                outputScreenLoader: false,
                outputScreenData: action.payload,
                outputScreenError: ''
            };
        case GET_OUTPUT_SCREEN_DATA_REQUEST_FAILURE:
            return {
                ...state,
                outputScreenLoader: false,
                outputScreenData: null,
                outputScreenError: action.payload,
            };
        default:
            return {
                ...state
            };
    }
};

export default outputScreenReducer;
