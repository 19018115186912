import {all, put, takeEvery} from 'redux-saga/effects';
import API from '../../../../utils/api';
import { GET_OUTPUT_SCREEN_DATA_REQUEST } from '../../../actionTypes/dentist/clinicalExam/outputScreen';
import { ApiEndpointVersion } from '../../../../utils/helper';
import { getOutputScreenRequestFailureAction, getOutputScreenRequestSuccessAction } from '../../../actions/dentist/clinicalExam/outputScreenAction';

function* outputScreenRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/appointments/${payload.payload?.appointment_id}/output-screen`)
    
        const data = response?.data?.detail
        yield put(
            getOutputScreenRequestSuccessAction(data)
        )
    } catch (e: any) {
        yield put(
            getOutputScreenRequestFailureAction(e?.meta?.message)
        )
    }
}


function* outputScreenWatch() {
    yield takeEvery(GET_OUTPUT_SCREEN_DATA_REQUEST, outputScreenRequestSaga)
}


export default function* outputScreenSaga() {
    yield all([
        outputScreenWatch(),
    ])
}
