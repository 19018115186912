import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {
    ApiEndpointVersion,
    changeAnnotationResponse,
    dentistProfileData,
    errorToaster,
    successToaster
} from '../../../../../utils/helper';
import {
    addAdditionalImageXrayAction,
    deletePhotosRequestFailureAction,
    deletePhotosRequestSuccessAction,
    getPhotosDetailsRequestFailureAction,
    getPhotosDetailsRequestSuccessAction,
    getPhotosRequestFailureAction,
    getPhotosRequestSuccessAction,
    putPhotosRequestFailureAction,
    putPhotosRequestSuccessAction,
    selectPhotosAction,
    uploadPhotosAction
} from '../../../../actions/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import {
    DELETE_PHOTOS_REQUEST,
    GET_PHOTOS_DETAILS_REQUEST,
    GET_PHOTOS_REQUEST,
    PUT_PHOTOS_REQUEST
} from '../../../../actionTypes/dentist/clinicalExam/exams/uploadPhotosXray';
import { RootState } from '../../../../reducers/rootReducer';


function* getPhotosXrayRequestSaga(payload: any): any {
    try {
        const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
        const urlForWellnessProfile = `/${ApiEndpointVersion}/images/wellness/profile/${payload.payload.patient_id}?image_type=${payload.payload?.image_type}&clinic_id=${localStorage?.getItem('clinic')}`
        const urlForClinicalExam = `/${ApiEndpointVersion}/images/appointment/${payload.payload?.appointment_id}?image_type=${payload?.payload?.image_type}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        yield put(
            getPhotosRequestSuccessAction(
                response?.data?.detail
            )
        )
        yield put(uploadPhotosAction(null))
    } catch (e: any) {
        yield put(
            getPhotosRequestFailureAction({
                error: e?.meta?.message,
            })
        )
    }
}

function* putPhotosXrayRequestSaga(payload: any): any {
    const form = new FormData();
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const urlForWellnessProfile = `/${ApiEndpointVersion}/wellness-profile/upload`
    const urlForClinicalExam = `/${ApiEndpointVersion}/images/upload`
    try {
        const {
            addAdditionalImageXray,
            selectPhotosData
        } = yield select((state: RootState) => state?.uploadPhotoXray) as any

        const response = yield API.post(isWellnessProfile ? urlForWellnessProfile : urlForClinicalExam, payload?.payload?.file)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        successToaster(response?.data?.meta?.message)
        if (addAdditionalImageXray) {
            yield put(addAdditionalImageXrayAction(false))
        }
        if (response) {
            const updateSelectPhotosData = selectPhotosData
            yield put(selectPhotosAction({ ...updateSelectPhotosData, image_id: undefined }))
        }
        yield put(putPhotosRequestSuccessAction(
            response?.data?.detail
        )
        )

    } catch (e: any) {
        form.delete("file");
        form.delete("appointment_id");
        form.delete("type");
        form.delete("image_name");
        yield put(uploadPhotosAction(null))
        errorToaster(e?.meta?.message)
        yield put(putPhotosRequestFailureAction({ error: e?.meta?.message })
        )
    }
}

function* deletePhotosXrayRequestSaga(payload: any): any {
    try {
        const { photosXrayData } = yield select((state: RootState) => state?.uploadPhotoXray) as any

        const response = yield API.delete(`/${ApiEndpointVersion}/images/${payload?.payload?.image_id}?profile_id=${dentistProfileData?.id}`)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        // start----- delete image from local redux while APIs is called successfully no need call GET API again after delete the image
        const updatedData = photosXrayData?.filter((f: any) => f?.ID !== payload?.payload?.image_id)
        yield put(
            getPhotosRequestSuccessAction(
                updatedData
            )
        )
        // end

        yield put(
            deletePhotosRequestSuccessAction(
                response?.data?.detail
            )
        )
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            deletePhotosRequestFailureAction({
                error: e?.meta?.message,
            })
        )
    }
}


function* getPhotosXrayDetailsRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/images/${payload.payload?.image_id}`)

        const allData = {
            ...response?.data?.detail,
            tags: changeAnnotationResponse(response?.data?.detail?.tags)
        }
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, allData)
        }
        yield put(
            getPhotosDetailsRequestSuccessAction(
                allData
            )
        )
    } catch (e: any) {
        yield put(
            getPhotosDetailsRequestFailureAction(e?.meta?.message)
        )
    }
}

function* getPhotosXrayRequestWatch() {
    yield takeEvery(GET_PHOTOS_REQUEST, getPhotosXrayRequestSaga)
}

function* putPhotosXrayRequestWatch() {
    yield takeEvery(PUT_PHOTOS_REQUEST, putPhotosXrayRequestSaga)
}

function* deletePhotosXrayRequestWatch() {
    yield takeEvery(DELETE_PHOTOS_REQUEST, deletePhotosXrayRequestSaga)
}

function* getPhotosXrayDetailsRequestWatch() {
    yield takeEvery(GET_PHOTOS_DETAILS_REQUEST, getPhotosXrayDetailsRequestSaga)
}

export default function* photosXraySaga() {
    yield all([
        getPhotosXrayRequestWatch(),
        putPhotosXrayRequestWatch(),
        deletePhotosXrayRequestWatch(),
        getPhotosXrayDetailsRequestWatch()

    ])
}
