import {
    GET_TC_REQUEST,
    GET_TC_REQUEST_FAILURE,
    GET_TC_REQUEST_SUCCESS,
    TERMS_CONDITION_V2_REQUEST,
    TERMS_CONDITION_V2_REQUEST_FAILURE,
    TERMS_CONDITION_V2_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {
    GetTCFailurePayload,
    GetTCRequest,
    GetTCRequestFailure,
    GetTCRequestPayload,
    GetTCRequestSuccess,
    GetTCSuccessPayload,
    TermsConditionsRequest,
    TermsConditionsRequestPayload,
} from '../../types/authActionTypes/termsConditionActionTypes';

export const termsConditionRequest = (payload: TermsConditionsRequestPayload): TermsConditionsRequest => ({
    type: TERMS_CONDITION_V2_REQUEST,
    payload
});

export const termsConditionSuccess = () => ({
    type: TERMS_CONDITION_V2_REQUEST_SUCCESS
});

export const termsConditionFailure = () => ({
    type: TERMS_CONDITION_V2_REQUEST_FAILURE
});

export const GetTCDataRequest = (payload: GetTCRequestPayload): GetTCRequest => ({
    type: GET_TC_REQUEST,
    payload
});

export const GetTCDataSuccess = (payload: GetTCSuccessPayload): GetTCRequestSuccess => ({
    type: GET_TC_REQUEST_SUCCESS,
    payload
});

export const GetTCDataFailure = (payload: GetTCFailurePayload): GetTCRequestFailure => ({
    type: GET_TC_REQUEST_FAILURE,
    payload
});
