import {
  UPDATE_APPOINTMENT_INFORMATION,
  GET_PRE_CLINICAL_DATA_REQUEST,
  GET_PRE_CLINICAL_DATA_SUCCESS,
  GET_PRE_CLINICAL_DATA_FAIL,
  PUT_PRE_CLINICAL_DATA_REQUEST,
  PUT_PRE_CLINICAL_DATA_SUCCESS,
  PUT_PRE_CLINICAL_DATA_FAIL,
  UPDATE_PRE_CLINICAL_VALIDATIONS,
  UPDATE_PRE_CLINICAL_PRE_INTAKE_VALIDATIONS,
  OPEN_POPUP_APPOINTMENT_TYPE_CHANGE,
  IS_PRE_CLINICAL_SUBMIT
} from '../../actionTypes/dentist/clinicalExam/preClinical';

export const updateAppointmentInfo = (payload: any): any => ({
  type: UPDATE_APPOINTMENT_INFORMATION,
  payload
});

// Get Pre-clinical data api actions
export const getPreClinicalDataAction = (payload: any): any => ({
  type: GET_PRE_CLINICAL_DATA_REQUEST,
  payload
});

export const getPreClinicalDataSuccessAction = (payload: any): any => ({
  type: GET_PRE_CLINICAL_DATA_SUCCESS,
  payload
});

export const getPreClinicalDataFailAction = (): any => ({
  type: GET_PRE_CLINICAL_DATA_FAIL
});

// Put Pre-clinical data api actions
export const putPreClinicalDataAction = (payload: any): any => ({
  type: PUT_PRE_CLINICAL_DATA_REQUEST,
  payload
});

export const putPreClinicalDataSuccessAction = (): any => ({
  type: PUT_PRE_CLINICAL_DATA_SUCCESS
});

export const putPreClinicalDataFailAction = (): any => ({
  type: PUT_PRE_CLINICAL_DATA_FAIL
});

export const updatePreClinicalValidationsAction = (payload: any): any => ({
  type: UPDATE_PRE_CLINICAL_VALIDATIONS,
  payload
});

export const updatePreClinicalPreIntakeValidationsAction = (payload: any): any => ({
  type: UPDATE_PRE_CLINICAL_PRE_INTAKE_VALIDATIONS,
  payload
});
export const openPopupAppointmentTypeChangeAction = (payload: any): any => ({
  type: OPEN_POPUP_APPOINTMENT_TYPE_CHANGE,
  payload
});

export const isPreClinicalSubmitAction = (payload: any): any => ({
  type: IS_PRE_CLINICAL_SUBMIT,
  payload
});
