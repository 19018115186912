import {all, put, takeEvery} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from "../../../utils/helper";
import {SEND_OTP_REQUEST, VERIFY_OTP_REQUEST} from "../../actionTypes/common/mobileVerification";
import {
    sendOtpRequestFailure,
    sendOtpRequestSuccess, verifyOtpRequestFailure,
    verifyOtpRequestSuccess
} from "../../actions/common/mobileVerificationAction";

function* verifyOtpRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/auth/verify/otp`, payload.payload)
        yield put(verifyOtpRequestSuccess());
        if (response) {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.callback) {
                payload?.payload?.callback(response)
            }
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(verifyOtpRequestFailure());
    }
}

function* sendOtpRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/auth/send/otp`, payload?.payload)
        yield put(sendOtpRequestSuccess());
        if (response) {
            successToaster(response?.data?.meta?.message)
            if (payload?.payload?.callback) {
                payload?.payload?.callback()
            }
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(sendOtpRequestFailure());
    }
}

function* verifyOtpSaga() {
    yield takeEvery(VERIFY_OTP_REQUEST, verifyOtpRequestSaga);
}

function* sendOtpSaga() {
    yield takeEvery(SEND_OTP_REQUEST, sendOtpRequestSaga);
}

export default function* mobileVerificationSaga() {
    yield all([
        verifyOtpSaga(),
        sendOtpSaga()
    ])
}
