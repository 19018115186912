import {
    PRIVACY_POLICY_ACCEPT_REQUEST,
    PRIVACY_POLICY_ACCEPT_REQUEST_FAILURE,
    PRIVACY_POLICY_ACCEPT_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {privacyPolicyActions, privacyPolicyInterface} from '../../types/authActionTypes/privacyPolicyActionTypes';

const initialState: privacyPolicyInterface = {
    privacyPolicyLoader: false,
};

const privacyPolicyReducer = (state = initialState, action: privacyPolicyActions) => {
    switch (action.type) {
        case PRIVACY_POLICY_ACCEPT_REQUEST:
            return {
                ...state,
                privacyPolicyLoader: true,

            };
        case PRIVACY_POLICY_ACCEPT_REQUEST_SUCCESS:
            return {
                ...state,
                privacyPolicyLoader: false,
            };
        case PRIVACY_POLICY_ACCEPT_REQUEST_FAILURE:
            return {
                ...state,
                privacyPolicyLoader: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default privacyPolicyReducer;
