import {
  UPDATE_PREAUTH_CHECKLIST_DATA,
  GET_INSURANCE_DATA_REQUEST,
  GET_INSURANCE_DATA_REQUEST_SUCCESS,
  GET_INSURANCE_DATA_REQUEST_FAILURE,
  GET_PROVIDER_DROPDOWN_REQUEST,
  GET_PROVIDER_DROPDOWN_REQUEST_SUCCESS,
  GET_PROVIDER_DROPDOWN_REQUEST_FAILURE,
  PUT_INSURANCE_DATA_REQUEST,
  PUT_INSURANCE_DATA_REQUEST_SUCCESS,
  PUT_INSURANCE_DATA_REQUEST_FAILURE,
  COPY_EXAM_NOTES_REQUEST,
  COPY_EXAM_NOTES_REQUEST_SUCCESS,
  COPY_EXAM_NOTES_REQUEST_FAILURE,
  UPDATE_HYGIENE_REGIME_DATA,
  UPDATE_INSURANCE_TREATMENT_DATA,
  UPDATE_INSURANCE_VALIDATIONS
} from '../../../actionTypes/dentist/clinicalExam/insurance';
import {
  CopyExamNotesFailurePayload,
  CopyExamNotesRequest,
  CopyExamNotesRequestFailure,
  CopyExamNotesRequestPayload,
  CopyExamNotesRequestSuccess,
  CopyExamNotesRequestSuccessPayload,
  UpdateHygieneRegimeData,
  UpdateInsuranceTreatmentData,
  getInsuranceDataFailurePayload,
  getInsuranceDataRequest,
  getInsuranceDataRequestFailure,
  getInsuranceDataRequestPayload,
  getInsuranceDataRequestSuccess,
  getInsuranceDataRequestSuccessPayload,
  putInsuranceDataFailurePayload,
  putInsuranceDataRequest,
  putInsuranceDataRequestFailure,
  putInsuranceDataRequestPayload,
  putInsuranceDataRequestSuccess,
  putInsuranceDataRequestSuccessPayload
} from '../../../types/dentist/clinicalExam/insurance';


export const updatePreauthChecklistDataAction = (payload: any): any => ({
  type: UPDATE_PREAUTH_CHECKLIST_DATA,
  payload
});

export const getInsuranceDataRequestAction = (payload: getInsuranceDataRequestPayload): getInsuranceDataRequest => ({
  type: GET_INSURANCE_DATA_REQUEST,
  payload
});

export const getInsuranceDataRequestSuccessAction = (
  payload: getInsuranceDataRequestSuccessPayload
): getInsuranceDataRequestSuccess => ({
  type: GET_INSURANCE_DATA_REQUEST_SUCCESS,
  payload
});

export const getInsuranceDataRequestFailureAction = (
  payload: getInsuranceDataFailurePayload
): getInsuranceDataRequestFailure => ({
  type: GET_INSURANCE_DATA_REQUEST_FAILURE,
  payload
});

export const getProviderDropdownRequestAction = (payload: any): any => ({
  type: GET_PROVIDER_DROPDOWN_REQUEST,
  payload
});

export const getProviderDropdownRequestSuccessAction = (payload: any): any => ({
  type: GET_PROVIDER_DROPDOWN_REQUEST_SUCCESS,
  payload
});

export const getProviderDropdownRequestFailureAction = (payload: any): any => ({
  type: GET_PROVIDER_DROPDOWN_REQUEST_FAILURE,
  payload
});

export const putInsuranceDataRequestAction = (payload: putInsuranceDataRequestPayload): putInsuranceDataRequest => ({
  type: PUT_INSURANCE_DATA_REQUEST,
  payload
});

export const putInsuranceDataRequestSuccessAction = (
  payload: putInsuranceDataRequestSuccessPayload
): putInsuranceDataRequestSuccess => ({
  type: PUT_INSURANCE_DATA_REQUEST_SUCCESS,
  payload
});

export const putInsuranceDataRequestFailureAction = (
  payload: putInsuranceDataFailurePayload
): putInsuranceDataRequestFailure => ({
  type: PUT_INSURANCE_DATA_REQUEST_FAILURE,
  payload
});

// copy exam notes

export const copyExamNotesRequestAction = (payload: CopyExamNotesRequestPayload): CopyExamNotesRequest => ({
  type: COPY_EXAM_NOTES_REQUEST,
  payload
});

export const copyExamNotesRequestSuccessAction = (
  payload: CopyExamNotesRequestSuccessPayload
): CopyExamNotesRequestSuccess => ({
  type: COPY_EXAM_NOTES_REQUEST_SUCCESS,
  payload
});

export const copyExamNotesRequestFailureAction = (payload: CopyExamNotesFailurePayload): CopyExamNotesRequestFailure => ({
  type: COPY_EXAM_NOTES_REQUEST_FAILURE,
  payload
});

export const updateHygieneRegimeDataAction = (payload: any): UpdateHygieneRegimeData => ({
  type: UPDATE_HYGIENE_REGIME_DATA,
  payload
});

export const updateInsuranceTreatmentDataAction = (payload: any): UpdateInsuranceTreatmentData => ({
  type: UPDATE_INSURANCE_TREATMENT_DATA,
  payload
});

export const updateInsuranceValidationsAction = (payload: any): any => ({
  type: UPDATE_INSURANCE_VALIDATIONS,
  payload
});
