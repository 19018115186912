// @ts-ignore
import {call, put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {GOOGLE_LOGIN_REQUEST, LOGIN_REQUEST} from "../../actionTypes/auth";
import {ApiEndpointVersion, errorToaster} from "../../../utils/helper";
import {
    googleLoginRequestFailureAction,
    googleLoginRequestSuccessAction,
    loginRequestFailure,
    loginRequestSuccess,
} from "../../actions/authAction/loginAction";

function* loginRequestSaga(payload: any): any {
    let newPayload = {
        email: payload?.payload?.email,
        password: payload?.payload?.password,
        scopes: payload?.payload?.scopes,
        isOwner: payload?.payload?.isOwner,
    } as any;
    if (payload?.payload?.device_token) {
        newPayload = {
            ...newPayload,
            device_token: payload?.payload?.device_token,
        };
    }
    try {
        const response = yield API.post(
            `/${ApiEndpointVersion}/auth/login?is_owner=${payload?.payload?.isOwner}`,
            newPayload,
        );
        yield call(payload?.payload?.callback, response?.data);
        yield put(loginRequestSuccess());
    } catch (e: any) {
        yield call(payload?.payload?.errorCallback);
        errorToaster(e?.meta?.message);
        yield put(loginRequestFailure());
    }
}


function* googleLoginRequestSaga({payload}: any): any {
    let newPayload = {
        code: payload?.code,
        redirect_uri: payload?.redirect_uri
    } as any;
    if (payload?.device_token) {
        newPayload = {
            ...newPayload,
            device_token: payload?.device_token,
        };
    }

    try {
        const response = yield API.post(
            `/${ApiEndpointVersion}/auth/google/signin`,
            newPayload,
        );
        yield call(payload?.callback, response?.data);
        yield put(
            googleLoginRequestSuccessAction(response?.data,)
        );
    } catch (e: any) {
        errorToaster(e?.meta?.message);
        yield put(
            googleLoginRequestFailureAction(e?.meta?.message)
        );
    }
}

function* loginWatch() {
    yield takeEvery(LOGIN_REQUEST, loginRequestSaga);
}

function* googleLoginWatch() {
    yield takeEvery(GOOGLE_LOGIN_REQUEST, googleLoginRequestSaga);
}

export default function* rootSaga() {
    yield all([loginWatch(), googleLoginWatch()]);
}
