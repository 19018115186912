import {
    GET_ANNOTATION_REQUEST,
    GET_ANNOTATION_REQUEST_FAILURE,
    GET_ANNOTATION_REQUEST_SUCCESS,
    PUT_ANNOTATION_REQUEST,
    PUT_ANNOTATION_REQUEST_FAILURE,
    PUT_ANNOTATION_REQUEST_SUCCESS,
    SELECT_ANNOTATION, SELECT_MARKER
} from '../../../../actionTypes/dentist/clinicalExam/exams/annotation';
import {
    getAnnotationRequest,
    getAnnotationRequestFailure,
    getAnnotationRequestFailurePayload,
    getAnnotationRequestSuccess,
    getAnnotationRequestSuccessPayload,
    putAnnotationRequest,
    putAnnotationRequestPayload,
    selectAnnotationRequest
} from '../../../../types/dentist/clinicalExam/exam/annotation';

//annotation
export const selectAnnotationAction = (payload: any): selectAnnotationRequest => ({
    type: SELECT_ANNOTATION,
    payload
});

// get annotation
export const getAnnotationRequestAction = (payload: string): getAnnotationRequest => ({
    type: GET_ANNOTATION_REQUEST,
    payload
});

export const getAnnotationRequestSuccessAction = (
    payload: getAnnotationRequestSuccessPayload
): getAnnotationRequestSuccess => ({
    type: GET_ANNOTATION_REQUEST_SUCCESS,
    payload
});

export const getAnnotationRequestFailureAction = (
    payload: getAnnotationRequestFailurePayload
): getAnnotationRequestFailure => ({
    type: GET_ANNOTATION_REQUEST_FAILURE,
    payload
});

// put annotation
export const putAnnotationRequestAction = (payload: putAnnotationRequestPayload): putAnnotationRequest => ({
    type: PUT_ANNOTATION_REQUEST,
    payload
});

export const putAnnotationRequestSuccessAction = () => ({
    type: PUT_ANNOTATION_REQUEST_SUCCESS
});

export const putAnnotationRequestFailureAction = () => ({
    type: PUT_ANNOTATION_REQUEST_FAILURE
});

export const selectMarkerAction = (payload: any): any => ({
    type: SELECT_MARKER,
    payload
});
