import {
    ADD_TREATMENT,
    GET_KEY_FINDING_DATA_REQUEST,
    GET_KEY_FINDING_DATA_SUCCESS,
    GET_KEY_FINDING_DATA_FAIL,
} from '../../../../actionTypes/dentist/clinicalExam/treatment/keyFinding';

const initialState: any = {
    loader: false,
    saveKeyFindingLoader: false,
    keyFindingData: null,
    treatmentPlanning: null
};

const keyFindingReducer = (state = initialState, action: any) => {
    switch (action?.type) {
        case GET_KEY_FINDING_DATA_REQUEST:
            return {
                ...state,
                loader: true,
                keyFindingData: null,
            };
        case GET_KEY_FINDING_DATA_SUCCESS:
            return {
                ...state,
                keyFindingData: action?.payload,
                loader: false
            };
        case GET_KEY_FINDING_DATA_FAIL:
            return {
                ...state,
                keyFindingData: null,
                loader: false
            };
        case ADD_TREATMENT:
            return {
                ...state,
                treatmentPlanning: action?.payload
            };
        default:
            return {
                ...state
            };
    }
};

export default keyFindingReducer;
