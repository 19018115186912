import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../utils/api';
import {ApiEndpointVersion, errorToaster} from '../../../utils/helper';
import {
    getRiskCategoryDataSuccessAction,
    getRiskCategoryDataFailAction,
} from "../../actions/master/riskCategoriesActions";
import { GET_RISK_CATEGORY_DATA_REQUEST } from "../../actionTypes/master/riskCategories";

function* getRiskCategoryDataSaga(): any {
    try {
        const response = yield API.get(`${ApiEndpointVersion}/treatment/risk/categories`);
        const data = response?.data?.detail
        //Set data in global redux to use for revert/discard changes (future usages)
        yield put(
            getRiskCategoryDataSuccessAction(data)
        );
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getRiskCategoryDataFailAction()
        );
    }
}

function* riskCategoryDataWatcher() {
    yield takeEvery(GET_RISK_CATEGORY_DATA_REQUEST, getRiskCategoryDataSaga);
}

export default function* riskCategorySaga() {
    yield all([riskCategoryDataWatcher()]);
}
