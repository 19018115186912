import {
  STAFF_NEW_PROFILE_REQUEST,
  STAFF_NEW_PROFILE_REQUEST_SUCCESS,
  STAFF_NEW_PROFILE_REQUEST_FAILURE,
  STAFF_NEW_PROFILE_EDIT_REQUEST,
  STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE,
  STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS
} from '../../actionTypes/dentist/profileDentist';
import {
  StaffProfileNewRequest,
  StaffProfileNewRequestSuccess,
  StaffProfileNewRequestFailure,
  StaffProfileNewEditRequest,
  StaffProfileNewRequestPayload,
  StaffProfileNewRequestSuccessPayload,
  StaffProfileNewRequestFailurePayload,
  staffProfileNewEditRequestPayload,
} from '../../types/dentist/dentistProfile';

//dentist Profile Data
export const staffProfileNewRequest = (payload: StaffProfileNewRequestPayload): StaffProfileNewRequest => ({
  type: STAFF_NEW_PROFILE_REQUEST,
  payload
});


export const staffProfileNewRequestSuccess = (
  payload: StaffProfileNewRequestSuccessPayload
): StaffProfileNewRequestSuccess => ({
  type: STAFF_NEW_PROFILE_REQUEST_SUCCESS,
  payload
});

export const staffProfileNewRequestFailure = (
  payload: StaffProfileNewRequestFailurePayload
): StaffProfileNewRequestFailure => ({
  type: STAFF_NEW_PROFILE_REQUEST_FAILURE,
  payload
});

//Edit Staff Profile

export const staffProfileNewEditRequest = (payload: staffProfileNewEditRequestPayload): StaffProfileNewEditRequest => ({
  type: STAFF_NEW_PROFILE_EDIT_REQUEST,
  payload
});
export const staffProfileNewEditRequestFailure = () => ({
  type: STAFF_NEW_PROFILE_EDIT_REQUEST_FAILURE
});

export const staffProfileNewEditRequestSuccess = () => ({
  type: STAFF_NEW_PROFILE_EDIT_REQUEST_SUCCESS,
});
