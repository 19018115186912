import {
    PATIENT_PROFILE_EDIT_REQUEST_V2,
    PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2,
    PATIENT_PROFILE_REQUEST_V2,
    PATIENT_PROFILE_REQUEST_FAILURE_V2,
    PATIENT_PROFILE_REQUEST_SUCCESS_V2,
    PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2
} from '../../actionTypes/patient/patientProfile';
import {
    PatientProfileEditRequest,
    PatientProfileEditRequestPayload,
    PatientProfileRequest,
    PatientProfileRequestFailure,
    PatientProfileRequestFailurePayload,
    PatientProfileRequestPayload,
    PatientProfileRequestSuccess,
    PatientProfileRequestSuccessPayload
} from '../../types/patient/patientProfileActionTypes';

//Patient Profile Data
export const patientProfileRequest = (payload: PatientProfileRequestPayload): PatientProfileRequest => ({
    type: PATIENT_PROFILE_REQUEST_V2,
    payload
});

export const patientProfileRequestSuccess = (payload: PatientProfileRequestSuccessPayload): PatientProfileRequestSuccess => ({
    type: PATIENT_PROFILE_REQUEST_SUCCESS_V2,
    payload
});

export const patientProfileRequestFailure = (payload: PatientProfileRequestFailurePayload): PatientProfileRequestFailure => ({
    type: PATIENT_PROFILE_REQUEST_FAILURE_V2,
    payload
});

//Edit Patient Profile

export const patientProfileEditRequest = (payload: PatientProfileEditRequestPayload): PatientProfileEditRequest => ({
    type: PATIENT_PROFILE_EDIT_REQUEST_V2,
    payload
});
export const patientProfileEditRequestSuccess = () => ({
    type: PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2
});
export const patientProfileEditRequestFailure = () => ({
    type: PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2
});
