import {UnsubscribeEmailAction, UnsubscribeEmailInterface} from "../../types/unsubscribeEmail/unsubscribeTypes";
import {
    UNSUBSCRIBE_EMAIL_REQUEST, UNSUBSCRIBE_EMAIL_REQUEST_FAILURE,
    UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS
} from "../../actionTypes/unsubscribeEmail/unsubscribeActionTypes";


const initialState: UnsubscribeEmailInterface = {
    unsubscribeEmailLoader: false,
};

const unsubscribeEmailReducer = (state = initialState, action: UnsubscribeEmailAction) => {
    switch (action.type) {
        case UNSUBSCRIBE_EMAIL_REQUEST:
            return {
                ...state,
                unsubscribeEmailLoader: true,

            };
        case UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS:
            return {
                ...state,
                unsubscribeEmailLoader: false,

            };
        case UNSUBSCRIBE_EMAIL_REQUEST_FAILURE:
            return {
                ...state,
                unsubscribeEmailLoader: false,

            };
        default:
            return {
                ...state
            };
    }
};

export default unsubscribeEmailReducer;
