import {Button, Divider, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ScrollableTagWrapper from "../ScrollableTagWrapper";
import ExamsCustomFields from '../../ExamsCustomFields';
import {selectMarkerAction} from "../../../../../../redux/actions/dentist/clinicalExam/exam/annotationAction";
import {setExamFindingsAction} from '../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction';
import {RootState} from '../../../../../../redux/reducers/rootReducer';
import {examList} from "../../../../../../utils/helper";
import {disabledField} from '../../../../../../utils/constant/clinicalExamConstant';
import DropDown from "../../../../../common/UIComponents/DropDown";
import InputHeading from "../../../../../common/UIComponents/InputHeading";

const useStyles = makeStyles((theme: any) => ({
    locateButton: {
        color: theme.palette.v2.primary.main,
        borderColor: theme.palette.v2.primary.main,
        '&:hover': {
            background: theme.palette.common.white,
            color: theme.palette.v2.primary.main,
            borderColor: theme.palette.v2.primary.main,
        },
    }
}));
type Props = {
    meta: any;
    data: any;
    examIndex?: number | undefined;
};
const TagField = React.memo((props: Props) => {
    const {meta, data} = props;
    const classes = useStyles();
    const dispatch = useDispatch()
    const {
        examFindingReducer: {examFindingData},
        annotation: {selectedMarker},
        appointmentsReducer: {appointmentDetails}
    } = useSelector((state: RootState) => state) as any
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const getExamFromTitle = (title: string) => {
        return examList[title]
    }
    const getTagData = () => {
        if (examFindingData !== null) {
            return examFindingData?.filter((f: any) => getExamFromTitle(f?.title || '')?.key === meta?.key)
        }
        return null
    }
    const handleSelectFinding = (e: any, tag: any) => {
        const updatedExamFindingData = JSON.parse(JSON.stringify(examFindingData))
        let index = 0;
        updatedExamFindingData.map((res: any, i: number) => {
            if (res?.key === tag?.key) {
                index = i
            }
            return true
        })
        updatedExamFindingData[index].finding = e?.target?.value
        Object.keys(updatedExamFindingData[index])?.forEach((f: any) => {
            if(f!=='finding' && f!=='x_position'&& f!=='y_position'&& f!=='title'&& f!=='key'&& f!=='tag_id'){
                updatedExamFindingData[index][f] = null
            }
        })
        dispatch(setExamFindingsAction(updatedExamFindingData))
    }
    const getFindingLists = () => {
        return data?.map((res: any) => {
            return {
                value: res?.key,
                name: res?.title
            }
        })
    }
    const getSelectedFindingData = (selectedFinding: string) => {
        if (selectedFinding) {
            return data.filter((f: any) => f?.key === selectedFinding)[0]?.options
        }
        return null
    }

    const handleTagDelete = (key: number) => {
        let index = 0
        examFindingData.map((res: any, i: number) => {
            if (res?.key === key) {
                index = i
            }
            return true
        })
        dispatch(setExamFindingsAction(examFindingData.filter((_f: any, i: number) => i !== index)))
    }

    const getFindingName = (findingKey: string) => {
        let findingName = '' as any
        getFindingLists()?.filter((f: any) => {
            if (f?.value === findingKey) {
                return findingName = f?.name
            }
            return true
        })
        return findingName
    }

    return (
        <React.Fragment>
            {
                getTagData()?.map((tag: any, k: number) => <React.Fragment key={k}>
                    <ScrollableTagWrapper>
                        <Grid container spacing={1}
                              className={selectedMarker?.key === tag?.key ? 'selected-markers-component' : ''}
                              sx={{marginLeft: '0px !important'}}>
                            <Grid item xs={12}>
                                <InputHeading title={'Select Finding'}/>
                                <DropDown
                                    selectOptions={getFindingLists()}
                                    selectProps={{
                                        value: getFindingName(tag?.finding),
                                        onChange: (e: any) => handleSelectFinding(e, tag),
                                        disabled: (disabledField(appointmentDetails) || isWellnessProfile)
                                    }}
                                />
                            </Grid>
                            {getSelectedFindingData(tag?.finding)?.map((option: any, k: number) => (
                                <ExamsCustomFields
                                    key={k}
                                    exam={meta.key}
                                    fieldData={option}
                                    finding={tag?.finding}
                                    index={0}
                                    isMulti={undefined}
                                    tag={tag}
                                    disabledWellnessAnnotation={isWellnessProfile}
                                />
                            ))}
                            {/*Tag's form buttons controls*/}
                            <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} mb={2} pr={1}>
                                <Button variant='outlined' color={'error'} onClick={() => handleTagDelete(tag?.key)}
                                        sx={{marginRight: '8px'}}
                                        disabled={disabledField(appointmentDetails) || isWellnessProfile}>
                                    Delete
                                </Button>
                                <Button variant='outlined' onClick={() => dispatch(selectMarkerAction(tag))}
                                        className={classes.locateButton}
                                        disabled={disabledField(appointmentDetails)}
                                >
                                    Locate
                                </Button>
                            </Grid>
                            {data?.length !== k + 1 && <Divider sx={{my: 2}}/>}
                        </Grid>
                    </ScrollableTagWrapper>
                </React.Fragment>)
            }
        </React.Fragment>
    );
})

export default TagField;
