export const implantRoot = (color?: string) => {
    return `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 27.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="40px" height="34px" viewBox="0 0 40 34" style="enable-background:new 0 0 40 34;" xml:space="preserve">
<style type="text/css">
	.st1{fill:#FFFFFF;}
</style>
<g>
	<path fill=${color || '#483698'} d="M16,8.195V34h8V8.195l-0.34-0.789c-1.41-3.208-5.9-3.208-7.31,0l-0.34,0.789H16z"/>
	<circle class="st1" cx="20" cy="10.329" r="2"/>
</g>
</svg>
`;
};
