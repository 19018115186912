import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster} from '../../../utils/helper';
import {
    patientCommunicationRequestFailure,
    patientCommunicationRequestSuccess
} from "../../actions/patient/patientCommunicationAction";
import {PATIENT_COMMUNICATION_REQUEST} from "../../actionTypes/patient/patientCommunication";


//patient Profile Data
function* patientCommunicationRequestSaga(payload: any): any {
    try {
        const response = yield API.post(
            `/${ApiEndpointVersion}/auth/communication-preferences`, payload.payload
        );
        yield put(
            patientCommunicationRequestSuccess(response.data?.detail,)
        );
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            patientCommunicationRequestFailure(e)
        );
    }
}


function* patientCommunicationWatch() {
    yield takeEvery(PATIENT_COMMUNICATION_REQUEST, patientCommunicationRequestSaga);
}


export default function* patientCommunicationSaga() {
    yield all([
        patientCommunicationWatch(),
    ]);
}
