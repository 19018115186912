import {
    CHANGE_WELLNESS_COMPLAINTS_LOCAL_REQUEST,
    CHANGE_WELLNESS_COMPLAINTS_REQUEST,
    CHANGE_WELLNESS_PATIENT_PREFERENCE_REQUEST,
    CHANGE_WELLNESS_SOCIAL_NOTES_REQUEST,
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST,
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_FAILURE,
    CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_SUCCESS,
    GET_WELLNESS_REQUEST,
    GET_WELLNESS_REQUEST_FAILURE,
    GET_WELLNESS_REQUEST_SUCCESS,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_FAILURE,
    UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_SUCCESS,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_FAILURE,
    UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_SUCCESS,
    UPDATE_WELLNESS_PROFILE_STEP,
    CHANGE_WELLNESS_HYGIENE_REGIME_LOCAL_REQUEST,
    UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST,
    UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_SUCCESS,
    UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_FAILURE,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST_SUCCESS,
    GENERATE_RISK_SCORE_WELLNESS_REQUEST_FAILURE,
} from '../../../actionTypes/dentist/wellnessProfile/wellnessProfileFilters';
import {
    wellnessProfileFiltersActions,
    wellnessProfileFiltersInterface
} from '../../../types/dentist/wellnessProfile/wellnessProfileFilters';

const initialState: wellnessProfileFiltersInterface = {
    wellnessProfileStep: 'patientInformation',
    wellnessProfileLoader: false,
    wellnessProfileData: null,
    wellnessProfileError: null,
    updateDentistWellnessProfileLoader: false,
    wellnessComplaints: null,
    wellnessPatientPreferences: '',
    wellnessSocialNotes: '',
    wellnessComplaintsLoader: false,
    wellnessComplaintsLocal: null,
    wellnessProfileDataLocal: null,
    createAppointmentFromWellnessLoader: false,
    changeWellnessHygieneRegimeLocal: null,
    updateWellnessHygieneRegimeLoader: false,
    wellnessRiskScoreDataLoader: false,
    wellnessRiskScoreDataData: null,
    wellnessRiskScoreDataError: null
};

const wellnessProfileFiltersReducer = (state = initialState, action: wellnessProfileFiltersActions) => {
    switch (action.type) {
        case UPDATE_WELLNESS_PROFILE_STEP:
            return {
                ...state,
                wellnessProfileStep: action.payload
            };
        case GET_WELLNESS_REQUEST:
            return {
                ...state,
                wellnessProfileLoader: true,
                wellnessProfileData: null,
                wellnessProfileError: null
            };
        case GET_WELLNESS_REQUEST_SUCCESS:
            return {
                ...state,
                wellnessProfileLoader: false,
                wellnessProfileData: action.payload,
                wellnessProfileDataLocal: action.payload,
                wellnessProfileError: null
            };
        case GET_WELLNESS_REQUEST_FAILURE:
            return {
                ...state,
                wellnessProfileLoader: false,
                wellnessProfileData: null,
                wellnessProfileError: action.payload
            };
        case UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST:
            return {
                ...state,
                updateDentistWellnessProfileLoader: true,

            };
        case UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                updateDentistWellnessProfileLoader: false,

            };
        case UPDATE_DENTIST_WELLNESS_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                updateDentistWellnessProfileLoader: false,
            };
        case CHANGE_WELLNESS_COMPLAINTS_REQUEST:
            return {
                ...state,
                wellnessComplaints: action.payload
            };
        case CHANGE_WELLNESS_PATIENT_PREFERENCE_REQUEST:
            return {
                ...state,
                wellnessPatientPreferences: action.payload
            };
        case CHANGE_WELLNESS_SOCIAL_NOTES_REQUEST:
            return {
                ...state,
                wellnessSocialNotes: action.payload
            };
        case UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST:
            return {
                ...state,
                wellnessComplaintsLoader: true,
            };
        case UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_SUCCESS:
            return {
                ...state,
                wellnessComplaintsLoader: false,
            };
        case UPDATE_DENTIST_WELLNESS_COMPLAINTS_REQUEST_FAILURE:
            return {
                ...state,
                wellnessComplaintsLoader: false,
            };
        case CHANGE_WELLNESS_COMPLAINTS_LOCAL_REQUEST:
            return {
                ...state,
                wellnessComplaintsLocal: action.payload
            }
        case CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST:
            return {
                ...state,
                createAppointmentFromWellnessLoader: true,

            };
        case CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_SUCCESS:
            return {
                ...state,
                createAppointmentFromWellnessLoader: false,

            };
        case CREATE_APPOINTMENT_FROM_WELLNESS_REQUEST_FAILURE:
            return {
                ...state,
                createAppointmentFromWellnessLoader: false,

            };
        case CHANGE_WELLNESS_HYGIENE_REGIME_LOCAL_REQUEST:
            return {
                ...state,
                changeWellnessHygieneRegimeLocal: action.payload,
            };
        case UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST:
            return {
                ...state,
                updateWellnessHygieneRegimeLoader: true,

            };
        case UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_SUCCESS:
            return {
                ...state,
                updateWellnessHygieneRegimeLoader: false,

            };
        case UPDATE_WELLNESS_HYGIENE_REGIME_REQUEST_FAILURE:
            return {
                ...state,
                updateWellnessHygieneRegimeLoader: false,

            };
        case GENERATE_RISK_SCORE_WELLNESS_REQUEST:
            return {
                ...state,
                wellnessRiskScoreDataLoader: true,
                wellnessRiskScoreDataData: null,
                wellnessRiskScoreDataError: null,
            };
        case GENERATE_RISK_SCORE_WELLNESS_REQUEST_SUCCESS:
            return {
                ...state,
                wellnessRiskScoreDataLoader: false,
                wellnessRiskScoreDataData: action.payload,
                wellnessRiskScoreDataError: null
            };
        case GENERATE_RISK_SCORE_WELLNESS_REQUEST_FAILURE:
            return {
                ...state,
                wellnessRiskScoreDataLoader: false,
                wellnessRiskScoreDataData: null,
                wellnessRiskScoreDataError: action.payload
            };
        default:
            return {
                ...state
            };
    }
};

export default wellnessProfileFiltersReducer;
