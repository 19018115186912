import mpClassImage from '../../assets/images/tootipsImages/mpClass.png';
import tonsilClassImage from '../../assets/images/tootipsImages/tonsilClass.png';
import tongueScallopingImage from '../../assets/images/tootipsImages/tongueScalloping.png';
import tongueTieImage from '../../assets/images/tootipsImages/tongueTie.png';
import uvulaImage from '../../assets/images/tootipsImages/uvula.png';
import softPalateImage from '../../assets/images/tootipsImages/softPalate.png';
import mandibularImage from '../../assets/images/tootipsImages/mandibular.png';
import maxillaryToriImage from '../../assets/images/tootipsImages/maxillaryTori.png';
import angleClassificationImage from '../../assets/images/tootipsImages/angleClassification.png';
import angleClassificationTwoImage from '../../assets/images/tootipsImages/angleClassification2.png';
import angleClassificationThreeImage from '../../assets/images/tootipsImages/angleClassification3.png';
import angleClassificationFourImage from '../../assets/images/tootipsImages/angleClassification4.png';
import excurLateralImage from '../../assets/images/tootipsImages/excur_lateral.png';
import cuspidRiseImage from '../../assets/images/tootipsImages/cuspidRise.png';
import groupFunctionImage from '../../assets/images/tootipsImages/groupFunction.png';
import protruGuideImage from '../../assets/images/tootipsImages/protru_guide.png';
import maxMidlineFaceMidlineImage from '../../assets/images/tootipsImages/max_midline_to_face_midline.png';
import maxMidlineFaceMidlineTwoImage from '../../assets/images/tootipsImages/max_midline_to_face_midline2.png';
import mandMidlineMaxMidlineImage from '../../assets/images/tootipsImages/mand_midline_to_max_midline.png';
import mandMidlineMaxMidlineTwoImage from '../../assets/images/tootipsImages/mand_midline_to_max_midline2.png';
import overjetImage from '../../assets/images/tootipsImages/overjet_mm.png';
import overjetTwoImage from '../../assets/images/tootipsImages/overjet_mm2.png';
import overbiteImage from '../../assets/images/tootipsImages/overbite.png';
import crowdingImage from '../../assets/images/tootipsImages/Crowding.png';
import crowdingTwoImage from '../../assets/images/tootipsImages/Crowding2.png';
import maxillaryCrowdingImage from '../../assets/images/tootipsImages/maxillaryCrowding.png';
import mandibularCrowdingImage from '../../assets/images/tootipsImages/mandibularCrowding.png';
import spacingTwoImage from '../../assets/images/tootipsImages/Spacing.png';
import maxillarySpacingImage from '../../assets/images/tootipsImages/maxillarySpacing.png';
import mandibularSpacingImage from '../../assets/images/tootipsImages/mandibularSpacing.png';
import thyroidImage from '../../assets/images/tootipsImages/thyroid.png';
import skeletalClassImage from '../../assets/images/tootipsImages/skeletal_class.png';
import tmjImage from '../../assets/images/tootipsImages/tmj.png';
import masseterImage from '../../assets/images/tootipsImages/masseter.png';
import fremitusImage from '../../assets/images/tootipsImages/fremitus.png';
import fremitusTwoImage from '../../assets/images/tootipsImages/fremitus2.png';
import shimbashiNumberImage from '../../assets/images/tootipsImages/shimbashiNumber.png';
import generalizedBoneLossImage from '../../assets/images/tootipsImages/generalized_bone_loss.png';
import gingivitisClassImage from '../../assets/images/tootipsImages/gingivitis_class.png';
import periodontitisClassImage from '../../assets/images/tootipsImages/periodontitis_class.png';
import localizedBoneLossImage from '../../assets/images/tootipsImages/localized_bone_loss.png';
import attritionImage from '../../assets/images/tootipsImages/attrition.png';
import erosionImage from '../../assets/images/tootipsImages/erosion.png';
import ncclImage from '../../assets/images/tootipsImages/nccl.png';
import erosionNcclImage from '../../assets/images/tootipsImages/erosionNccl.png';
import exposedDentinImage from '../../assets/images/tootipsImages/exposedDentin.png';
import recessionImage from '../../assets/images/tootipsImages/recession.png';
import furcationImage from '../../assets/images/tootipsImages/furcation.png';
import stagingImage from '../../assets/images/staging.png';
import gradingImage from '../../assets/images/grading.png';

export const tooltipData = {
  mp_class: {
    question: 'What is MP Class?',
    answer: {
      images: [{ image: mpClassImage, label: 'mpClass' }],
      text: [
        { title: 'Class I: Full visual of patient’s airway.' },
        { title: "Class II: ¾ visualization of patient's airway." },
        { title: "Class III: ¼ visualization of patient's airway." },
        { title: "Class IV: No visualization of patient's airway." }
      ]
    }
  },
  tonsil_class: {
    question: 'What is Tonsil Class?',
    answer: {
      images: [{ image: tonsilClassImage, label: 'tonsilClass' }]
    }
  },
  tongue_scalloping: {
    question: 'What is Tongue Scalloping?',
    answer: {
      images: [{ image: tongueScallopingImage, label: 'tongueScalloping' }]
    }
  },
  tongue_ties: {
    question: 'What is Tongue Tie?',
    answer: {
      images: [{ image: tongueTieImage, label: 'tongueTie' }]
    }
  },
  soft_palate: {
    question: 'What is Soft Palate?',
    answer: {
      images: [{ image: softPalateImage, label: 'softPalate' }]
    }
  },
  uvula: {
    question: 'What is Uvula?',
    answer: {
      images: [{ image: uvulaImage, label: 'uvula' }]
    }
  },
  mandibular: {
    question: 'What is Mandibular Tori?',
    answer: {
      images: [{ image: mandibularImage, label: 'mandibular' }]
    }
  },
  maxillary: {
    question: 'What is Maxillary Tori?',
    answer: {
      images: [{ image: maxillaryToriImage, label: 'mandibular' }]
    }
  },
  angle_class_right_cuspid: {
    question: 'Angle Classification Right Cuspid?',
    answer: {
      images: [
        { image: angleClassificationImage, label: 'angleClassification' },
        { image: angleClassificationTwoImage, label: 'angleClassification' },
        { image: angleClassificationThreeImage, label: 'angleClassification' },
        { image: angleClassificationFourImage, label: 'angleClassification' }
      ],
      text: [
        { title: 'Class I:' },
        { title: 'A Class 1 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding in line with the buccal groove of the mandibular first molar (i.e., the maxillary first molar is slightly posteriorly positioned relative to the mandibular first molar.).'
        },
        { title: 'Class II:' },
        { title: 'A Class 2 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding anterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is in line with or anteriorly positioned relative to the mandibular first molar).'
        },
        { title: 'Class 2 Division 1 – Class 2 molars with normally inclined or pro-clined maxillary central incisors' },
        { title: 'Class 2 Division 2 – Class 2 molars with retro-clined maxillary central incisors' },
        { title: 'Class III:' },
        { title: 'A Class 3 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding posterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is severely posteriorly positioned relative to the mandibular first molar).'
        }
      ]
    }
  },
  angle_class_left_cuspid: {
    question: 'Angle Classification Left Cuspid?',
    answer: {
      images: [
        { image: angleClassificationImage, label: 'angleClassification' },
        { image: angleClassificationTwoImage, label: 'angleClassification' },
        { image: angleClassificationThreeImage, label: 'angleClassification' },
        { image: angleClassificationFourImage, label: 'angleClassification' }
      ],
      text: [
        { title: 'Class I:' },
        { title: 'A Class 1 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding in line with the buccal groove of the mandibular first molar (i.e., the maxillary first molar is slightly posteriorly positioned relative to the mandibular first molar.).'
        },
        { title: 'Class II:' },
        { title: 'A Class 2 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding anterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is in line with or anteriorly positioned relative to the mandibular first molar).'
        },
        { title: 'Class 2 Division 1 – Class 2 molars with normally inclined or pro-clined maxillary central incisors' },
        { title: 'Class 2 Division 2 – Class 2 molars with retro-clined maxillary central incisors' },
        { title: 'Class III:' },
        { title: 'A Class 3 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding posterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is severely posteriorly positioned relative to the mandibular first molar).'
        }
      ]
    }
  },
  angle_class_right_molar: {
    question: 'Angle Classification Right Molar?',
    answer: {
      images: [
        { image: angleClassificationImage, label: 'angleClassification' },
        { image: angleClassificationTwoImage, label: 'angleClassification' },
        { image: angleClassificationThreeImage, label: 'angleClassification' },
        { image: angleClassificationFourImage, label: 'angleClassification' }
      ],
      text: [
        { title: 'Class I:' },
        { title: 'A Class 1 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding in line with the buccal groove of the mandibular first molar (i.e., the maxillary first molar is slightly posteriorly positioned relative to the mandibular first molar.).'
        },
        { title: 'Class II:' },
        { title: 'A Class 2 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding anterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is in line with or anteriorly positioned relative to the mandibular first molar).'
        },
        { title: 'Class 2 Division 1 – Class 2 molars with normally inclined or pro-clined maxillary central incisors' },
        { title: 'Class 2 Division 2 – Class 2 molars with retro-clined maxillary central incisors' },
        { title: 'Class III:' },
        { title: 'A Class 3 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding posterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is severely posteriorly positioned relative to the mandibular first molar).'
        }
      ]
    }
  },
  angle_class_left_molar: {
    question: 'Angle Classification Left Molar?',
    answer: {
      images: [
        { image: angleClassificationImage, label: 'angleClassification' },
        { image: angleClassificationTwoImage, label: 'angleClassification' },
        { image: angleClassificationThreeImage, label: 'angleClassification' },
        { image: angleClassificationFourImage, label: 'angleClassification' }
      ],
      text: [
        { title: 'Class I:' },
        { title: 'A Class 1 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding in line with the buccal groove of the mandibular first molar (i.e., the maxillary first molar is slightly posteriorly positioned relative to the mandibular first molar.).'
        },
        { title: 'Class II:' },
        { title: 'A Class 2 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding anterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is in line with or anteriorly positioned relative to the mandibular first molar).'
        },
        { title: 'Class 2 Division 1 – Class 2 molars with normally inclined or pro-clined maxillary central incisors' },
        { title: 'Class 2 Division 2 – Class 2 molars with retro-clined maxillary central incisors' },
        { title: 'Class III:' },
        { title: 'A Class 3 molar relationship is described as:' },
        {
          title:
            'The mesio-buccal cusp of the maxillary first molar occluding posterior to the buccal groove of the mandibular first molar (i.e., the maxillary first molar is severely posteriorly positioned relative to the mandibular first molar).'
        }
      ]
    }
  },
  excur_lateral_right: {
    question: 'What is Lateral Excursion Right?',
    answer: {
      images: [
        { image: excurLateralImage, label: 'excur_lateral_right' },
        { image: groupFunctionImage, label: 'groupFunction' },
        { image: cuspidRiseImage, label: 'cuspidRise' }
      ],
      text: [
        {
          title:
            'Cuspid Rise: During excursion right/left, the pre molars are ONLY in contact (upper and lower). Spacing visualized on molars.'
        },
        { title: 'Group Function: Pre molars and Molars are in contact (upper and lower) during excursion.' }
      ]
    }
  },
  excur_lateral_left: {
    question: 'What is Lateral Excursion Left?',
    answer: {
      images: [
        { image: excurLateralImage, label: 'excur_lateral_right' },
        { image: groupFunctionImage, label: 'groupFunction' },
        { image: cuspidRiseImage, label: 'cuspidRise' }
      ],
      text: [
        {
          title:
            'Cuspid Rise: During excursion right/left, the pre molars are ONLY in contact (upper and lower). Spacing visualized on molars.'
        },
        { title: 'Group Function: Pre molars and Molars are in contact (upper and lower) during excursion.' }
      ]
    }
  },
  protru_guide: {
    question: 'What is Protrusive Guidance?',
    answer: {
      images: [{ image: protruGuideImage, label: 'protru_guide' }],
      text: [
        {
          title:
            'Inform patient occlude on normal bite, and slide their front teeth forward, indicate which teeth are in contact (anterior) during this protrusive slide forward (i.e. 11,21, 41,31).'
        }
      ]
    }
  },
  max_midline_to_face_midline: {
    question: 'Maxillary Midline in relation to Philtrum?',
    answer: {
      images: [
        { image: maxMidlineFaceMidlineImage, label: 'max_midline_to_face_midline' },
        { image: maxMidlineFaceMidlineTwoImage, label: 'max_midline_to_face_midline' }
      ],
      text: [
        {
          title:
            'Philtrum: A vertical groove in the midline portion of the upper lip bordered by two lateral ridges or pillars.'
        }
      ]
    }
  },
  mand_midline_to_max_midline: {
    question: 'Mandibular Midline in relation to Maxillary Midline?',
    answer: {
      images: [
        { image: mandMidlineMaxMidlineImage, label: 'mandMidlineMaxMidline' },
        { image: mandMidlineMaxMidlineTwoImage, label: 'mandMidlineMaxMidlineTwo' }
      ]
    }
  },
  overjet: {
    question: 'Overjet at Tooth #31?',
    answer: {
      images: [{ image: overjetImage, label: 'overjet' }, { image: overjetTwoImage, label: 'overjet' }],
      text: [
        {
          title:
            'Overjet Dimensions (mm): Using probe measure the distance from maxillary anterior protrude in relation to the mandibular anterior when patient is in normal occlusion.'
        }
      ]
    }
  },
  overbite: {
    question: 'Overbite at Tooth?',
    answer: {
      images: [{ image: overbiteImage, label: 'overbite' }],
      text: [
        {
          title:
            'Overbite: Is the extent of vertical overlap of the maxillary central incisors over the mandibular central incisors.'
        },
        {
          title:
            'Overbite, Dimensions (mm): Using probe measure the distance from maxillary anterior overlaps in relation to the mandibular anterior when patient is in normal occlusion.'
        },
        { title: 'Overbite, Dimensions (%): Overbite percentage: 30%, 50% or 100% ' },
        { title: 'Type of Overbite --- Width of overbite(mm) --- Percentage Severity' },
        { title: 'Normal               1 to 3 mm                 30%' },
        { title: 'Deep                 4-8 mm                    50%' },
        { title: 'Severe               9mm or more               100%' }
      ]
    }
  },
  overbite_mm: {
    question: 'Overbite at Tooth?',
    answer: {
      images: [{ image: overbiteImage, label: 'overbite' }],
      text: [
        {
          title:
            'Overbite: Is the extent of vertical overlap of the maxillary central incisors over the mandibular central incisors.'
        },
        {
          title:
            'Overbite, Dimensions (mm): Using probe measure the distance from maxillary anterior overlaps in relation to the mandibular anterior when patient is in normal occlusion.'
        },
        { title: 'Overbite, Dimensions (%): Overbite percentage: 30%, 50% or 100% ' },
        { title: 'Type of Overbite --- Width of overbite(mm) --- Percentage Severity' },
        { title: 'Normal               1 to 3 mm                 30%' },
        { title: 'Deep                 4-8 mm                    50%' },
        { title: 'Severe               9mm or more               100%' }
      ]
    }
  },
  crowding_max: {
    question: 'Crowding, total Maxillary?',
    answer: {
      images: [
        // { image: crowdingImage, label: 'crowding' },
        { image: crowdingTwoImage, label: 'crowding' },
        { image: maxillaryCrowdingImage, label: 'crowding' }]
    }
  },
  crowding_mand: {
    question: 'Crowding, total Mandibular?',
    answer: {
      images: [
        { image: crowdingImage, label: 'crowding' },
        { image: crowdingTwoImage, label: 'crowding' },
        { image: mandibularCrowdingImage, label: 'crowding' }]
    }
  },
  spacing_max: {
    question: 'Spacing, total Maxillary?',
    answer: {
      images: [
        { image: spacingTwoImage, label: 'spacing' },
        { image: maxillarySpacingImage, label: 'spacing' }
      ]
    }
  },
  spacing_mand: {
    question: 'Spacing, total Mandibular?',
    answer: {
      images: [
        { image: spacingTwoImage, label: 'spacing' },
        { image: mandibularSpacingImage, label: 'spacing' }
      ]
    }
  },
  asa_classification: {
    question: 'ASA Classification?',
    answer: {
      text: [
        { title: 'ASA Physical Status 1 ---> A normal healthy patient' },
        { title: 'ASA Physical Status 2 ---> A patient with mild systemic disease' },
        { title: 'ASA Physical Status 3 ---> A patient with severe systemic disease' },
        { title: 'ASA Physical Status 4 ---> A patient with severe systemic disease that is a constant threat to life' },
        { title: 'ASA Physical Status 5 ---> A moribund patient who is not expected to survive without the operation' },
        { title: 'ASA Physical Status 6 ---> A declared brain-dead patient whose organs are being removed for donor purposes' }
      ]
    }
  },
  thyroid: {
    question: 'Thyroid?',
    answer: {
      images: [{ image: thyroidImage, label: 'thyroid' }]
    }
  },
  skeletal_class: {
    question: 'Skeletal Class?',
    answer: {
      images: [{ image: skeletalClassImage, label: 'skeletalClass' }]
    }
  },
  click: {
    question: 'TMJ: Click?',
    answer: {
      images: [{ image: tmjImage, label: 'tmj' }]
    }
  },
  crepitus: {
    question: 'TMJ: Crepitus?',
    answer: {
      images: [{ image: tmjImage, label: 'tmj' }]
    }
  },
  masseter: {
    question: 'Masseter?',
    answer: {
      images: [{ image: masseterImage, label: 'masseter' }]
    }
  },
  temporalis: {
    question: 'Temporalis?',
    answer: {
      images: [{ image: masseterImage, label: 'masseter' }]
    }
  },
  medial_pterygoid: {
    question: 'Medial Pterygoid?',
    answer: {
      images: [{ image: masseterImage, label: 'masseter' }]
    }
  },
  lateral_pterygoid: {
    question: 'Lateral Pterygoid?',
    answer: {
      images: [{ image: masseterImage, label: 'masseter' }]
    }
  },
  fremitus: {
    question: 'Fremitus?',
    answer: {
      images: [{ image: fremitusImage, label: 'fremitus' }, { image: fremitusTwoImage, label: 'fremitus' }]
    }
  },
  shimbashi: {
    question: 'Shimbashi Number?',
    answer: {
      images: [{ image: shimbashiNumberImage, label: 'shimbashiNumber' }],
      text: [
        {
          title:
            'This simple number which we use is the measurement from the gum line of the upper front tooth to the gum line of the lower front tooth. This ideal distance to be approximately 19 mm plus or minus one mm (about three quarters of an inch) when a patient bites.'
        }
      ]
    }
  },
  generalized_bone_loss: {
    question: 'Generalized Bone Loss?',
    answer: {
      images: [{ image: generalizedBoneLossImage, label: 'generalizedBoneLoss' }],
      text: [
        {
          title:
            'Generalized bone loss occurs most frequently as horizontal bone loss. Horizontal bone loss manifests as a somewhat even degree of bone resorption so that the height of the bone in relation to the teeth has been uniformly decreased'
        }
      ]
    }
  },
  clinical_attachment_loss_manual: {
    question: 'What is clinical attachment loss?',
    answer: {
      text: [
        {
          title:
            'Clinical attachment loss is the amount of recession plus the pocket depth on a specific tooth. (Recession + Pocket depth = clinical attachment loss)'
        }
      ]
    }
  },
  gingivitis_class: {
    question: 'Gingivitis?',
    answer: {
      images: [{ image: gingivitisClassImage, label: 'gingivitisClass' }],
      text: [
        {
          title:
            'Gingivitis is a common and mild form of gum disease (periodontal disease) that causes irritation, redness and swelling (inflammation) of your gingiva, the part of your gum around the base of your teeth.'
        }
      ]
    }
  },
  periodontitis_class: {
    question: 'Periodontitis?',
    answer: {
      images: [{ image: periodontitisClassImage, label: 'periodontitisClass' }],
      text: [
        {
          title:
            'Periodontitis also called gum disease, is a serious gum infection that damages the soft tissue and, without treatment, can destroy the bone that supports your teeth. Periodontitis can cause teeth to loosen or lead to tooth loss.'
        }
      ]
    }
  },
  localized_bone_loss: {
    question: 'Localized Bone Loss?',
    answer: {
      images: [{ image: localizedBoneLossImage, label: 'localizedBoneLoss' }],
      text: [{ title: 'Localized bone loss occurs in isolated areas' }]
    }
  },
  non_carious_cervical_lesions: {
    question: 'NCCLs: Non-carious cervical lesions?',
    answer: {
      images: [
        { image: ncclImage, label: 'nccl' },
        { image: erosionNcclImage, label: 'erosionNccl' },
      ],
      text: [
        {
          title:
            'NCCLs are defects on teeth near the gums. The wedge-shaped loss of tooth structure resembles the mark that an axe leaves on a tree when chopping it. The lesions can be attributed to a combination of internal stresses on the tooth (from high biting forces, usually associated with grinding or clenching during sleep) and/or external wear forces (from aggressive tooth brushing or regular dietary acidic exposure).'
        },

      ]
    }
  },
  erosion: {
    question: 'Erosion?',
    answer: {
      images: [{ image: erosionImage, label: 'erosion' },
      { image: erosionNcclImage, label: 'erosionNccl' },],
      text: [{ title: 'Erosion: Tooth erosion constitutes the wearing a way of tooth enamel by acid. ' }]
    }
  },
  attrition: {
    question: 'Attrition?',
    answer: {
      images: [{ image: erosionNcclImage, label: 'erosionNccl' }, { image: attritionImage, label: 'attrition' }],
      text: [{ title: 'Attrition: Tooth attrition is dental wear caused by one tooth’s contact with another.' }]
    }
  },
  is_dentin_exposed: {
    question: 'Exposed Dentin?',
    answer: {
      images: [{ image: exposedDentinImage, label: 'exposedDentinNccl' }, { image: attritionImage, label: 'is_dentin_exposed' }],

    }
  },
  recession: {
    question: 'Recession?',
    answer: {
      images: [{ image: recessionImage, label: 'recession' }],
      text: [
        {
          title:
            'Receding gums are a condition in which your gums pull back from the tooth surface, exposing the root surfaces of your teeth.'
        }
      ]
    }
  },
  furcation: {
    question: 'Furcation?',
    answer: {
      images: [{ image: furcationImage, label: 'furcation' }],
      text: [
        {
          title:
            'A furcation involvement, also called a furcation invasion, is defined as an area of bone loss at this branching point of a tooth root.'
        }
      ]
    }
  },
  stageAndGrade: {
    question: 'What is Stage and Grade?',
    answer: [
      {
        images: [
          { image: stagingImage, label: 'stage' },
        ],
        heading: 'Stage'
      },
      {
        images: [
          { image: gradingImage, label: 'grade' }
        ],
        heading: 'Grade'
      }
    ]
  }
} as any;
