import React from 'react';
import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {makeStyles} from "@mui/styles";
import {Box} from '@mui/system';
import theme from '../../../../theme';

type Props = {
    groupProps?: any,
    options?: any,
    sx?: any,
    renderOptions?: any,
    disabled?: boolean
    isPatient?: boolean
};

const RadioSelect = (props: Props) => {
    const {groupProps, options, sx, renderOptions, disabled,isPatient} = props as Props;
    const useStyles = makeStyles((theme: any) => ({
        radioButton: {
            height: '50px',
            padding: '0 10px 0 0',
            margin: 0,
            '& .MuiButtonBase-root': {
                color: disabled ? theme.palette.text.secondary : theme.palette.common.black50,
            },
            '& .Mui-checked': {
                color: disabled ? theme.palette.text.secondary : isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,

            },
            '& .MuiTypography-root': {
                fontSize: `14px !important`,
                fontFamily: 'FavoritPro-Regular',
                color: theme.palette.common.black50
            }
        }
    }));
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box className='d-flex flex-col'>
                <RadioGroup
                    aria-labelledby='radio-buttons-group-label'
                    sx={{display: 'flex', flexDirection: 'row', ...sx}}
                    className='fw-regular'
                    {...groupProps}
                >
                    {renderOptions && renderOptions}
                    {options && options?.map((res: any, index: number) =>
                        <React.Fragment   key={index}>
                            <FormControlLabel
                                value={res.value}
                                disabled={disabled || res?.disabled}
                                control={<Radio/>}
                                label={res?.label}
                                className={`${classes.radioButton} labelColor fw-regular f-w-400 f-14 lh-16`}
                                sx={{
                                    '& .MuiButtonBase-root': {
                                        color: res?.disabled ? `${theme.palette.text.secondary} !important` : theme.palette.common.black50,
                                    },
                                    '& .Mui-checked': {
                                        color: res?.disabled ? `${theme.palette.text.secondary} !important` : theme.palette.v2.primary.main,
                                    },
                                    textTransform: 'capitalize !important'
                                }}
                            />
                        </React.Fragment>
                    )}
                </RadioGroup>
                {groupProps?.error && <Typography ml={4} color={theme.palette.error.main}
                                                  className='f-12 lh-14 fw-regular'>{groupProps?.helperText}</Typography>}
            </Box>
        </React.Fragment>
    );
};

export default RadioSelect;
