import {CssBaseline, Paper} from '@mui/material';
import {Box} from '@mui/system';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../../redux/reducers/rootReducer';
import theme from '../../../theme';
import {appointmentStatus} from '../../../utils/constant/clinicalExamConstant';
import HelmetComponent from '../HelmetComponent';
import Sidebar from "../../dentist/DentistLayout/Sidebar";

export const AppointmentLayout = (props: any) => {
    const {component, name} = props;
    const {  appointmentsReducer: {appointmentDetails}} = useSelector((state: RootState) => state);
    const {Finished} = appointmentStatus
    const {getProfileData} = useSelector((state: RootState) => state.patientProfileData);
    const patientName = getProfileData ? `${getProfileData?.first_name} ${getProfileData?.last_name}'s Wellness Profile` : 'Wellness Profile'

    const getSelectedTab = () => {
        switch (appointmentDetails?.status) {
            case Finished:
                return `${appointmentDetails?.patient?.patientName}'s Exam`;
            default:
                return appointmentDetails?.patient?.patientName;
        }
    };

    return (
        <Paper sx={{background: `${theme.palette.background.secondaryPaper} !important`, height: '100vh'}}
               elevation={0}>
            <HelmetComponent
                title={(name === 'clinic-exam') ? getSelectedTab() : name === 'wellness-profile' ? patientName : name}/>
            <CssBaseline/>
            <Sidebar layout={'appointment'}
                         headerTitle={(name === 'clinic-exam') ? getSelectedTab() : name === 'wellness-profile' ? patientName : name}/>
            <Paper
                sx={{
                    // marginLeft: `${sidebarWidth}px`,
                    transition: theme.transitions.create('margin', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen
                    }),
                    marginTop: '80px',
                    background: `${theme.palette.background.secondaryPaper} !important`,
                    height: 'calc(100vh - 101px)'
                }}
                elevation={0}
            >
                <Box sx={{background: `${theme.palette.background.secondaryPaper} !important`}}>{component}</Box>
            </Paper>
        </Paper>
    );
};
