import React, {useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from '@mui/styles'
import {Box} from '@mui/system'
import {Grid, IconButton, InputAdornment} from '@mui/material'
import {useDispatch, useSelector} from "react-redux";
import PopupComponent from "../UIComponents/PopupComponent";
import TextBox from "../UIComponents/TextBox";
import theme from '../../../theme'
import {verifyPasswordRequest} from "../../../redux/actions/common/clinicSelection/clinicActions";
import {getLocalStorageItem} from '../../../utils/helper'


const useStyles = makeStyles(() => ({
    showPassword: {
        marginRight: '0px !important',
        color: `${theme.palette.common.black50} !important`,
        width: '48px',
        height: ' 48px',
    },
}))

type Props = {
    isPatient?: boolean | undefined,
    open: boolean,
    setOpen: any,
    clinic: any,
    setNewClinic: any
}
const VerifyPasswordPopup = (props: Props) => {
    const {open, setOpen, clinic, setNewClinic, isPatient} = props
    const classes = useStyles()
    const dispatch = useDispatch()
    const {pending} = useSelector((state: any) => state.verifyPassword);
    const {data} = useSelector((state: any) => state.Clinic);
    const [showPassword, setShowPassword] = useState(false)
    const [password, setPassword] = useState('')

    const handleSubmit = () => {
        dispatch(verifyPasswordRequest({
            password,
            callback: () => {
                if (isPatient) {
                    localStorage.setItem('patientClinic', clinic)
                    localStorage.setItem('patientClinicInfo', JSON.stringify(data?.data?.filter((cf: any) => cf?.clinic_id === clinic)[0]))
                    //Redirect user to Wellness profile after changing clinicSelection
                    window.location.href = '/patient/wellness-profile'
                } else {
                    localStorage.setItem('clinic', clinic)
                    localStorage.setItem('clinicInfo', JSON.stringify(data?.data?.filter((cf: any) => cf?.clinic_id === clinic)[0]))
                    //Redirect user to dashboard after changing clinicSelection
                    window.location.href = '/dentist/dashboard'
                }
                setOpen(false)
                setPassword('')
                setShowPassword(false)

            }
        }))
    }
    const handleClose = () => {
        if (isPatient) {
            localStorage.setItem('patientClinic', getLocalStorageItem('patientClinic') || '')
            setNewClinic(getLocalStorageItem('patientClinic'))
        } else {
            localStorage.setItem('clinic', getLocalStorageItem('clinic') || '')
            setNewClinic(getLocalStorageItem('clinic'))
        }
        setOpen(false)
        setPassword('')
        setShowPassword(false)
    }

    return (
        <PopupComponent open={open} headerAction footerAction width={'458px'} handleClose={handleClose}
                        handleSave={handleSubmit}
                        isPatient={isPatient} saveBtnText={'Verify'} heading={'Verify your password'} isOraqIcon
                        notDisplayCancel disabledbtn={pending || password.length < 3}>
            <Box className={`d-flex-all-center flex-col`}>
                <Grid item={true} lg={12} md={12} sm={12} xs={12} className='w-100'>
                    <TextBox
                        isPatient
                        label={'Password'}
                        required
                        isPadding
                        inputProps={{
                            name: "password",
                            onChange: (e: any) => {
                                setPassword(e.target.value)
                            },
                            value: password,
                            fullWidth: true,
                            type: showPassword ? 'text' : 'password',
                            InputProps: {
                                classes: {
                                    root: 'border-radius-8 textFieldEndIconPadding',
                                    input: 'textFieldEndIcon'
                                },
                                endAdornment: (
                                    <InputAdornment position="end" sx={{
                                        width: '48px',
                                        height: ' 48px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginLeft: '0px',
                                        maxHeight: '',
                                        marginRight: '1px'

                                    }}>
                                        <IconButton
                                            disableFocusRipple
                                            disableRipple
                                            aria-label='toggle password visibility'
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge='end'
                                            className={classes.showPassword}
                                        >
                                            {showPassword ?
                                                <FontAwesomeIcon icon={faEye} width={16} height={16}
                                                                 className='f-w-900 fw-medium f-16 lh-16'/>
                                                : <FontAwesomeIcon icon={faEyeSlash} width={16} height={16}
                                                                   className='f-w-900 fw-medium f-16 lh-16'/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            },
                        }}
                    />
                </Grid>
            </Box>
        </PopupComponent>
    )
}

export default VerifyPasswordPopup