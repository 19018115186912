import React from 'react';
import { Typography } from '@mui/material';
import theme from '../../theme';


type Props = {
  variant?: string;
  className?: string;
  sx?: any;
  heading: string;
};

const Heading = (props: Props) => {
  const { heading, className, sx } = props;
  return (
    <Typography
      className={`fw-regular ${className}`}
      fontSize={'16px'}
      sx={{ lineHeight: '21px', mb: '15px', color: theme.palette.common.black50, ...sx }}
    >
      {heading}
    </Typography>
  );
};

export default Heading;
