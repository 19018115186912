import React, { useMemo } from 'react'
import { Box, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import MedicalAlertsCollapsibleBox from "./MedicalAlertsCollapsibleBox";
import theme from '../../../../theme';
import CardComponent from '../../../common/UIComponents/CardComponent'
import { RootState } from "../../../../redux/reducers/rootReducer";
import { stringToArray } from "../../../../utils/helper";
import NoDataFound from "../../../common/NoDataFound";

const MedicalAlerts = () => {
    const { patientId } = useParams();
    const navigate = useNavigate()
    const { wellnessProfileFilters: { wellnessProfileData }, } = useSelector((state: RootState) => state);
    const medicalAlerts = wellnessProfileData?.medical_alerts;

    const diseasesList: string[] = useMemo(() => {
        return stringToArray(medicalAlerts?.medical_conditions)
    }, [medicalAlerts?.medical_conditions]);

    const allergiesList: string[] = useMemo(() => {
        return stringToArray(medicalAlerts?.allergies_list)
    }, [ medicalAlerts?.allergies_list]);

    const viewAll = () => {
        const search = `?section=medicalHealthHistory`
        navigate({
            pathname: `/dentist/appointment/health-history/${patientId}`,
            search: search
        })
    }
    const medicalAlertsList = useMemo(() => [
        {
            title: 'Serious Illnesses Or Operations?',
            content: medicalAlerts?.serious_illness_or_operation_existance
                ? medicalAlerts?.serious_illness_or_operation_name
                : 'No',
            isData: !!medicalAlerts?.serious_illness_or_operation_existance
        },
        {
            title: 'Currently Taking Any Medications And/Or Supplements?',
            content: medicalAlerts?.currently_taking_any_medication
                ? medicalAlerts?.medication_list
                : 'No',
            isData: !!medicalAlerts?.currently_taking_any_medication
        },
        {
            title: 'Chest Pains And/Or Shortness Of Breath?',
            content: medicalAlerts?.chest_pain_shortness_breath ? 'Yes' : 'No',
            isData: !!medicalAlerts?.chest_pain_shortness_breath
        },
        {
            title: 'Are You Nursing?',
            content: medicalAlerts?.nursing ? 'Yes' : 'No',
            isData: !!medicalAlerts?.nursing
        },
        {
            title: 'Are You Pregnant?',
            content: medicalAlerts?.pregnant ? 'Yes' : 'No',
            isData: !!medicalAlerts?.pregnant
        },
        {
            title: 'Birth Control',
            content: medicalAlerts?.birth_control ? 'Yes' : 'No',
            isData: !!medicalAlerts?.birth_control
        },
        {
            title: 'Diseases List',
            content: diseasesList,
            isData: diseasesList?.length > 0
        },
        {
            title: 'Allergies List',
            content: allergiesList,
            isData: allergiesList?.length > 0
        }
    ], [medicalAlerts, diseasesList, allergiesList]);

    const isDataAvailable = medicalAlertsList?.some((item) => item?.isData);

    return (
        <CardComponent
            bgColor={theme.palette.v2.primary.primary25}
            color={theme.palette.v2.primary.main}
            title='Medical Alerts'
            rightText='+ Update Health History'
            height={230}
            btnClick={viewAll}
            fromMedicalAlerts
        >
            {!medicalAlerts || !isDataAvailable ? (
                <NoDataFound errorMessage={'data'} />
            ) : (
                medicalAlertsList?.map((item, index) =>
                    item?.isData && (
                        <MedicalAlertsCollapsibleBox key={index} title={item.title}>
                            {typeof item.content === 'string' ? (
                                <Typography className="f-14 f-w-400 fw-regular labelColor">
                                    {item?.content}
                                </Typography>
                            ) : (
                                <Box>
                                    {item?.content?.map((res: any, idx: number) => (
                                        <Box key={idx} ml={2} display="flex" alignItems="center" mb={1}>
                                            <FontAwesomeIcon color={theme.palette.common.black50} icon={faCircle} width="5px" />
                                            <Typography className="f-14 f-w-400 fw-regular" color={theme.palette.common.black50} ml={1}>
                                                {res}
                                            </Typography>
                                        </Box>
                                    ))}
                                </Box>
                            )}
                        </MedicalAlertsCollapsibleBox>
                    )
                )
            )}
        </CardComponent>
    )
}

export default MedicalAlerts