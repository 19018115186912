import {
    CHANGE_PASSWORD_V2_REQUEST,
    CHANGE_PASSWORD_V2_REQUEST_FAILURE,
    CHANGE_PASSWORD_V2_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {
    ChangePasswordRequest,
    ChangePasswordRequestPayload,
} from '../../types/authActionTypes/changePasswordActionTypes';

export const changePasswordRequest = (payload: ChangePasswordRequestPayload): ChangePasswordRequest => ({
    type: CHANGE_PASSWORD_V2_REQUEST,
    payload
});

export const changePasswordRequestSuccess = () => ({
    type: CHANGE_PASSWORD_V2_REQUEST_SUCCESS
});

export const changePasswordRequestFailure = () => ({
    type: CHANGE_PASSWORD_V2_REQUEST_FAILURE
});
