import { useEffect, useState } from "react"
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from "@mui/styles";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0,0,0,0.5)',
    boxShadow: 24,
    outline: 'none',
    p: 2,
};
const useStyles = makeStyles((theme: any) => ({
    arrowBox: {
        position: 'relative',
        borderRadius: '50%',
        backgroundColor: theme?.palette?.common?.black,
        height: '40px',
        width: '40px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme?.palette?.text?.darkGrey,
        },
    },
    arrowIcon: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        color: theme?.palette?.common?.white,
    }
}))

type Props = {
    open?: boolean | any;
    handleClose?: any;
    imageList?: any;
    question?: any;
};

export default function ImagePopUp(props: Props) {
    const { open, handleClose, imageList, question } = props as Props;
    const classes = useStyles()
    const [selectedItem, setSelectedItem]: any = useState(0);

    useEffect(() => {
        setSelectedItem(0)
    }, [question])

    const handlePrevNext = (string: any) => {
        string === 'prev' && setSelectedItem(selectedItem - 1);
        string === 'next' && setSelectedItem(selectedItem + 1);
    }

    return (
        <Modal
            keepMounted
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box display={'flex'} justifyContent={'flex-end'} onClick={handleClose}>
                        <Box className={classes.arrowBox}>
                            <FontAwesomeIcon icon={faXmark} className={`${classes.arrowIcon} f-24 f-w-900 cursor-pointer`} />
                        </Box>
                    </Box>

                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} height={'100%'}>
                        <Box>
                            {selectedItem > 0 && Array.isArray(imageList) &&
                                <Box className={classes.arrowBox} onClick={() => handlePrevNext('prev')}>
                                    <FontAwesomeIcon icon={faAngleLeft} className={`${classes.arrowIcon} f-24 f-w-900 cursor-pointer`} />
                                </Box>
                            }
                        </Box>
                        <Box>
                            {imageList?.length > 0 && (
                                Array.isArray(imageList) ? <img src={imageList[selectedItem]?.image} alt={imageList[selectedItem]?.label} />
                                    : <img src={imageList} alt={imageList} />
                            )}
                        </Box>
                        <Box>
                            {(selectedItem !== imageList?.length - 1) && Array.isArray(imageList) &&
                                <Box className={classes.arrowBox} onClick={() => handlePrevNext('next')}>
                                    <FontAwesomeIcon icon={faAngleRight} className={`${classes.arrowIcon} f-24 f-w-900 cursor-pointer`} />
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal >
    );
}