import {Box} from '@mui/material';
import React, {useEffect, useState} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {isSupported} from 'firebase/messaging';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Loader from './components/common/Loader';
import {onMessageListener, requestForToken} from './firebase';
import ErrorUI from "./pages/ErrorUi";
import {paths} from "./routing/AppRoutes";
import theme from './theme';

const PrivateRoute = React.lazy(() => import("./routing/PrivateRoute"));

interface routeInterface {
    path: string
    name: string
    exact: boolean
    component?: any
    private?: boolean
}

const App = () => {
    const [, setHasError] = useState(false);

    useEffect(() => {
        isSupported()?.then((res: any) => {
            if (res) {
                requestForToken().then(() => {
                    onMessageListener()
                        .then(() => {
                        })
                        .catch(() => {
                        });
                });
            }
        })
    }, [])

    useEffect(() => {
        const errorHandler = (error: any) => {
            console.error('Error:', error);
            setHasError(true);
        };

        window.addEventListener('error', errorHandler);
        return () => window.removeEventListener('error', errorHandler);
    }, []);

    // TODO: Hide ResizeObserver error in the browser
    useEffect(() => {
        const handleError = (e: any) => {
            if (e?.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        };

        window?.addEventListener('error', handleError);

        // Cleanup function to remove the event listener on unmount
        return () => {
            window?.removeEventListener('error', handleError);
        };
    }, []);


    return (
        <React.Fragment>
            <React.Suspense fallback={<Loader/>}>
                <Box sx={{
                    backgroundColor: `${theme.palette.background.secondaryPaper} !important`,
                    display: 'flex', flexDirection: 'column'
                }}
                     className={'appClassMain'}>
                    {/*Handle errors*/}
                    <ErrorUI>
                        <Routes>
                            <Route path="/" element={<Navigate to="/home" replace/>}/>
                            {paths?.map((res: routeInterface, i: number): any => (
                                <Route key={i} path={res.path}
                                       element={res.private ? (<PrivateRoute path={res}>
                                           <span>{res.component}</span></PrivateRoute>) : (res.component)}
                                />
                            ))}
                        </Routes>
                    </ErrorUI>
                </Box>
            </React.Suspense>
        </React.Fragment>
    );
};

export default App;
