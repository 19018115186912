//Edit Patient Wellness Profile
import {
    UpdatePatientWellnessProfileRequest,
    UpdatePatientWellnessProfileRequestPayload,
} from "../../types/patient/patientWellnessProfileActionTypes";
import {
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS
} from "../../actionTypes/patient/wellnessProfile";

export const updatePatientWellnessProfileRequest = (payload: UpdatePatientWellnessProfileRequestPayload): UpdatePatientWellnessProfileRequest => ({
    type: UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    payload
});
export const updatePatientWellnessProfileRequestSuccess = () => ({
    type: UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS
});
export const updatePatientWellnessProfileRequestFailure = () => ({
    type: UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE
});
