import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CustomRouter } from './components/common/CustomRouter';
import Loader from './components/common/Loader';
import './index.css';
import App from './App';
import store from './redux/types/store';
import theme from './theme';

ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer limit={0} position={'top-right'} hideProgressBar={true} autoClose={2000} />
          <CustomRouter>
            <App />
          </CustomRouter>
        </ThemeProvider>
      </Provider>
    </GoogleOAuthProvider>
  </React.Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
