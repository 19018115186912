import React, {useCallback, useMemo, useEffect} from 'react';
import {makeStyles} from "@mui/styles";
import {debounce} from "lodash";
import InputHeading from "../../../../../common/UIComponents/InputHeading";
import AsyncSelect from 'react-select/async'; // react-select/async to load options lazily when the user types, rather than loading all options at once.
import {customStylesForMultiSelect, DropdownIndicator} from "../../../../../../utils/constant/clinicalExamConstant";
import {toothNumberPreferences} from "../../../../../../utils/helper";

const useStyles = makeStyles((theme: any) => ({
    multiSelectCssOutlinedInput: {
        width: "100%",
        "&$cssFocused $notchedOutline": {
            border: `2px solid ${theme.palette.v2.primary.main} !important`,
        },
        "& .MuiSelect-select": {
            padding: "10px !important",
            borderRadius: "4px !important",
        },
        "& .select__menu-list": {
            maxHeight: "150px",
        },
        "& .select__control--is-disabled": {
            background: `${theme.palette.common.white} !important`,
            border: `1px solid rgba(0, 0, 0, 0.26) !important`,
            color: `${theme.palette.text.secondary} !important`,
        },
    },
}));

type Props = {
    fieldData: any
    selectedValue: any
    disabledField: any
    handleChangeMultiSelect: any
    exam: any
}
const MultiSelectDropDown = React.memo((props: Props) => {
    const {fieldData, selectedValue, disabledField, handleChangeMultiSelect, exam} = props
    const classes = useStyles();

    const getDropDownOption = (option: any) => {
        return {
            label: option,
            value: option,
        };
    };
    // eslint-disable-next-line
    const debouncedHandleChange = useCallback(
        debounce((e, key, exam, isMultiSelection) => {
            handleChangeMultiSelect(e, key, exam, isMultiSelection);
        }, 50),
        // eslint-disable-next-line
        [handleChangeMultiSelect]
    );

    const options = useMemo(() => {
        return (fieldData?.key?.includes('tooth_number'))
            ? fieldData?.options
                ?.map((res: string) => ({
                    label: toothNumberPreferences(parseInt(res)),
                    value: res,
                }))
                ?.sort((a: any, b: any) => parseInt(a?.label) - parseInt(b?.label))
            : fieldData?.options?.map(getDropDownOption);
    }, [fieldData?.key, fieldData?.options]);
    const customFilterOption = (option: any, rawInput: any) => {
        const label = option.label;
        if (typeof label === 'number') {
            // Convert the number to a string for searching.
            const labelString = label?.toString();
            const input = rawInput?.toLowerCase(); // Convert the input to lowercase for case-insensitive filtering.

            return labelString?.includes(input);
        }

        return false; // Return false for non-number labels.
    };
    useEffect(() => {
        return () => {
            debouncedHandleChange.cancel(); // Cancel debounce on unmount
        };
        // eslint-disable-next-line
    }, []);

    const loadOptions = (_: string, callback: (options: any[]) => void) => {
        // Simply return the memoized options, since you're not actually fetching from an API
        callback(options);
    };

    return (
        <React.Fragment>
            <InputHeading title={fieldData?.label}/>
            <AsyncSelect
                menuPortalTarget={document.body}
                menuShouldScrollIntoView={false}
                menuPlacement='auto'
                placeholder=""
                components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: (props: any) => DropdownIndicator(props, disabledField)
                }}
                value={selectedValue}
                styles={customStylesForMultiSelect}
                isMulti={fieldData?.type === "multi-select"}
                isDisabled={disabledField}
                id={fieldData?.key}
                name={fieldData?.key}
                filterOption={(fieldData?.key?.includes('tooth_number') ? customFilterOption : undefined)} //TODO changes if tooth_number include in other field
                loadOptions={loadOptions}
                defaultOptions
                className={`${classes.multiSelectCssOutlinedInput} border-radius-10`}
                classNamePrefix="select"
                onChange={(e: any) => {
                    debouncedHandleChange(e, fieldData?.key, exam, fieldData?.type === "multi-select");
                }}
            />
        </React.Fragment>
    )
})

export default MultiSelectDropDown;