import {
    PATIENT_PROFILE_EDIT_REQUEST_V2,
    PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2,
    PATIENT_PROFILE_REQUEST_V2,
    PATIENT_PROFILE_REQUEST_FAILURE_V2,
    PATIENT_PROFILE_REQUEST_SUCCESS_V2,
    PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2
} from '../../actionTypes/patient/patientProfile';
import {patientProfileInterface, PatientProfileActions} from '../../types/patient/patientProfileActionTypes';

const initialState: patientProfileInterface = {
    pending: false,
    error: null,
    getProfileData: null
};
const patientProfileReducer = (state = initialState, action: PatientProfileActions) => {
    switch (action.type) {
        case PATIENT_PROFILE_REQUEST_V2:
            return {
                ...state,
                pending: true,
                getProfileData: null,
                error: null
            };

        case PATIENT_PROFILE_REQUEST_SUCCESS_V2:
            return {
                ...state,
                pending: false,
                getProfileData: action.payload,
                error: null
            };

        case PATIENT_PROFILE_REQUEST_FAILURE_V2:
            return {
                ...state,
                pending: false,
                getProfileData: null,
                error: action.payload
            };
        case PATIENT_PROFILE_EDIT_REQUEST_V2:
            return {
                ...state,
                pending: true
            };
        case PATIENT_PROFILE_EDIT_REQUEST_SUCCESS_V2:
            return {
                ...state,
                pending: false,
            };

        case PATIENT_PROFILE_EDIT_REQUEST_FAILURE_V2:
            return {
                ...state,
                pending: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default patientProfileReducer;
