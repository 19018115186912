import {
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST, UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS
} from "../../actionTypes/patient/wellnessProfile";
import {
    PatientWellnessProfileActions,
    patientWellnessProfileInterface
} from "../../types/patient/patientWellnessProfileActionTypes";


const initialState: patientWellnessProfileInterface = {
    updateWellnessProfileLoader: false,
};
const patientWellnessProfileReducer = (state = initialState, action: PatientWellnessProfileActions) => {
    switch (action.type) {

        case UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST:
            return {
                ...state,
                updateWellnessProfileLoader: true,
            };
        case UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                updateWellnessProfileLoader: false,
            };

        case UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                updateWellnessProfileLoader: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default patientWellnessProfileReducer;
