import {
    ADD_EXISTING_FINDING,
    CHECKED_EXISTING_FINDING,
    FAILURE_EXAM_API_DATA,
    GET_HARD_TISSUE_REQUEST,
    GET_ODONTOGRAM_REQUEST,
    GET_OTHER_FORM_FINDING_REQUEST,
    GET_PERIOGRAM_REQUEST,
    GET_SOFT_TISSUE_REQUEST,
    PUT_HARD_TISSUE_REQUEST,
    PUT_ODONTOGRAM_REQUEST,
    PUT_OTHER_FORM_FINDING_REQUEST,
    PUT_PERIOGRAM_REQUEST,
    PUT_SOFT_TISSUE_REQUEST,
    SET_EXAM_FINDINGS,
    SUCCESS_EXAM_API_DATA,
    UPDATE_EXAM_API_DATA,
    EXAM_GROUP_FINDING_DATA_COUNT,
    SYNC_PERIOGRAM_PMS_DATA_REQUEST,
    SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE,
    SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS,
    SYNC_ODONTOGRAM_PMS_DATA_REQUEST,
    SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE,
    SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS,
    FAILURE_PUT_EXAM_API_DATA,
    GET_LIMITED_EXAM_REQUEST,
    PUT_LIMITED_EXAM_REQUEST,
    SET_EXAM_DATA_LOADER,
    GET_ODONTOGRAM_OTHER_FINDINGS_REQUEST,
    GET_ODONTOGRAM_OTHER_FINDINGS_SUCCESS_REQUEST,
    GET_ODONTOGRAM_OTHER_FINDINGS_FAILURE_REQUEST,
    UPDATED_ODONTOGRAM_OTHER_FINDING_DATA, GET_EXAM_REQUEST, PUT_EXAM_REQUEST
} from '../../../../actionTypes/dentist/clinicalExam/exams/examFindingsTypes';
import {
    AddExistingFindingsDataRequest,
    CheckedExistingFindingsRequest,
    ExamFindingDataCountRequest,
    GetHardTissueRequest,
    GetHardTissueRequestPayload,
    GetOdontogramRequest,
    GetOdontogramRequestPayload,
    GetOtherFormFindingRequest,
    GetOtherFormFindingRequestPayload,
    GetPeriogramRequest,
    GetPeriogramRequestPayload,
    GetSoftTissueRequest,
    GetSoftTissueRequestPayload,
    PutHardTissueRequest,
    PutHardTissueRequestPayload,
    PutOdontogramRequest,
    PutOdontogramRequestPayload,
    PutOtherFormFindingRequest,
    PutOtherFormFindingRequestPayload,
    PutPeriogramRequest,
    PutPeriogramRequestPayload,
    PutSoftTissueRequest,
    PutSoftTissueRequestPayload,
    SetExamFindingsRequest,
    syncPeriogramPmsDataRequestFailure,
    syncPeriogramPmsDataRequestFailurePayload,
    syncPeriogramPmsDataRequestSuccess,
    syncPeriogramPmsDataRequestSuccessPayload,
    syncOdontogramPmsDataRequestFailure,
    syncOdontogramPmsDataRequestFailurePayload,
    syncOdontogramPmsDataRequestSuccess,
    syncOdontogramPmsDataRequestSuccessPayload,
    GetLimitedExamRequestPayload,
    GetLimitedExamRequest,
    PutLimitedExamRequestPayload,
    PutLimitedExamRequest,
    GetOdontogramOtherFindingRequest,
    GetOdontogramOtherFindingRequestSuccess,
    GetOdontogramOtherFindingRequestFailure,
    GetOdontogramOtherFindingRequestPayload,
    GetOdontogramOtherFindingRequestSuccessPayload,
    GetOdontogramOtherFindingRequestFailurePayload,
    UpdatedOdontogramOtherFindingRequest,
    UpdatedOdontogramOtherFindingRequestPayload,
    GetExamRequestPayload,
    GetExamRequest, PutExamRequestPayload, PutExamRequest
} from '../../../../types/dentist/clinicalExam/exam/examFindingsTypes';

export const setExamFindingsAction = (payload: any): SetExamFindingsRequest => ({
    type: SET_EXAM_FINDINGS,
    payload
});

// add finding in existing exam
export const addExistingFindingsDataAction = (payload: any): AddExistingFindingsDataRequest => ({
    type: ADD_EXISTING_FINDING,
    payload
});

export const checkedExistingFindingsDataAction = (payload: any): CheckedExistingFindingsRequest => ({
    type: CHECKED_EXISTING_FINDING,
    payload
});

// get Soft tissue
export const getSoftTissueRequestAction = (payload: GetSoftTissueRequestPayload): GetSoftTissueRequest => ({
    type: GET_SOFT_TISSUE_REQUEST,
    payload
});

// put Soft tissue
export const putSoftTissueRequestAction = (payload: PutSoftTissueRequestPayload): PutSoftTissueRequest => ({
    type: PUT_SOFT_TISSUE_REQUEST,
    payload
});

// get hard tissue
export const getHardTissueRequestAction = (payload: GetHardTissueRequestPayload): GetHardTissueRequest => ({
    type: GET_HARD_TISSUE_REQUEST,
    payload
});

// put hard tissue
export const putHardTissueRequestAction = (payload: PutHardTissueRequestPayload): PutHardTissueRequest => ({
    type: PUT_HARD_TISSUE_REQUEST,
    payload
});

// get Odontogram
export const getOdontogramRequestAction = (payload: GetOdontogramRequestPayload): GetOdontogramRequest => ({
    type: GET_ODONTOGRAM_REQUEST,
    payload
});

// put Odontogram
export const putOdontogramRequestAction = (payload: PutOdontogramRequestPayload): PutOdontogramRequest => ({
    type: PUT_ODONTOGRAM_REQUEST,
    payload
});

// get Periogram
export const getPeriogramRequestAction = (payload: GetPeriogramRequestPayload): GetPeriogramRequest => ({
    type: GET_PERIOGRAM_REQUEST,
    payload
});

// put Periogram
export const putPeriogramRequestAction = (payload: PutPeriogramRequestPayload): PutPeriogramRequest => ({
    type: PUT_PERIOGRAM_REQUEST,
    payload
});

// get other form finding
export const getOtherFormFindingRequestAction = (payload: GetOtherFormFindingRequestPayload): GetOtherFormFindingRequest => ({
    type: GET_OTHER_FORM_FINDING_REQUEST,
    payload
});

// put other form finding
export const putOtherFormFindingRequestAction = (payload: PutOtherFormFindingRequestPayload): PutOtherFormFindingRequest => ({
    type: PUT_OTHER_FORM_FINDING_REQUEST,
    payload
});

// get Limited Exam
export const getLimitedExamRequestAction = (payload: GetLimitedExamRequestPayload): GetLimitedExamRequest => ({
    type: GET_LIMITED_EXAM_REQUEST,
    payload
});

// put Limited Exam
export const putLimitedExamRequestAction = (payload: PutLimitedExamRequestPayload): PutLimitedExamRequest => ({
    type: PUT_LIMITED_EXAM_REQUEST,
    payload
});

export const updateExamAPIDataAction = (payload: any): any => ({
    type: UPDATE_EXAM_API_DATA,
    payload
});
export const successExamAPIDataAction = (): any => ({
    type: SUCCESS_EXAM_API_DATA
});

export const failureExamAPIDataAction = (): any => ({
    type: FAILURE_EXAM_API_DATA
});
export const failurePutExamAPIDataAction = (): any => ({
    type: FAILURE_PUT_EXAM_API_DATA
});
export const examFindingDataCountAction = (payload: any): ExamFindingDataCountRequest => ({
    type: EXAM_GROUP_FINDING_DATA_COUNT,
    payload
});

export const syncPeriogramPmsDataRequestAction = (payload: any): any => ({
    type: SYNC_PERIOGRAM_PMS_DATA_REQUEST,
    payload
});

export const syncOdontogramPmsDataRequestAction = (payload: any): any => ({
    type: SYNC_ODONTOGRAM_PMS_DATA_REQUEST,
    payload
});

export const syncPeriogramPmsDataRequestSuccessAction = (
    payload: syncPeriogramPmsDataRequestSuccessPayload
): syncPeriogramPmsDataRequestSuccess => ({
    type: SYNC_PERIOGRAM_PMS_DATA_REQUEST_SUCCESS,
    payload
});

export const syncPeriogramPmsDataRequestFailureAction = (
    payload: syncPeriogramPmsDataRequestFailurePayload
): syncPeriogramPmsDataRequestFailure => ({
    type: SYNC_PERIOGRAM_PMS_DATA_REQUEST_FAILURE,
    payload
});

export const syncOdontogramPmsDataRequestSuccessAction = (
    payload: syncOdontogramPmsDataRequestSuccessPayload
): syncOdontogramPmsDataRequestSuccess => ({
    type: SYNC_ODONTOGRAM_PMS_DATA_REQUEST_SUCCESS,
    payload
});

export const syncOdontogramPmsDataRequestFailureAction = (
    payload: syncOdontogramPmsDataRequestFailurePayload
): syncOdontogramPmsDataRequestFailure => ({
    type: SYNC_ODONTOGRAM_PMS_DATA_REQUEST_FAILURE,
    payload
});

export const setExamDataLoader = (payload: any): any => ({
    type: SET_EXAM_DATA_LOADER,
    payload
})

export const getOdontogramOtherFindingRequestAction = (payload: GetOdontogramOtherFindingRequestPayload): GetOdontogramOtherFindingRequest => ({
    type: GET_ODONTOGRAM_OTHER_FINDINGS_REQUEST,
    payload
});

export const getOdontogramOtherFindingRequestSuccessAction = (
    payload: GetOdontogramOtherFindingRequestSuccessPayload
): GetOdontogramOtherFindingRequestSuccess => ({
    type: GET_ODONTOGRAM_OTHER_FINDINGS_SUCCESS_REQUEST,
    payload
});

export const getOdontogramOtherFindingRequestFailureAction = (
    payload: GetOdontogramOtherFindingRequestFailurePayload
): GetOdontogramOtherFindingRequestFailure => ({
    type: GET_ODONTOGRAM_OTHER_FINDINGS_FAILURE_REQUEST,
    payload
});

export const updatedOdontogramOtherFindingRequestAction = (
    payload: UpdatedOdontogramOtherFindingRequestPayload
): UpdatedOdontogramOtherFindingRequest => ({
    type: UPDATED_ODONTOGRAM_OTHER_FINDING_DATA,
    payload
});

export const getExamRequestAction = (payload: GetExamRequestPayload): GetExamRequest => ({
    type: GET_EXAM_REQUEST,
    payload
});

// put Soft tissue
export const putExamRequestAction = (payload: PutExamRequestPayload): PutExamRequest => ({
    type: PUT_EXAM_REQUEST,
    payload
});