import {put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';
import {disclaimerFailure, disclaimerSuccess} from '../../actions/authAction/disclaimerAction';
import {DISCLAIMER_ACCEPT_REQUEST} from '../../actionTypes/auth';

function* acceptDisclaimerRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/auth/disclaimer/accepted`, payload?.payload?.payload)
        yield put(
            disclaimerSuccess()
        );
        if (payload?.payload?.callback) {
            payload?.payload?.callback(response)
        }
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            disclaimerFailure()
        );
    }
}

function* acceptDisclaimerWatch() {
    yield takeEvery(DISCLAIMER_ACCEPT_REQUEST, acceptDisclaimerRequestSaga);
}

export default function* disclaimerSaga() {
    yield all([
        acceptDisclaimerWatch()
    ])
}
