import {makeStyles} from '@mui/styles';
import React from 'react'
import Box from '@mui/material/Box';
import WellnessUpcomingAppointment from './WellnessUpcomingAppointment';
import TabsComponent from "../../../common/UIComponents/TabsComponent";
import {appointmentTabData} from "../../../../utils/constant/wellnessProfileConstant";

const useStyles = makeStyles((theme: any) => ({
    tabContainer: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflowY: 'auto'
    }
}));

const WellnessAppointment = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(1);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className='cursor-pointer'>
                <TabsComponent tabData={appointmentTabData} value={value} handleChange={handleChange}/>
            </Box>
            <Box className={classes.tabContainer} p={2}>
                {value === 1 && <WellnessUpcomingAppointment appointment={'upcoming'}/>}
                {value === 2 && <WellnessUpcomingAppointment appointment={'past'}/>}
            </Box>
        </Box>
    )
}

export default WellnessAppointment