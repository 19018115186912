import Reference18 from '../../assets/images/refPhotos/clinicalExamImage/reference18.png';
import Reference1 from '../../assets/images/refPhotos/clinicalExamImage/reference1new.png';
import Reference2 from '../../assets/images/refPhotos/clinicalExamImage/reference2new.png';
import Reference3 from '../../assets/images/refPhotos/clinicalExamImage/reference3new.png';
import Reference4 from '../../assets/images/refPhotos/clinicalExamImage/reference4new.png';
import Reference5 from '../../assets/images/refPhotos/clinicalExamImage/reference5new.png';
import Reference7 from '../../assets/images/refPhotos/clinicalExamImage/reference7new.png';
import Reference8 from '../../assets/images/refPhotos/clinicalExamImage/reference8new.png';
import Reference11 from '../../assets/images/refPhotos/clinicalExamImage/reference11new.png';
import Reference12 from '../../assets/images/refPhotos/clinicalExamImage/reference12new.png';
import Reference13 from '../../assets/images/refPhotos/clinicalExamImage/reference13new.png';
import Reference14 from '../../assets/images/refPhotos/clinicalExamImage/reference14new.png';
import Reference15 from '../../assets/images/refPhotos/clinicalExamImage/reference15new.png';
import Reference16 from '../../assets/images/refPhotos/clinicalExamImage/reference16new.png';
import Reference17 from '../../assets/images/refPhotos/clinicalExamImage/reference17new.png';
import ReferenceImg3 from '../../assets/images/refPhotos/image1.png';
import ESoundImage from '../../assets/images/refPhotos/eSound.png';
import FrontRefClosed from '../../assets/images/refPhotos/frontRefClosed.png';
import FrontRefTeethApart from '../../assets/images/refPhotos/frontRefteethApart.png';
import SideRightClosed from '../../assets/images/refPhotos/sideRightClose.png';
import PostOrophrynx from '../../assets/images/refPhotos/postOropharynx.png';
import SideLeftTeethClose from '../../assets/images/refPhotos/sideLeftTeethClose.png';
import TongueSticking from '../../assets/images/refPhotos/tongueSticking.png';
import {CE, RE, LE} from './examConstant';
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";

export const referencePhotos = [
    {
        title: 'Front face profile full smile',
        refImage: Reference18,
        examType: [CE]
    },
    {
        title: 'Front face profile repose (open mouth)',
        refImage: Reference15,
        examType: [CE]
    },
    {
        title: 'Side face profile full smile',
        refImage: Reference16,
        examType: [CE]
    },
    {
        title: 'Side face profile repose (open mouth)',
        refImage: Reference17,
        examType: [CE]
    },
    {
        title: 'Front face profile with retractors and teeth apart',
        refImage: ReferenceImg3,
        examType: [CE]
    },
    {
        title: 'E Sound',
        refImage: ESoundImage,
        examType: [CE]
    },
    {
        title: 'Repose (teeth showing)',
        refImage: Reference1,
        examType: [CE]
    },
    {
        title: 'Full smile (teeth showing)',
        refImage: Reference2,
        examType: [CE, RE, LE]
    },
    {
        title: 'Side Left Smile',
        refImage: Reference4,
        examType: [CE]
    },
    {
        title: 'Side Right Smile',
        refImage: Reference3,
        examType: [CE]
    },
    {
        title: 'Front retractors closed',
        refImage: FrontRefClosed,
        examType: [CE, RE, LE]
    },
    {
        title: 'Front retractors teeth apart',
        refImage: FrontRefTeethApart,
        examType: [CE]
    },
    {
        title: 'Front retractors anterior edge to edge',
        refImage: Reference5,
        examType: [CE]
    },
    {
        title: 'Side left teeth closed with both retractors',
        refImage: SideLeftTeethClose,
        examType: [CE]
    },
    {
        title: 'Side left teeth open with both retractors',
        refImage: Reference8,
        examType: [CE]
    },
    {
        title: 'Side right closed with both retractors',
        refImage: SideRightClosed,
        examType: [CE]
    },
    {
        title: 'Side right open with both retractors',
        refImage: Reference7,
        examType: [CE]
    },
    {
        title: 'Maxillary 13-23 with contrastor',
        refImage: Reference12,
        examType: [CE]
    },
    {
        title: 'Mandibular 33-43 with contrastor',
        refImage: Reference11,
        examType: [CE]
    },
    {
        title: 'Tongue depressed posterior oropharynx',
        refImage: PostOrophrynx,
        examType: [CE]
    },
    {
        title: 'Tongue sticking out posterior oropharynx (Saying AH)',
        refImage: TongueSticking,
        examType: [CE, RE, LE]
    },
    {
        title: 'Maxillary occlusal arch',
        refImage: Reference13,
        examType: [CE, RE, LE]
    },
    {
        title: 'Mandibular occlusal arch',
        refImage: Reference14,
        examType: [CE, RE, LE]
    }
];

export const referenceXray = [
    {title: 'Right Molar BW: Q1+4'},
    {title: 'Right Premolar BW: Q1+4'},
    {title: 'Left Premolar BW: Q2+3'},
    {title: 'Left Molar BW: Q2+3'},
    {title: 'Maxillary Anterior PA R Canine-Central: 13-11'},
    {title: 'Maxillary Anterior PA Centrals: 11+21'},
    {title: 'Maxillary Anterior PA L Canine-Centrals: 23-21'},
    {title: 'Mandibular PA Centrals: 32-42'},
    {title: 'Panoramic xray'}
];


export const FACING_MODE_ENVIRONMENT = "environment";
export const videoConstraints = {
    width: 440,
    height: 223,
    video: false,
    facingMode: FACING_MODE_ENVIRONMENT,
}


export const fileTypes = ["JPG", "PNG", "JPEG"];

export const AntSwitch = styled(Switch)(({theme}) => ({
    width: 27,
    height: 17,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: '#077178',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 9,
        height: 9,
        borderRadius: 6,
        marginTop: '1.5px',
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));
