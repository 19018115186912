import { all, call, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { ApiEndpointVersion, errorToaster, getLocalStorageItem, successToaster } from '../../../../utils/helper';
import { updateAppointmentDetailsRequestAction } from "../../../actions/dentist/appointmentsAction";
import {
    getRiskFilterScoreRequestFailureAction,
    getRiskFilterScoreRequestSuccessAction,
    openAdditionalNotesAction,
    putAdditionalNotesRequestFailureAction,
    putAdditionalNotesRequestSuccessAction,
    sendWellnessEmailLinkRequestSuccessAction,
    sendWellnessEmailLinkRequestFailureAction,
    endExamRequestSuccessAction,
    endExamRequestFailureAction,
    syncImageXrayRequestSuccessAction, syncImageXrayRequestFailureAction,
    viewEventLogRequestSuccessAction,
    viewEventLogRequestFailureAction
} from '../../../actions/dentist/clinicalExam/clinicalExamFilterAction';
import {
    END_EXAM_REQUEST,
    GET_RISK_FILTER_SCORE_REQUEST,
    PUT_ADDITIONAL_NOTES_REQUEST,
    SEND_WELLNESS_EMAIL_LINK_REQUEST, SYNC_IMAGE_XRAY_REQUEST,
    VIEW_EVENT_LOG_REQUEST
} from '../../../actionTypes/dentist/clinicalExam/clinicalExamFilters';


function* riskFilterScoreRequestSaga(payload: any): any {
    try {
        const response = yield API.get(
            payload.payload?.isPusher ?
                `/${ApiEndpointVersion}/risk/profile/filter/${payload.payload?.appointment_id}?is_pusher=${payload?.payload?.isPusher}&core_apis=true`
                :
                `/${ApiEndpointVersion}/risk/profile/filter/${payload.payload?.appointment_id}`
        )
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        yield put(
            getRiskFilterScoreRequestSuccessAction(response?.data?.detail)
        )

    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getRiskFilterScoreRequestFailureAction({
                riskFilterScoreError: e?.meta?.message,
            })
        )
    }
}

function* putAdditionalNotesRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/appointments/additional/notes`, payload.payload)
        yield put(putAdditionalNotesRequestSuccessAction(response?.data?.detail))
        yield put(
            updateAppointmentDetailsRequestAction({
                key: 'additional_notes',
                value: payload.payload?.additional_notes
            })
        )
        yield put(openAdditionalNotesAction(false))
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            putAdditionalNotesRequestFailureAction(e?.meta?.message)
        )
    }
}


function* sendWellnessEmailLinkRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/wellness-profile/send-wellness-profile`, payload.payload)
        yield put(sendWellnessEmailLinkRequestSuccessAction())
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
        successToaster("Invitation sent successfully")
    } catch (e: any) {
        errorToaster('Invitation failed to send')
        yield put(sendWellnessEmailLinkRequestFailureAction())
    }
}

function* endExamRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/appointments/${payload.payload.appointment_id}/end-exam`, payload.payload)
        yield put(endExamRequestSuccessAction())
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(endExamRequestFailureAction())
    }
}

function* syncImageXrayRequestSaga(payload: any): any {
    try {
        const isWellnessProfile = window.location.pathname.includes('/dentist/wellness-profile/')
        const urlForWellnessProfile = `${process.env.REACT_APP_PMS_API_URL}/images/patient/${payload.payload?.patientId}?clinic_id=${getLocalStorageItem('clinic')}`
        const urlForExam = `${process.env.REACT_APP_PMS_API_URL}/images/${payload.payload?.appointment_id}`
        const response = yield API.get(isWellnessProfile ? urlForWellnessProfile : urlForExam)
        yield put(syncImageXrayRequestSuccessAction())
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback, response?.data?.detail)
        }
    } catch (e: any) {
        // errorToaster(e?.meta?.message)
        yield put(syncImageXrayRequestFailureAction())
    }
}
function* viewEventLogRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/event-log`, payload.payload)
        if(response){
            yield put(viewEventLogRequestSuccessAction())
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(viewEventLogRequestFailureAction())
    }
}

function* riskFilterScoreWatch() {
    yield takeEvery(GET_RISK_FILTER_SCORE_REQUEST, riskFilterScoreRequestSaga)
}

function* putAdditionalNotesWatch() {
    yield takeEvery(PUT_ADDITIONAL_NOTES_REQUEST, putAdditionalNotesRequestSaga)
}

function* sendWellnessEmailLinkWatch() {
    yield takeEvery(SEND_WELLNESS_EMAIL_LINK_REQUEST, sendWellnessEmailLinkRequestSaga)
}

function* endExamRequestWatch() {
    yield takeEvery(END_EXAM_REQUEST, endExamRequestSaga)
}

function* syncImageXrayRequestWatch() {
    yield takeEvery(SYNC_IMAGE_XRAY_REQUEST, syncImageXrayRequestSaga)
}

function* viewEventLogRequestWatch() {
    yield takeEvery(VIEW_EVENT_LOG_REQUEST, viewEventLogRequestSaga)
}

export default function* clinicalExamFiltersSaga() {
    yield all([
        riskFilterScoreWatch(),
        putAdditionalNotesWatch(),
        sendWellnessEmailLinkWatch(),
        endExamRequestWatch(),
        syncImageXrayRequestWatch(),
        viewEventLogRequestWatch()
    ])
}
