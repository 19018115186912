import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../../utils/api';
import {ApiEndpointVersion, errorToaster} from '../../../../../utils/helper';
import {
  getKeyFindingDataSuccessAction,
  getKeyFindingDataFailAction
} from "../../../../actions/dentist/clinicalExam/treatment/keyFindingActions";
import { GET_KEY_FINDING_DATA_REQUEST } from "../../../../actionTypes/dentist/clinicalExam/treatment/keyFinding";

function* getKeyFindingDataSaga(payload: any): any {
  try {
    const response = yield API.get(`${ApiEndpointVersion}/exam/key/findings/${payload.payload?.appointmentID}`);
    const data = response?.data?.detail
    //Set data in global redux to use for revert/discard changes (future usages)
    yield put(
      getKeyFindingDataSuccessAction(data)
    );
  } catch (e: any) {
    errorToaster(e?.meta?.message)
    yield put(
      getKeyFindingDataFailAction()
    );
  }
}

function* keyFindingDataWatcher() {
  yield takeEvery(GET_KEY_FINDING_DATA_REQUEST, getKeyFindingDataSaga);
}

export default function* keyFindingSaga() {
  yield all([keyFindingDataWatcher()]);
}
