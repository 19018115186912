import {
    DENTIST_LOGOUT_REQUEST, DENTIST_LOGOUT_REQUEST_FAILURE,
    DENTIST_LOGOUT_REQUEST_SUCCESS,
} from '../../actionTypes/auth';
import {
    LogoutRequest,
} from '../../types/authActionTypes/logOutActionTypes';

export const logoutRequest = (): LogoutRequest => ({
    type: DENTIST_LOGOUT_REQUEST
});

export const logoutRequestSuccess = () => ({
    type: DENTIST_LOGOUT_REQUEST_SUCCESS,
});

export const logoutRequestFailure = () => ({
    type: DENTIST_LOGOUT_REQUEST_FAILURE,
});
