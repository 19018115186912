import { GET_OUTPUT_SCREEN_DATA_REQUEST, GET_OUTPUT_SCREEN_DATA_REQUEST_FAILURE, GET_OUTPUT_SCREEN_DATA_REQUEST_SUCCESS } from "../../../actionTypes/dentist/clinicalExam/outputScreen";
import { GetOutputScreenFailurePayload, GetOutputScreenRequest, GetOutputScreenRequestFailure, GetOutputScreenRequestPayload, GetOutputScreenRequestSuccess, GetOutputScreenRequestSuccessPayload } from "../../../types/dentist/clinicalExam/outputScreen";

export const getOutputScreenRequestAction = (payload: GetOutputScreenRequestPayload): GetOutputScreenRequest => ({
    type: GET_OUTPUT_SCREEN_DATA_REQUEST,
    payload
  });
  
  export const getOutputScreenRequestSuccessAction = (
    payload: GetOutputScreenRequestSuccessPayload
  ): GetOutputScreenRequestSuccess => ({
    type: GET_OUTPUT_SCREEN_DATA_REQUEST_SUCCESS,
    payload
  });
  
  export const getOutputScreenRequestFailureAction = (
    payload: GetOutputScreenFailurePayload
  ): GetOutputScreenRequestFailure => ({
    type: GET_OUTPUT_SCREEN_DATA_REQUEST_FAILURE,
    payload
  });