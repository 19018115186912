import React, {useRef} from 'react';
import {toothImages} from "../../../../../../../assets/images/tooth/toothImages";


type Props = {
    lingual?: boolean
    bottomJaw: boolean
    tooth: number

}
const TeethRoot = React.memo((props: Props) => {
    const {tooth, lingual, bottomJaw} = props
    const toothRoot = useRef(null) as any;
    const getTooth = (part: string) => {
        return toothImages[`${part}${tooth}`];
    };
    return (
        <img
            style={{transform: ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'rotate(180deg)' : ''}}
            ref={toothRoot}
            src={getTooth(lingual ? 'LingualRoot' : 'Root')}
            alt={`tooth${tooth}`}
        />
    )
})

export default TeethRoot