import React from 'react';
import {implantRoot} from "../../../../../../../../assets/images/odontogramFindings/odontogramIcons";

interface Props {
    isImplant: { hasTooth: boolean; color: string };
}

const Implant = React.memo((props: Props) => {
    const {isImplant} = props

    return (
        <div className='d-flex align-flex-end'
            dangerouslySetInnerHTML={{__html: implantRoot(isImplant.color)}}
        />
    );
})

export default Implant;
