import {Box, Grid} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import PeriogramTeeth from "../PerogramTeeth";
import PeriogramBoxWrapper from "../PeriogramBoxWrapper";
import ToothNumberWrapper from "../../Odontogram/ToothNumberWrapper";
import MeasureScaleBg from "../../Odontogram/MeasureScaleBg";
import {aboveTeeth, examConstantInterface, findingData, findingTypes} from "../../../../../../utils/constant/examConstant";
import {completeCheckout, viewExam} from '../../../../../../utils/constant/clinicalExamConstant';

const useStyles = makeStyles(() => ({
    perioChartWrapper: {
        width: '854px',
    },
    toothSectionWrapper: {
        position: 'relative',
        height: '100px',
        paddingTop: 5.5,
        marginTop: 1.75,
        width: '100%',
        justifyContent: 'start !important',
        flexDirection: 'column'
    },

}));
type Props = {
    bottomJaw?: boolean
}
const PeriogramTeeths = React.memo((props: Props) => {
    const {bottomJaw} = props
    const classes = useStyles();
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const {
        examFindingReducer: {examDataFromAPI},
        appointmentsReducer: {appointmentDetails},
        clinicalExamFilters: {riskFilters, findingType},
        odontogram: {procedureFilters, showRejectedTreatmentsOnly},
    } = useSelector((state: any) => state) as any

    const isImpactedTooth = (tooth: number) => {
        let hasTooth = false;
        const updatedFindingData = examDataFromAPI?.['occlusion_exam']?.['impacted_tooth'];
        if (updatedFindingData?.length === 1) {
            const impactedToothData = updatedFindingData[0];
            const isTooth = impactedToothData['tooth_number'];
            const isSubType = impactedToothData['subtype'];
            const procedure_status = impactedToothData['procedure_status'];
            const isRejected = impactedToothData['rejected'];
            if (
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${tooth}`) &&
                isSubType
            ) {
                hasTooth = true;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((impactedToothData: any) => {
                const isTooth = impactedToothData['tooth_number'];
                const isSubType = impactedToothData['subtype'];
                const procedure_status = impactedToothData['procedure_status'];
                const isRejected = impactedToothData['rejected'];
                if (
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${tooth}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                }
            });
        }
        return {
            hasTooth,
        };
    }
    const getData = useMemo(() => {
        const examType = appointmentDetails?.appointmentType;
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType])
        ) as any;
        return isWellnessProfile ? findingTypeWiseFilteredData : (findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.examType?.includes(examType)));
        // eslint-disable-next-line
        }, [isWellnessProfile, examDataFromAPI, appointmentDetails, findingType]);
    const disabledField = useMemo(() => {
        if ([completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status)) {
            return ["mobility", "probing_depth", 'furcation', 'bleed_on_probing', 'plaque', 'recession']
        } else {
            return isWellnessProfile ? getData : (getData.filter((f: any) => (
                (!f?.examType?.includes(appointmentDetails?.appointmentType)) ||
                (riskFilters?.length ? (f?.risks?.filter((ff: string) => riskFilters?.includes(ff))?.length) === 0 : false)
            )).map((r: any) => r.key));
        }
    }, [appointmentDetails, getData, riskFilters, isWellnessProfile]);

    return (
        <Box className={classes.perioChartWrapper}>
            <Grid container width={'100%'}>
                {
                    //Handling tooth numbers based on bottomJaw
                    aboveTeeth?.map((res: number) => bottomJaw ? res + 20 : res)?.map((tooth: number, index: number) =>
                        <Grid
                            item
                            xs={0.75} md={0.75} key={index}
                            className={`d-flex-all-center`}
                            sx={{
                                borderTop: '0.3px solid rgba(0,0,0,0.15)',
                                borderBottom: '0.3px solid rgba(0,0,0,0.15)',
                                borderLeft: index === 0 ? '0.1px solid rgba(0,0,0,0.15)' : "",
                                borderRight: '0.1px solid rgba(0,0,0,0.15)',
                                paddingLeft: '1px'
                            }}
                        >
                            <Box width={'100%'} sx={{
                                marginTop: (isImpactedTooth(tooth)?.hasTooth && !bottomJaw) ? '18px' : '',
                                marginBottom: (isImpactedTooth(tooth)?.hasTooth && bottomJaw) ? '18px' : ''
                            }}>
                                <PeriogramBoxWrapper disabledField={disabledField} tooth={tooth} lingual={bottomJaw}
                                                     isImpactedTooth={isImpactedTooth(tooth)}/>
                                {!bottomJaw && <ToothNumberWrapper tooth={tooth} fromPeriogram/>}
                                {/*Tooth section start*/}
                                {/*Buccal*/}
                                <Box className={`${classes.toothSectionWrapper} d-flex-all-center`}>
                                    <MeasureScaleBg tooth={tooth}/> {/* measureScale bg */}
                                    <PeriogramTeeth tooth={tooth} lingual={bottomJaw}/>
                                </Box>
                                {/*Lingual*/}
                                <Box className={`${classes.toothSectionWrapper} d-flex-all-center`}>
                                    <MeasureScaleBg tooth={tooth} lingual={true}/> {/* measureScale bg */}
                                    <PeriogramTeeth tooth={tooth} lingual={!bottomJaw}/>
                                </Box>
                                {bottomJaw && <ToothNumberWrapper tooth={tooth} periogramBottom fromPeriogram/>}
                                {/*Tooth section end*/}
                                <PeriogramBoxWrapper disabledField={disabledField} tooth={tooth} lingual={!bottomJaw}
                                                     isImpactedTooth={isImpactedTooth(tooth)}/>
                            </Box>
                        </Grid>)
                }
            </Grid>
        </Box>
    );
});

export default PeriogramTeeths;
