import React from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleXmark} from "@fortawesome/free-solid-svg-icons";
import {Box, Chip, Grid, Stack, Tooltip} from "@mui/material";
import CollapsibleBox from "../UIComponents/CollapsibleBox";
import theme from "../../../theme";
import NoDataFound from "../NoDataFound";

type Props = {
    referralsData: any
    disabledField: boolean
    changeStatus?: any
    isPatient?: boolean | undefined
}
const ReferralsSection = (props: Props) => {
    const {referralsData, changeStatus, disabledField, isPatient} = props
    const {palette} = theme

    return (
        <CollapsibleBox title={'Referrals'} isPatient={isPatient}>
            {referralsData?.length > 0 ?
                <Grid container rowSpacing={1.5} columnSpacing={2.5} mb={2.5}>
                    <Grid item xs={12}>
                        <Stack direction="row" gap={1} p={'12px'}
                               border={`1px solid ${palette.divider}`} borderRadius={'8px'} flexWrap={'wrap'}>
                            {referralsData?.map((res: any, index: number) =>
                                <Tooltip title={res?.description} key={index}>
                                    <Box sx={{
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                    }}>
                                        <Chip
                                            disabled={disabledField}
                                            label={res?.description}
                                            component="a"
                                            onDelete={!disabledField ? () => changeStatus(res) : undefined}
                                            className={'fw-regular'}
                                            deleteIcon={!res?.status ?
                                                <FontAwesomeIcon icon={faCircleXmark}/> :
                                                <FontAwesomeIcon icon={faCircleCheck}/>}
                                            sx={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                // maxWidth: '170px',
                                                color: palette.common.white,
                                                backgroundColor: `${res?.status ? palette.success.main : palette.error.main} !important `,
                                                '& .MuiChip-deleteIcon': {
                                                    color: palette.common.white,
                                                    '&:hover': {
                                                        color: palette.common.white,
                                                    }
                                                }
                                            }}
                                        />
                                    </Box>
                                </Tooltip>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                : <NoDataFound errorMessage={'data'}/>
            }
        </CollapsibleBox>
    )
}

export default ReferralsSection;