import {
    LOGIN_REQUEST,
    LOGIN_REQUEST_FAILURE,
    LOGIN_REQUEST_SUCCESS,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOGIN_REQUEST_SUCCESS,
    GOOGLE_LOGIN_REQUEST_FAILURE
} from '../../actionTypes/auth';
import {
    googleLoginRequest,
    googleLoginRequestFailure,
    googleLoginRequestFailurePayload,
    googleLoginRequestPayload,
    googleLoginRequestSuccess,
    googleLoginRequestSuccessPayload,
    LoginRequest,
    LoginRequestPayload,
} from '../../types/authActionTypes/loginActionTypes';

export const loginRequest = (payload: LoginRequestPayload): LoginRequest => ({
    type: LOGIN_REQUEST,
    payload
});

export const loginRequestSuccess = () => ({
    type: LOGIN_REQUEST_SUCCESS,
});

export const loginRequestFailure = () => ({
    type: LOGIN_REQUEST_FAILURE
});

// TODO: For future use
// export const updateDeviceTokenAction = (payload: any): updateDeviceToken => ({
//     type: UPDATE_DEVICE_TOKEN,
//     payload
// });

export const googleLoginRequestAction = (payload: googleLoginRequestPayload): googleLoginRequest => ({
    type: GOOGLE_LOGIN_REQUEST,
    payload
});

export const googleLoginRequestSuccessAction = (payload: googleLoginRequestSuccessPayload): googleLoginRequestSuccess => ({
    type: GOOGLE_LOGIN_REQUEST_SUCCESS,
    payload
});

export const googleLoginRequestFailureAction = (payload: googleLoginRequestFailurePayload): googleLoginRequestFailure => ({
    type: GOOGLE_LOGIN_REQUEST_FAILURE,
    payload
});
