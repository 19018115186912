import {call, put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';
import {
    forgotPasswordRequestFailure,
    forgotPasswordRequestSuccess,
    isMultipleAccountForgotPasswordPopUp
} from '../../actions/authAction/forgotPasswordAction';
import {FORGOT_PASSWORD_V2_REQUEST} from '../../actionTypes/auth';


function* forgotPasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.post(`/${ApiEndpointVersion}/auth/forgot/password`, payload.payload)
        yield call(payload.payload.callback, response);
        yield put(forgotPasswordRequestSuccess());
        if (response) {
            successToaster(response?.data?.meta?.message)
        }
    } catch (e: any) {
        if (e?.detail?.is_multiple_profile) {
            yield put(isMultipleAccountForgotPasswordPopUp(true))
        }
        if (e?.detail == null) {
            errorToaster(e?.meta?.message)
        }
        yield put(forgotPasswordRequestFailure());
    }
}


function* forgotPasswordSaga() {
    yield takeEvery(FORGOT_PASSWORD_V2_REQUEST, forgotPasswordRequestSaga);
}

export default function* rootSaga() {
    yield all([
        forgotPasswordSaga(),
    ])
}