import {
    PatientCommunicationRequest, PatientCommunicationRequestFailure, PatientCommunicationRequestFailurePayload,
    PatientCommunicationRequestPayload, PatientCommunicationRequestSuccess, PatientCommunicationRequestSuccessPayload
} from "../../types/patient/auth/communicationsActionType";
import {
    PATIENT_COMMUNICATION_REQUEST, PATIENT_COMMUNICATION_REQUEST_FAILURE,
    PATIENT_COMMUNICATION_REQUEST_SUCCESS
} from "../../actionTypes/patient/patientCommunication";

export const patientCommunicationRequest = (payload: PatientCommunicationRequestPayload): PatientCommunicationRequest => ({
    type: PATIENT_COMMUNICATION_REQUEST,
    payload
});

export const patientCommunicationRequestSuccess = (payload: PatientCommunicationRequestSuccessPayload): PatientCommunicationRequestSuccess => ({
    type: PATIENT_COMMUNICATION_REQUEST_SUCCESS,
    payload
});

export const patientCommunicationRequestFailure = (payload: PatientCommunicationRequestFailurePayload): PatientCommunicationRequestFailure => ({
    type: PATIENT_COMMUNICATION_REQUEST_FAILURE,
    payload
});

