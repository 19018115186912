import React, {useEffect, useState} from 'react';
import {Box, Divider} from "@mui/material";
import Backdrop from '@mui/material/Backdrop'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import theme from "../../../../theme";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    outline: 'none',
    p: 4,
}

type Props = {
    open: boolean;
    setOpen: (value: boolean) => void;
};

const SupportFormPopUp = (props: Props) => {
    const {open, setOpen} = props;
    const [refresh, setRefresh] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const formBox = document.querySelectorAll('#support-form-dentist');
        const form = document.createElement('script');
        if (formBox.length === 0) {
            setRefresh(true);
            return;
        }
        form.textContent = 'hbspt.forms.create({region:"na1",portalId:"22309723",formId:"d42d53ff-4fb0-48b0-ac7a-02d812bb0f17",onFormReady: () => setLoading(false) })';

        formBox[0]?.appendChild(form);
    }, [refresh]);

    return (
        <Modal
            keepMounted
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography ml={1} className='f-18 f-w-500 fw-medium'>
                            Support
                        </Typography>
                        <FontAwesomeIcon
                            onClick={handleClose}
                            icon={faClose}
                            color={theme.palette.common.black50}
                            className='f-20 cursor-pointer'
                            width={'20px'}
                            height={'20px'}
                        />
                    </Box>
                    <Divider sx={{width: '100%',my:'20px', borderColor: theme.palette.divider}}/>
                    <Box id='support-form-dentist'/>
                </Box>
            </Fade>
        </Modal>
    );
};

export default SupportFormPopUp;
