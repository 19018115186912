import React from 'react';
import CollapsibleBox from "../../../../../common/UIComponents/CollapsibleBox";
import HygieneRegime from "../../../../HygieneRegime";


type Props = {
    hygieneRegimeData: any
}
const WellnessPastHygieneRegimen = (props: Props) => {
    const {hygieneRegimeData} = props
    return (
        <CollapsibleBox title={'Hygiene Regimen'}>
            <HygieneRegime hygieneRegimeData={hygieneRegimeData}
                           disabled={true}/>
        </CollapsibleBox>
    )
}

export default WellnessPastHygieneRegimen