import {
  requireMessage,
  requireValidMsg,
  restrictNumberMessage,
  restrictSpecialCharacterMessage,
} from "./validationMessage";
import {
  canadaPostalRegex,
  checkIsEmptyValidator,
  convertToLowerCase,
  isEmptyObject,
  usPostalRegex,
} from "../utils/helper";
import validator from "validator";

const notAllowSpecialCharRegex = new RegExp(
    /[!"`'#%&,:;<>=@{}~$()*+/?[\]^|]+/
);

const notAllowNumericRegex = new RegExp(/[0-9]/);

export const emergencyContactInfoValidator = (data) => {
  let errors = {};
  if (
    !checkIsEmptyValidator(data?.emergencyContactNumber) &&
    data?.emergencyContactNumber &&
    data?.emergencyContactNumber?.length !== 14
  )
    errors.emergencyContactNumber = requireValidMsg("contact number");
  if (
    !checkIsEmptyValidator(data?.emergencyContactPerson) &&
    notAllowNumericRegex.test(data?.emergencyContactPerson)
  )
    errors.emergencyContactPerson = restrictNumberMessage;
  if (
    convertToLowerCase(data.relationshipToEmergencyContactPerson) === "other" &&
    checkIsEmptyValidator(data?.other)
  )
    errors.other = requireMessage("relationship with the patient");
  return { errors, isValid: isEmptyObject(errors) };
};

export const dentistInsuranceInfoValidator = (data, insurance) => {
  let errors = {};
  data?.forEach((res, index) => {
    let error = {};
    if (insurance?.doYouHaveDentalInsurance === "yes") {
      if (
        insurance?.doYouHaveDentalInsurance === "yes" &&
        checkIsEmptyValidator(res?.insuranceProvider)
      ) {
        error.insuranceProvider = "This field is required";
      }
      if (
        Object.keys(error).length >= 0 &&
        error?.hasOwnProperty("insuranceProvider")
      ) {
        error.errIndex = index;
        errors[`${index}`] = error;
      }
      if (
        insurance?.doYouHaveDentalInsurance === "yes" &&
        checkIsEmptyValidator(res?.insuranceGroup)
      ) {
        error.insuranceGroup = "This field is required";
      }
      if (
        Object.keys(error).length >= 0 &&
        error?.hasOwnProperty("insuranceGroup")
      ) {
        error.errIndex = index;
        errors[`${index}`] = error;
      }
      if (
        insurance?.doYouHaveDentalInsurance === "yes" &&
        checkIsEmptyValidator(res?.insuranceID)
      ) {
        error.insuranceID = "This field is required";
      }
      if (
        Object.keys(error).length >= 0 &&
        error?.hasOwnProperty("insuranceID")
      ) {
        error.errIndex = index;
        errors[`${index}`] = error;
      }
      if (checkIsEmptyValidator(res?.planMemberName)) {
        error.planMemberName = "This field is required";
      }
      if (
        Object.keys(error).length >= 0 &&
        error?.hasOwnProperty("planMemberName")
      ) {
        error.errIndex = index;
        errors[`${index}`] = error;
      }
      if (checkIsEmptyValidator(res?.planMemberDob)) {
        error.planMemberDob = "This field is required";
      }
      if (
        Object.keys(error).length >= 0 &&
        error?.hasOwnProperty("planMemberDob")
      ) {
        error.errIndex = index;
        errors[`${index}`] = error;
      }
    }
  });
  return { errors, isValid: isEmptyObject(errors) };
};

// dentalInfoValidator
export const dentalInfoValidator = (data) => {
  let errors = {};
  if (data?.doesYourPhysicianRecommendAntibiotic === "yes") {
    if (checkIsEmptyValidator(data?.antibioticPremeditation))
      errors.antibioticPremeditation = "field is required";
  }
  return { errors, isValid: isEmptyObject(errors) };
};

export const letsGetToKnowYouBetterValidator = (data) => {
  let errors = {};
  if (
    !checkIsEmptyValidator(data?.occupation) &&
    notAllowNumericRegex.test(data?.occupation)
  )
    errors.occupation = restrictNumberMessage;

  if (
    !checkIsEmptyValidator(data?.occupation) &&
    notAllowSpecialCharRegex.test(data?.occupation)
  )
    errors.occupation = restrictSpecialCharacterMessage;

  if (
    !checkIsEmptyValidator(data?.referredBy) &&
    notAllowNumericRegex.test(data?.referredBy)
  )
    errors.referredBy = restrictNumberMessage;

  if (
    !checkIsEmptyValidator(data?.referredBy) &&
    notAllowSpecialCharRegex.test(data?.referredBy)
  )
    errors.referredBy = restrictSpecialCharacterMessage;

  return { errors, isValid: isEmptyObject(errors) };
};

export const medicalHealthHistoryValidator = (data, getFiledData) => {
  let errors = {};
  const otherIdMedical = getFiledData?.medicalConditionsData?.find(
    (item) => item.name === "Other"
  );
  const isOtherMedicalIncluded =
    otherIdMedical && data?.medicalConditions.includes(otherIdMedical?.id);
  const otherAllergies = getFiledData?.allergiesListData?.find(
    (item) => item.name === "Other"
  );
  const isOtherAllergiesIncluded =
    otherAllergies && data?.checkboxListTwo?.includes(otherAllergies?.id);

  if (
    !checkIsEmptyValidator(data?.physicianName) &&
    notAllowNumericRegex.test(data?.physicianName)
  )
    errors.physicianName = restrictNumberMessage;
  if (
    !checkIsEmptyValidator(data?.physicianName) &&
    notAllowSpecialCharRegex.test(data?.physicianName)
  )
    errors.physicianName = restrictSpecialCharacterMessage;
  if (
    !checkIsEmptyValidator(data?.physicianContactNumber) &&
    data?.physicianContactNumber &&
    data?.physicianContactNumber?.length !== 14
  )
    errors.physicianContactNumber = requireValidMsg("contact number");
  if (
    convertToLowerCase(data?.seriousIllnessesOrOperations) === "yes" &&
    checkIsEmptyValidator(data?.seriousIllnessesOrOperationsInput)
  )
    errors.seriousIllnessesOrOperationsInput = requireMessage(
      "serious illnesses or operations"
    );
  if (
    convertToLowerCase(data?.takingMedicationsOrSupplements) === "yes" &&
    checkIsEmptyValidator(data?.takingMedicationsOrSupplementsInput)
  )
    errors.takingMedicationsOrSupplementsInput =
      requireMessage("medications list");
  if (
    isOtherMedicalIncluded &&
    checkIsEmptyValidator(data?.medicalConditionsOther)
  )
    errors.medicalConditionsOther = "This field is required";
  if (
    isOtherAllergiesIncluded &&
    checkIsEmptyValidator(data?.checkboxListTwoOtherInput)
  )
    errors.checkboxListTwoOtherInput = "This field is required";
  return { errors, isValid: isEmptyObject(errors) };
};

export const communicationPreferencesValidator = (data) => {
  let errors = {};
  if (
    convertToLowerCase(data?.concernsWithAppearanceOfYourTeeth) === "yes" &&
    checkIsEmptyValidator(data?.concernsWithAppearanceOfYourTeethReason)
  )
    errors.concernsWithAppearanceOfYourTeethReason = requireMessage(
      "concerns with the appearance of your teeth"
    );
  if (
    convertToLowerCase(data?.teethSensitive) === "yes" &&
    checkIsEmptyValidator(data?.teethSensitiveReason)
  )
    errors.teethSensitiveReason = requireMessage("teeth pain");
  return { errors, isValid: isEmptyObject(errors) };
};

export const generalHealthValidator = (data) => {
  let errors = {};
  if (
    convertToLowerCase(data.drugUse) === "yes" &&
    checkIsEmptyValidator(data?.drugInputUse)
  )
    errors.drugInputUse = "This field is required";
  return { errors, isValid: isEmptyObject(errors) };
};

// patientInformation
export const patientInformationValidator = (data) => {
  let errors = {};
  if (data?.emailAddress && !validator.isEmail(data?.emailAddress))
    errors.emailAddress = requireMessage("valid email address");
  if (checkIsEmptyValidator(data?.emailAddress))
    errors.emailAddress = requireMessage("email address");
  if (checkIsEmptyValidator(data?.mobilePhoneNumber))
    errors.mobilePhoneNumber = requireMessage("mobile number");
  if (checkIsEmptyValidator(data?.dateOfBirth))
    errors.dateOfBirth = requireMessage("date of birth");
  if (checkIsEmptyValidator(data?.firstName))
    errors.firstName = requireMessage("first name");
  if (checkIsEmptyValidator(data?.lastName))
    errors.lastName = requireMessage("last name");
  if (data?.firstName !== "" && notAllowSpecialCharRegex.test(data?.firstName))
    errors.firstName = "first name can not allow special character.";
  if (data?.firstName !== "" && notAllowNumericRegex.test(data?.firstName))
    errors.firstName = "first name can not allow numbers.";
  if (data?.lastName !== "" && notAllowSpecialCharRegex.test(data?.lastName))
    errors.lastName = "last name can not allow special character.";
  if (data?.lastName !== "" && notAllowNumericRegex.test(data?.lastName))
    errors.lastName = "last name can not allow numbers.";
  if (
    data?.middleName !== "" &&
    notAllowSpecialCharRegex.test(data?.middleName)
  )
    errors.middleName = "middle name can not allow special character.";
  if (data?.middleName !== "" && notAllowNumericRegex.test(data?.middleName))
    errors.middleName = "middle name can not allow numbers.";
  if (data?.mobilePhoneNumber && data?.mobilePhoneNumber.length !== 14)
    errors.mobilePhoneNumber = requireMessage("valid mobile number");
  if (data?.homePhoneNumber && data?.homePhoneNumber.length !== 14)
    errors.homePhoneNumber = requireMessage("valid mobile number");
  if (
    data?.postalCode !== null &&
    data?.postalCode &&
    !(
      canadaPostalRegex.test(data?.postalCode) ||
      usPostalRegex.test(data?.postalCode)
    )
  ) {
    errors.postalCode = "Please enter valid postal code.";
  }
  if (data?.gender === "Other" && checkIsEmptyValidator(data?.genderOther))
    errors.genderOther = requireMessage("gender");
  if (data?.race === "Other" && checkIsEmptyValidator(data?.raceOther))
    errors.raceOther = requireMessage("race");
  return { errors, isValid: isEmptyObject(errors) };
};

// patientInformation without account
export const patientInformationValidatorWithoutAccount = (data) => {
  let errors = {};

  if (data?.emailAddress && !validator.isEmail(data?.emailAddress))
    errors.emailAddress = requireMessage("valid email address");

  if (data?.mobilePhoneNumber && data?.mobilePhoneNumber.length !== 14)
    errors.mobilePhoneNumber = requireMessage("valid mobile number");

  if (data?.gender === "Other" && checkIsEmptyValidator(data?.genderOther))
    errors.genderOther = requireMessage("gender");

  if (data?.race === "Other" && checkIsEmptyValidator(data?.raceOther))
    errors.raceOther = requireMessage("race");

  return { errors, isValid: isEmptyObject(errors) };
};

export const patientInformationSecondValidator = (data) => {
  let errors = {};
  if (data?.gender === "Other" && checkIsEmptyValidator(data?.genderOther))
    errors.genderOther = requireMessage("gender");
  if (data?.race === "Other" && checkIsEmptyValidator(data?.raceOther))
    errors.raceOther = requireMessage("race");
  return { errors, isValid: isEmptyObject(errors) };
};
