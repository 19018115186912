import {
    PatientEmailMobileDataPayload,
    PatientEmailMobileDataRequest,
    PatientEmailMobileErrorRequest,
    PatientEmailSendOtpRequest,
    PatientEmailSendOtpRequestFailure,
    PatientEmailSendOtpRequestFailurePayload,
    PatientEmailSendOtpRequestPayload,
    PatientEmailSendOtpRequestSuccess,
    PatientEmailSendOtpRequestSuccessPayload,
    PatientEmailVerifyOtpRequest,
    PatientEmailVerifyOtpRequestFailure,
    PatientEmailVerifyOtpRequestFailurePayload,
    PatientEmailVerifyOtpRequestPayload,
    PatientEmailVerifyOtpRequestSuccess,
    PatientEmailVerifyOtpRequestSuccessPayload,
    PatientQrCodeDataPayload,
    PatientQrCodeDataRequest,
    PatientQrSubmitRequest,
    PatientQrSubmitRequestFailure,
    PatientQrSubmitRequestPayload,
    PatientQrSubmitRequestSuccess,
    PatientQrSubmitRequestSuccessPayload,
} from "../../types/patient/emailAndQrCodeFormActionTypes";
import {
    PATIENT_EMAIL_MOBILE_DATA,
    PATIENT_EMAIL_MOBILE_ERROR,
    PATIENT_EMAIL_SEND_OTP_REQUEST,
    PATIENT_EMAIL_SEND_OTP_REQUEST_FAILURE,
    PATIENT_EMAIL_SEND_OTP_REQUEST_SUCCESS,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST_FAILURE,
    PATIENT_EMAIL_VERIFY_OTP_REQUEST_SUCCESS,
    PATIENT_QR_CODE_DATA,
    PATIENT_QR_CODE_ERROR,
    PATIENT_QR_SUBMIT_DATA_REQUEST,
    PATIENT_QR_SUBMIT_DATA_REQUEST_FAILURE,
    PATIENT_QR_SUBMIT_DATA_REQUEST_SUCCESS,
} from "../../actionTypes/patient/emailAndQrCodeForm";

export const patientEmailMobileDataRequest = (payload: PatientEmailMobileDataPayload): PatientEmailMobileDataRequest => ({
    type: PATIENT_EMAIL_MOBILE_DATA,
    payload
});

export const patientEmailMobileErrorRequest = (payload: any): PatientEmailMobileErrorRequest => ({
    type: PATIENT_EMAIL_MOBILE_ERROR,
    payload
})
export const patientQrCodeDataRequest = (payload: PatientQrCodeDataPayload): PatientQrCodeDataRequest => ({
    type: PATIENT_QR_CODE_DATA,
    payload
});

export const patientQrCodeErrorRequest = (payload: any): any => ({
    type: PATIENT_QR_CODE_ERROR,
    payload
})

//Send OTP
export const patientEmailSendOtpRequest = (payload: PatientEmailSendOtpRequestPayload): PatientEmailSendOtpRequest => ({
    type: PATIENT_EMAIL_SEND_OTP_REQUEST,
    payload
});

export const patientEmailSendOtpRequestSuccess = (payload: PatientEmailSendOtpRequestSuccessPayload): PatientEmailSendOtpRequestSuccess => ({
    type: PATIENT_EMAIL_SEND_OTP_REQUEST_SUCCESS,
    payload
});

export const patientEmailSendOtpRequestFailure = (payload: PatientEmailSendOtpRequestFailurePayload): PatientEmailSendOtpRequestFailure => ({
    type: PATIENT_EMAIL_SEND_OTP_REQUEST_FAILURE,
    payload
});

//Verify OTP
export const patientEmailVerifyOtpRequest = (payload: PatientEmailVerifyOtpRequestPayload): PatientEmailVerifyOtpRequest => ({
    type: PATIENT_EMAIL_VERIFY_OTP_REQUEST,
    payload
});
export const patientEmailVerifyOtpRequestSuccess = (payload: PatientEmailVerifyOtpRequestSuccessPayload): PatientEmailVerifyOtpRequestSuccess => ({
    type: PATIENT_EMAIL_VERIFY_OTP_REQUEST_SUCCESS,
    payload
});

export const patientEmailVerifyOtpRequestFailure = (payload: PatientEmailVerifyOtpRequestFailurePayload): PatientEmailVerifyOtpRequestFailure => ({
    type: PATIENT_EMAIL_VERIFY_OTP_REQUEST_FAILURE,
    payload
});

//Qr Code Form Submit
export const patientQrSubmitRequest = (payload: PatientQrSubmitRequestPayload): PatientQrSubmitRequest => ({
    type: PATIENT_QR_SUBMIT_DATA_REQUEST,
    payload
});
export const patientQrSubmitRequestSuccess = (payload: PatientQrSubmitRequestSuccessPayload): PatientQrSubmitRequestSuccess => ({
    type: PATIENT_QR_SUBMIT_DATA_REQUEST_SUCCESS,
    payload
});

export const patientQrSubmitRequestFailure = (payload: any): PatientQrSubmitRequestFailure => ({
    type: PATIENT_QR_SUBMIT_DATA_REQUEST_FAILURE,
    payload
});
