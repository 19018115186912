import {riskScoreChartActions, riskScoreChartInterface} from "../../../types/dentist/wellnessProfile/riskScoreChart";
import {
    GET_RISK_SCORE_CHART_DATA_REQUEST, GET_RISK_SCORE_CHART_DATA_REQUEST_FAILURE,
    GET_RISK_SCORE_CHART_DATA_REQUEST_SUCCESS
} from "../../../actionTypes/dentist/wellnessProfile/riskScoreChart";

const initialState: riskScoreChartInterface = {
    riskScoreChartLoader: false,
    riskScoreChartData: [],
};


const riskScoreChartReducer = (state = initialState, action: riskScoreChartActions) => {
    switch (action.type) {
        case GET_RISK_SCORE_CHART_DATA_REQUEST:
            return {
                ...state,
                riskScoreChartLoader: true,
                riskScoreChartData: null,
            };
        case GET_RISK_SCORE_CHART_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                riskScoreChartLoader: false,
                riskScoreChartData: action.payload,
            };
        case GET_RISK_SCORE_CHART_DATA_REQUEST_FAILURE:
            return {
                ...state,
                riskScoreChartLoader: false,
                riskScoreChartData: null,
            };
        default:
            return {
                ...state
            };
    }
};

export default riskScoreChartReducer;
