import React from 'react';
import {resourceDateFormate} from "../../../../../../utils/helper";
import {useSelector} from "react-redux";
import theme from "../../../../../../theme";


const PeriogramDate = () => {
    const {palette} = theme
    const {examFindingReducer: {examDataFromAPI}} = useSelector((state: any) => state) as any;
    return (
        <ul className='d-flex-all-center' style={{gap: '30px', color: palette.text.secondary}}>
            <li className='f-14 f-w-400 fw-regular'>Created: {resourceDateFormate(examDataFromAPI?.created_at)}</li>
            {examDataFromAPI?.updated_at &&
                <li className='f-14 f-w-400 fw-regular'>Last
                    updated: {resourceDateFormate(examDataFromAPI?.updated_at)}</li>}
        </ul>
    )
}

export default PeriogramDate;