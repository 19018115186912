import React from 'react';
import {Box} from "@mui/material";
import Plaque from '../../../../../../../../assets/images/periogramFindings/plaque.svg';


interface Props {
    isFindingPresent: (position: string, type: string) => boolean;
    lingual?: boolean;
    bottomJaw: boolean;
}

const PlaqueFinding = React.memo((props: Props) => {
    const {bottomJaw, lingual, isFindingPresent} = props
    const positions = [
        lingual ? 'right' : 'left',
        'center',
        lingual ? 'left' : 'right'
    ];
    return (
        <Box
            width={42}
            className='d-flex align-center justify-content-between pos-absolute'
            sx={{zIndex: 100, bottom: bottomJaw ? 9 : ''}}
        >
            {positions.map((position, index) => (
                <Box key={index} height={'10px'} width={'10px'}>
                    {isFindingPresent(position, 'plaque') && (
                        <img src={Plaque} alt={'Plaque'} width={10} height={10}/>
                    )}
                </Box>
            ))}
        </Box>
    )
})

export default PlaqueFinding