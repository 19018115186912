import {
    GET_RESOURCE_DATA_REQUEST,
    GET_RESOURCE_DATA_SUCCESS,
    GET_RESOURCE_DATA_FAIL,
    GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST
} from '../../actionTypes/dentist/resource'
import { ResourceTypeActions, ResourceTypeState } from '../../types/dentist/resource';

const initialState: ResourceTypeState = {
    resourceLoader: false,
    resourceData: null,
    resourceMeta: null,
    resourceError: null,
};

const resourceReducer = (state = initialState, action: ResourceTypeActions) => {
    switch (action.type) {
        // get resource data
        case GET_RESOURCE_DATA_REQUEST:
            return {
                ...state,
                resourceLoader: true,
                resourceData: null
            }
        case GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                resourceData: null
            }
        case GET_RESOURCE_DATA_SUCCESS:
            return {
                ...state,
                resourceLoader: false,
                resourceData: action.payload?.resourceData,
                resourceMeta: action.payload?.resourceMeta,
                resourceError: null
            }
        case GET_RESOURCE_DATA_FAIL:
            return {
                ...state,
                resourceLoader: false,
                resourceData: null,
                resourceError: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default resourceReducer
