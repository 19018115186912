import { debounce } from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { keyframes } from "@mui/material";
import validator from 'validator';
import theme from "../theme";
import { getShortSurface } from './constant/clinicalExamConstant';
import { findingData } from "./constant/examConstant";
import pusher from "./pusher";

export const getUniqId = () => uuidv4();
export const currentYear = moment().year();
export const url =
    process.env.REACT_APP_API_ENDPOINT ||
    "https://dev-oraq-apis.35.239.156.91.nip.io";

export const formatPhoneNumber = (phoneNumberString: any) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return cleaned;
};
export const getLocalStorageItem = (key: any) => localStorage.getItem(key);
const patientClinicInfo = localStorage.getItem('patientClinicInfo')
const parseClinicInfoData = patientClinicInfo ? JSON.parse(patientClinicInfo || '') : {};
export const patientProfileData = parseClinicInfoData && parseClinicInfoData?.profile

const dentistClinicInfo = localStorage.getItem('clinicInfo')
const parseDentistClinicInfoData = dentistClinicInfo ? JSON.parse(dentistClinicInfo || '') : {};
export const dentistProfileData = parseDentistClinicInfoData && parseDentistClinicInfoData?.profile

export const phoneNumberPlaceholder = "(XXX)-XXX-XXXX";
export const canadaPostalRegex = new RegExp(/[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/);
export const usPostalRegex = new RegExp(/^\d{5}(?:-\d{4})?$/);
export const emailRegex = /^(?!\.)[A-Za-z0-9._%+-]+(?!\.)@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
// export const NUMBER_REGEX = /^[0-9 ]*$/i; // TODO:For future use
export const accessCodeRegex = /^[0-9-]*$/;
export const ApiEndpointVersion = 'v1'

export function AvoidSpace(e: any) {
    if (e.target.value.length === 0 && e.which === 32) {
        e.preventDefault();
    }
}

export function AvoidSpaceForPassword(e: any) {
    if (e.which === 32) {
        e.target.value.trim();
        e.preventDefault();
    }
}

export const convertToLowerCase = (string: string) => {
    let newValue = "";
    if (string) {
        newValue = string?.toLowerCase();
    }
    return newValue;
};

export const selectedClinic = getLocalStorageItem("clinic");

export const stringToArray = (str: string) => {
    return str?.split(",")?.filter((f: string) => f?.length);
};

export const splitData = (str: string) => {
    return str?.split(",");
};

export const arrayToString = (arr: any) => {
    return arr?.toString();
};

export const removeRefFromJson = (data: any) => {
    return JSON.parse(JSON.stringify(data)) as any;
}

export const usDateFormat = "MM/DD/YYYY" as string;

export const newUsDateFormatInput = "MM-DD-YYYY";
export const newUsDateFormat = "YYYY-MM-DD";

export const dateFormat = (dateValue: any) => {
    if (dateValue) {
        return moment(dateValue).format(usDateFormat);
    }
    return "";
};

export const newDateFormate = (dateValue: any) => {
    if (dateValue) {
        return moment(new Date(dateValue))?.format(newUsDateFormat);
    }
    return "";
};

export const resourceDateFormate = (dateValue: any) => {
    if (dateValue) {
        return moment(new Date(dateValue))?.format(newUsDateFormatInput);
    }
    return "";
};

export const newDateFormateNotificationAgo = (dateValue: any) => {
    if (dateValue) {
        return moment(dateValue).fromNow();
    }
    return "";
};

export const newDateFormateNotification = (dateValue: any) => {
    if (dateValue) {
        return moment(dateValue).format("MM-DD-YYYY, LT");
    }
    return "";
};


export const marks10 = [
    {
        value: 0,
        label: "00",
    },
    {
        value: 1,
        label: "01",
    },
    {
        value: 2,
        label: "02",
    },
    {
        value: 3,
        label: "03",
    },
    {
        value: 4,
        label: "04",
    },
    {
        value: 5,
        label: "05",
    },
    {
        value: 6,
        label: "06",
    },
    {
        value: 7,
        label: "07",
    },
    {
        value: 8,
        label: "08",
    },
    {
        value: 9,
        label: "09",
    },
    {
        value: 10,
        label: "10",
    },
];

export const marks5 = [
    {
        value: 0,
        label: "00",
    },
    {
        value: 1,
        label: "01",
    },
    {
        value: 2,
        label: "02",
    },
    {
        value: 3,
        label: "03",
    },
    {
        value: 4,
        label: "04",
    },
    {
        value: 5,
        label: "05",
    },
];

export const downloadPdfFromUrl = (url: string, name: string) => {
    fetch(url).then((response) => {
        response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = name;
            alink.click();
        });
    });
};

export const convertPayloadToString = (data: any, key: string) => {

    const keysToConvert = [] as any;
    findingData?.map((res: any) => {
        res?.options?.map((opt: any) => {
            if (opt?.type === "multi-select") {
                if (!keysToConvert?.includes(opt?.key)) {
                    keysToConvert?.push(opt?.key);
                }
            }
            return true;
        });
        return true;
    });
    if (keysToConvert?.includes(key)) {
        return data?.toString();
    }
    return data;
};

export const convertMultiSelectArrayToString = (findings: any) => {
    const newFindings = findings;
    Object.keys(findings)?.forEach((exam: string) => {
        let newExam = newFindings[exam];
        if (findings[exam] !== null) {
            Object.keys(findings[exam])?.map((finding: string) => {
                if (Array.isArray(findings[exam][finding])) {
                    let convertedData: any;
                    if (
                        Array.isArray(
                            convertPayloadToString(findings[exam][finding], finding)
                        )
                    ) {
                        convertedData = convertPayloadToString(
                            findings[exam][finding],
                            finding
                        )?.map((res: any) => {
                            const newFields = {} as any;
                            res && Object.keys(res)?.map((field: string) => {
                                if (Array.isArray(res[field])) {
                                    newFields[field] = convertPayloadToString(res[field], field);
                                } else {
                                    // TODO: add condition only for occlusal_trauma_present due to resolve: in the present filed is false then its pass null everytime
                                    if (finding === 'occlusal_trauma_present') {
                                        newFields[field] = res[field] === 'yes' ? true : res[field] === 'no' ? false : res[field];
                                    } else {
                                        newFields[field] = res[field] || null;
                                    }

                                }
                                return true;
                            });
                            return newFields;
                        });
                    } else {
                        convertedData = convertPayloadToString(
                            findings[exam][finding],
                            finding
                        );
                    }
                    newExam[finding] = convertedData;
                }
                return true;
            });
            return true;
        }

    });
    return newFindings;
};


export const convertMultiSelectStringToArray = (examData: any) => {
    const updatedExamData = removeRefFromJson(examData) as any
    if (updatedExamData) {
        Object.keys(updatedExamData)?.map((exams: any) => {
            updatedExamData[exams] && Object.keys(updatedExamData[exams])?.map((findings: any) => {
                if (updatedExamData[exams][findings] && Array.isArray(updatedExamData[exams][findings])) {
                    updatedExamData[exams][findings]?.map((fields: any, idx: number) => {
                        fields && Object.keys(fields)?.map((field: string,) => {
                            if (findingData?.filter((f: any) => f?.key === findings)[0]?.options?.filter((f: any) => f?.key === field)[0]?.type === 'multi-select') {
                                if (updatedExamData[exams] && updatedExamData[exams][findings] && updatedExamData[exams][findings][idx] && typeof updatedExamData[exams][findings][idx][field] === 'string' && updatedExamData[exams][findings][idx][field]) {
                                    updatedExamData[exams][findings][idx][field] = stringToArray(updatedExamData[exams][findings][idx][field] || '')
                                }
                            }
                            return true
                        })

                        return true
                    })
                } else {
                    // TODO : it's for single value: will manage it later
                    // if (findingData?.filter((f: any) => f?.key === findings)[0]?.options?.filter((f: any) => f?.key === findings)[0]?.type === 'multi-select') {
                    //   if (updatedExamData[exams] && updatedExamData[exams][findings] && updatedExamData[exams][findings]) {
                    //     updatedExamData[exams][findings] = stringToArray(updatedExamData[exams][findings] || '')
                    //   }
                    // }
                    // return true
                    // })
                }

                return true
            })

            return true
        })
    }
    return updatedExamData
}

export const removeNullValue = (examData: any) => {
    const modifiedResponse = { ...examData };
    if (modifiedResponse && modifiedResponse !== null && modifiedResponse !== undefined) {
        Object.keys(modifiedResponse)?.forEach((exam: any) => {
            if (modifiedResponse?.[exam] && modifiedResponse?.[exam] !== null && modifiedResponse?.[exam] !== undefined) {
                Object.keys(modifiedResponse?.[exam])?.forEach((key: any) => {
                    const value = modifiedResponse?.[exam]?.[key];
                    // Check if the value is an array
                    if (Array.isArray(value)) {
                        // Filter out objects with all null values
                        const filteredArray = value?.filter((item: any) => {
                            return !Object.values(item)?.every((val: any) => val === null || val === '');
                        });
                        // Update the array with the filtered results
                        modifiedResponse[exam][key] = filteredArray?.length === 0 ? null : filteredArray;
                    }
                })
            }

        })
    }

    return modifiedResponse
}

export const comparePayloadResponse = (payload: any, res: any) => {
    if (payload && res) {
        const keys1 = Object.keys(payload);
        for (const key of keys1) {
            const value1 = payload[key];
            const value2 = res[key];
            if (typeof value1 === 'object' && typeof value2 === 'object') {
                if (comparePayloadResponse(value1, value2)) {
                    return true;
                }
            } else if (value1 !== value2) {
                return true;
            }
        }
    }
    if (res === null) {
        return true
    }
    return false;
}

export const errorToaster = (message: string) => {
    toast.error(message || "Something went wrong", {
        className: "toaster-container-error",
        icon: false,
    });
};
export const successToaster = (message: string) => {
    toast.success(message, {
        className: "toaster-container",
        icon: false,
    });
};

/*To check the object is empty or not [returnType: boolean]*/
export const isEmptyObject = (obj: object) => Object.keys(obj).length < 1;

//logout

export const handleLogout = () => {
    localStorage.removeItem("clinicInfo");
    localStorage.removeItem("clinic");
    localStorage.removeItem("dentistToken");
    localStorage.removeItem("dentistUserData");
    window.location.href = "/dentist/login";
};

export const handlePatientLogout = () => {
    localStorage.removeItem("patientToken");
    localStorage.removeItem("patientUserData");
    localStorage.removeItem("social_login");
    localStorage.removeItem('patientClinicInfo')
    localStorage.removeItem('patientClinic')
    window.location.href = '/patient/login'
};

export const examList = {
    IO: {
        title: "IO",
        key: "intra_oral",
        name: "Intra Oral Exam",
        color: theme.palette.tagColor.green,
        isXray: true,
        isImage: true,
    },
    EO: {
        title: "EO",
        key: "extra_oral",
        name: "Extra Oral Exam",
        color: theme.palette.primary.main,
        isXray: false,
        isImage: true,
    },
    OE: {
        title: "OE",
        key: "occlusion_exam",
        name: "Occlusion Exam",
        color: theme.palette.secondary.main,
        isXray: true,
        isImage: true,
    },
    PR: {
        title: "PR",
        key: "periodontal_exam",
        text: "Periodontal Risk",
        color: theme.palette.grey["A400"],
        isXray: true,
        isImage: true,
    },
    WE: {
        title: "WE",
        key: "wear_exam",
        name: "Wear Exam",
        color: theme.palette.warning.main,
        isXray: false,
        isImage: true,
    },
    TE: {
        title: "TE",
        key: "tmj_exam",
        name: "TMJ & Muscle exam",
        color: theme.palette.tagColor.lightBlue,
        isXray: false,
        isImage: false,
    },
    EE: {
        title: "EE",
        key: "existing_treatment",
        name: "Existing Exam",
        color: theme.palette.tagColor.purple,
        isXray: true,
        isImage: true,
    },
    PE: {
        title: "PE",
        key: "pathology_treatment",
        name: "Pathology Exam",
        color: "#FD9308",
        isXray: true,
        isImage: true,
    },
} as any;

export const convertValueToRoman = (val: string) => {
    return val === '1' ? 'I' : val === '2' ? 'II' : val === '3' ? 'III' : ''
}
export const handleChangeWithDebounce = (fn: any) => debounce(fn, 50);

export const getToothIndex = (tooth: number, lingual?: boolean) => {
    switch (tooth) {
        case 18:
            return lingual ? 16 : 1
        case 17:
            return lingual ? 15 : 2
        case 16:
            return lingual ? 14 : 3
        case 15:
            return lingual ? 13 : 4
        case 14:
            return lingual ? 12 : 5
        case 13:
            return lingual ? 11 : 6
        case 12:
            return lingual ? 10 : 7
        case 11:
            return lingual ? 9 : 8
        case 21:
            return lingual ? 8 : 9
        case 22:
            return lingual ? 7 : 10
        case 23:
            return lingual ? 6 : 11
        case 24:
            return lingual ? 5 : 12
        case 25:
            return lingual ? 4 : 13
        case 26:
            return lingual ? 3 : 14
        case 27:
            return lingual ? 2 : 15
        case 28:
            return lingual ? 1 : 16
        case 38:
            return lingual ? 32 : 17
        case 37:
            return lingual ? 31 : 18
        case 36:
            return lingual ? 30 : 19
        case 35:
            return lingual ? 29 : 20
        case 34:
            return lingual ? 28 : 21
        case 33:
            return lingual ? 27 : 22
        case 32:
            return lingual ? 26 : 23
        case 31:
            return lingual ? 25 : 24
        case 41:
            return lingual ? 24 : 25
        case 42:
            return lingual ? 23 : 26
        case 43:
            return lingual ? 22 : 27
        case 44:
            return lingual ? 21 : 28
        case 45:
            return lingual ? 20 : 29
        case 46:
            return lingual ? 19 : 30
        case 47:
            return lingual ? 18 : 31
        case 48:
            return lingual ? 17 : 32
        default:
            return 0
    }
}

interface ToothNumbers {
    [key: number]: number;
}

export const toothNumberPreferences = (tooth: number, isPatient?: boolean) => {
    const localStorageData = getLocalStorageItem(isPatient ? "patientClinicInfo" : "clinicInfo") as any;
    const ClinicInfo = JSON.parse(localStorageData)
    const toothNumbers = {
        18: 1,
        17: 2,
        16: 3,
        15: 4,
        14: 5,
        13: 6,
        12: 7,
        11: 8,
        21: 9,
        22: 10,
        23: 11,
        24: 12,
        25: 13,
        26: 14,
        27: 15,
        28: 16,
        38: 17,
        37: 18,
        36: 19,
        35: 20,
        34: 21,
        33: 22,
        32: 23,
        31: 24,
        41: 25,
        42: 26,
        43: 27,
        44: 28,
        45: 29,
        46: 30,
        47: 31,
        48: 32,
    } as ToothNumbers
    return ClinicInfo?.tooth_numbering_format === "CAN" ? tooth : toothNumbers[tooth]
}

export const treatmentDescription = (treatmentFinding: any, fromAlternative: boolean, isPatient?: boolean) => {
    if (treatmentFinding) {
        const { tooth_number, tooth_surface, subtype, finding } = treatmentFinding;
        const newToothNumber = toothNumberPreferences(parseInt(tooth_number), isPatient);
        const updatedToothSurface = fromAlternative
            ? (stringToArray(tooth_surface)?.map((surface: string) => getShortSurface(surface))?.join(''))
            : tooth_surface;
        const sanitizedFinding = finding ? finding?.replace(/_/g, ' ') : ''; // Ensure `finding` is optional.
        // Helper function to build the description dynamically
        const parts = [
            newToothNumber,
            sanitizedFinding, // Only include if `finding` exists
            updatedToothSurface?.replace(/,/g, '') || null,
            subtype || null
        ]?.filter(Boolean); // Filter out null/undefined/empty values.
        return parts?.join('-'); // Join non-empty parts with `-`.
    }
    return '';
};


export const spinLoader = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

export const checkIsEmptyValidator = (value: any) => {
    if (value === '') {
        return validator.isEmpty(value)
    } else {
        return value === null || value === ''
    }

}

export const alphabetRegex = /[a-zA-Z]/;

// TODO:For future use
// export const calculateTotalFees = (item: any) => {
//     let totalFees;
//     if (item?.fees?.includes("-") || alphabetRegex.test(item?.fees)) {
//         totalFees = item?.fees
//     } else {
//         const fee = parseFloat(item?.fees)?.toFixed(2) as any
//         totalFees = ((fee) * (item?.quantity || 1))?.toFixed(2) as any
//     }
//     return totalFees;
// };

// hide middle digit of mobile number
export const hideMiddleDigits = (phoneNumber: string) => {
    const cleaned = phoneNumber?.replace(/\D/g, ''); // Remove all non-numeric characters
    const firstTwo = cleaned?.slice(0, 2);
    const lastTwo = cleaned?.slice(-2);
    const maskedSection = cleaned?.slice(2, -2)?.replace(/./g, '*');
    return `${firstTwo}${maskedSection}${lastTwo}`;
}

export const exceptThisSymbols = ['e', 'E', '+', '-', '.']

export const callAPIForSecondScreenUpdate = (appointmentId: any, isSecondScreenCondition: any, apRequest: any) => {
    // Subscribe to the Pusher channel
    const channel = pusher.subscribe('second_screen_channel');
    // Define the handler for the event
    const handleEvent = (data: any) => {
        if (data?.data_changed && isSecondScreenCondition(data) && !data?.is_annotation && data?.core_apis) {
            apRequest();
        }
    };
    // Bind the handler to the event
    channel.bind(appointmentId || '', handleEvent);
    // Return a cleanup function
    return () => {
        channel.unbind(appointmentId || '', handleEvent);
        pusher.unsubscribe('second_screen_channel');
    };
};

export const accessCodeDisplay = (code: string) => {
    if (code?.length !== 10) return code; // Ensure the code has exactly 10 digits
    return `${code?.slice(0, 3)}-${code?.slice(3, 6)}-${code?.slice(6)}`;
};

export const changeAnnotationResponse = (annotationData: any) => {
    const keyToReplace = [
        "cause",
        "tooth_number",
        "subtype",
        "tooth_site",
        "tooth_surface",
        "grade",
        "present",
        "mm",
        "notes"
    ] as any;
    const keysToKeep = [
        'recession',
        "furcation",
        'decay',
        'filling',
        'watch',
        'implant',
        'impacted_tooth',
        'crown',
        'sct',
        'inlay',
        'onlay',
        'veneer',
        'pontic',
        'abutment',
        'denture',
        'open_contact',
        'mobility',
        'localized_bone_loss',
        'non_carious_cervical_lesions',
        'minimal_attached_tissue'
    ]
    const updatedDetails = [] as any;
    const isMultiSelect = (finding: any, key: string) => {
        const currentFinding = findingData.filter(
            (f: any) => finding?.findings === f.key
        ) as any;
        return (
            currentFinding[0]?.options.filter((f: any) => f.key === key)[0] &&
            currentFinding[0]?.options.filter((f: any) => f.key === key)[0]
                ?.type === "multi-select"
        );
    };
    const transformedDetails = annotationData.map((item: any) => {
        const { finding_details, ...rest } = item;
        return {
            ...rest,
            ...finding_details
        };
    });
    const processData = () => {
        return transformedDetails.map((item: any) => {
            const ncclTypes = [];

            // Check for the conditions and populate ncclTypes accordingly
            if (item.erosion) {
                ncclTypes.push("Erosion");
            }
            if (item.abrasion) {
                ncclTypes.push("Abrasion");
            }
            if (item.attrition) {
                ncclTypes.push("Attrition");
            }
            if (item.is_dentin_exposed) {
                ncclTypes.push("Is Dentin Exposed");
            }
            if (item.abfraction) {
                ncclTypes.push("Abfraction");
            }
            delete item.erosion
            delete item.abrasion
            delete item.attrition
            delete item.is_dentin_exposed
            delete item.abfraction
            // Return the item with the added ncclTypes field
            return {
                ...item,
                ncclTypes
            };
        });
    };
    processData()?.map((res: any) => {
        const newObj = {} as any;
        Object.keys(res).forEach((k) => {
            if (keyToReplace.includes(k) && !isMultiSelect(res, k) && !keysToKeep?.includes(res?.finding)) {
                newObj[`${res.finding}_${k}`] =
                    typeof res[k] === "boolean" ? (res[k] ? "yes" : "no") : res[k];
            } else {
                newObj[k] =
                    typeof res[k] === "boolean" ? (res[k] ? "yes" : "no") : res[k];
            }
        });
        updatedDetails.push(newObj);
        return true;
    });
    return updatedDetails;
}

export const checkAPIParams = (params: any) => {
    let tmpUrl = params?.length ? `?` : ''
    params?.map((p: any) => {
        if (tmpUrl.length > 1) {
            if (p.value) {
                tmpUrl = `${tmpUrl}&${p.key}=${p.value}`;
            }
        } else {
            if (p.value) {
                tmpUrl = `${tmpUrl}${p.key}=${p.value}`;
            }
        }
        return true
    });
    return tmpUrl
}

export const preventAutoCompleteText = (filed: string) => {
    return {
        autocomplete: filed,
        form: {
            autocomplete: filed,
        }
    }
}

// for remove duplicates data and store previous api res- infinite scroll
export const removedDuplicatesData = (data: any, payload: any, id: string) => {
    const existingIds = data?.map((item: any) => item[id]) || []
    const newData = payload?.filter((item: any) => !existingIds.includes(item[id])) || []
    return [
        ...(data || []), // Keep the existing data
        ...(newData || []), // Append new data
    ]
}
