import {
    DISCLAIMER_ACCEPT_REQUEST,
    DISCLAIMER_ACCEPT_REQUEST_SUCCESS,
    DISCLAIMER_ACCEPT_REQUEST_FAILURE
} from '../../actionTypes/auth';
import {disclaimerActions, disclaimerInterface} from '../../types/authActionTypes/disclaimerActionTypes';

const initialState: disclaimerInterface = {
    disclaimerLoader: false,
};

const disclaimerReducer = (state = initialState, action: disclaimerActions) => {
    switch (action.type) {
        case DISCLAIMER_ACCEPT_REQUEST:
            return {
                ...state,
                disclaimerLoader: true,
            };
        case DISCLAIMER_ACCEPT_REQUEST_SUCCESS:
            return {
                ...state,
                disclaimerLoader: false,
            };
        case DISCLAIMER_ACCEPT_REQUEST_FAILURE:
            return {
                ...state,
                disclaimerLoader: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default disclaimerReducer;
