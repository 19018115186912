import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Button } from '@mui/material';
import moment from 'moment';
import { appointmentDetailsRequestAction } from "../../../redux/actions/dentist/appointmentsAction";
import {
  saveFindingTypeStepAction,
  selectImageAnnotationAction,
  updateExamFormSizeAction
} from '../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction';
import { downloadPreIntakePdfAction, getPreIntakeDataAction } from "../../../redux/actions/dentist/preIntakeActions";
import { RootState } from "../../../redux/reducers/rootReducer";
import FullScreenLoader from "../../../components/common/FullScreenLoader";
import CommunicationPreferences from "../../../components/common/PreIntake/CommunicationPreferences";
import DentalInformation from "../../../components/common/PreIntake/DentalInformation";
import DiscProfile from "../../../components/common/PreIntake/DiscProfile";
import EmergencyContactInformation from "../../../components/common/PreIntake/EmergencyContactInformation";
import GeneralHealth from "../../../components/common/PreIntake/GeneralHealth";
import LetsGetToKnowYouBetter from "../../../components/common/PreIntake/LetsGetToKnowYouBetter";
import MedicalHealthHistory from "../../../components/common/PreIntake/MedicalHealthHistory";
import PatientInformation from "../../../components/common/PreIntake/PatientInformation";
import SleepQuality from "../../../components/common/PreIntake/SleepQuality";
import {
  completeCheckout,
  limitedExam,
  medical,
  viewExam
} from '../../../utils/constant/clinicalExamConstant';

export const textLengthStyles = makeStyles((theme: any) => ({
  textLengthCount: {
    marginRight: '12px',
    alignItems: 'flex-start',
    "& .MuiTypography-root": {
      fontFamily: "FavoritPro-Regular !important",
      fontSize: '12px',
    }
  },
  buttonHover: {
    padding: "0px",
    color: theme.palette.v2.primary.main,
    "&:hover": {
      background: "transparent",
    },
  },
}));


const PreIntake = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const formRef = useRef() as any
  const { patientId, appointmentId } = useParams();

  const {
    preIntake: { loader, getPreIntakeLoader, preIntakeData },
    appointmentsReducer: { appointmentDetails },
    clinicalExamFilters: { findingType },
    anonymousPreIntake: { isClearPreIntake }
  } = useSelector((state: RootState) => state) as any;
  const isAnonymous = useMemo(() => {
    return location.pathname.includes('/anonymous-health-history/')
  }, [location.pathname]) as boolean
  const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
  const infoForPatientInformation = 'Basic patient information is provided by your PMS, if information here is no longer up to date please let your admin know.'

  useEffect(() => {
    if (!isAnonymous) {
      dispatch(saveFindingTypeStepAction(findingType))
      dispatch(getPreIntakeDataAction({ patientId: patientId, clinic_id: localStorage?.getItem('clinic') }));
      if (findingType !== medical && findingType !== limitedExam) {
        dispatch(updateExamFormSizeAction(false));
      }
      dispatch(selectImageAnnotationAction(''));
      if (appointmentId) {
        dispatch(
          appointmentDetailsRequestAction({
            appointment_id: appointmentId,
            fromPreIntake: true
          })
        );
      }
    }

  }, [dispatch,patientId,findingType,appointmentId,isAnonymous]);

  const disabledField = useMemo(() => {
    if (!appointmentId) {
      return false
    } else {
      if (!isAnonymous) {
        return (
          [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status)
        );
      } else {
        return false
      }
    }
  }, [appointmentDetails, isAnonymous,appointmentId]) as boolean;

  const clearForm = () => {
    formRef.current.reset();
  };

  useEffect(() => {
    if (isClearPreIntake) {
      clearForm()
    }
  }, [isClearPreIntake])

  const downloadPreIntakePdf = () => {
    const currentDate = moment().format('DD-MM-YYYY');
    dispatch(downloadPreIntakePdfAction({
      patient_id: patientId,
      callback: (data: any) => {
        if (data) {
          const byteCharacters = atob(data) as any
          const byteNumbers = new Array(byteCharacters.length) as any
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters?.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers) as any
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          // Create a URL for the Blob
          const url = URL.createObjectURL(blob) as any
          // Create a link and trigger download
          const link = document.createElement('a') as any
          link.href = url;
          link.download = `${preIntakeData?.first_name}${preIntakeData?.last_name}Preintake${currentDate}`;
          document.body.appendChild(link);
          link.click();
          // Clean up
          URL.revokeObjectURL(url);
          document.body.removeChild(link);
        }
      },
    }))
  }

  return (
    <Box p={["20px"]} >
      {((loader || getPreIntakeLoader) && !isAnonymous) && <FullScreenLoader />}
      {isWellnessProfile &&
        <Box display={'flex'} justifyContent={'end'} mb={2}>
          <Button variant='contained' className={`button`} onClick={downloadPreIntakePdf} >Download Health History</Button>
        </Box>}
      {(!isAnonymous ? !getPreIntakeLoader : true) && (
        <form ref={formRef}>
          {/*Patient Information*/}
          {!isWellnessProfile && <PatientInformation info={infoForPatientInformation} disabledField={disabledField} />}
          {/*Emergency Contact Information*/}
          {!isWellnessProfile && <EmergencyContactInformation disabledField={disabledField} />}
          {/*Let’s get to know you better*/}
          {!isWellnessProfile && <LetsGetToKnowYouBetter disabledField={disabledField} />}
          {/*Dental Insurance Information - Hide until we have import/export support (7558, 7054,5245) */}
          {/*           {convertToLowerCase(
            letsGetToKnowYouBetter?.doYouHaveDentalInsurance
          ) === "yes" && (
              <DentalInsuranceInformation disabledField={disabledField} />
            )} */}
          {/*General Health*/}
          <GeneralHealth disabledField={disabledField} />
          {/*Medical Health History*/}
          <MedicalHealthHistory disabledField={disabledField} />
          {/*Dental Information*/}
          <DentalInformation disabledField={disabledField} />
          {/* Sleep Quality */}
          <SleepQuality disabledField={disabledField} />
          {/*Communication Preferences*/}
          <CommunicationPreferences disabledField={disabledField} />
          {/*Disc Profile */}
          {(isWellnessProfile && preIntakeData?.disc_profile) && <DiscProfile />}
        </form>
      )}
    </Box>
  );
};

export default PreIntake;
