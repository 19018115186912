import React, {useState} from "react";
import {FormControl, ListItemText, MenuItem, Select} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {useSelector} from "react-redux";
import FullScreenLoader from '../FullScreenLoader';
import theme from "../../../theme";
import {MenuProps} from "../../../utils/constant/appointmentConstant";


const useStyles = makeStyles((themes: any) => ({
    inputLabel: {
        "& .MuiInputLabel-root": {
            top: "-5px !important",
            fontFamily: 'FavoritPro-Regular !important',
        },
        "&$cssFocused $notchedOutline": {
            borderColor: `${themes.palette.secondary.main} !important`,
        },
        "& .MuiOutlinedInput-input": {
            fontFamily: 'FavoritPro-Regular !important',
        }
    },
    selectClass: (isPatient: any) => ({
        color: `${theme.palette.common.black50} !important`,
        "& .MuiSelect-select": {
            height: '100% !important',
            display: 'flex',
            alignItems: 'center'
        },

        "& fieldset": {
            borderColor: theme.palette.text.darkGrey, //  TODO: base border color -- >> #666666
        },
        "&:hover fieldset": {
            borderColor: theme.palette.common.black50, // hover color -- >> TODO: #333333
        },
        "&.Mui-focused fieldset": {
            border: `2px solid ${isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main} !important`,
        },
        "& .Mui-disabled": {
            "& .MuiOutlinedInput-input": {
                fontFamily: "FavoritPro-Regular !important",
                color: `${theme.palette.text.secondary} !important`,
                "& fieldset": {
                    border: `1px solid ${theme.palette.text.secondary} !important`,
                },
            },
            "-webkit-text-fill-color": `${theme.palette.text.secondary} !important`,
            color: `${theme.palette.text.secondary} !important`,
            opacity: 1,
        },
    }),
    menuText: {
        "& .MuiTypography-root": {
            fontSize: "14px",
            fontFamily: "FavoritPro-Regular !important",
            fontWeight: 400,
            color: theme.palette?.common.black50,
        },
    },
}));
type Props = {
    isPatient: boolean | undefined
}
const ClinicDropDown = (props: Props) => {
    const {isPatient} = props
    const classes = useStyles(!!isPatient);
    const [clinicName, setClinicName] = useState("") as any;
    const clinicData = useSelector((state: any) => state.Clinic?.data?.data);
    const pending = useSelector((state: any) => state.Clinic?.pending)

    return (
        <FormControl fullWidth>
            {pending && <FullScreenLoader/>}
            <Select
                fullWidth
                id="clinic_name"
                name="clinic_name"
                value={clinicName}
                className={`${classes.selectClass} ${classes.inputLabel} cssOutlinedInput text-overflow-ellipsis fw-regular f-w-400 f-14`}
                displayEmpty
                placeholder={'Select Clinic'}
                onChange={(e) => {
                    setClinicName(e.target.value);
                    if (e.target.value) {
                        if (isPatient) {
                            localStorage.setItem("patientClinic", e.target.value);
                            localStorage.setItem('patientClinicInfo', JSON.stringify(clinicData?.filter((cf: any) => cf?.clinic_id === e.target.value)[0]))
                        } else {
                            localStorage.setItem("clinic", e.target.value);
                            localStorage.setItem('clinicInfo', JSON.stringify(clinicData?.filter((cf: any) => cf?.clinic_id === e.target.value)[0]))
                        }
                    }
                }}
                MenuProps={MenuProps}
            >
                <MenuItem value={""} id={""} className="text-overflow-ellipsis fw-regular f-w-400 f-14">
                    <ListItemText
                        className={`${classes.menuText} text-overflow-ellipsis fw-regular f-w-400 f-14`}
                        primary={"Select Clinic"}
                    />
                </MenuItem>
                {clinicData &&
                    clinicData?.map((itm: any, index: number) => (
                        <MenuItem
                            key={index}
                            value={itm.clinic_id}
                            id={itm.clinic_id}
                            className="text-overflow-ellipsis fw-regular f-w-400 f-14"
                            disabled={pending}
                        >
                            <ListItemText
                                className={`${classes.menuText} text-overflow-ellipsis fw-regular f-w-400 f-14`}
                                primary={itm?.clinic_name}
                            />
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    );
};

export default ClinicDropDown;
