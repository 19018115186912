import {call, put, takeEvery, all} from "redux-saga/effects";

import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';
import {
    changePasswordRequestFailure,
    changePasswordRequestSuccess
} from '../../actions/authAction/changePasswordAction';
import {CHANGE_PASSWORD_V2_REQUEST} from '../../actionTypes/auth';

function* changePasswordRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/auth/password`, payload.payload)
        yield call(payload.payload.callback, response.data);
        yield put(changePasswordRequestSuccess());
        successToaster(response?.data?.meta?.message)
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(changePasswordRequestFailure());
    }
}

function* changePasswordSaga() {
    yield takeEvery(CHANGE_PASSWORD_V2_REQUEST, changePasswordRequestSaga);
}

export default function* rootSaga() {
    yield all([
        changePasswordSaga(),
    ])
}