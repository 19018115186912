import validator from 'validator';
import { isEmptyObject } from '../../../utils/helper';

export const uploadPhotosXrayValidator = additionalImageXrayTitle => {
  let errors = {};

  if (additionalImageXrayTitle == null || validator.isEmpty(additionalImageXrayTitle))
    errors.additionalImageXrayTitle = 'This field is required';

  return { errors, isValid: isEmptyObject(errors) };
};
