import {
    UnsubscribeEmailRequest,
   UnsubscribeEmailRequestPayload,
} from "../../types/unsubscribeEmail/unsubscribeTypes";
import {
    UNSUBSCRIBE_EMAIL_REQUEST, UNSUBSCRIBE_EMAIL_REQUEST_FAILURE,
    UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS
} from "../../actionTypes/unsubscribeEmail/unsubscribeActionTypes";


export const unsubscribeEmailRequest = (payload: UnsubscribeEmailRequestPayload): UnsubscribeEmailRequest => ({
    type: UNSUBSCRIBE_EMAIL_REQUEST,
    payload
});


export const unsubscribeEmailRequestSuccess = () => ({
    type: UNSUBSCRIBE_EMAIL_REQUEST_SUCCESS
});

export const unsubscribeEmailRequestFailure = () => ({
    type: UNSUBSCRIBE_EMAIL_REQUEST_FAILURE
});