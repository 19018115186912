import {
    MULTIPLE_NUMBER_POPUP_REQUEST,

    PATIENT_CHANGE_PASSWORD_REQUEST,
    PATIENT_CHANGE_PASSWORD_REQUEST_SUCCESS,
    PATIENT_CHANGE_PASSWORD_REQUEST_FAILURE,

} from '../../actionTypes/patient/auth';
import {patientAuthActions, patientAuthInterface} from '../../types/patient/auth/authActionType';

const initialState: patientAuthInterface = {

    multipleNumberPopUp: false,
    multipleNumberData: null,

    pending: false,
    data: null,
    error: null,

};

const PatientAuthReducer = (state = initialState, action: patientAuthActions) => {
    switch (action.type) {

        case MULTIPLE_NUMBER_POPUP_REQUEST:
            return {
                ...state,
                multipleNumberPopUp: action.payload?.multipleNumberPopUp,
                multipleNumberData: action.payload?.multipleNumberData
            };


        case PATIENT_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                pending: true
            };

        case PATIENT_CHANGE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            };

        case PATIENT_CHANGE_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            };


        default:
            return {
                ...state
            };
    }
};
export default PatientAuthReducer;
