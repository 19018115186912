import {all, call, put, select, takeEvery} from "redux-saga/effects";
import {
    GET_PATIENT_DATA_REQUEST,
    SAVE_PATIENT_REGISTER_REQUEST,
    VERIFY_PATIENT_USERNAME_REQUEST
} from "../../actionTypes/patient/patientRegister";
import {ApiEndpointVersion, errorToaster, formatPhoneNumber} from "../../../utils/helper";
import {
    getPatientDataFailure,
    getPatientDataSuccess,
    patientRegisterFormDataRequest,
    savePatientRegisterFailure,
    savePatientRegisterSuccess,
    verifyPatientUsernameFailure,
    verifyPatientUsernameSuccess
} from "../../actions/patient/patientRegisterAction";
import api from "../../../utils/api";

function* patientRegisterRequestSaga({payload}: any): any {
    try {
        const response = yield api.post(`/${ApiEndpointVersion}/patient/register`, payload);

        yield put(savePatientRegisterSuccess());

        if (payload.callback) {
            yield call(payload.callback, response.data?.detail);
        }

    } catch (e: any) {
        errorToaster(e?.meta?.message);
        yield put(savePatientRegisterFailure());
    }
}

function* verifyPatientUsernameRequestSaga({payload}: any): any {
    try {
        const response = yield api.post(`/${ApiEndpointVersion}/auth/verify/username`, payload);

        yield put(verifyPatientUsernameSuccess(response?.data));

    } catch (e: any) {
        // errorToaster(e?.meta?.message);
        if (e?.code === 400) {
            yield put(
                verifyPatientUsernameFailure(e?.meta?.message)
            );
        }
    }
}

//get patient data
function* getPatientDataRequestSaga({payload}: any): any {
    const {patientRegisterData} = yield select((state: any) => state?.patientRegister) as any

    try {
        const response = yield api.get(`/${ApiEndpointVersion}/patient/${payload?.profile_id}/profile`);

        yield put(
            getPatientDataSuccess(response?.data)
        );

        const responseData = response?.data?.detail

        if (payload.callback) {
            yield call(payload.callback, response.data?.detail);
        }

        if (responseData) {
            yield put(patientRegisterFormDataRequest({
                ...patientRegisterData,
                email: responseData?.email,
                mobile: formatPhoneNumber(responseData?.mobile_number)
            }))
        }


    } catch (e: any) {
        // errorToaster(e?.meta?.message);
        yield put(
            getPatientDataFailure(e?.meta?.message)
        );
    }
}

function* patientRegisterWatch() {
    yield takeEvery(SAVE_PATIENT_REGISTER_REQUEST, patientRegisterRequestSaga);
}

function* verifyPatientUsernameWatch() {
    yield takeEvery(VERIFY_PATIENT_USERNAME_REQUEST, verifyPatientUsernameRequestSaga);
}

function* getPatientDataWatch() {
    yield takeEvery(GET_PATIENT_DATA_REQUEST, getPatientDataRequestSaga);
}

export default function* patientRegisterSaga() {
    yield all([
        patientRegisterWatch(),
        verifyPatientUsernameWatch(),
        getPatientDataWatch()
    ])
}