import {
    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_REQUEST_FAILURE,
    CREATE_PASSWORD_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {
    CreatePasswordRequest,
    CreatePasswordRequestPayload,
} from '../../types/authActionTypes/createPasswordActionTypes';

export const createPasswordRequest = (payload: CreatePasswordRequestPayload): CreatePasswordRequest => ({
    type: CREATE_PASSWORD_REQUEST,
    payload
});

export const createPasswordRequestSuccess = () => ({
    type: CREATE_PASSWORD_REQUEST_SUCCESS
});

export const createPasswordRequestFailure = () => ({
    type: CREATE_PASSWORD_REQUEST_FAILURE
});
