import {
    GetRiskScoreChartFailure,
    GetRiskScoreChartRequest,
    GetRiskScoreChartRequestPayload, GetRiskScoreChartSuccess, GetRiskScoreChartSuccessPayload
} from "../../../types/dentist/wellnessProfile/riskScoreChart";
import {
    GET_RISK_SCORE_CHART_DATA_REQUEST, GET_RISK_SCORE_CHART_DATA_REQUEST_FAILURE,
    GET_RISK_SCORE_CHART_DATA_REQUEST_SUCCESS
} from "../../../actionTypes/dentist/wellnessProfile/riskScoreChart";

export const getRiskScoreChartRequestAction = (payload: GetRiskScoreChartRequestPayload): GetRiskScoreChartRequest => ({
    type: GET_RISK_SCORE_CHART_DATA_REQUEST,
    payload
});

export const getRiskScoreChartSuccessAction = (payload: GetRiskScoreChartSuccessPayload): GetRiskScoreChartSuccess => ({
    type: GET_RISK_SCORE_CHART_DATA_REQUEST_SUCCESS,
    payload
});

export const getRiskScoreChartFailureAction = (payload:any): GetRiskScoreChartFailure => ({
    type: GET_RISK_SCORE_CHART_DATA_REQUEST_FAILURE,
    payload
});
