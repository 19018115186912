import React, {useMemo} from "react";
import Typography from "@mui/material/Typography";
import {Box, Button, InputAdornment} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faFileArrowUp} from "@fortawesome/free-solid-svg-icons";
import {makeStyles} from "@mui/styles";
import {useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import PhotoXrayCard from "../PhotoXrayCard";
import theme from "../../../../../../theme";
import {RootState} from '../../../../../../redux/reducers/rootReducer';
import {referencePhotos, referenceXray} from '../../../../../../utils/constant/photoXrayConstant';
import {uploadPhotosModelAction} from '../../../../../../redux/actions/dentist/clinicalExam/exam/uploadPhotosXrayAction';
import FullScreenLoader from '../../../../../common/FullScreenLoader';
import {
    completeCheckout,
    photo,
    viewExam
} from '../../../../../../utils/constant/clinicalExamConstant';
import {updateAppointmentStepAction} from '../../../../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction';
import {
    handleImageXrayFromTreatmentPlanComingAction
} from '../../../../../../redux/actions/dentist/clinicalExam/treatment/proposedTreatments/oraqProposedAction';
import {CE} from "../../../../../../utils/constant/examConstant";

const useStyles = makeStyles((theme: any) => ({
    actionButton: {
        width: "fit-content",
        minWidth: "154px",
        height: "30px",
        borderColor: theme.palette.v2.primary.main,
        color: theme.palette.v2.primary.main,
        fontSize: "12px",
    },
    exampleGrid: {
        height: '305px',
        width: '440px',
        [theme.breakpoints.down('xl')]: {
            height: '290px',
            width: '440px'
        },
        [theme.breakpoints.down('lg')]: {
            height: '275px',
            width: '440px'
        },
        [theme.breakpoints.down('md')]: {
            height: '270px !important',
            width: '380px'
        },
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            width: '420px'
        },
        [theme.breakpoints.down('xs')]: {
            height: '220px',
            width: '440px'
        },
        [theme.breakpoints.between(1760, 1920)]: {
            width: '410px',
        },
        [theme.breakpoints.between(1640, 1760)]: {
            width: '380px',
            height: '275px'
        },
        [theme.breakpoints.down(1415)]: {
            width: '380px',
            height: '275px'
        },
        [theme.breakpoints.between(1000, 1235)]: {
            width: '440px',
            height: '300px'
        },
        [theme.breakpoints.between(750, 835)]: {
            width: '340px',
            height: '250px !important'
        },
        [theme.breakpoints.down(750)]: {
            width: '440px',
            height: '300px !important'
        }
    },
    exampleParentBox: {
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, max-content))'
        },
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(420px, max-content))'
        },
        [theme.breakpoints.up('xs')]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.between(1760, 1920)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(410px, max-content))'
        },
        [theme.breakpoints.between(1640, 1760)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, max-content))'
        },
        [theme.breakpoints.down(1415)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(380px, max-content))'
        },
        [theme.breakpoints.between(1000, 1235)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        },
        [theme.breakpoints.between(650, 835)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(340px, max-content))'
        },
        [theme.breakpoints.down(750)]: {
            gridTemplateColumns: 'repeat(auto-fit, minmax(440px, max-content))'
        }
    }
}));

export default function PhotosXrayList() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const location = useLocation()
    const {
        uploadPhotoXray: {photosXrayData, photosXrayLoader},
        clinicalExamFilters: {findingType},
        appointmentsReducer: {appointmentDetails},
        oraqProposedReducer: {cameImageXrayFromTreatmentPlan},
        wellnessProfileFilters: {wellnessProfileStep,wellnessProfileData}
    } = useSelector((state: RootState) => state);

    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhoto = tabData === photo

    const disabledField = useMemo(() => {
        return (
            [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || wellnessProfileData?.is_wellness_locked
        );
        // eslint-disable-next-line
    }, [appointmentDetails, location?.pathname,wellnessProfileData?.is_wellness_locked]);

    const getImagesName = () => {
        let uploaded = [] as any
        (isPhoto ? referencePhotos : referenceXray)?.map((res: any) =>
            uploaded?.push(res?.title)
        )
        return uploaded
    }
    const filterImageData = () => {
        const filteredPhotoData = referencePhotos as any
        const examType = appointmentDetails?.appointmentType || CE;
        const examTypeWiseFilteredData = filteredPhotoData?.filter((f: any) => f?.examType?.includes(examType));
        return isPhoto ? examTypeWiseFilteredData : referenceXray
    }

    const getRemainingPhotoNumber = () => {
        if (photosXrayData) {
            const lengthOfImage = photosXrayData?.filter((r: any) =>
                getImagesName()?.includes(r?.image_name))
            return (filterImageData())?.length - lengthOfImage?.length
        }
        return 0
    }

    const openUploadPhotosModel = () => {
        dispatch(uploadPhotosModelAction(true));
    };
    const backToTreatment = () => {
        const step = 3 as any;
        dispatch(handleImageXrayFromTreatmentPlanComingAction(false));
        dispatch(updateAppointmentStepAction(step));
    }

    return (
        <Box>
            {photosXrayLoader && <FullScreenLoader/>}
            {<Box
                p={'16px 40px'}
                className="d-flex justify-content-between align-center"
            >
                {cameImageXrayFromTreatmentPlan ?
                    <Button onClick={backToTreatment} variant='outlined'
                            startIcon={<FontAwesomeIcon icon={faArrowLeft}/>}
                            sx={{color: theme.palette.v2.primary.main, borderColor: theme.palette.v2.primary.main}}>
                        Back to Treatment Planning</Button>
                    : <Box/>}
                {getRemainingPhotoNumber() > 0 &&
                    <Typography className="fw-regular f-16 f-w-500 text-center lh-18 danger">
                        You still have {getRemainingPhotoNumber()} {isPhoto ? 'photos' : "x-rays"} remaining to upload.
                    </Typography>}
                {!disabledField ? <Button
                    variant={"outlined"}
                    className={`fw-regular`}
                    classes={{root: classes.actionButton}}
                    onClick={openUploadPhotosModel}
                    disabled={disabledField}
                >
                    <InputAdornment position="start">
                        <FontAwesomeIcon
                            icon={faFileArrowUp}
                            width={12}
                            height={16}
                            className="f-w-900 fw-medium f-16 lh-16"
                            style={{
                                color: disabledField ? theme.palette.text.secondary : theme.palette.v2.primary.main,
                            }}
                        />
                    </InputAdornment>
                    Upload New {isPhoto ? "Photo" : "Xray"}
                </Button>:<Typography/>}
            </Box>}

            <Box px={0} pt={isWellnessProfile ? '16px' : '0px'}>
                <Box
                    margin={'0 auto'}
                    width={'100%'}
                    gap={'25px'}
                    className={classes.exampleParentBox}
                    sx={{
                        display: 'grid', paddingInline: '20px',
                        justifyContent: {
                            xl: photosXrayData?.length < 4 ? 'flex-start' : "center",
                            lg: photosXrayData?.length < 4 ? 'flex-start' : "center",
                            md: photosXrayData?.length < 2 ? 'flex-start' : "center",
                            sm: photosXrayData?.length < 2 ? 'flex-start' : "center",
                            xs: "center"
                        },
                        [theme.breakpoints.between(1000, 1280)]: {
                            justifyContent: photosXrayData?.length < 3 ? 'flex-start' : "center"
                        },
                        gridRowGap: '0px !important'
                    }}
                >
                    {photosXrayData?.map((data: any, index: number) => (
                        <Box key={index}
                             className={classes.exampleGrid}
                             sx={{display: 'inline-block', boxSizing: 'border-box', m: 1}}
                        >
                            <PhotoXrayCard data={data}/>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
}
