import React, {useRef} from "react";
import {Grid, InputAdornment} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {
    updateLetsGetToKnowYouBetterAction,
    updatePreIntakeValidationsAction,
} from "../../../../redux/actions/dentist/preIntakeActions";
import {RootState} from "../../../../redux/reducers/rootReducer";
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import DropDown from "../../UIComponents/DropDown";
import InputHeading from "../../UIComponents/InputHeading";
import RadioSelect from "../../UIComponents/RadioSelect";
import TextBox from "../../UIComponents/TextBox";
import {
    maritalStatus,
    radioButtonOptions,
} from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import {letsGetToKnowYouBetterValidator} from "../../../../validation/preIntakeValidator";
import {handleChangeWithDebounce} from "../../../../utils/helper";
import {textLengthStyles} from "../../../../pages/dentist/PreIntake";

type Props = {
    disabledField?: boolean;
};

const LetsGetToKnowYouBetter = React.memo((props: Props) => {
    const {disabledField} = props;
    const lengthClasses = textLengthStyles();
    const dispatch = useDispatch();
    const noteRef = useRef(null) as any
    const {letsGetToKnowYouBetter, errors} = useSelector(
        (state: RootState) => state.preIntake
    );
    const handleChange = handleChangeWithDebounce((e: any) => {
        const {value, name, id} = e.target as any;
        let updatedLetsGetToKnowYouBetter = letsGetToKnowYouBetter;
        updatedLetsGetToKnowYouBetter[name || id] = value;
        dispatch(updateLetsGetToKnowYouBetterAction(updatedLetsGetToKnowYouBetter));

        // for the update error
        if (errors?.letsGetToKnowYouBetter?.[name || id]) {
            const letsGetToKnowYouBetterValid = letsGetToKnowYouBetterValidator(
                updatedLetsGetToKnowYouBetter
            ) as any;
            dispatch(
                updatePreIntakeValidationsAction({
                    ...errors,
                    letsGetToKnowYouBetter: {
                        ...errors.letsGetToKnowYouBetter,
                        [name || id]: errors.letsGetToKnowYouBetter[name || id]
                            ? letsGetToKnowYouBetterValid.errors[name || id]
                            : "",
                    },
                })
            );
        }
    });
// TODO:For future use
    // const removeDentalInsuranceValue = () => {
    //     const updatedDentistInsuranceInfo = [
    //         {
    //             insuranceProvider: '',
    //             insuranceGroup: '',
    //             insuranceID: ''
    //         }
    //     ];
    //
    //     dispatch(updateDentistInsuranceInfoAction(updatedDentistInsuranceInfo));
    //
    //     // for the update error
    //     dispatch(
    //         updatePreIntakeValidationsAction({
    //             ...errors,
    //             dentistInsuranceInfo: {}
    //         })
    //     );
    // }

    return (
        <CollapsibleBox
            title={"Let’s get to know you better"}
            id={"letsGetToKnowYouBetter"}
            scrollBlock={'center'}
        >
            <Grid container rowSpacing={1.5} columnSpacing={2.5}>
                <Grid item xs={12} sm={6}>
                    <TextBox
                        disabled={disabledField}
                        label={"Occupation"}
                        labelProps={{
                            htmlFor: "occupation",
                        }}
                        maxLength={50}
                        resize={true}
                        resizeVerticalScreen={true}
                        inputProps={{
                            id: "occupation",
                            name: "occupation",
                            onChange: (e: any) => handleChange(e),
                            defaultValue: letsGetToKnowYouBetter?.occupation,
                            error: errors.letsGetToKnowYouBetter?.occupation,
                            helperText: errors.letsGetToKnowYouBetter?.occupation,
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIconNumbers",
                                },
                                endAdornment: (
                                    <InputAdornment
                                        position="end"
                                        className={lengthClasses.textLengthCount}
                                        sx={{alignSelf: 'flex-end', paddingBottom: '15px'}}
                                    >
                                        {`${letsGetToKnowYouBetter?.occupation?.length || 0} / 50`}
                                    </InputAdornment>
                                )
                            },
                            multiline: true,
                            ref: noteRef
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading
                        title={"Is night shift involved with your occupation?"}
                    />
                    <RadioSelect
                        disabled={disabledField}
                        options={radioButtonOptions}
                        groupProps={{
                            name: "isNightShiftInvolvedWithYourOccupation",
                            id: "isNightShiftInvolvedWithYourOccupation",
                            defaultValue:
                            letsGetToKnowYouBetter?.isNightShiftInvolvedWithYourOccupation,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputHeading title={"Marital Status"}/>
                    <DropDown
                        disabled={disabledField}
                        selectOptions={maritalStatus}
                        defaultValue={letsGetToKnowYouBetter?.maritalStatus}
                        selectProps={{
                            id: "maritalStatus",
                            name: "maritalStatus",
                            // value: letsGetToKnowYouBetter?.maritalStatus,
                            onChange: (e: any) => handleChange(e),
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextBox
                        disabled={disabledField}
                        label={"Referred By"}
                        labelProps={{
                            htmlFor: "referredBy",
                        }}
                        maxLength={30}
                        inputProps={{
                            id: "referredBy",
                            name: "referredBy",
                            onChange: (e: any) => handleChange(e),
                            defaultValue: letsGetToKnowYouBetter?.referredBy,
                            error: errors.letsGetToKnowYouBetter?.referredBy,
                            helperText: errors.letsGetToKnowYouBetter?.referredBy,
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                                    input: "textFieldStartEndIcon",
                                },
                                endAdornment: (
                                    <InputAdornment position="end" className={lengthClasses.textLengthCount}>
                                        {`${letsGetToKnowYouBetter?.referredBy?.length || 0} / 30`}
                                    </InputAdornment>
                                )
                            }
                        }}
                    />
                </Grid>
                {/* Hide until we have import/export support (7558, 7054,5245) <Grid item xs={12}>
          <InputHeading title={"Do You Have Dental Insurance?"} />
          <RadioSelect
            disabled={disabledField}
            options={dentalInsuranceOptions}
            groupProps={{
              name: "doYouHaveDentalInsurance",
              id: "doYouHaveDentalInsurance",
              defaultValue: letsGetToKnowYouBetter?.doYouHaveDentalInsurance,
              onChange: (e: any) => {
                handleChange(e)
                removeDentalInsuranceValue();
              }
            }}
          />
        </Grid> */}
            </Grid>
        </CollapsibleBox>
    );
})

export default LetsGetToKnowYouBetter;
