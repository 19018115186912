import {makeStyles} from '@mui/styles';
import React from 'react'
import Box from '@mui/material/Box';
import WellnessTreatmentsList from "./WellnessTreatmentsList";
import WellnessPlannedTreatments from "./WellnessPlannedTreatments";
import TabsComponent from "../../../common/UIComponents/TabsComponent";
import {outstandingTreatmentTabData, treatmentTabData} from "../../../../utils/constant/wellnessProfileConstant";
import WellnessOutstandingTreatments from "./WellnessOutstandingTreatments";
import {getLocalStorageItem} from "../../../../utils/helper";


const useStyles = makeStyles((theme: any) => ({
    indicator: {
        backgroundColor: `${theme.palette.v2.primary.main} !important`,
        top: 0,
        borderRadius: '10px !important',
        height: '3px'
    },
    tabContainer: {
        background: theme.palette.common.white,
        border: `1px solid ${theme.palette.divider}`,
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        overflowY: 'auto'
    }
}));


const WellnessTreatmentPlan = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(1);
    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const isPMSClinic = JSON.parse(getLocalStorageItem('clinicInfo') || '')?.pms_integration


    return (
        <Box sx={{width: '100%'}}>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} className='cursor-pointer'>
                <TabsComponent value={value} tabData={isPMSClinic ? outstandingTreatmentTabData : treatmentTabData}
                               handleChange={handleChange}/>
            </Box>
            <Box className={classes.tabContainer} p={2}>
                {value === 1 && <WellnessPlannedTreatments/>}
                {value === 2 && <WellnessTreatmentsList/>}
                {value === 3 && <WellnessOutstandingTreatments/>}
            </Box>
        </Box>
    )
}

export default WellnessTreatmentPlan