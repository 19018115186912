import React from 'react';
import {Button, CircularProgress} from "@mui/material";
import theme from "../../../../theme";

type Props = {
    handleClick?: any
    disabled?: boolean
    buttonLoader?: boolean
    className?: any
    btnName: string
    sx?: any
    isPatient?: boolean
}

const ButtonContained = (props: Props) => {
    const {buttonLoader, disabled, handleClick, className, btnName, sx, isPatient} = props;
    return (
        <Button
            disabled={disabled}
            variant={'contained'}
            className={`fw-regular f-14 f-w-500  ${className}`}
            onClick={handleClick}
            sx={{
                height: '40px !important',
                borderRadius: '4px',
                background: isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,
                color: theme.palette.common.white,
                textDecoration: 'none',
                textAlign: 'center',
                '&:hover': {
                    background: isPatient ? theme.palette.secondary.main : theme.palette.v2.primary.main,
                    color: theme.palette.common.white
                },
                ...sx
            }}
        >
            {buttonLoader ? (<CircularProgress color={"primary"} size={19}/>) : (btnName)}
        </Button>
    )
}

export default ButtonContained