import {all, call, put, takeEvery} from 'redux-saga/effects';
import API from '../../../utils/api';
import {STAFF_NEW_PROFILE_REQUEST, STAFF_NEW_PROFILE_EDIT_REQUEST} from '../../actionTypes/dentist/profileDentist';
import {
    staffProfileNewRequestSuccess,
    staffProfileNewRequestFailure,
    staffProfileNewEditRequestFailure,
    staffProfileNewEditRequestSuccess
} from '../../actions/dentist/dentistProfileNewAction';
import {ApiEndpointVersion, errorToaster, successToaster} from '../../../utils/helper';

//Staff Profile Data
function* staffProfileNewRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/staff/${payload?.payload?.staff_id}`);
        yield put(
            staffProfileNewRequestSuccess(response?.data?.detail)
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response?.data?.detail);
        }
    } catch (e: any) {
        yield put(
            staffProfileNewRequestFailure(e?.detail)
        );
    }
}

//Edit Staff Profile
function* staffProfileNewEditRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/staff?staff_id=${payload?.payload?.staff_id}&clinic_id=${payload?.payload?.clinic_id}`, payload.payload.profile_picture);
        if (response) {
            successToaster('Profile update successfully ')
        }
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response?.data?.detail);
        }
        yield put(
            staffProfileNewEditRequestSuccess()
        );
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            staffProfileNewEditRequestFailure()
        );
    }
}

function* getStaffProfileNewSaga() {
    yield takeEvery(STAFF_NEW_PROFILE_REQUEST, staffProfileNewRequestSaga);
}

function* staffProfileNewEditSaga() {
    yield takeEvery(STAFF_NEW_PROFILE_EDIT_REQUEST, staffProfileNewEditRequestSaga);
}

export default function* staffProfileNewSaga() {
    yield all([getStaffProfileNewSaga(), staffProfileNewEditSaga()]);
}
