import {
    HealthHistoryWithoutACActions,
    HealthHistoryWithoutACInterface
} from "../../types/patient/healthHistoryWithoutAccountActionTypes";
import {
    GET_HEALTH_HISTORY_WITHOUT_AC_FAIL,
    GET_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    GET_HEALTH_HISTORY_WITHOUT_AC_SUCCESS,
    PUT_HEALTH_HISTORY_WITHOUT_AC_FAIL,
    PUT_HEALTH_HISTORY_WITHOUT_AC_REQUEST,
    PUT_HEALTH_HISTORY_WITHOUT_AC_SUCCESS,
    REGISTER_POP_UP_REQUEST
} from "../../actionTypes/patient/healthHistoryWithoutAccount";


const initialState: HealthHistoryWithoutACInterface = {
    getHealthHistoryWithoutAccountLoader: false,
    getHealthHistoryWithoutAccountData: null,
    getHealthHistoryWithoutAccountError: null,
    putHealthHistoryWithoutAccountLoader: false,
    putHealthHistoryWithoutAccountData: null,
    putHealthHistoryWithoutAccountError: null,
    openPopUp: false,
};
const HealthHistoryWithoutAcReducer = (state = initialState, action: HealthHistoryWithoutACActions) => {
    switch (action.type) {
        case GET_HEALTH_HISTORY_WITHOUT_AC_REQUEST:
            return {
                ...state,
                getHealthHistoryWithoutAccountLoader: true
            };

        case GET_HEALTH_HISTORY_WITHOUT_AC_SUCCESS:
            return {
                ...state,
                getHealthHistoryWithoutAccountLoader: false,
                getHealthHistoryWithoutAccountData: action?.payload,
                getHealthHistoryWithoutAccountError: null
            };
        case GET_HEALTH_HISTORY_WITHOUT_AC_FAIL:
            return {
                ...state,
                getHealthHistoryWithoutAccountLoader: false,
                getHealthHistoryWithoutAccountData: null,
                getHealthHistoryWithoutAccountError: action?.payload
            };
        case PUT_HEALTH_HISTORY_WITHOUT_AC_REQUEST:
            return {
                ...state,
                putHealthHistoryWithoutAccountLoader: true
            };

        case PUT_HEALTH_HISTORY_WITHOUT_AC_SUCCESS:
            return {
                ...state,
                putHealthHistoryWithoutAccountLoader: false,
                putHealthHistoryWithoutAccountData: action?.payload,
                putHealthHistoryWithoutAccountError: null
            };
        case PUT_HEALTH_HISTORY_WITHOUT_AC_FAIL:
            return {
                ...state,
                putHealthHistoryWithoutAccountLoader: false,
                putHealthHistoryWithoutAccountData: null,
                putHealthHistoryWithoutAccountError: action?.payload
            };

        //register popup
        case REGISTER_POP_UP_REQUEST:
            return {
                ...state,
                openPopUp: action?.payload
            };

        default:
            return {
                ...state
            };
    }
};
export default HealthHistoryWithoutAcReducer;