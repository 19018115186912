import React from 'react';
import {Box} from "@mui/material";
import Furcation1 from "../../../../../../../../assets/images/periogramFindings/furcation1.svg";
import Furcation2 from "../../../../../../../../assets/images/periogramFindings/furcation2.svg";
import Furcation3 from "../../../../../../../../assets/images/periogramFindings/furcation3.svg";

type Props = {
    isFindingPresent: (position: string, type: string) => number;
    lingual?: boolean;
    bottomJaw: boolean;
}
const FurcationFindings = React.memo((props: Props) => {
    const {isFindingPresent, lingual, bottomJaw} = props
    const furcationValue = isFindingPresent('', 'furcation');

    const transformValue = bottomJaw
        ? (!lingual ? '' : 'rotate(180deg)')
        : !lingual ? 'rotate(180deg)' : '';

    const imgTransform = (lingual && !bottomJaw) || (!lingual && bottomJaw)
        ? 'rotate(180deg)'
        : '';

    const getFurcationIcons = (val?: any) => {
        const normalizedVal = parseInt(val, 10); // Normalize val to an integer
        return normalizedVal === 1
            ? Furcation1
            : normalizedVal === 2
                ? Furcation2
                : Furcation3;
    }
    if (furcationValue <= 0) return null;

    return (
        <Box
            className='pos-absolute d-flex align-flex-end'
            sx={{
                bottom: 5,
                zIndex: 1,
                transform: transformValue,
            }}
        >
            <img
                style={{transform: imgTransform}}
                src={getFurcationIcons(furcationValue)}
                alt={'furcation'}
                width={10}
                height={10}
            />
        </Box>
    )
})

export default FurcationFindings;