import {
  ADD_TREATMENT,
  GET_KEY_FINDING_DATA_REQUEST,
  GET_KEY_FINDING_DATA_SUCCESS,
  GET_KEY_FINDING_DATA_FAIL,
} from '../../../../actionTypes/dentist/clinicalExam/treatment/keyFinding';


export const addTreatment = (payload: any): any => ({
  type: ADD_TREATMENT,
  payload
});

// Get Key Finding data api actions
export const getKeyFindingDataAction = (payload: any): any => ({
  type: GET_KEY_FINDING_DATA_REQUEST,
  payload
});

export const getKeyFindingDataSuccessAction = (payload: any): any => ({
  type: GET_KEY_FINDING_DATA_SUCCESS,
  payload
});

export const getKeyFindingDataFailAction = (): any => ({
  type: GET_KEY_FINDING_DATA_FAIL
});

