import {Box, Slider, Typography} from '@mui/material';
import React from 'react';
import theme from '../../../../theme';
import InputHeading from '../InputHeading';

type Props = {
    onChange?: any;
    sliderTitle?: string;
    leftLabel?: string;
    rightLabel?: string;
    sliderProps?: any;
    sx?: any;
    disabled?: boolean;
};

const RangeSlider = React.memo((props: Props) => {
    const {onChange, sliderTitle, leftLabel, rightLabel, sliderProps, sx, disabled} = props as Props;

    const changeValue = (e: any) => {
        onChange(e);
    };

    return (
        <React.Fragment>
            <InputHeading
                title={sliderTitle}
                whiteSpace={true}
                titleProps={{
                    sx: {
                        marginBottom: '12px',
                        whiteSpace: {
                            xs: 'break-spaces',
                        },
                    },

                }}
                // disabled={disabled}
            />
            {(leftLabel || rightLabel) && (
                <Box mb={{xs: 0, sm: 1}} className={'d-flex justify-content-between'}>
                    <Typography color={theme.palette.text.darkGrey} className={`f-14 lh-19 fw-regular f-w-400`}>
                        {leftLabel}
                    </Typography>
                    <Typography color={theme.palette.text.darkGrey} className={`f-14 lh-19 fw-regular f-w-400`}>
                        {rightLabel}
                    </Typography>
                </Box>
            )}
            <Box px={{xs: 1, sm: 2.5}} className='d-flex justify-center'>
                <Box className='w-100'>
                    <Slider
                        sx={{
                            margin: '0 0px 10px 0px',
                            padding: '14px 0px',
                            color: theme.palette.v2.primary.main,
                            '.MuiSlider-mark': {
                                width: '0',
                                height: '0'
                            },
                            '.MuiSlider-markLabel': {
                                marginTop: '2px',
                                color: theme.palette.text.darkGrey,
                                fontFamily: 'FavoritPro-Regular !important'
                            },
                            ...sx
                        }}
                        disabled={disabled}
                        aria-label='none'
                        track='normal'
                        step={1}
                        min={0}
                        max={10}
                        {...sliderProps}
                        onChangeCommitted={(_: any, n: any) => {
                            changeValue(n);
                        }}
                        valueLabelDisplay='off'
                    />
                </Box>
            </Box>
        </React.Fragment>
    );
})

export default RangeSlider;
