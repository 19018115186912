import {
  PRIVACY_POLICY_ACCEPT_REQUEST,
  PRIVACY_POLICY_ACCEPT_REQUEST_FAILURE,
  PRIVACY_POLICY_ACCEPT_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {
  PrivacyPolicyRequest,
  PrivacyPolicyRequestPayload,
} from '../../types/authActionTypes/privacyPolicyActionTypes';

export const privacyPolicyRequest = (payload: PrivacyPolicyRequestPayload): PrivacyPolicyRequest => ({
  type: PRIVACY_POLICY_ACCEPT_REQUEST,
  payload
});

export const privacyPolicySuccess = () => ({
  type: PRIVACY_POLICY_ACCEPT_REQUEST_SUCCESS
});

export const privacyPolicyFailure = () => ({
  type: PRIVACY_POLICY_ACCEPT_REQUEST_FAILURE
});
