import React, {useEffect, useState} from 'react'
import {Box, Tooltip, Typography, useMediaQuery} from '@mui/material'
import {PieChart, Pie, Cell} from "recharts";
import {makeStyles} from '@mui/styles';
import {useSelector} from 'react-redux';
import theme from '../../../../theme';
import {discData} from '../../../../utils/constant/discData';
import CardComponent from '../../../common/UIComponents/CardComponent'
import {RootState} from '../../../../redux/reducers/rootReducer';

const useStyles = makeStyles((theme: any) => ({
    chartHeader: {
        fontSize: "14px",
        color: theme.palette.common.black50,
        lineHeight: "16px",
        marginBottom: "8px",
    },
    chartDescription: {
        color: theme.palette.common.black50,
        fontSize: "12px",
        marginBottom: "12px",
        lineHeight: "16px",
    },
    cellData: {
        outline: "none",
        "&:hover": {
            backgroundColor: "red",
        },
    },
    customTooltip: {
        padding: "10px",
        color: theme.palette.common.white,
        marginTop: "-10px",
        width: "380px",
        borderRadius: "8px",
        [theme.breakpoints.down('md')]: {
            width: '320px',
            marginLeft: '-10px',
            height: 'fit-content',
            marginTop: '-50px'
        }
    },
    tooltip: {
        backgroundColor: 'transparent'
    }
}));

const RADIAN = Math.PI / 180;

const WellnessDiscProfile = () => {
    const classes = useStyles();
    const [activeIndex, setActiveIndex]: any = useState()
    const {wellnessProfileFilters: {wellnessProfileData},} = useSelector((state: RootState) => state);

    useEffect(() => {
        if (wellnessProfileData?.disc_profile) {
            wellnessProfileData.disc_profile === "I" && setActiveIndex(0);
            wellnessProfileData.disc_profile === "D" && setActiveIndex(1);
            wellnessProfileData.disc_profile === "C" && setActiveIndex(2);
            wellnessProfileData.disc_profile === "S" && setActiveIndex(3);
        }
    }, [wellnessProfileData]);

    const renderCustomizedLabel = ({
                                       cx,
                                       cy,
                                       midAngle,
                                       innerRadius,
                                       outerRadius,
                                       name,
                                       index
                                   }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        const payload = discData
        return (
            <Tooltip classes={{tooltip: classes.tooltip}} title={
                <Box
                    sx={{backgroundColor: payload[index].lightColor}}
                    className={`${classes.customTooltip} fw-regular`}
                >
                    <Typography
                        fontSize={"18px"}
                        color={theme.palette.common.black50}
                        className={"fw-medium"}
                        mb={"16px"}
                        textTransform={"uppercase"}
                    >
                        {payload[index]?.title}
                    </Typography>
                    <Typography className={`${classes.chartHeader} fw-regular`}>
                        Priorities:
                    </Typography>
                    <Typography className={`${classes.chartDescription} fw-regular`}>
                        {payload[index]?.priorities}
                    </Typography>
                    <Typography className={`${classes.chartHeader} fw-regular`}>
                        Motivated by:
                    </Typography>
                    <Typography className={`${classes.chartDescription} fw-regular`}>
                        {payload[index]?.motivatedBy}
                    </Typography>
                    <Typography className={`${classes.chartHeader} fw-regular`}>
                        Fears:
                    </Typography>
                    <Typography className={`${classes.chartDescription} fw-regular`}>
                        {payload[index]?.fear}
                    </Typography>
                    <Typography className={`${classes.chartHeader} fw-regular`}>
                        You will notice:
                    </Typography>
                    <Typography className={`${classes.chartDescription} fw-regular`}>
                        {payload[index]?.notice}
                    </Typography>
                    <Typography className={`${classes.chartHeader} fw-regular`}>
                        Limitations:
                    </Typography>
                    <Typography className={`${classes.chartDescription} fw-regular`}>
                        {payload[index]?.limitations}
                    </Typography>
                </Box>}>
                <text
                    x={x}
                    y={y}
                    fill="#FFFFFF"
                    textAnchor={x > cx ? "start" : "end"}
                    dominantBaseline="central"
                    fontSize={22}
                    fontWeight={700}
                    transform={"rotate(-50deg)"}
                >
                    {name}
                </text>
            </Tooltip>
        );
    };

    const isLargeScreen = useMediaQuery(theme.breakpoints.up(1541));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between(1116, 1540));
    const isSmallScreen = useMediaQuery(theme.breakpoints.between(950, 1116));
    const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down(650));

    const getChartDimensions = () => {
        let chartWidth = 170;
        let chartHeight = 170;
        if (isLargeScreen) {
            chartWidth =180;
            chartHeight = 180;
        } else if (isMediumScreen) {
            chartWidth = 170;
            chartHeight = 170;
        } else if (isSmallScreen) {
            chartWidth = 200;
            chartHeight = 180;
        } else if (isExtraSmallScreen) {
            chartWidth = 210;
            chartHeight = 210;
        }
        return {chartWidth, chartHeight};
    };

    const {chartWidth, chartHeight} = getChartDimensions();
    const isWideScreen = useMediaQuery(theme.breakpoints.down(650));
    const height: number = isWideScreen ? 255 : 230;
    return (
        <CardComponent bgColor={theme.palette.v2.primary.primary25} color={theme.palette.v2.primary.main}
                       title='Disc Profile' height={height}>
            <Box style={{height: "fit-content"}} width={'100%'} display={'flex'} justifyContent={'center'}
                 alignItems={'center'}>
                <PieChart
                    width={chartWidth}
                    height={chartHeight}
                >
                    <Pie
                        data={discData}
                        labelLine={false}
                        label={({
                                    cx,
                                    cy,
                                    midAngle,
                                    innerRadius,
                                    outerRadius,
                                    name,
                                    index
                                }) =>
                            renderCustomizedLabel({
                                cx,
                                cy,
                                midAngle,
                                innerRadius,
                                outerRadius,
                                name,
                                index
                            })
                        }
                        fill="#FFFFFF"
                        dataKey="value"
                        activeIndex={activeIndex}
                        height={"155px"}
                        width={"155px"}
                        strokeWidth={5}
                    >
                        {discData?.map((entry: any, index: any) => (
                            <Cell
                                className={classes.cellData}
                                key={`cell-${index}`}
                                fill={
                                    activeIndex === index
                                        ? entry.color
                                        : "#9899A1"
                                }
                            />
                        ))}
                    </Pie>
                </PieChart>
            </Box>
        </CardComponent>
    )
}

export default WellnessDiscProfile