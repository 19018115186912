import React from 'react';
import {Box} from "@mui/material";
import {makeStyles} from "@mui/styles";
import BopIcon from "../../../../../../../../assets/images/periogramFindings/bop.svg";

const useStyles = makeStyles(() => ({
    isBOPIconContainer: {
        top: '-5px',
        position: 'absolute',
        zIndex: 1024
    },
}));

type Props = {
    isFindingPresent: (position: string, type: string) => boolean;
    lingual?: boolean;
    bottomJaw: boolean;
}
const BOPFinding = React.memo((props: Props) => {
    const classes = useStyles();
    const {isFindingPresent, lingual, bottomJaw,} = props
    const positions = [
        ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'right' : 'left',
        'center',
        ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'left' : 'right'
    ];

    return (
        <Box width={42} className={`d-flex align-center justify-content-between ${classes.isBOPIconContainer}`}>
            {positions.map((position, index) => {
                const show = isFindingPresent(position, 'bleed_on_probing');
                return (
                    <Box key={index} height={'10px'} width={'10px'}>
                        {show && (
                            <img
                                style={{transform: ((lingual && !bottomJaw) || (!lingual && bottomJaw)) ? 'rotate(180deg)' : ''}}
                                src={BopIcon}
                                alt={'bleed_on_probing'}
                                width={10}
                                height={10}
                            />
                        )}
                    </Box>
                );
            })}
        </Box>
    )
})

export default BOPFinding