import React, {useRef} from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExpandAlt, faMaximize, faMinimize, faMinus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {makeStyles} from "@mui/styles";
import {isArray} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import AccordionDetails from "@mui/material/AccordionDetails";
import theme from "../../../../../../theme";
import {
    hardTissue,
    limitedExam, medical,
    odontogram,
    photo,
    softTissue,
    xray
} from "../../../../../../utils/constant/clinicalExamConstant";
import {RootState} from "../../../../../../redux/reducers/rootReducer";
import {setExamFindingsAction} from "../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import {
    handleOdontogramFormFindingSelectionAction
} from "../../../../../../redux/actions/dentist/clinicalExam/odontogramAction";

const useStyles = makeStyles((theme: any) => ({
    accordionRoot: {
        position: 'absolute',
        width: '25%',
        [theme.breakpoints.down('lg')]: {
            width: '33.33% !important'
        },
        right: 0
    },
    accordionSummary: {
        color: theme.palette.common.white,
        height: '36px',
        backgroundColor: `${theme.palette.v2.primary.main} !important`,
        cursor: 'auto !important'
    },
    accordionDetails: {
        padding: '1px 0px 0px !important',
        backgroundColor: theme.palette.common.white
    },
    expanded: {
        '&$expanded': {
            minHeight: 36,
            maxHeight: 36
        }
    },
    accordionRootForm: {
        margin: '0 !important',
        '& .MuiAccordion-root': {
            margin: '0 !important'
        }
    },
    accordionSummaryForm: {
        color: theme.palette.v2.primary.main,
        height: '42px',
        backgroundColor: theme.palette.v2.primary.primary25,
        padding: '10px 12px',
        border: `1px solid ${theme.palette.v2.primary.main}`
    },
    accordionDetailsForm: {
        padding: '16px 20px !important',
        backgroundColor: theme.palette.common.white
    },
    expandedForm: {
        '&$expanded': {
            minHeight: 42,
            maxHeight: 42,
        }
    },
}));

type Props = {
    showFooter: boolean
    handleExpanded:any
    handleFullSize: any
    children: any
}
const ExamFormAccordion = (props: Props) => {
    const {showFooter, handleExpanded, handleFullSize, children} = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const examFormRef = useRef(null) as any
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const {
        odontogram: {selectedFormFinding},
        examFindingReducer: {examFindingData},
        wellnessProfileFilters: {wellnessProfileStep},
        clinicalExamFilters: {
            findingType,
            examAccordionExpanded,
            examFormSize,
            openSpeedometerClinical,
            selectedImage
        },
    } = useSelector((state: RootState) => state) as any
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhotoXray = tabData === photo || tabData === xray
    const isIconDisplay = () => {
        if (isWellnessProfile) {
            return !(wellnessProfileStep === softTissue || wellnessProfileStep === hardTissue);
        } else return !(findingType === medical || findingType === limitedExam);
    }
    const handleDeleteFindings = () => {
        let updatedExamFindingData = examFindingData as any
        selectedFormFinding?.map((fin: any) => {
            if (fin?.exam_group && fin?.key) {
                if (fin?.key?.includes('missing_tooth')) {
                    const toothToBeDelete = selectedFormFinding?.filter((f: any) => f.key === fin.key).map((tn: any) => tn?.tooth_number) || [] as any
                    //Put the conditions' coz sometime toots founded as array and sometime as a string. (Need to be fixed)
                    if (isArray(updatedExamFindingData[fin.exam_group][fin.key])) {
                        updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key].filter((f: string) => !toothToBeDelete?.includes(f))
                    } else {
                        updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key]?.split(',').filter((f: string) => !toothToBeDelete?.includes(f))
                    }
                } else {
                    updatedExamFindingData[fin.exam_group][fin.key] = updatedExamFindingData[fin.exam_group][fin.key].filter((f: any) => Object.keys(f).filter((ff: string) => JSON.stringify(f[ff]) === JSON.stringify(fin[ff])).length !== Object.keys(f).length)
                }
            }
            return true
        })
        dispatch(setExamFindingsAction(updatedExamFindingData))
        dispatch(handleOdontogramFormFindingSelectionAction([]))
    }
    return (
        <Accordion expanded={examAccordionExpanded} className={examAccordionExpanded ? '' : classes.accordionRoot}
                   sx={{bottom: showFooter && !examAccordionExpanded ? 80 : 0}}>
            <AccordionSummary
                expandIcon={
                    (isIconDisplay()) &&
                    <FontAwesomeIcon
                        className='cursor-pointer f-16'
                        onClick={handleExpanded}
                        icon={(examAccordionExpanded ? faMinus : faExpandAlt)}
                        color={theme.palette.common.white}
                    />
                }
                className={`${classes.accordionSummary} ${classes.expanded}`}
            >
                <Box className='d-flex justify-content-between align-center w-100' mr={2.5}>
                    <Typography className='f-18 f-w-500 fw-medium'>Findings</Typography>
                    <Box>
                        {(selectedFormFinding?.length > 0 && (tabData === odontogram)) &&
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={handleDeleteFindings}
                                className='cursor-pointer f-16 pr-10'
                            />
                        }
                        {(isIconDisplay()) &&
                            <FontAwesomeIcon icon={examFormSize ? faMinimize : faMaximize} onClick={handleFullSize}
                                             className='cursor-pointer f-16'/>}
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails
                className={classes.accordionDetails}
                sx={{
                    height: isWellnessProfile ? ((isPhotoXray && selectedImage) ? `calc(100vh - 168px)` : `calc(100vh - 261px)`) : showFooter ? `calc(100vh - ${openSpeedometerClinical ? '518px' : '366px'}) !important` : `calc(100vh - ${openSpeedometerClinical ? '436px' : '284px'}) !important`,
                    overflow: 'scroll  !important',
                }}
                ref={examFormRef}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default ExamFormAccordion;