import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../utils/api";
import {GET_PATIENT_LIST_REQUEST} from "../../actionTypes/dentist/patientsList";
import {
    getPatientListRequestFailure,
    getPatientListRequestSuccess
} from "../../actions/dentist/patientsListActions";
import {ApiEndpointVersion, checkAPIParams} from "../../../utils/helper";

function* patientsListRequestSaga(payload: any): any {
    try {
        const params = [
            {key: 'search', value: payload.payload.search},
            {key: 'page', value: payload.payload.page},
            {key: 'size', value: payload.payload.size},
        ]
        if (payload.payload.clinic_id) {
            params.push({key: 'clinic_id', value: payload.payload.clinic_id})
        }
        const response = yield API.get(`/${ApiEndpointVersion}/patient/get/all${checkAPIParams(params)}`)
        yield put(
            getPatientListRequestSuccess({
                getPatientData: response?.data?.detail,
                getPatientMeta: response?.data?.meta
            })
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response?.data?.detail);
        }
    } catch (e: any) {
        yield put(
            getPatientListRequestFailure({})
        );
    }
}

function* patientsListRequestWatch() {
    yield takeEvery(GET_PATIENT_LIST_REQUEST, patientsListRequestSaga);
}

export default function* patientsListSaga() {
    yield all([patientsListRequestWatch()]);
}
