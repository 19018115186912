import {Box} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    setExamFindingsAction
} from "../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import {selectOdontrogramToothAction} from "../../../../../../redux/actions/dentist/clinicalExam/odontogramAction";
import {getLocalStorageItem, stringToArray, toothNumberPreferences} from '../../../../../../utils/helper';
import {completeCheckout, viewExam} from '../../../../../../utils/constant/clinicalExamConstant';

const useStyles = makeStyles((theme: any) => ({
    toothNumberWrapper: {
        backgroundColor: theme.palette.common.burgundy,
        width: '38px',
        height: '36px',
        borderRadius: '50%',
        fontSize: 16,
    },
}));

type Prop = {
    tooth: number,
    periogramBottom?: boolean
    fromPeriogram?: boolean
}

const ToothNumberWrapper = React.memo((props: Prop) => {
    const {tooth, periogramBottom, fromPeriogram} = props
    const classes = useStyles();
    const dispatch = useDispatch()
    const bottomJaw = tooth > 28
    const {
        odontogram: {teeth, finding, cameOdontogramFromTreatment},
        examFindingReducer: {examFindingData},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {wellnessProfileData}
    } = useSelector((state: any) => state);

    const clinicInfo = getLocalStorageItem('clinicInfo') as any

    const handleToothSelection = () => {
        if (finding) {
            let updatedExamFindingData = JSON.parse(
                JSON.stringify(examFindingData)
            ) as any;
            if (finding === 'missing_tooth') {
                const selectedTeeth = JSON.parse(JSON.stringify(updatedExamFindingData.occlusion_exam[`${finding}_tooth_number`] || [])) as string[]
                selectedTeeth.push(`${tooth}`)
                updatedExamFindingData.occlusion_exam[`${finding}_tooth_number`] = selectedTeeth
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        } else {
            let selectedTeeth = JSON.parse(JSON.stringify(teeth)) as any
            if (selectedTeeth.includes(tooth)) {
                selectedTeeth = selectedTeeth.filter((f: number) => f !== tooth)
            } else {
                selectedTeeth.push(tooth)
            }
            dispatch(selectOdontrogramToothAction(selectedTeeth))
        }
    }

    // FUTURE USE
    // const imageUrl = () => {
    //   return FindingList?.filter((find: any) => find?.key === finding && find)
    // }

    const disabledField = useMemo(() => {
        return (
            !cameOdontogramFromTreatment &&
            ([completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || fromPeriogram || wellnessProfileData?.is_wellness_locked
            ))
    }, [cameOdontogramFromTreatment, fromPeriogram, appointmentDetails, wellnessProfileData?.is_wellness_locked]);

    const disabledMissingTooth = () => {
        let selectedTeeth: any
        if (examFindingData && examFindingData?.occlusion_exam && examFindingData?.occlusion_exam[`missing_tooth_tooth_number`]) {
            if (typeof examFindingData?.occlusion_exam[`missing_tooth_tooth_number`] === 'string') {
                selectedTeeth = stringToArray(examFindingData?.occlusion_exam[`missing_tooth_tooth_number`]) || [] as string[]
            } else {
                selectedTeeth = examFindingData?.occlusion_exam[`missing_tooth_tooth_number`] || [] as string[]
            }
            return selectedTeeth?.includes(`${tooth}`)
        }
    }

    return (
        <Box
            sx={{
                transform: bottomJaw && periogramBottom ? 'scaleX(-1)' : '',
            }}
            my={1}
            className={`d-flex-all-center cursor-pointer ${disabledField || disabledMissingTooth() ? "cursor-default" : 'cursor-pointer'}`}
        >
            <Box
                className={classes.toothNumberWrapper + ' d-flex-all-center'}
                onClick={() => (!disabledField && !disabledMissingTooth()) && handleToothSelection()}
            >
                {/* Split and join to add '-' between the tooth numbers */}
                {clinicInfo?.tooth_numbering_format === "CAN" ? toothNumberPreferences(tooth)?.toString().split('').join('-') : toothNumberPreferences(tooth)}
            </Box>
        </Box>
    );
})

export default ToothNumberWrapper;
