import React from 'react';
import {Box} from "@mui/material";
import Button from "@mui/material/Button";
import {makeStyles} from "@mui/styles";
import {useDispatch, useSelector} from "react-redux";
import {photo} from "../../../../../../../utils/constant/clinicalExamConstant";
import {
    addAdditionalImageXrayAction,
    selectPhotosAction
} from "../../../../../../../redux/actions/dentist/clinicalExam/exam/uploadPhotosXrayAction";
import ButtonContained from "../../../../../../common/UIComponents/ButtonContained";

const useStyles = makeStyles((theme: any) => ({
    skipPhotos: {
        color: theme.palette.v2.secondary,
        marginRight: '10px',
        '&:hover': {
            background: 'transparent',
            color: theme.palette.v2.secondary,
        }
    },

    cancelBtn: {
        height: '40px',
        padding: '8.5px 20px',
        border: `1px solid ${theme.palette.v2.primary.main}`,
        color: theme.palette.v2.primary.main,
        '&:hover': {
            backgroundColor: 'transparent',
            borderColor: theme.palette.v2.primary.main
        }
    },

}));


type Props = {
    openCamera: boolean
    handleClose: () => void
    cancelAdditional: () => void
    getIndexOfSelectedPhoto: number
    getSelectedImageData: any
    uploadImagesFromCamera: () => void
    hideTakePhotosButton: boolean
    displayStartTakingPhotosButton: boolean
    auto: boolean
    skipPhotos: () => void
    setOpenCamera: any
}
const UploadPhotosXrayFooter = (props: Props) => {
    const {
        openCamera,
        handleClose,
        cancelAdditional,
        getIndexOfSelectedPhoto,
        getSelectedImageData,
        uploadImagesFromCamera,
        hideTakePhotosButton,
        displayStartTakingPhotosButton,
        auto,
        skipPhotos,
        setOpenCamera
    } = props
    const dispatch = useDispatch()
    const classes = useStyles()
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const {
        uploadPhotoXray: {
            selectPhotosData,
            addAdditionalImageXray,
        },
        clinicalExamFilters: {findingType},
        wellnessProfileFilters: {wellnessProfileStep}

    } = useSelector((state: any) => state);
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhoto = tabData === photo


    const addAdditionalImageXrayFunc = () => {
        dispatch(addAdditionalImageXrayAction(true))
        dispatch(selectPhotosAction(null))
    }
    const closeCamera = () => {
        setOpenCamera(false)
    }

    return (
        <React.Fragment>
            <Box>
                {!addAdditionalImageXray ?
                    <Button variant='outlined' onClick={openCamera ? closeCamera : handleClose}
                            className={classes.cancelBtn}>
                        {openCamera ? 'Stop process ' : 'Close'}
                    </Button>
                    :
                    < Button variant='outlined' onClick={cancelAdditional} className={classes.cancelBtn}>
                        cancel
                    </Button>
                }
                {!openCamera &&
                    <ButtonContained
                        disabled={addAdditionalImageXray}
                        handleClick={addAdditionalImageXrayFunc}
                        btnName={'Add additional'}
                        sx={{maxWidth: '180px', marginLeft: '10px',}}
                    />
                }
            </Box>
            {isPhoto &&
                (openCamera ?
                    <Box>
                        {getIndexOfSelectedPhoto < 22 && auto && openCamera && !addAdditionalImageXray &&
                            <Button variant='text' className={classes.skipPhotos} onClick={skipPhotos}>Skip
                                Photo</Button>}
                        {((selectPhotosData?.image_id === undefined && getSelectedImageData === undefined) ||
                                (getSelectedImageData?.ID === selectPhotosData?.image_id)) &&
                            <ButtonContained
                                disabled={hideTakePhotosButton}
                                handleClick={uploadImagesFromCamera}
                                btnName={'Take Photo'}
                                sx={{width: '170px', marginLeft: '10px'}}
                                isPatient
                            />
                        }
                    </Box>
                    : ((displayStartTakingPhotosButton)
                        &&
                        <ButtonContained
                            disabled={addAdditionalImageXray}
                            handleClick={() => setOpenCamera(true)}
                            btnName={'Start taking photos'}
                            sx={{maxWidth: '180px', marginLeft: '10px',}}
                        />
                    ))
            }
        </React.Fragment>
    )
}

export default UploadPhotosXrayFooter;