import { all, put, takeEvery } from 'redux-saga/effects';
import API from '../../../../utils/api';
import { downloadPdfRequestFailure, downloadPdfRequestSuccess } from '../../../actions/patient/downloadPdf/downloadPdf';
import { DOWNLOAD_PDF_REQUEST } from '../../../actionTypes/patient/downloadPdf';
import {ApiEndpointVersion} from "../../../../utils/helper";

function* downloadPdfRequestSaga(payload: any): any {
  try {
    const response = yield API.get(
      payload.payload.appointmentID
        ? `${ApiEndpointVersion}/appointments/pdf/download/${payload.payload.appointmentID}`
        : `${ApiEndpointVersion}/appointments/download/pdf/${payload.payload.patientID}?clinic_id=${payload?.payload?.clinicID}`
    );
    yield put(
      downloadPdfRequestSuccess({
        downloadPdfData: response.data
      })
    );
    if (payload.payload.callback) {
      payload.payload.callback(response.data);
    }
  } catch (e: any) {
    //TODO: update toast according to status code
    //   errorToaster(e?.meta?.message)
    yield put(
      downloadPdfRequestFailure({
        downloadPdfError: e?.detail
      })
    );
  }
}

function* downloadPdfSagaRequest() {
  yield takeEvery(DOWNLOAD_PDF_REQUEST, downloadPdfRequestSaga);
}

export default function* downloadPdfSaga() {
  yield all([downloadPdfSagaRequest()]);
}
