import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducers from '../reducers/rootReducer';
import { sagas } from '../sagas/rootSaga';

export function* rootSaga() {
  yield all([...sagas]);
}

const rootReducer = combineReducers({ ...rootReducers });

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
export default store;
