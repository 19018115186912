import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_CACHE_DATA_CLEAR_REQUEST,
  PUT_NOTIFICATION_REQUEST,
  PUT_NOTIFICATION_SUCCESS,
  PUT_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_FAILURE,
  GET_NOTIFICATION_COUNT_CACHE_DATA_CLEAR_REQUEST
} from '../../actionTypes/dentist/notificationListing';
import {
  GetNotificationTypeRequestPayload,
  GetNotificationTypeRequest,
  GetNotificationTypeSuccessPayload,
  GetNotificationTypeSuccess,
  GetNotificationTypeFailurePayload,
  GetNotificationTypeFailure,
  PutNotificationTypeRequestPayload,
  PutNotificationTypeRequest,
  PutNotificationTypeSuccessPayload,
  PuNotificationTypeSuccess,
  PutNotificationTypeFailurePayload,
  PutNotificationTypeFailure,
  getNotificationCountTypeSuccessPayload,
  getNotificationCountTypeSuccess,
  getNotificationCountTypeFailurePayload,
  getNotificationCountTypeFailure
} from '../../types/dentist/notificationListing';

//get notification list
export const getNotificationListTypeRequestAction = (payload: GetNotificationTypeRequestPayload): GetNotificationTypeRequest =>
({
  type: GET_NOTIFICATION_REQUEST,
  payload
} as any);

export const getNotificationListTypeSuccessAction = (payload: GetNotificationTypeSuccessPayload): GetNotificationTypeSuccess => {
  return {
    type: GET_NOTIFICATION_SUCCESS,
    payload
  };
};

export const getNotificationListTypeFailureAction = (payload: GetNotificationTypeFailurePayload): GetNotificationTypeFailure =>
({
  type: GET_NOTIFICATION_FAILURE,
  payload
} as any);

export const getNotificationListCacheDataClearRequest = (): any => ({
  type: GET_NOTIFICATION_CACHE_DATA_CLEAR_REQUEST
});

export const putNotificationListTypeRequestAction = (payload: PutNotificationTypeRequestPayload): PutNotificationTypeRequest => ({
  type: PUT_NOTIFICATION_REQUEST,
  payload
});

export const putNotificationListTypeSuccessAction = (payload: PutNotificationTypeSuccessPayload): PuNotificationTypeSuccess => ({
  type: PUT_NOTIFICATION_SUCCESS,
  payload
});

export const putNotificationListTypeFailureAction = (payload: PutNotificationTypeFailurePayload): PutNotificationTypeFailure => ({
  type: PUT_NOTIFICATION_FAILURE,
  payload
});

// TODO: for future use
// export const getNotificationCountTypeRequestAction = (payload: getNotificationCountTypeRequestPayload): getNotificationCountTypeRequest =>
// ({
//   type: GET_NOTIFICATION_COUNT_REQUEST,
//   payload
// } as any);

export const getNotificationCountTypeSuccessAction = (payload: getNotificationCountTypeSuccessPayload): getNotificationCountTypeSuccess => {
  return {
    type: GET_NOTIFICATION_COUNT_SUCCESS,
    payload
  };
};

export const getNotificationCountTypeFailureAction = (payload: getNotificationCountTypeFailurePayload): getNotificationCountTypeFailure =>
({
  type: GET_NOTIFICATION_COUNT_FAILURE,
  payload
} as any);

export const getNotificationCountCacheDataClearRequest = (): any => ({
  type: GET_NOTIFICATION_COUNT_CACHE_DATA_CLEAR_REQUEST
});
