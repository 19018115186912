import {
    FORGOT_USERNAME_V2_REQUEST,
    FORGOT_USERNAME_V2_REQUEST_SUCCESS,
    FORGOT_USERNAME_V2_REQUEST_FAILURE,
    MULTIPLE_NUMBER_POPUP_REQUEST,

    PATIENT_CHANGE_PASSWORD_REQUEST,
    PATIENT_CHANGE_PASSWORD_REQUEST_SUCCESS,
    PATIENT_CHANGE_PASSWORD_REQUEST_FAILURE,

} from '../../actionTypes/patient/auth';

import {
    ForgotUserNameRequest,
    ForgotUserNameRequestFailure,
    ForgotUserNameRequestFailurePayload,
    ForgotUserNameRequestPayload,
    ForgotUserNameRequestSuccess,
    ForgotUserNameRequestSuccessPayload,
    MultipleNumberPopUpRequest,
    ChangePasswordRequestPayload,
    ChangePasswordRequest,
    ChangePasswordRequestSuccessPayload,
    ChangePasswordRequestSuccess,
    ChangePasswordRequestFailurePayload,
    ChangePasswordRequestFailure,

} from '../../types/patient/auth/authActionType';

export const forgotUserNameRequest = (payload: ForgotUserNameRequestPayload): ForgotUserNameRequest => ({
    type: FORGOT_USERNAME_V2_REQUEST,
    payload
});

export const forgotUserNameRequestSuccess = (payload: ForgotUserNameRequestSuccessPayload): ForgotUserNameRequestSuccess => ({
    type: FORGOT_USERNAME_V2_REQUEST_SUCCESS,
    payload
});

export const forgotUserNameRequestFailure = (payload: ForgotUserNameRequestFailurePayload): ForgotUserNameRequestFailure => ({
    type: FORGOT_USERNAME_V2_REQUEST_FAILURE,
    payload
});

export const multipleNumberPopUp = (payload: any): MultipleNumberPopUpRequest => ({
    type: MULTIPLE_NUMBER_POPUP_REQUEST,
    payload
});



//change password
export const patientChangePasswordRequest = (payload: ChangePasswordRequestPayload): ChangePasswordRequest => ({
    type: PATIENT_CHANGE_PASSWORD_REQUEST,
    payload,
});

export const patientChangePasswordRequestSuccess = (
    payload: ChangePasswordRequestSuccessPayload
): ChangePasswordRequestSuccess => ({
    type: PATIENT_CHANGE_PASSWORD_REQUEST_SUCCESS,
    payload
});

export const patientChangePasswordRequestFailure = (
    payload: ChangePasswordRequestFailurePayload
): ChangePasswordRequestFailure => ({
    type: PATIENT_CHANGE_PASSWORD_REQUEST_FAILURE,
    payload
});


