import { Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import photoSuccessfully from '../../../../../../../assets/images/refPhotos/photoSuccessfully.png';
import theme from '../../../../../../../theme';
import { photo } from '../../../../../../../utils/constant/clinicalExamConstant';

const UploadSuccessfully = () => {
  const {clinicalExamFilters: { findingType }  } = useSelector((state: any) => state);
  const isPhoto = findingType === photo;
  return (
    <Box
      sx={{ border: '1px dashed #A39BCB' }}
      className='border-radius-8 d-flex-all-center flex-col'
      m={2.5}
      height='calc(100% - 40px)'
    >
      <Typography className='f-20 f-w-500 fw-medium lh-27' color={theme.palette.v2.secondary}>
        Well done!
      </Typography>
      <Typography mb={'50px'} className='f-20 f-w-500 fw-medium lh-27' color={theme.palette.v2.secondary}>
        you have taken all {isPhoto ? 'photos' : 'x-rays'} successfully
      </Typography>
      <Box>
        <img alt='success' src={photoSuccessfully} />
      </Box>
    </Box>
  );
};

export default UploadSuccessfully;
