import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, Typography} from "@mui/material";
import {RootState} from "../../../../../redux/reducers/rootReducer";
import theme from "../../../../../theme";
import NoDataFound from "../../../../common/NoDataFound";
import CheckboxSelect from "../../../../common/UIComponents/CheckboxSelect";
import {
    changeWellnessComplaintsLocalRequestAction,
    updateDentistWellnessComplaintsRequest
} from "../../../../../redux/actions/dentist/wellnessProfile/wellnessProfileFiltersAction";
import {useParams} from "react-router-dom";
import {dentistProfileData} from "../../../../../utils/helper";

const ChiefComplaints = () => {
    const dispatch = useDispatch()
    const takeFromUrl= useParams()
    const {wellnessProfileFilters: {wellnessComplaintsLocal,wellnessProfileData}} = useSelector((state: RootState) => state);

    const handleCheckBoxChange = (event: any, data: any,isRecent?:boolean) => {
        const {checked} = event.target;
        const {appointment_id} = data;
        if (wellnessComplaintsLocal?.present && wellnessComplaintsLocal?.present?.appointment_id === appointment_id) {
            const updatedData = {
                ...wellnessComplaintsLocal?.present,
                is_complaint: checked ? 1 : 0
            };
            dispatch(updateDentistWellnessComplaintsRequest({
                appointment_id: data?.appointment_id,
                patient_id: takeFromUrl?.patientId,
                profile_id: dentistProfileData?.id,
                isRecent,
                callback: () => {
                    dispatch(changeWellnessComplaintsLocalRequestAction({
                        ...wellnessComplaintsLocal,
                        present: updatedData
                    }));
                }
            }))
        } else {
            const updatedPast = wellnessComplaintsLocal?.past?.map((item: any) => {
                if (item.appointment_id === appointment_id) {
                    return {
                        ...item,
                        is_complaint: checked ? 1 : 0
                    };
                }
                return item;
            });
            dispatch(updateDentistWellnessComplaintsRequest({
                appointment_id: data?.appointment_id, profile_id: dentistProfileData?.id, callback: () => {
                    dispatch(changeWellnessComplaintsLocalRequestAction({
                        ...wellnessComplaintsLocal,
                        past: updatedPast
                    }));
                }
            }))
        }
    }

    return (
        <React.Fragment>
            {wellnessComplaintsLocal?.present?.chief_complaint || wellnessComplaintsLocal?.past?.length > 0 ?
                <React.Fragment>
                    {wellnessComplaintsLocal?.present?.chief_complaint ? <Box mb={1}>
                        <Typography mb={1} className={'f-14 f-w-400 fw-regular'} color={theme.palette.text.secondary}>
                            Recent
                        </Typography>
                        <Box ml={2} display={'flex'} alignItems={'center'}>
                            <CheckboxSelect
                                sx={{marginBottom: '0px !important', height: '20px !important'}}
                                label={wellnessComplaintsLocal?.present?.chief_complaint}
                                disabled={wellnessProfileData?.is_wellness_locked}
                                checkBoxProps={{
                                    name: 'is_complaint',
                                    checked: wellnessComplaintsLocal?.present?.is_complaint,
                                    onChange: (e: any) => handleCheckBoxChange(e, wellnessComplaintsLocal?.present,true),
                                }}
                            />
                        </Box>
                    </Box> : ''}
                    {wellnessComplaintsLocal?.past?.length > 0 ?
                        <Box>
                            <Typography mb={1} className={'f-14 f-w-400 fw-regular'}
                                        color={theme.palette.text.secondary}>Past</Typography>
                            {wellnessComplaintsLocal?.past?.map((item: any, index: number) =>
                                <Box key={index} ml={2} display={'flex'} alignItems={'center'} mb={1}>
                                    <CheckboxSelect
                                        sx={{marginBottom: '0px !important', height: '20px !important'}}
                                        label={`${item?.chief_complaint ? item?.chief_complaint : ''} (${item?.date})`}
                                        checkBoxProps={{
                                            name: 'is_complaint',
                                            checked: item?.is_complaint,
                                            onChange: (e: any) => handleCheckBoxChange(e, item),
                                        }}
                                    />
                                </Box>
                            )}
                        </Box> : ''}
                </React.Fragment>
                : <NoDataFound errorMessage={'data'}/>}
        </React.Fragment>
    )
}
export default ChiefComplaints;