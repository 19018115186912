import { Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageMarker from "react-image-marker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { selectImageAnnotationAction } from "../../../../../redux/actions/dentist/clinicalExam/clinicalExamFilterAction";
import { setExamFindingsAction } from "../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import { RootState } from "../../../../../redux/reducers/rootReducer";
import { examList, getUniqId } from "../../../../../utils/helper";
import { annotationData, findingData, findingTypes,  } from "../../../../../utils/constant/examConstant";
import {
    getAnnotationRequestAction,
    selectAnnotationAction,
    selectMarkerAction
} from "../../../../../redux/actions/dentist/clinicalExam/exam/annotationAction";
import theme from '../../../../../theme';
import FullScreenLoader from '../../../../common/FullScreenLoader';
import { completeCheckout, viewExam } from '../../../../../utils/constant/clinicalExamConstant';

const useStyles = makeStyles((theme: any) => ({
    annotationExamBox: {
        boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.3)",
    },
    annotationExamBoxContainer: {
        background: `${theme.palette.divider} !important`,
        borderWidth: "1px 1px 0px 0px !important",
        borderStyle: "solid !important",
        borderColor: `${theme.palette.divider} !important`,
    },
    backButton: {
        width: 190,
        zIndex: 1,
        color: theme.palette.v2.primary.main,
        '&:hover': {
            background: theme.palette.common.white,
            color: theme.palette.v2.primary.main,
        },
    },
    nextPrevButton: {
        textTransform: "none",
        color: theme.palette.v2.primary.main,
        border: `1px solid ${theme.palette.v2.primary.main}`
    }
}));
const Annotation = React.memo(() => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        appointmentsReducer: { appointmentDetails },
        examFindingReducer: { examFindingData },
        uploadPhotoXray: { photosXrayData, photosXrayLoader },
        clinicalExamFilters: { selectedImage, riskFilters, findingType },
        annotation: { selectAnnotation, selectedMarker, getAnnotationLoader },
    } = useSelector((state: RootState) => state);
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)

    useEffect(() => {
        dispatch(getAnnotationRequestAction(`${selectedImage}`))
    }, [selectedImage,dispatch])

    const getImageData = useMemo(() => {
        return photosXrayData?.filter((f: any) => f?.ID === selectedImage);
    }, [photosXrayData, selectedImage]);

    const selectTag = (tag: any) => {
        dispatch(selectAnnotationAction(tag));
    };

    const addTag = (markerData: any) => {
        let reduxData = examFindingData || ([] as any);
        if (
            (reduxData.length > 0 && reduxData[reduxData?.length - 1].finding) ||
            reduxData.length < 1
        ) {
            reduxData.push({
                x_position: markerData.left,
                y_position: markerData.top,
                title: selectAnnotation.title,
                finding: "",
                key: getUniqId()
            });

        } else {
            reduxData[reduxData.length - 1] = {
                x_position: markerData.left,
                y_position: markerData.top,
                title: selectAnnotation.title,
                finding: "",
                key: getUniqId()
            };
        }
        dispatch(setExamFindingsAction(reduxData));
        dispatch(selectMarkerAction(reduxData[reduxData.length - 1]))
    };

    const handleMarkerUpdate = (markerData: any) => {
        if (selectAnnotation) {
            addTag(markerData);
        }
    };

    const getMarkersFromTag = useMemo(() => {
        if (examFindingData && examFindingData.length > 0) {
            return (
                examFindingData.map((res: any) => {
                    return {
                        top: res.y_position,
                        left: res.x_position,
                        color: examList[res.title].color,
                        title: res.title,
                    };
                }) || []
            );
        }
        return [];
    }, [examFindingData]);

    const getData = useMemo(() => {
        const examType = appointmentDetails?.appointmentType;

        const findingTypeWiseFilteredData = findingData?.filter((f: any) =>
            f?.findingTypes?.includes(findingTypes[findingType])
        ) as any;

        const riskWiseFilteredData = riskFilters.length
            ? (findingTypeWiseFilteredData?.filter((f: any) =>
                f?.risks?.filter((ff: any) => riskFilters?.includes(ff))?.length
            ) as any)
            : findingTypeWiseFilteredData;

        return isWellnessProfile ? riskWiseFilteredData : (riskWiseFilteredData?.filter((f: any) =>
            f?.examType?.includes(examType)
        ))
        // eslint-disable-next-line
    }, [appointmentDetails, findingTypes, findingType, riskFilters,isWellnessProfile]);


    const handleMarkerClick = (e: any) => {
        dispatch(selectMarkerAction(examFindingData?.filter((f: any) => f.x_position === e.left && f.y_position === e.top)[0]))
    }
    const disabledField = () => {
        return [completeCheckout, viewExam]?.includes(appointmentDetails?.sub_status) || isWellnessProfile
    }
    const backToList = () => {
        dispatch(selectAnnotationAction(null));
        dispatch(selectImageAnnotationAction(""))
    }
    // Function to handle next and prev image
    const currentIndex = photosXrayData.findIndex((item: any) => item.ID === selectedImage);
    const navigateImage = (direction: string) => {
        dispatch(selectAnnotationAction(null));
        let newIndex: any
        if (currentIndex !== -1) {
            if (direction === 'next') {
                newIndex = (currentIndex + 1) % photosXrayData?.length;
            } else if (direction === 'prev') {
                newIndex = currentIndex - 1;
                if (newIndex < 0) {
                    newIndex = photosXrayData?.length - 1;
                }
            }
            const newObject = photosXrayData[newIndex];
            dispatch(selectImageAnnotationAction(newObject?.ID));
        }
    };

    const isNextButtonDisabled = currentIndex === photosXrayData?.length - 1;
    const isPrevButtonDisabled = currentIndex === 0;

    return (
        <Box sx={{ height: '100% !important' }}>
            {(getAnnotationLoader || photosXrayLoader) && <FullScreenLoader />}
            <Box>
                <Button
                    className={`${classes.backButton} cursor-pointer`}
                    onClick={backToList}
                    startIcon={<FontAwesomeIcon icon={faChevronLeft} color={theme.palette.v2.primary.main} />}
                >
                    Back to list
                </Button>
            </Box>
            <Grid container>

                <Grid item xs={12} className="d-flex" sx={{ position: 'relative !important' }}>
                    <Box>
                        <Box
                            className={`bg-white ${classes.annotationExamBoxContainer}`}
                            width={40}
                            sx={{ height: '100% !important' }}
                        >
                            {annotationData
                                ?.filter((f: any) =>
                                    getData
                                        ?.map((res: any) => res?.examGroup)
                                        .includes(f.key)
                                )
                                ?.map((tag: any, i: number) => (
                                    <Box
                                        key={i}
                                        width={30}
                                        height={30}
                                        onClick={() => disabledField() ? "" : selectTag(tag)}
                                        className={`d-flex-all-center border-radius-4  ${classes.annotationExamBox}`}
                                        m={0.5}
                                        sx={{
                                            background:
                                                tag?.title === selectAnnotation?.title
                                                    ? tag?.color
                                                    : "rgba(255, 255, 255, 0.75)",
                                            color:
                                                tag?.title === selectAnnotation?.title
                                                    ? "white"
                                                    : "#666666",
                                            cursor: disabledField() ? 'default' : 'pointer'
                                        }}
                                    >
                                        <Typography className="fw-medium f-w-500 fs-14">
                                            {tag?.title}
                                        </Typography>
                                    </Box>
                                ))}
                        </Box>
                    </Box>
                    <Box width={"100%"} className="d-flex-all-center flex-col">
                        {getImageData?.map((res: any, i: number) => (
                            <Box className={"d-flex flex-col"} key={i}>

                                <Box>
                                    <ImageMarker
                                        extraClass={"image-class-view-all cursor-pointer"}
                                        src={res?.image_path}
                                        markers={getMarkersFromTag || []}
                                        onAddMarker={(marker) =>
                                            handleMarkerUpdate(marker)
                                        }
                                        markerComponent={(e: any) => (
                                            <div
                                                className={`image-marker__marker image-marker__marker--default ${selectedMarker?.x_position === e.left && selectedMarker?.y_position === e.top ? 'selected-marker-style' : ''}`}
                                                data-testid="marker"
                                                style={{
                                                    cursor: "pointer",
                                                    background: e?.color,
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    padding: "15px 15px",
                                                    fontWeight: 600,
                                                }}
                                                onClick={() => handleMarkerClick(e)}
                                            >
                                                {e?.title}
                                            </div>
                                        )}
                                    />
                                </Box>
                                <Typography mt={"12px"} className="f-16 f-w-500 fw-medium">
                                    {res?.image_name}
                                </Typography>

                            </Box>
                        ))}
                        {/* */}
                        <Box className="d-flex justify-content-between w-100" p={'10px 50px'}>
                            <Button variant='outlined' onClick={() => navigateImage('prev')}
                                className={`fw-regular ${classes.nextPrevButton}`}
                                startIcon={<FontAwesomeIcon icon={faAngleLeft} />}
                                disabled={isPrevButtonDisabled}>Previous</Button>
                            <Button variant='outlined' onClick={() => navigateImage('next')}
                                className={`fw-regular ${classes.nextPrevButton}`}
                                endIcon={<FontAwesomeIcon icon={faAngleRight} />}
                                disabled={isNextButtonDisabled}>Next</Button>
                        </Box>
                    </Box>

                </Grid>
            </Grid>
        </Box>
    );
})

export default Annotation;
