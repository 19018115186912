

//Edit Patient Wellness Profile
import {
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST,
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS,
    WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS,
    WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_SUCCESS,
    DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_FAILURE
} from "../../actionTypes/patient/WellnessProfileWithoutAccount";
import {
    DownloadPdfWithoutAccountFailurePayload,
    DownloadPdfWithoutAccountRequest,
    DownloadPdfWithoutAccountRequestFailure,
    DownloadPdfWithoutAccountRequestPayload,
    DownloadPdfWithoutAccountRequestSuccess,
    DownloadPdfWithoutAccountSuccessPayload,
    WithoutAccountGetWellnessProfileFailure,
    WithoutAccountGetWellnessProfileFailurePayload,
    WithoutAccountGetWellnessProfileRequest,
    WithoutAccountGetWellnessProfileRequestPayload,
    WithoutAccountGetWellnessProfileSuccess,
    WithoutAccountGetWellnessProfileSuccessPayload,
    WithoutAccountUpdatePatientWellnessProfileRequest,
    WithoutAccountUpdatePatientWellnessProfileRequestPayload,
} from "../../types/patient/WellnessProfileWithoutAccount";

//Get Patient Wellness Profile
export const getWellnessProfileWithoutAccountRequestAction = (
    payload: WithoutAccountGetWellnessProfileRequestPayload): WithoutAccountGetWellnessProfileRequest => ({
        type: WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST,
        payload
    });

export const getWellnessProfileWithoutAccountSuccessAction = (
    payload: WithoutAccountGetWellnessProfileSuccessPayload): WithoutAccountGetWellnessProfileSuccess => ({
        type: WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS,
        payload
    });

export const getWellnessProfileWithoutAccountFailureAction = (
    payload: WithoutAccountGetWellnessProfileFailurePayload): WithoutAccountGetWellnessProfileFailure => ({
        type: WITHOUT_ACCOUNT_GET_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE,
        payload
    });

//Update Patient Wellness Profile
export const withoutAccountUpdatePatientWellnessProfileRequest = (
    payload: WithoutAccountUpdatePatientWellnessProfileRequestPayload
): WithoutAccountUpdatePatientWellnessProfileRequest => ({
    type: WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST,
    payload
});
export const withoutAccountUpdatePatientWellnessProfileRequestSuccess = () => ({
    type: WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_SUCCESS
});
export const withoutAccountUpdatePatientWellnessProfileRequestFailure = () => ({
    type: WITHOUT_ACCOUNT_UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST_FAILURE
});

//Download PDF Without Account
export const downloadPdfWithoutAccountRequest = (
    payload: DownloadPdfWithoutAccountRequestPayload
): DownloadPdfWithoutAccountRequest => ({
    type: DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST,
    payload,
});
export const downloadPdfWithoutAccountRequestSuccess = (
    payload: DownloadPdfWithoutAccountSuccessPayload
): DownloadPdfWithoutAccountRequestSuccess => ({
    type: DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_SUCCESS,
    payload
});

export const downloadPdfWithoutAccountRequestFailure = (
    payload: DownloadPdfWithoutAccountFailurePayload
): DownloadPdfWithoutAccountRequestFailure => ({
    type: DOWNLOAD_PDF_WITHOUT_ACCOUNT_REQUEST_FAILURE,
    payload
});
