import {
    CREATE_PASSWORD_REQUEST,
    CREATE_PASSWORD_REQUEST_FAILURE,
    CREATE_PASSWORD_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {createPasswordActions, createPasswordInterface} from '../../types/authActionTypes/createPasswordActionTypes';

const initialState: createPasswordInterface = {
    createPasswordLoader: false,
};

const createPasswordReducer = (state = initialState, action: createPasswordActions) => {
    switch (action.type) {
        case CREATE_PASSWORD_REQUEST:
            return {
                ...state,
                createPasswordLoader: true,
            };
        case CREATE_PASSWORD_REQUEST_SUCCESS:
            return {
                ...state,
                createPasswordLoader: false,
            };
        case CREATE_PASSWORD_REQUEST_FAILURE:
            return {
                ...state,
                createPasswordLoader: false,
            };
        default:
            return {
                ...state
            };
    }
};
export default createPasswordReducer;
