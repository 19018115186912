import {put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster} from '../../../utils/helper';
import {privacyPolicyFailure, privacyPolicySuccess} from '../../actions/authAction/privacyPolicyAction';
import {PRIVACY_POLICY_ACCEPT_REQUEST} from '../../actionTypes/auth';

function* acceptPolicyRequestSaga(payload: any): any {
    try {
        if (payload.payload?.isDentist) {

        }
        const url = payload.payload?.isDentist ? `/${ApiEndpointVersion}/auth/policy/accepted?is_owner=${payload?.payload?.isOwner}` : `/${ApiEndpointVersion}/auth/policy/accepted?is_owner=${payload?.payload?.isOwner}&social_login=${payload?.payload?.social_login}`

        const response = yield API.put(url, payload?.payload?.payload)
        yield put(privacyPolicySuccess() );
        if (payload?.payload?.callback) {
            payload?.payload?.callback(response)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(privacyPolicyFailure());
    }
}

function* acceptPolicySaga() {
    yield takeEvery(PRIVACY_POLICY_ACCEPT_REQUEST, acceptPolicyRequestSaga);
}

export default function* rootSaga() {
    yield all([
        acceptPolicySaga()
    ])
}
