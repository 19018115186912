import {
    DISCLAIMER_ACCEPT_REQUEST,
    DISCLAIMER_ACCEPT_REQUEST_SUCCESS,
    DISCLAIMER_ACCEPT_REQUEST_FAILURE
} from '../../actionTypes/auth';
import {
    DisclaimerRequest,
    DisclaimerRequestPayload,
} from '../../types/authActionTypes/disclaimerActionTypes';

export const disclaimerRequest = (payload: DisclaimerRequestPayload): DisclaimerRequest => ({
    type: DISCLAIMER_ACCEPT_REQUEST,
    payload
});

export const disclaimerSuccess = () => ({
    type: DISCLAIMER_ACCEPT_REQUEST_SUCCESS,
});

export const disclaimerFailure = () => ({
    type: DISCLAIMER_ACCEPT_REQUEST_FAILURE
});
