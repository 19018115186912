import {
    GET_CLINIC_REQUEST, GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
    GET_CLINIC_REQUEST_SUCCESS,
    GET_CLINIC_REQUEST_FAILURE,
} from '../../../actionTypes/common/clinicSelection'
import {GetClinicActions, getClinicState} from "../../../types/common/clinicSelectionActionTypes";


const initialState: getClinicState = {
    pending: false,
    data: null,
    error: null
};


const getClinicReducer = (state = initialState, action: GetClinicActions) => {
    switch (action.type) {
        case GET_CLINIC_REQUEST:
            return {
                ...state,
                pending: true,
                data: null,
                error: null
            }
        case GET_CLINIC_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                data: null
            }
        case GET_CLINIC_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload,
                error: null
            }
        case GET_CLINIC_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload
            }
        default:
            return {
                ...state
            };
    }
}

export default getClinicReducer