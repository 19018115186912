import {put, takeEvery, all} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster} from '../../../utils/helper';
import {
    GetTCDataFailure,
    GetTCDataSuccess,
    termsConditionFailure,
    termsConditionSuccess
} from '../../actions/authAction/termsConditionsAction';
import {GET_TC_REQUEST, TERMS_CONDITION_V2_REQUEST} from '../../actionTypes/auth';

function* acceptTermsConditionRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/auth/terms/conditions/accepted?is_owner=${payload?.payload?.isOwner}`, payload?.payload?.payload)
        yield put(
            termsConditionSuccess()
        );
        if (payload?.payload?.callback) {
            payload?.payload?.callback(response)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            termsConditionFailure()
        );
    }
}

function* getTCDataRequestSaga(payload: any): any {
    try {
        const response = yield API.get(`/${ApiEndpointVersion}/admin/cms?seo_url=${payload?.payload?.seo_url}&module=${payload?.payload?.module}&country=${payload?.payload?.country}`)
        yield put(
            GetTCDataSuccess(response?.data?.detail)
        );
        if (payload?.payload?.callback) {
            payload?.payload?.callback(response)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            GetTCDataFailure(e?.meta?.message || "Something went wrong!")
        );
    }
}

function* acceptTermsConditionSaga() {
    yield takeEvery(TERMS_CONDITION_V2_REQUEST, acceptTermsConditionRequestSaga);
}

function* getTCDataSaga() {
    yield takeEvery(GET_TC_REQUEST, getTCDataRequestSaga);
}

export default function* rootSaga() {
    yield all([
        acceptTermsConditionSaga(),
        getTCDataSaga()
    ])
}
