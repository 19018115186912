import { createTheme } from '@mui/material/styles';

// A custom theme for this app
// @ts-ignore
const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'laptop', 'lg', 'large', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      laptop: 1230,
      lg: 1280,
      large: 1550,
      xl: 1920
    }
  },
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48
      },
      '@media (min-width:600px)': {
        minHeight: 64
      }
    }
  },
  palette: {
    common: {
      black: '#000',
      white: '#FFFFFF',
      black50: '#333333',
      white50: '#faecff',
      purple: '#CB40FC',
      green50: '#f2f9ed',
      green: '#7bc250',
      grey: '#afafaf',
      lightGrey: '#33333333',
      borderGrey: '#D6D6D6',
      burgundy: '#EDE0EE',
      skyBorder: '#A4ABC4',
      skyBg: '#F5FBFE',
      blueBg: '#0989C0',
      LEColor: '#FF483C',
      lightRed: '#FFEDEB',
      lightPurple: '#f2e8f7',
      NPEColor: '#d644fc',
      lightBlue: '#e6f1f1',
      REColor: '#99c253',
    },
    primary: {
      light: '#87ead7',
      main: '#40C1AC',
      greenLight: '#ECF9F7',
      dark: '#0aa991',
      contrastText: '#fff',
      otherColor: '#9FE0D5',
      otherDarkPurple: '#483698',
      '100': '#ecf9f7'
    },
    secondary: {
      light: '#F8F9FF',
      main: '#4A3699',
      dark: '#2c0cae',
      contrastText: '#fff',
      lightShade: '#F3F0FF',
    },
    error: {
      light: '#f57e7e',
      main: '#FF4A4A',
      dark: '#f80a0a',
      contrastText: '#fff',
      lightColor: '#FFF6F6'
    },
    success: {
      light: '#91b484',
      main: '#67AB50',
      dark: '#4bbb24',
      contrastText: '#fff',
      lightColor: '#F7FBF6'
    },
    warning: {
      light: '#f8d376',
      main: '#FEC022',
      dark: '#f7b204',
      contrastText: '#fff',
      lightColor: '#FFFCF4'
    },
    information: {
      main: '#0989C0'
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      Gray80: '#cccccc',
      Gray77: '#C4C4C4',
      GrayBg: '#F5F7F9',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
      A900: '#DDDDDD',
      B100: '#DDE0E9',
      B200: '#D4D6E1',
      B300: '#9D9D9D',
      B400: '#E1E3EE',
      dividerGray: '#DFDFDF',
      lightBorderGray: '#8B8B8A',
      videoGray: '#33333380'
    },
    tagColor: {
      green: '#A9C33E',
      purple: '#8D7DF4',
      lightBlue: '#798BA2'
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#000000',
      secondary: '#999999',
      disabled: 'rgba(0, 0, 0, 0.50)',
      darkGrey: '#666666'
    },
    other: {
      hygieneBg: '#FFF9E8'
    },
    divider: '#EBEBEB',
    background: {
      paper: '#F8F9FF',
      secondaryPaper: '#F5F7F9',
      default: '#fafafa'
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)'
    },
    v1: {
      labelGrey: '#848484'
    },
    v2: {
      labelGrey: '#848484',
      primary: {
        main: '#077178',
        primary25: '#CFEFEA'
      },
      secondary: '#483698',
      secondary25: '#DAEDF6',
      dark: {
        grey25: 'rgba(51, 51, 51, 0.25)' /*Grey with 0.25 opacity */,
        labelGrey: '#848484'
      },
      riskColor: {
        green: '#008000',
        red: '#ff0000',
        greenYellow: '#bdb21d',
        lightGreenYellow: '#b7b01c1a',
        yellowRed: '#fd9f1e',
        lightYellowRed: '#fda01d1a'
      }

    },
    procedure: {
      completed: {
        color: '#1EA547', // 67AB50 changed competed color as per feedback 
        bgColor: '#ECFFE5',
      },
      planned: {
        color: '#D21F31',
        bgColor: '#FFE4E2',
      },
      existing: {
        color: '#333333', /*Repeating color for better/easy grouping*/
        bgColor: '#EDE0EE',
      },
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none'

    // "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    // "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    // "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    // "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    // "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    // "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    // "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    // "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    // "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    // "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    // "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    // "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    // "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    // "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    // "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    // "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    // "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    // "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    // "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    // "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    // "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    // "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    // "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    // "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
  ],
  typography: {
    fontFamily: `"Inter"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    h5: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      color: ' #FFFFFF'
    },
    h6: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000'
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#000000'
    },

    body1: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      primary: '#000000'
    },
    button: {
      textTransform: 'capitalize',
      borderRadius: '10px',
      fontFamily: 'FavoritPro-Regular',
      fontStyle: 'normal',
    },
  },

  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195
    }
  },

  zIndex: {
    mobileStepper: 1000,
    appBar: 99,
    drawer: 99,
    modal: 1300,
    snackbar: 1400,
    tooltip: 100
  }
} as any) as any;

export default theme;
