import moment from 'moment';
import {usDateFormat} from '../../../utils/helper';
import {
    GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST,
    GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_FAILURE,
    GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_SUCCESS,
    PATIENT_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
    PATIENT_APPOINTMENT_DETAILS_CACHE_DATA_CLEAR_REQUEST,
    PATIENT_APPOINTMENT_DETAILS_REQUEST,
    PATIENT_APPOINTMENT_DETAILS_REQUEST_FAILURE,
    PATIENT_APPOINTMENT_DETAILS_REQUEST_SUCCESS,
    PATIENT_APPOINTMENT_REQUEST,
    PATIENT_APPOINTMENT_REQUEST_FAILURE,
    PATIENT_APPOINTMENT_REQUEST_SUCCESS,
    SET_DATE_APPOINTMENT,
} from '../../actionTypes/patient/patientAppointment'
import {
    PatientAppointmentActions,
    patientAppointmentState
} from "../../types/patient/patientAppointmentActionTypes";


const initialState: patientAppointmentState = {
    pending: false,
    patientAppointmentPending: false,
    loader: false,
    data: null,
    appointment_data: null,
    details: null,
    error: null,
    startDate: moment().format(usDateFormat),
    endDate: moment().format(usDateFormat)

};

const patientAppointmentReducer = (state = initialState, action: PatientAppointmentActions) => {
    switch (action.type) {
        case PATIENT_APPOINTMENT_REQUEST:
            return {
                ...state,
                pending: !state.loader,
                loader: false,
                patientAppointmentPending: true,
                appointment_data: null,
                appointment_meta: null,
            }
        case PATIENT_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                appointment_data: null
            }

        case PATIENT_APPOINTMENT_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                patientAppointmentPending: false,
                appointment_data: action?.payload?.appointment_data,
                appointment_meta: action?.payload?.appointment_meta,
                error: null
            }

        case PATIENT_APPOINTMENT_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                patientAppointmentPending: false,
                appointment_data: null,
                error: action.payload.error
            }

        case PATIENT_APPOINTMENT_DETAILS_REQUEST:
            return {
                ...state,
                pending: true,
            }
        case PATIENT_APPOINTMENT_DETAILS_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                details: null
            }
        case PATIENT_APPOINTMENT_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                details: action.payload?.data,
                error: null
            }
        case PATIENT_APPOINTMENT_DETAILS_REQUEST_FAILURE:
            return {
                ...state,
                pending: true,
            }
        case GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST:
            return {
                ...state,
                pending: true
            }
        case GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                data: action.payload.data,
                error: null
            }
        case GET_APPOINTMENT_NOTIFICATION_DENTIST_REQUEST_FAILURE:
            return {
                ...state,
                pending: false,
                data: null,
                error: action.payload.error
            }
        case SET_DATE_APPOINTMENT:
            return {
                ...state,
                startDate: action?.payload.startDate,
                endDate: action?.payload.endDate
            }
        default:
            return {
                ...state
            };
    }
}

export default patientAppointmentReducer
