import React from 'react';
import {Box} from '@mui/material';
import theme from "../../../../../../../../theme"; // Assuming you're using Material-UI

interface Props {
    isFindingPresent: (position: string, type: string) => number;
    lingual?: boolean;
    bottomJaw: boolean;
}

const ProbingDepthFinding = React.memo((props: Props) => {
    const {isFindingPresent, lingual, bottomJaw} = props
    const {palette} = theme

    const transformValue = lingual
        ? bottomJaw ? '' : 'scaleX(-1)'
        : bottomJaw ? 'scaleX(1)' : '';

    return (
        <Box
            className='pos-absolute d-flex align-flex-end'
            sx={{
                bottom: 0,
                zIndex: 1,
                transform: transformValue,
            }}
        >
            {['left', 'center', 'right'].map((site, index) => {
                const probingDepth = isFindingPresent(site, 'probing_depth');
                return (
                    <Box
                        key={index}
                        sx={{
                            height: `${parseInt(`${probingDepth}`) * 3}px`,
                            width: '6px',
                            background: probingDepth > 3 ? 'red' : palette.common.black50,
                            mx: index === 1 ? 0.5 : ''
                        }}
                    >
                        {/* Probing Depth Bar */}
                    </Box>
                );
            })}
        </Box>
    );
})

export default ProbingDepthFinding;
