import {Box, Divider} from '@mui/material';
import Typography from '@mui/material/Typography';
import {isArray} from 'lodash';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from "@mui/material/CircularProgress";
import ExamFormAccordion from "./ExamFormAccordion";
import ExamGroup from '../ExamGroup';
import ChartingForm from "../Odontogram/ChartingForm";
import {
    examFindingDataCountAction, setExamDataLoader,
    setExamFindingsAction
} from '../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction';
import {RootState} from '../../../../../redux/reducers/rootReducer';
import {examList, getUniqId} from "../../../../../utils/helper";
import {
    exam,
    hardTissue,
    limitedExam,
    odontogram,
    photo,
    softTissue,
    xray
} from '../../../../../utils/constant/clinicalExamConstant';
import {
    examGroupsData, examGroupsDataForCEREMainCat,
    examTabName,
    findingData,
    findingTypes
} from '../../../../../utils/constant/examConstant';
import CollapsibleBoxWithoutBorder from '../../../../common/UIComponents/CollapsibleBoxWithoutBorder';
import ExamGroupCE from "../ExamGroup/ExamGroupCE";

type Props = {
    handleExpanded: any
    handleFullSize: any
    showFooter: boolean;
};
type examConstantInterface = {
    key?: string;
    title?: string;
    risks?: Array<string>;
    examType?: Array<string>;
    findingTypes?: Array<string>;
    examGroup?: string;
    options?: Array<any>;
};

const ExamForm = React.memo((props: Props) => {
    const {handleExpanded, handleFullSize, showFooter} = props;
    const dispatch = useDispatch()
    const examFormRef = useRef(null) as any
    const [handleState, updateHandleState] = useState(false)
    const {
        annotation: {annotationDataFromAPI},
        examFindingReducer: {examDataFromAPI, examFindingData, examDataLoader},
        appointmentsReducer: {appointmentDetails},
        wellnessProfileFilters: {wellnessProfileStep},
        clinicalExamFilters: {
            riskFilters,
            findingType,
            openCompleteStep,
            appointmentStep
        },
    } = useSelector((state: RootState) => state) as any
    const isWellnessProfile = window.location.pathname?.includes(`/dentist/wellness-profile/`)
    const tabData = isWellnessProfile ? wellnessProfileStep : findingType
    const isPhotoXray = (isWellnessProfile ? true : appointmentStep === 1) && (tabData === photo || tabData === xray)

    useEffect(() => {
        if (examFormRef?.current) {
            examFormRef.current.scroll(0, 0)
        }
    }, [tabData])

    useEffect(() => {
        updateHandleState(!handleState)
        // eslint-disable-next-line
    }, [openCompleteStep, examFindingData])

    useEffect(() => {
        createStructureForCurrentExam()
        getData()
        // eslint-disable-next-line
    }, [riskFilters, tabData, examDataFromAPI, openCompleteStep, annotationDataFromAPI]) /*TODO REDUX State for popup open*/

    const getData = () => {
        const examType = appointmentDetails?.appointmentType;
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[tabData])
        ) as any;
        // Limited Exam data
        const limitedExamData = (tabData === limitedExam) ? findingTypeWiseFilteredData?.filter((res: any) => res?.findingTypes?.includes(softTissue) || res?.findingTypes?.includes(hardTissue)) : findingTypeWiseFilteredData
        const riskWiseFilteredData = riskFilters?.length
            ? (limitedExamData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : limitedExamData; /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/

        const examTypeWiseFilteredData = riskWiseFilteredData?.filter((f: examConstantInterface) => f?.examType?.includes(examType));
        return (isPhotoXray || isWellnessProfile) ? riskWiseFilteredData : examTypeWiseFilteredData
    };

    const examGroupWiseFilteredData = (key: any) => {
        return getData()?.filter((f: examConstantInterface) => f?.examGroup === key);
    };
    const examGroupWiseFilteredDataForOdontogram = (key: any) => {
        return getData()?.filter((f: examConstantInterface) => f?.examGroup === key)?.filter((ff: any) => ff?.displayOdontogramForm === true);
    }

    const examTabWiseFilteredData = (key: any) => {
        return getData()?.filter((f: examConstantInterface) => f?.findingTypes?.includes(key));
    };
    const createStructureForCurrentExam = () => {
        let dataForReducer = {} as any
        // get exam groups
        let examGroups = getData()?.map((res: any) => res?.examGroup) as any
        // remove duplicate exam groups and assign exam key to dataForReducer
        examGroups?.filter((item: any, index: number) => examGroups?.indexOf(item) === index)?.map((examKey: string) => {
            dataForReducer[examKey] = {}
            return true
        })
        // addExistingFindingData
        Object.keys(dataForReducer)?.map((examKey: string) => {
            let currentExamValues = {} as any
            examGroupWiseFilteredData(examKey)?.map((res: any) => {
                if (res?.isMulti && !isPhotoXray) {
                    const findingOptions = {} as any
                    res?.options?.map((option: any) => {
                        findingOptions[option?.key] = null
                        return true
                    })
                    if (examDataFromAPI && examDataFromAPI[examKey] && (((isArray(examDataFromAPI[examKey][res?.key]) && examDataFromAPI[examKey][res?.key].length > 0) || (!isArray(examDataFromAPI[examKey][res?.key]) && examDataFromAPI[examKey][res?.key])) || examDataFromAPI[examKey][res?.key] === false)) {
                        //Set Data from an API
                        currentExamValues[res?.key] = examDataFromAPI && examDataFromAPI[examKey] && examDataFromAPI[examKey][res?.key]
                    } else {
                        currentExamValues[res?.key] = [findingOptions] as any
                    }
                } else {
                    res?.options?.map((option: any) => {
                        if (examDataFromAPI && examDataFromAPI[examKey] && (examDataFromAPI[examKey][option?.key] || examDataFromAPI[examKey][option?.key] === false || examDataFromAPI[examKey][option?.key] === 0)) {
                            currentExamValues[option?.key] = examDataFromAPI && examDataFromAPI[examKey] && examDataFromAPI[examKey][option?.key]
                        } else {
                            currentExamValues[option?.key] = null
                        }
                        return true
                    })
                }
                return true
            })
            if (currentExamValues) {
                dataForReducer[examKey] = {...currentExamValues}
            }
            return true
        })

        if (isPhotoXray && annotationDataFromAPI) {
            // Make tag reducerData for Photo & Xray
            dispatch(setExamFindingsAction(annotationDataFromAPI?.map((res: any) => ({
                ...res,
                key: getUniqId()
            })) || []))
        } else {
            dispatch(setExamFindingsAction(dataForReducer))
            dispatch(setExamDataLoader(false))
        }
    }

    const hasTagForExam = (examName?: string) => {
        if (isPhotoXray && examFindingData && isArray(examFindingData))
            return examFindingData?.map((res: any) => examList[res?.title]?.key).includes(examName)
        return null
    }

    const hasFindingData = () => {
        return examGroupsData?.filter((res: any) => examGroupWiseFilteredData(res?.key)?.length > 0)
    }
    const hasFindingDataForOdontogram = () => {
        return examGroupsData && examGroupsData?.filter((res: any) => examGroupWiseFilteredDataForOdontogram(res?.key)?.length > 0)
    }
    const hasFindingDataCERE = () => {
        return examGroupsDataForCEREMainCat?.filter((res: any) => examGroupWiseFilteredData(res?.key)?.length > 0)
    }

    const hasTabFindingData = () => {
        return examTabName?.filter((res: any) => examTabWiseFilteredData(res?.key)?.length > 0)
    }

    useEffect(() => {
        dispatch(examFindingDataCountAction(hasFindingData().length))
        // eslint-disable-next-line
    }, [hasFindingData()?.length,dispatch])

    return (
        <ExamFormAccordion showFooter={showFooter} handleExpanded={handleExpanded} handleFullSize={handleFullSize}>
            {examDataLoader ? <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <CircularProgress/>
                </Box> :
                <React.Fragment>
                    {/*tabData === 4 is for Odontogram form only*/}
                    { tabData === odontogram ? (
                            <Box>
                                {hasFindingDataForOdontogram()?.length > 0 ? (
                                    hasFindingDataForOdontogram()?.map((res: examConstantInterface, i: number) => (
                                        <ExamGroup
                                            key={i}
                                            meta={res}
                                            data={examGroupWiseFilteredDataForOdontogram(res?.key)}
                                        />
                                    ))
                                ) : (
                                    <Typography
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        className="fw-regular"
                                        fontSize="18px"
                                        mt="20px"
                                        mb={2}
                                    >
                                        No findings found...
                                    </Typography>
                                )}
                                <Divider/>
                                <CollapsibleBoxWithoutBorder title="Charted Findings">
                                    <ChartingForm/>
                                </CollapsibleBoxWithoutBorder>
                                <Divider/>
                                {/*TODO: Removed from Dev and stage-azure*/}
                                {/* {(appointmentDetails?.pms_appointment_no !== null || appointmentDetails?.patient?.pms_id !== null) && (
                                    <CollapsibleBoxWithoutBorder title="Other Findings">
                                       <OtherFindings />
                                    </CollapsibleBoxWithoutBorder>
                                  )} */}
                            </Box>
                        ) : hasFindingData()?.length > 0 ? (
                            (tabData === limitedExam ? hasTabFindingData() :
                                    tabData === exam
                                        ? hasFindingDataCERE()
                                        : hasFindingData()
                            )?.map((res: examConstantInterface, i: number) => (
                                <React.Fragment key={i}>
                                    {/* For photo or x-ray */}
                                    {isPhotoXray && hasTagForExam(res?.key) && (
                                        <ExamGroup
                                            meta={res}
                                            data={examGroupWiseFilteredData(res?.key)}
                                        />
                                    )}
                                    {/* For non-photo/x-ray, non-limited exams, or wellness profile */}
                                    {!isPhotoXray && tabData !== limitedExam && tabData !== exam &&(
                                            <ExamGroup
                                                meta={res}
                                                data={examGroupWiseFilteredData(res?.key)}
                                            />
                                        )
                                    }
                                    {/* Limited exams */}
                                    {tabData === limitedExam && (
                                        <ExamGroup
                                            meta={res}
                                            data={examTabWiseFilteredData(res?.key)}
                                        />
                                    )}
                                    {/* exams (CE) or RE:Created separate component for CE to wrap exam groups inside the extra oral and intra oral group */}
                                    {tabData === exam&&
                                        <ExamGroupCE
                                            meta={res}
                                            data={examGroupWiseFilteredData(res?.key)}
                                            getData={getData}
                                        />
                                    }
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                className="fw-regular"
                                fontSize="18px"
                                mt="20px"
                            >
                                No findings found...
                            </Typography>
                        )
                    }

                </React.Fragment>}
        </ExamFormAccordion>

    );
})

export default ExamForm;
