import {
    FORGOT_PASSWORD_V2_REQUEST,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
    FORGOT_PASSWORD_REQUEST_FAILURE,
    IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP
} from '../../actionTypes/auth';
import {
    ForgotPasswordRequest,
    ForgotPasswordRequestPayload,
    IsMultipleAccountForgotPasswordPopUp
} from '../../types/authActionTypes/forgotPasswordActionTypes';

export const forgotPasswordRequest = (payload: ForgotPasswordRequestPayload): ForgotPasswordRequest => ({
    type: FORGOT_PASSWORD_V2_REQUEST,
    payload
});

export const forgotPasswordRequestSuccess = () => ({
    type: FORGOT_PASSWORD_REQUEST_SUCCESS
});

export const forgotPasswordRequestFailure = () => ({
    type: FORGOT_PASSWORD_REQUEST_FAILURE
});

export const isMultipleAccountForgotPasswordPopUp = (payload: any): IsMultipleAccountForgotPasswordPopUp => ({
    type: IS_MULTIPLE_ACCOUNT_FORGOT_PASSWORD_POPUP,
    payload
});
