import {all, call, put, takeEvery} from "redux-saga/effects";
import API from "../../../utils/api";
import {ApiEndpointVersion, errorToaster, getLocalStorageItem, successToaster} from '../../../utils/helper';
import {
    updatePatientWellnessProfileRequestFailure,
    updatePatientWellnessProfileRequestSuccess
} from "../../actions/patient/patientWellnessProfileAction";
import {UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST} from "../../actionTypes/patient/wellnessProfile";


//Edit patient wellness Profile
function* updatePatientWellnessProfileRequestSaga(payload: any): any {
    try {
        const response = yield API.put(`/${ApiEndpointVersion}/wellness-profile?clinic_id=${getLocalStorageItem('patientClinic')}`, payload.payload)
        yield put(
            updatePatientWellnessProfileRequestSuccess()
        );
        if (payload.payload.callback) {
            yield call(payload.payload.callback, response.data?.detail);
        }
        successToaster(response?.data?.meta?.message);
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            updatePatientWellnessProfileRequestFailure()
        );
    }
}


function* updatePatientWellnessProfileWatch() {
    yield takeEvery(UPDATE_PATIENT_WELLNESS_PROFILE_REQUEST, updatePatientWellnessProfileRequestSaga);
}

export default function* patientWellnessProfileSaga() {
    yield all([
        updatePatientWellnessProfileWatch(),
    ]);
}
