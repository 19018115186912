import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import theme from '../../../../theme';
import CardComponent from "../../../common/UIComponents/CardComponent";
import {RootState} from "../../../../redux/reducers/rootReducer";
import NoDataFound from "../../../common/NoDataFound";
import {
    changeWellnessHygieneRegimeRequestAction,
} from "../../../../redux/actions/dentist/wellnessProfile/wellnessProfileFiltersAction";
import HygieneRegime from "../../HygieneRegime";


const WellnessHygieneRegimen = () => {
    const dispatch = useDispatch()
    const {
        wellnessProfileFilters: { wellnessProfileData, changeWellnessHygieneRegimeLocal,}
    } = useSelector((state: RootState) => state);
    const hygieneRegimeData = changeWellnessHygieneRegimeLocal

    const handleChange = (event: any) => {
        const {value, name} = event?.target as any
        let changeWellnessHygieneRegimeLocalTemp = changeWellnessHygieneRegimeLocal
        changeWellnessHygieneRegimeLocalTemp[name] = value || null
        dispatch(changeWellnessHygieneRegimeRequestAction(changeWellnessHygieneRegimeLocalTemp))
    }
    // TODO: For future use
    // const saveHygieneRegimeData = () => {
    //     dispatch(updateWellnessHygieneRegimeRequestAction(changeWellnessHygieneRegimeLocal))
    // }
    const disabledFiled = useMemo(() => {
        return true
        // return wellnessProfileData?.is_wellness_locked
        // eslint-disable-next-line
    }, [wellnessProfileData?.is_wellness_locked])


    return (
        <CardComponent bgColor={theme.palette.v2.primary.primary25} color={theme.palette.v2.primary.main}
                       title='Hygiene Regimen'>
            {hygieneRegimeData ?
                <HygieneRegime hygieneRegimeData={hygieneRegimeData} handleChange={handleChange}
                               disabled={disabledFiled}/> :
                <Box py={1.5}> <NoDataFound errorMessage={'hygiene regimen'}/></Box>
            }
        </CardComponent>
    )
}

export default WellnessHygieneRegimen