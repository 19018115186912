import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyle = makeStyles(() => ({
  mainWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
    position: "fixed",
    top: "0",
    right: "0",
    backgroundColor: "rgba(0,0,0,0.3)",
    zIndex: 1025,
    overflow: "hidden",
  },
}));

const FullScreenLoader = () => {
  const classes = useStyle();
  return (
    <Box className={classes.mainWrap}>
      <CircularProgress color={"primary"} size={40} />
    </Box>
  );
};

export default FullScreenLoader;
