import {
    GET_TC_REQUEST,
    GET_TC_REQUEST_FAILURE,
    GET_TC_REQUEST_SUCCESS,
    TERMS_CONDITION_V2_REQUEST,
    TERMS_CONDITION_V2_REQUEST_FAILURE,
    TERMS_CONDITION_V2_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {
    termsConditionsActions,
    termsConditionsInterface
} from '../../types/authActionTypes/termsConditionActionTypes';

const initialState: termsConditionsInterface = {
    termsConditionsLoader: false,
    getTCLoader: false,
    getTCData: null,
    getTCError: null
};

const termsConditionReducer = (state = initialState, action: termsConditionsActions) => {
    switch (action.type) {
        case TERMS_CONDITION_V2_REQUEST:
            return {
                ...state,
                termsConditionsLoader: true,

            };
        case TERMS_CONDITION_V2_REQUEST_SUCCESS:
            return {
                ...state,
                termsConditionsLoader: false,

            };
        case TERMS_CONDITION_V2_REQUEST_FAILURE:
            return {
                ...state,
                termsConditionsLoader: false,

            };
        case GET_TC_REQUEST:
            return {
                ...state,
                getTCLoader: true,
                getTCData: null,
                getTCError: null
            };
        case GET_TC_REQUEST_SUCCESS:
            return {
                ...state,
                getTCLoader: false,
                getTCData: action.payload,
                getTCError: null
            };
        case GET_TC_REQUEST_FAILURE:
            return {
                ...state,
                getTCLoader: false,
                getTCError: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
export default termsConditionReducer;
