import {Grid} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import TextBox from "../../../../../common/UIComponents/TextBox";
import {
    getPhotosDetailsRequestAction,
    putPhotosRequestAction
} from "../../../../../../redux/actions/dentist/clinicalExam/exam/uploadPhotosXrayAction";
import PopupComponent from "../../../../../common/UIComponents/PopupComponent";
import {getPatientReviewRequestAction} from "../../../../../../redux/actions/dentist/clinicalExam/patientReviewAction";
import {dentistProfileData} from "../../../../../../utils/helper";
import {RootState} from "../../../../../../redux/reducers/rootReducer";
import FullScreenLoader from "../../../../../common/FullScreenLoader";

type Props = {
    open: boolean;
    setOpen: any
    isPhoto: boolean
    selectImageXray: any
    disabledComment: boolean
    isPatient?: boolean | undefined
    patientReview?: boolean | undefined
    setSelectImageXray?: any
};

const AddCommentsPopup = (props: Props) => {
    const {
        open,
        setOpen,
        isPhoto,
        selectImageXray,
        isPatient,
        patientReview,
        setSelectImageXray,
        disabledComment
    } = props;

    const location = useLocation();
    const dispatch = useDispatch();
    const takeFromUrl: any = useParams();
    const [comments, setComments] = useState('') as any
    const {
        uploadPhotoXray: {photosXrayDetailsLoader},
    } = useSelector((state: RootState) => state);
    const isWellnessProfile= window.location.pathname?.includes(`/dentist/wellness-profile/`)

    const withoutAccountWellnessProfile: boolean = useMemo(() => {
        return location?.pathname?.includes('/patient/without-account/wellness-profile/')
        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (!withoutAccountWellnessProfile) {
            dispatch(getPhotosDetailsRequestAction({
                image_id: (patientReview || isPatient) ? selectImageXray?.image_id : selectImageXray?.ID,
                callback: (data: any) => {
                    setComments(data?.comments)
                }
            }))
        } else {
            setComments(selectImageXray?.comments)
        }
        // eslint-disable-next-line
    }, [])

    const handleClose = () => {
        setOpen(false)
        if (patientReview || isPatient) {
            setSelectImageXray(null)
        }

    };
    const handleSave = () => {
        setOpen(false)
        const form = new FormData();
        if (!isWellnessProfile) {
            form.append("appointment_id", takeFromUrl?.appointmentId);
        }
        form.append("type", isPhoto ? "image" : 'xray');
        form.append("image_name", selectImageXray?.image_name);
        form.append("image_id", (patientReview || isPatient) ? selectImageXray?.image_id : selectImageXray?.ID);
        form.append("comments", comments);
        form.append('profile_id', dentistProfileData?.id)
        form.append('patient_id', takeFromUrl?.patientId)
        dispatch(putPhotosRequestAction({
            file: form,
            callback: () => {
                if (patientReview) {
                    dispatch(getPatientReviewRequestAction({appointment_id: takeFromUrl?.appointmentId}));
                } else {
                    dispatch(getPhotosDetailsRequestAction({
                        image_id: selectImageXray?.ID,
                        callback: (data: any) => {
                            setComments(data?.comments)
                        }
                    }))
                }
            }
        }))
    };
    const handleChange = (event: any) => {
        setComments(event.target.value)
    }

    return (
        <PopupComponent
            width={'450px'}
            saveBtnText={'Save'}
            cancelBtnText={'Cancel'}
            headerAction={true}
            footerAction={!isPatient}
            open={open}
            heading={'Comments'}
            handleClose={handleClose}
            disabledbtn={disabledComment}
            handleSave={handleSave} isOraqIcon isPatient={isPatient}>
            <Grid container>
                {photosXrayDetailsLoader && <FullScreenLoader/>}
                <Grid item xs={12} pt={"12px"}>
                    <TextBox
                        disabled={disabledComment}
                        labelProps={{htmlFor: "comments",}}
                        resize={true}
                        resizeVerticalScreen={true}
                        inputProps={{
                            id: "comments",
                            name: "comments",
                            onChange: (e: any) => handleChange(e),
                            value: comments,
                            error: false,
                            helperText: "",
                            fullWidth: true,
                            InputProps: {
                                classes: {
                                    root: "border-radius-8 bg-white",
                                },
                            },
                            multiline: true,
                            rows: 3,
                        }}
                    />
                </Grid>
            </Grid>
        </PopupComponent>
    );
};

export default AddCommentsPopup;
