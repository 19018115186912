import {styled} from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarDay,
    faCalendarPlus,
    faCalendarWeek, faPersonWalkingArrowLoopLeft,
    faPersonWalkingArrowRight
} from "@fortawesome/free-solid-svg-icons";
import TransferWithinAStationIcon from "@mui/icons-material/TransferWithinAStation";
import React from "react";
import {appointmentStatus} from './clinicalExamConstant';
import {LE, CE, RE} from "./examConstant";
import theme from '../../theme';

export const dropDownName = [
    {
        id: "role",
        heading: "Role",
    },
    {
        id: "teamMember",
        heading: "Team Member",
    },
    {
        id: "status",
        heading: "Status",
    },
    {
        id: "exam",
        heading: "Exam",
    },
];
export const examTypes = [
    {
        label: 'ALL',
        value: ''
    },
    {
        label: 'CE',
        value: 'CE'
    },
    {
        label: 'RC',
        value: 'RE'
    },
    {
        label: 'LE',
        value: 'LE'
    }
]
export const examRoles = [
    {
        label: 'All',
        value: ''
    },
    {
        label: 'Assistant',
        value: 'Assistant'
    },
    {
        label: 'Dentist',
        value: 'Dentist'
    }, {
        label: 'Hygienist',
        value: 'Hygienist'
    }

]
export const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "10rem",
            overflow: "auto",
            boxShadow: "10px 12px 25px 0px rgb(0 0 0 / 25%)",
        },
    },
};
export const AntSwitch = styled(Switch)(() => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    color: `${theme.palette.v2.primary.main}`,
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px) ',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: `${theme.palette.v2.primary.main}`,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}))

export interface columnInterface {
    label?: string;
    minWidth?: number;
    maxWidth?: number;
    textAlign?: any;
    topArrow?: any;
    downArrow?: any;
    info?: any;
    id?: any;
}
export const appointmentTypesOption = [
    {
        label: "Comprehensive Exam",
        value: "CE",
    },
    {
        label: "Recall Exam",
        value: "RE",
    },
    {
        label: "Limited Exam",
        value: "LE",
    },
];

export const verbalConsentObtained = [
    {
        label: "No",
        value: "no",
    },
    {
        label: "Yes",
        value: "yes",
    },
];

export const recallCyclePeriod = [
    {
        value: "3 months",
        label: "3 months",
    },
    {
        value: "4 months",
        label: "4 months",
    },
    {
        value: "6 months",
        label: "6 months",
    },
    {
        value: "12 months",
        label: "12 months",
    },
    {
        value: "Overdue",
        label: "Overdue",
    },
];

export const examStatus = [
    {
        label: 'Upcoming',
        value: appointmentStatus?.Upcoming
    },
    {
        label: 'Ongoing',
        value: appointmentStatus?.Ongoing
    },
    {
        label: 'Finished',
        value: appointmentStatus?.Finished
    },
    {
        label: 'Completed',
        value: appointmentStatus?.Completed
    },
    {
        label: 'Cancelled',
        value: appointmentStatus?.Cancelled
    },
    {
        label: 'No Show',
        value: appointmentStatus?.NoShow
    },
]

//Dashboard data
export const appointmentData = (countData: any) => {
    return [
        {
            date: countData?.appointments?.today,
            duration: 'Today',
            key: 'today',
            icon: <FontAwesomeIcon icon={faCalendarDay} className={`f-20`}/>
        },
        {
            date: countData?.appointments?.tomorrow,
            duration: 'Tomorrow',
            key: 'tomorrow',
            icon: <FontAwesomeIcon icon={faCalendarPlus} className={`f-20`}/>
        },
        {
            date: countData?.appointments?.week,
            duration: 'This Week',
            key: 'week',
            icon: <FontAwesomeIcon icon={faCalendarWeek} className={`f-20`}/>
        },
    ] as any
}
export const examTypesDashboard = [
    {
        id: CE,
        heading: CE,
        description: 'Comprehensive Exam',
        color: theme.palette.common.purple,
        imageBoxColor: theme.palette.common.lightPurple,
        imageSelectBoxColor: theme.palette.common.NPEColor,
        icon: <FontAwesomeIcon icon={faPersonWalkingArrowRight} className={`f-20`}/>
    },
    {
        id: RE,
        heading: 'Recall',
        description: 'Recall',
        color: theme.palette.common.green,
        imageBoxColor: theme.palette.common.lightBlue,
        imageSelectBoxColor: theme.palette.common.REColor,
        icon: <FontAwesomeIcon icon={faPersonWalkingArrowLoopLeft} className={`f-20`}/>
    },
    {
        id: LE,
        heading: 'Limited Exam',
        description: 'Limited Exam',
        color: theme.palette.error.red,
        imageBoxColor: theme.palette.common.lightRed,
        imageSelectBoxColor: theme.palette.common.LEColor,
        icon: <TransferWithinAStationIcon className={`f-26`}/>
    }
]



export const riskColorClass = (classes:any,score: number) => {
    if (score === null) {
        return classes.colorBlack
    } else if (score <= 0.9) {
        return classes.colorGreen
    } else if (score <= 1.5) {
        return classes.colorGreenYellow
    } else if (score <= 3) {
        return classes.colorYellow
    } else if (score <= 3.7) {
        return classes.colorYellowRed
    } else {
        return classes.colorRed
    }
}