export const SELECT_PHOTO = 'SELECT_PHOTO';
export const UPLOAD_PHOTO = 'UPLOAD_PHOTO';

export const UPLOAD_PHOTO_MODEL = 'UPLOAD_PHOTO_MODEL';

export const GET_PHOTOS_REQUEST = 'GET_PHOTOS_REQUEST';
export const GET_PHOTOS_REQUEST_SUCCESS = 'GET_PHOTOS_REQUEST_SUCCESS';
export const GET_PHOTOS_REQUEST_FAILURE = 'GET_PHOTOS_REQUEST_FAILURE';

export const GET_PHOTOS_DETAILS_REQUEST = 'GET_PHOTOS_DETAILS_REQUEST';
export const GET_PHOTOS_REQUEST_DETAILS_SUCCESS = 'GET_PHOTOS_REQUEST_DETAILS_SUCCESS';
export const GET_PHOTOS_REQUEST_DETAILS_FAILURE = 'GET_PHOTOS_REQUEST_DETAILS_FAILURE';

export const PUT_PHOTOS_REQUEST = 'PUT_PHOTOS_REQUEST';
export const PUT_PHOTOS_REQUEST_SUCCESS = 'PUT_PHOTOS_REQUEST_SUCCESS';
export const PUT_PHOTOS_REQUEST_FAILURE = 'PUT_PHOTOS_REQUEST_FAILURE';

export const DELETE_PHOTOS_REQUEST = 'DELETE_PHOTOS_REQUEST';
export const DELETE_PHOTOS_REQUEST_SUCCESS = 'DELETE_PHOTOS_REQUEST_SUCCESS';
export const DELETE_PHOTOS_REQUEST_FAILURE = 'DELETE_PHOTOS_REQUEST_FAILURE';

export const ADD_ADDITIONAL_IMAGE_XRAY = 'ADD_ADDITIONAL_IMAGE_XRAY';
export const CHANGE_ADDITIONAL_IMAGE_XRAY_TITLE = 'CHANGE_ADDITIONAL_IMAGE_XRAY_TITLE';

// phots xray title validations for additional
export const UPDATE_PHOTOS_XRAY_VALIDATIONS = 'UPDATE_PHOTOS_XRAY_VALIDATIONS';

//clear redux state
export const CLEAR_PHOTO_XRAY_DATA = 'CLEAR_PHOTO_XRAY_DATA';

// set uploaded phots/xray index
export const JUST_UPLOADED_INDEX = 'JUST_UPLOADED_INDEX';
