import {
    CHANGE_PASSWORD_V2_REQUEST,
    CHANGE_PASSWORD_V2_REQUEST_FAILURE,
    CHANGE_PASSWORD_V2_REQUEST_SUCCESS
} from '../../actionTypes/auth';
import {changePasswordActions, changePasswordInterface} from '../../types/authActionTypes/changePasswordActionTypes';

const initialState: changePasswordInterface = {
    changePasswordLoader: false,

};

const changePasswordReducer = (state = initialState, action: changePasswordActions) => {
    switch (action.type) {
        case CHANGE_PASSWORD_V2_REQUEST:
            return {
                ...state,
                changePasswordLoader: true,
            };

        case CHANGE_PASSWORD_V2_REQUEST_SUCCESS:
            return {
                ...state,
                changePasswordLoader: false,
            };

        case CHANGE_PASSWORD_V2_REQUEST_FAILURE:
            return {
                ...state,
                changePasswordLoader: false,
            };

        default:
            return {
                ...state
            };
    }
};
export default changePasswordReducer;
