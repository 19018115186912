import {
  SELECT_PHOTO,
  UPLOAD_PHOTO,
  GET_PHOTOS_REQUEST,
  GET_PHOTOS_REQUEST_FAILURE,
  GET_PHOTOS_REQUEST_SUCCESS,
  PUT_PHOTOS_REQUEST,
  PUT_PHOTOS_REQUEST_SUCCESS,
  PUT_PHOTOS_REQUEST_FAILURE,
  UPLOAD_PHOTO_MODEL,
  DELETE_PHOTOS_REQUEST,
  DELETE_PHOTOS_REQUEST_SUCCESS,
  DELETE_PHOTOS_REQUEST_FAILURE,
  ADD_ADDITIONAL_IMAGE_XRAY,
  CHANGE_ADDITIONAL_IMAGE_XRAY_TITLE,
  UPDATE_PHOTOS_XRAY_VALIDATIONS,
  CLEAR_PHOTO_XRAY_DATA,
  JUST_UPLOADED_INDEX,
  GET_PHOTOS_DETAILS_REQUEST,
  GET_PHOTOS_REQUEST_DETAILS_SUCCESS,
  GET_PHOTOS_REQUEST_DETAILS_FAILURE
} from '../../../../actionTypes/dentist/clinicalExam/exams/uploadPhotosXray';
import { uploadPhotosAction, uploadPhotosInterface } from '../../../../types/dentist/clinicalExam/exam/uploadPhotosXray';

const initialState: uploadPhotosInterface = {
  selectPhotosData: null,
  uploadPhotosData: null,
  photosXrayData: null,
  photosXrayLoader: false,
  putPhotosXrayData: null,
  putPhotosXrayLoader: false,
  deletePhotoXrayLoader: false,
  uploadPhotoModel: false,
  addAdditionalImageXray: false,
  additionalImageXrayTitle: '',
  errors: {
    uploadPhotosXray: {}
  },
  justUploadedIndex: -1,
  photosXrayDetailsLoader: false,
  photosXrayDetails: null,
  photosXrayDetailsError: ''
};

const uploadPhotosXrayReducer = (state = initialState, action: uploadPhotosAction) => {
  switch (action.type) {
    case SELECT_PHOTO:
      return {
        ...state,
        selectPhotosData: action.payload
      };
    case UPLOAD_PHOTO:
      return {
        ...state,
        uploadPhotosData: action.payload
      };
    case UPLOAD_PHOTO_MODEL:
      return {
        ...state,
        uploadPhotoModel: action.payload
      };
    case GET_PHOTOS_REQUEST:
      return {
        ...state,
        photosXrayLoader: true,
        photosXrayData: null
      };
    case GET_PHOTOS_REQUEST_SUCCESS:
      return {
        ...state,
        photosXrayLoader: false,
        photosXrayData: action.payload
      };
    case GET_PHOTOS_REQUEST_FAILURE:
      return {
        ...state,
        photosXrayLoader: false,
        photosXrayData: null
      };
    case PUT_PHOTOS_REQUEST:
      return {
        ...state,
        putPhotosXrayLoader: true
      };
    case PUT_PHOTOS_REQUEST_SUCCESS:
      return {
        ...state,
        putPhotosXrayLoader: false,
        putPhotosXrayData: action.payload
      };
    case PUT_PHOTOS_REQUEST_FAILURE:
      return {
        ...state,
        putPhotosXrayLoader: false,
        putPhotosXrayData: null
      };
    case DELETE_PHOTOS_REQUEST:
      return {
        ...state,
        deletePhotoXrayLoader: true
      };
    case DELETE_PHOTOS_REQUEST_SUCCESS:
      return {
        ...state,
        deletePhotoXrayLoader: false
      };
    case DELETE_PHOTOS_REQUEST_FAILURE:
      return {
        ...state,
        deletePhotoXrayLoader: false,
        photosXrayData: null
      };
    case ADD_ADDITIONAL_IMAGE_XRAY:
      return {
        ...state,
        addAdditionalImageXray: action.payload
      };
    case CHANGE_ADDITIONAL_IMAGE_XRAY_TITLE:
      return {
        ...state,
        additionalImageXrayTitle: action.payload
      };
    case UPDATE_PHOTOS_XRAY_VALIDATIONS:
      return {
        ...state,
        errors: action.payload
      };
    case CLEAR_PHOTO_XRAY_DATA:
      return {
        state: initialState
      };
    case JUST_UPLOADED_INDEX:
      return {
        ...state,
        justUploadedIndex: action.payload
      };
    case GET_PHOTOS_DETAILS_REQUEST:
      return {
        ...state,
        photosXrayDetailsLoader: true,
        photosXrayDetails: null,
        photosXrayDetailsError:null
      };
    case GET_PHOTOS_REQUEST_DETAILS_SUCCESS:
      return {
        ...state,
        photosXrayDetailsLoader: false,
        photosXrayDetails: action.payload,
        photosXrayDetailsError:null
      };
    case GET_PHOTOS_REQUEST_DETAILS_FAILURE:
      return {
        ...state,
        photosXrayDetailsLoader: false,
        photosXrayDetails: null,
        photosXrayDetailsError:action.payload,
      };
    default:
      return {
        ...state
      };
  }
};

export default uploadPhotosXrayReducer;
