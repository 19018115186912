import React from 'react';
import {Typography} from '@mui/material';
import {Box} from '@mui/system';
import PopupComponent from "../UIComponents/PopupComponent";

type Props = {
    open: boolean;
    setOpen: any;
    deleteHandler: any;
    deleteText: string;
    loader?: boolean;
};
const DeleteConfirmationPopUp = (props: Props) => {
    const {open, setOpen, deleteHandler, deleteText, loader} = props;

    const handleClose = () => {
        setOpen(false);
    };
    const handleDelete = () => {
        deleteHandler();
    };
    return (
        <>
            <PopupComponent
                open={open}
                heading={'Delete'}
                headerAction={true}
                footerAction={true}
                width={'600px'}
                handleClose={handleClose}
                cancelBtnText={'Cancel'}
                isDelete
                saveBtnText={'Delete'}
                btnLoader={loader}
                handleSave={handleDelete}
                disabledbtn={loader}
            >
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <Typography className='f-14 f-w-500 fw-medium'>Are you sure you want to delete
                        this {deleteText}?</Typography>
                </Box>
            </PopupComponent>
        </>
    );
};

export default DeleteConfirmationPopUp;
