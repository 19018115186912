import {makeStyles} from "@mui/styles";
import React, {useMemo} from 'react';
import {unstable_batchedUpdates} from 'react-dom';
import {useDispatch, useSelector} from "react-redux";
import {
    setExamFindingsAction
} from "../../../../../../../redux/actions/dentist/clinicalExam/exam/examFindingsTypesAction";
import BOP from '../../../../../../../assets/images/periogramFindings/bop.svg'
import Furcation1 from '../../../../../../../assets/images/periogramFindings/furcation1.svg'
import Furcation2 from '../../../../../../../assets/images/periogramFindings/furcation2.svg'
import Furcation3 from '../../../../../../../assets/images/periogramFindings/furcation3.svg'
import Plaque from '../../../../../../../assets/images/periogramFindings/plaque.svg'
import {removeRefFromJson, stringToArray} from '../../../../../../../utils/helper';
import {examConstantInterface, findingData, findingTypes} from '../../../../../../../utils/constant/examConstant';

type Props = {
    variant?: string
    tooth: number
    finding: string,
    toothSite?: string
    disabled?: boolean
    bottomJaw?: any
}

const useStyles = makeStyles((theme: any) => ({
    periogramInputBox: {
        display: 'flex',
        justifyContent: 'center',
        m: '1px',
        border: `0.122819px solid ${theme.palette.grey.lightBorderGray}`,
        borderRadius: '3px',
        maxWidth: '48px',
        textAlign: 'center',
        outline: 'none',
    },
    smallVariantBox: {
        width: '14px',
        height: '14px',
        margin: '1px',
        border: `0.09px solid ${theme.palette.grey.lightBorderGray} !important`,
        borderRadius: '3px !important',
        fontSize: '9px'
    },
    mobilityRomenFont: {
        fontFamily: 'slick',
        letterSpacing: '1px',
        height: 17
    }
}));

const PeriogramImageInput = React.memo((props: Props) => {
    const {variant, tooth, finding, toothSite, disabled, bottomJaw} = props
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        examFindingReducer: {examFindingData},
        clinicalExamFilters: {riskFilters, findingType},
    } = useSelector((state: any) => state) as any

    const handleChange = (toothSiteValue: string | undefined) => {
        unstable_batchedUpdates(() => {
            let updatedExamFindingData = removeRefFromJson(examFindingData) as any
            let currentFindingData = (updatedExamFindingData?.periodontal_exam && updatedExamFindingData?.periodontal_exam[finding]) || [] as any[]
            if (currentFindingData?.length === 1 && currentFindingData[0]?.tooth_number === null) {
                if (finding === 'furcation') {
                    currentFindingData[0] = {
                        tooth_number: `${tooth}`,
                        "buccal_grade": toothSite === 'buccal_grade' ? '1' : null,
                        "lingual_grade": toothSite === 'lingual_grade' ? '1' : null,
                        "notes": ""
                    }
                } else {
                    //  Plaque and BOP
                    currentFindingData[0] = {
                        tooth_number: `${tooth}`,
                        tooth_site: [toothSiteValue],
                    }
                }
            } else {
                let index = -1 as number
                currentFindingData?.map((res: any, i: number) => {
                    if (res.tooth_number === `${tooth}`) {
                        index = i
                    }
                    return true
                })
                if (index >= 0) {
                    if (finding === 'furcation' && toothSite) {
                        if (currentFindingData[index][toothSite] === null) {
                            currentFindingData[index][toothSite] = '1'
                        } else if (currentFindingData[index][toothSite] === '1') {
                            currentFindingData[index][toothSite] = '2'
                        } else if (currentFindingData[index][toothSite] === '2') {
                            currentFindingData[index][toothSite] = '3'
                        } else if (currentFindingData[index][toothSite] === '3') {
                            currentFindingData[index][toothSite] = null
                        }
                        // @ts-ignore
                    } else {
                        //  Plaque and BOP
                        if (currentFindingData[index]?.tooth_site === null || currentFindingData[index]?.tooth_site?.length < 1) {
                            currentFindingData[index].tooth_site = [toothSiteValue]
                        } else {
                            if (!currentFindingData[index].tooth_site?.includes(toothSiteValue)) {
                                currentFindingData[index].tooth_site = [...currentFindingData[index].tooth_site, toothSiteValue]
                            } else {
                                currentFindingData[index].tooth_site = currentFindingData[index]?.tooth_site?.filter((f: string) => f !== toothSiteValue)
                                if (currentFindingData[index].tooth_site?.length < 1) {
                                    currentFindingData = currentFindingData?.filter((_: any, i: number) => i !== index)
                                }
                            }
                        }
                    }
                } else {
                    if (finding === 'furcation') {
                        currentFindingData?.push({
                            tooth_number: `${tooth}`,
                            "buccal_grade": toothSite === 'buccal_grade' ? '1' : null,
                            "lingual_grade": toothSite === 'lingual_grade' ? '1' : null,
                            "notes": ""
                        })
                    } else {
                        //  Plaque and BOP
                        currentFindingData?.push({
                            tooth_number: `${tooth}`,
                            tooth_site: [toothSiteValue],
                        })
                    }
                }
            }
            if (currentFindingData && updatedExamFindingData?.periodontal_exam) {
                updatedExamFindingData.periodontal_exam[finding] = currentFindingData
                dispatch(setExamFindingsAction(updatedExamFindingData));
            }
        })
    }

    const riskWiseFilteredData = useMemo(() => {
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType])
        ) as any;
        /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskFilters?.length
            ? (findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData
        // eslint-disable-next-line
    }, [riskFilters, riskFilters?.length, findingData, findingType]);

    const getPeriogramFindingValue = useMemo(() => {
        let value = false as boolean | null | string;
        const filteredFindingData = removeRefFromJson(riskWiseFilteredData) as any
        if (examFindingData && examFindingData?.periodontal_exam && examFindingData?.periodontal_exam[finding] && filteredFindingData) {
            filteredFindingData?.filter((findingKey: any) => findingKey?.key === finding)?.map((fk: any) => {
                examFindingData?.periodontal_exam[fk?.key]?.map((res: any) => {
                    if ((typeof res?.tooth_site === 'string' ? stringToArray(res?.tooth_site) : res?.tooth_site)?.includes(toothSite) && res?.tooth_number === `${tooth}`) {
                        value = true;
                    }
                    if (finding === 'furcation' && res?.tooth_number === `${tooth}`) {
                        // @ts-ignore
                        value = res[toothSite];
                    }
                    return true;
                });
                return true
            })
        }


        return value;
    }, [examFindingData, finding, tooth, toothSite, riskWiseFilteredData]);

    const getFindingImage = (val?: any) => {
        switch (finding) {
            case 'bleed_on_probing':
                return BOP
            case 'furcation':
                const normalizedVal = parseInt(val, 10); // Normalize val to an integer
                return normalizedVal === 1
                    ? Furcation1
                    : normalizedVal === 2
                        ? Furcation2
                        : Furcation3;
            default:
                return Plaque
        }
    }

    return (
        <div
            style={{
                opacity: disabled ? 0.8 : 1,
                marginLeft: variant !== 'small' ? bottomJaw ? '1.5px' : '1px' : '',
                marginRight: (variant !== 'small' || !bottomJaw) ? '1px !important' : ''
            }}
            className={`${classes.periogramInputBox} ${variant === 'small' ? classes.smallVariantBox : classes.mobilityRomenFont} d-flex-all-center 
      ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
            onClick={() => {
                if (!disabled) {
                    if (finding === 'bleed_on_probing' || finding === 'plaque' || finding === 'furcation') {
                        handleChange(toothSite);
                    }
                }
            }}>
            {getPeriogramFindingValue ?
                <img src={getFindingImage(getPeriogramFindingValue)} alt={finding} width={10} height={10}/> : null}
        </div>
    );
})

export default PeriogramImageInput;
