import { Box, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import theme from "../../../../../../theme";

const useStyles = makeStyles(() => ({
  strippedBG: {
    position: 'absolute',
    height: '55px',
    width: '100%',
    zIndex: 0
  },
}));

type Props = {
  lingual?: boolean,
  tooth?: number
}

const MeasureScaleBg = (props: Props) => {
  const { lingual } = props
  const classes = useStyles();
  const { palette } = theme
  const measureScale = Array.from({ length: 10 }, (_, index) => index + 1) as any

  return (
    <Box className={classes.strippedBG} sx={{ top: lingual ? '' : 0, bottom: lingual ? '10px' : '' }}>
      { measureScale?.map((_: number, i: number) => <Box key={i} sx={{ height: '6px' }}>
          {i !== 0 ? <Divider color={palette.grey.lightBorderGray} sx={{ opacity: 3 }}/> : null}
        </Box>)
      }
    </Box>
  );
};

export default MeasureScaleBg;
