import React, { useRef } from 'react';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { Grid, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  updateEmergencyContactInfoAction,
  updatePreIntakeValidationsAction,
} from "../../../../redux/actions/dentist/preIntakeActions";
import { RootState } from "../../../../redux/reducers/rootReducer";
import { textLengthStyles } from "../../../../pages/dentist/PreIntake";
import {
  convertToLowerCase,
  formatPhoneNumber,
  handleChangeWithDebounce,
  phoneNumberPlaceholder,
  removeRefFromJson,
} from "../../../../utils/helper";
import { relationshipToEmergencyContactPerson } from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import { emergencyContactInfoValidator } from "../../../../validation/preIntakeValidator";
import CollapsibleBox from "../../UIComponents/CollapsibleBox";
import EndAdornmentIcon from "../../UIComponents/EndAdornmentIcon";
import InputHeading from "../../UIComponents/InputHeading";
import RadioSelect from "../../UIComponents/RadioSelect";
import StartAdornmentIcon from "../../UIComponents/StartAdornmentIcon";
import TextBox from "../../UIComponents/TextBox";

type Props = {
  disabledField?: boolean;
};

const EmergencyContactInformation = React.memo((props: Props) => {
  const { disabledField } = props;
  const lengthClasses = textLengthStyles()
  const dispatch = useDispatch();
  const noteRef = useRef(null) as any
  const { emergencyContactInfo, errors } = useSelector(
    (state: RootState) => state.preIntake
  );
  const handleChange = handleChangeWithDebounce((e: any) => {
    const { value, name } = e.target as any;
    let updatedEmergencyContactInfo = removeRefFromJson(emergencyContactInfo);
    if (name === "emergencyContactNumber") {
      // Format value before save mobile number (in US format)
      updatedEmergencyContactInfo[name] = formatPhoneNumber(value)
    } else {
      updatedEmergencyContactInfo[name] = value;
    }
    dispatch(updateEmergencyContactInfoAction(updatedEmergencyContactInfo));

    // for the update error
    const emergencyContactInfoValid = emergencyContactInfoValidator(
      updatedEmergencyContactInfo
    ) as any;

    if (errors?.emergencyContactInfo?.[name]) {
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          emergencyContactInfo: {
            ...errors.emergencyContactInfo,
            [name]: emergencyContactInfoValid.errors[name],
          },
        })
      );
    }
  });

  const handleChangeWithoutDebounce = (e: any) => {
    const { value, name } = e.target as any;
    let updatedEmergencyContactInfo = emergencyContactInfo;
    updatedEmergencyContactInfo[name] =
      (name) === "emergencyContactNumber"
        ? formatPhoneNumber(value)
        : value;
    dispatch(updateEmergencyContactInfoAction(updatedEmergencyContactInfo));

    // for the update error
    const emergencyContactInfoValid = emergencyContactInfoValidator(
      updatedEmergencyContactInfo
    ) as any;
    if (errors?.emergencyContactInfo?.[name]) {
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          emergencyContactInfo: {
            ...errors.emergencyContactInfo,
            [name]: emergencyContactInfoValid.errors[name],
          },
        })
      );
    }
  };

  //for the remove other filed value while select the relationship status
  const removeOtherValue = () => {
    dispatch(updateEmergencyContactInfoAction({ ...removeRefFromJson(emergencyContactInfo), other: "" }));
    // for the update error
    let updatedEmergencyContactInfo = emergencyContactInfo;
    const emergencyContactInfoValid = emergencyContactInfoValidator(
      updatedEmergencyContactInfo
    ) as any;
    if (errors?.emergencyContactInfo?.['other']) {
      dispatch(
        updatePreIntakeValidationsAction({
          ...errors,
          emergencyContactInfo: {
            ...errors.emergencyContactInfo,
            'other': emergencyContactInfoValid.errors['other'],
          },
        })
      );
    }
  };

  return (
    <CollapsibleBox
      title={"Emergency Contact Information"}
      id={"emergencyContactInfo"}
      scrollBlock={'center'}
    >
      <Grid
        container
        rowSpacing={1.5}
        columnSpacing={2.5}
        display="flex"
        alignItems={"flex-end"}
      >
        <Grid item xs={12} sm={6}>
          <TextBox
            disabled={disabledField}
            label={"Emergency Contact Person"}
            labelProps={{
              htmlFor: "emergencyContactPerson",
            }}
            maxLength={30}
            inputProps={{
              name: "emergencyContactPerson",
              onChange: (e: any) => handleChange(e),
              defaultValue: emergencyContactInfo?.emergencyContactPerson,
              error: errors.emergencyContactInfo?.emergencyContactPerson,
              helperText: errors.emergencyContactInfo?.emergencyContactPerson,
              fullWidth: true,
              InputProps: {
                classes: {
                  root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                  input: "textFieldStartEndIcon",
                },
                endAdornment: (
                  <InputAdornment position="end" className={lengthClasses.textLengthCount}>
                    {`${emergencyContactInfo?.emergencyContactPerson?.length || 0} / 30`}
                  </InputAdornment>
                )
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextBox
            disabled={disabledField}
            label={"Emergency Contact Number"}
            labelProps={{
              htmlFor: "emergencyContactNumber",
            }}
            maxLength={14}
            inputProps={{
              placeholder: phoneNumberPlaceholder,
              name: "emergencyContactNumber",
              InputProps: {
                classes: {
                  root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white",
                  input: "textFieldStartEndIcon",
                },
                startAdornment: (
                  <StartAdornmentIcon text={"+1"} disabled={disabledField} />
                ),
                endAdornment: (
                  <EndAdornmentIcon icon={faPhone} disabled={disabledField} />
                ),
              },
              onChange: (e: any) => handleChangeWithoutDebounce(e),
              // defaultValue: formatPhoneNumber(
              //   emergencyContactInfo?.emergencyContactNumber
              // ),
              value: formatPhoneNumber(
                emergencyContactInfo?.emergencyContactNumber
              ),
              error: errors.emergencyContactInfo?.emergencyContactNumber,
              helperText: errors.emergencyContactInfo?.emergencyContactNumber,
              fullWidth: true,
            }}
          />
        </Grid>
        <Grid item lg={'auto'} md={12} sm={12} xs={12}>
          <InputHeading title={"Relationship to Emergency Contact Person"} />
          <RadioSelect
            disabled={disabledField}
            options={relationshipToEmergencyContactPerson}
            groupProps={{
              value: emergencyContactInfo?.relationshipToEmergencyContactPerson,
              name: "relationshipToEmergencyContactPerson",
              onChange: (e: any) => {
                handleChangeWithoutDebounce(e);
                removeOtherValue();
              },
              error:
                errors.emergencyContactInfo
                  ?.relationshipToEmergencyContactPerson,
              helperText:
                errors.emergencyContactInfo
                  ?.relationshipToEmergencyContactPerson,
            }}
          />
        </Grid>
        {convertToLowerCase(
          emergencyContactInfo?.relationshipToEmergencyContactPerson
        ) === "other" && (
            <Grid item xs={12} md={9} lg={6} >
              <TextBox
                disabled={disabledField}
                label=""
                labelProps={{
                  htmlFor: "other",
                }}
                maxLength={50}
                resize={true}
                resizeVerticalScreen={true}
                inputProps={{
                  placeholder: "Please describe your relationship with the patient",
                  name: "other",
                  onChange: (e: any) => handleChange(e),
                  defaultValue: emergencyContactInfo?.other,
                  error: errors.emergencyContactInfo.other,
                  helperText: errors.emergencyContactInfo.other,
                  fullWidth: true,
                  InputProps: {
                    classes: {
                      root: "border-radius-8 textFieldEndIconPadding textFieldStartIconPadding bg-white ",
                      input: "textFieldStartEndIconNumbers",
                    },
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={lengthClasses.textLengthCount}
                        sx={{ alignSelf: 'flex-end', paddingBottom: '15px' }}
                      >
                        {`${emergencyContactInfo?.other?.length || 0} / 50`}
                      </InputAdornment>
                    )
                  },
                  multiline: true,
                  ref: noteRef
                }}
              />
            </Grid>
          )}
      </Grid>
    </CollapsibleBox>
  );
})

export default EmergencyContactInformation;
