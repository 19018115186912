import moment from 'moment';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {
    getPreClinicalDataFailAction,
    getPreClinicalDataSuccessAction, putPreClinicalDataFailAction, putPreClinicalDataSuccessAction,
    updateAppointmentInfo
} from "../../../actions/dentist/preClinicalActions";
import {getYesNo, passTrueFalse} from "../../../../utils/constant/preIntakeConstant/preIntakeConstant";
import {
    ApiEndpointVersion,
    arrayToString,
    dentistProfileData,
    errorToaster,
    stringToArray,
    usDateFormat
} from "../../../../utils/helper";
import {
    GET_PRE_CLINICAL_DATA_REQUEST,
    PUT_PRE_CLINICAL_DATA_REQUEST
} from "../../../actionTypes/dentist/clinicalExam/preClinical";
import API from '../../../../utils/api';
import {RE} from '../../../../utils/constant/examConstant'


function* getPreClinicalDataSaga(payload: any): any {

    const preClinicalData = {
        appointmentType: '',
        lastDateOfExamination: null,
        recallCyclePeriod: '',
        instrumentCycle: null,
        verbalConsent: '',
        chiefComplaint: '',
        socialNotes: '',
        patientPreferences: ''
    }
    const preIntakeData = null

    try {
        yield put(updateAppointmentInfo({
            appointmentInfo: preClinicalData,
            appointmentPreIntakeData: preIntakeData
        }));

        const response = yield API.get(`${ApiEndpointVersion}/exam/preclinical/${payload.payload}`);
        const data = response?.data?.detail
        //Set data in global redux to use for revert/discard changes (future usages)
        /*
         * Start
         * Update pre-clinical components value based on api response
         * */
        const appointmentData = {
            // dateOfExam: data?.appointment_details?.exam_date,
            appointmentType: data?.appointment_details?.appt_type,
            lastDateOfExamination: data?.appointment_details?.last_date_of_examination,
            recallCyclePeriod: data?.appointment_details?.recall_cycle_period,
            instrumentCycle: data?.appointment_details?.inst_steri_cycle,
            verbalConsent: getYesNo(data?.appointment_details?.exam_consent),
            chiefComplaint: data?.appointment_details?.chief_complaint,
            socialNotes: data?.appointment_details?.social_note,
            patientPreferences: data?.appointment_details?.patient_preferences
        }

        const appointmentPreData = {
            checkboxListTwo: stringToArray(data?.pre_intake?.allergies_list)?.map((allergies: string) => parseInt(allergies)) || [],
            checkboxListTwoOtherInput: data?.pre_intake?.allergies_list_other || "",
            antibioticPremeditation: data?.pre_intake?.antibiotic_premedication || "",
            doesYourPhysicianRecommendAntibiotic: getYesNo(data?.pre_intake?.antibiotic_premedication_require_prior_dental_appointments) || null,
            anyAllergies: getYesNo(data?.pre_intake?.any_allergies) || null,
            rateAppearanceOfYourSmile: data?.pre_intake?.appearance_smile_scale_to_10 || null,
            takingMedicationsOrSupplements: getYesNo(data?.pre_intake?.currently_taking_any_medication) || null,
            dentalInfo: stringToArray(data?.pre_intake?.dental_info) || [],
            device: data?.pre_intake?.device || "",
            feelDuringDay: getYesNo(data?.pre_intake?.fatigue_daily_bases) || null,
            heightUnit: data?.pre_intake?.height_unit || "ft",
            heightFT: data?.pre_intake?.height_unit === "ft" ? data?.pre_intake?.height?.split(".")[0] : "",
            heightIN:
                data?.pre_intake?.height_unit === "ft" ? data?.pre_intake?.height?.split(".")[1] || "" : "",
            heightCM: data?.pre_intake?.height_unit === "cm" ? data?.pre_intake?.height : "",
            historyOfSmoking: data?.pre_intake?.history_of_smoking || "",
            medicalConditions: stringToArray(data?.pre_intake?.medical_conditions) || [],
            takingMedicationsOrSupplementsInput: data?.pre_intake?.medication_list || "",
            pregnant: getYesNo(data?.pre_intake?.pregnant) || null,
            seriousIllnessesOrOperations: getYesNo(data?.pre_intake?.serious_illness_or_operation_existance) || null,
            seriousIllnessesOrOperationsInput: data?.pre_intake?.serious_illness_or_operation_name || "",
            doYouSnackMoreThatDay: getYesNo(data?.pre_intake?.snack_3x_day) || null,
            weightUnit: data?.pre_intake?.weight_unit || "lb",
            weight: data?.pre_intake?.weight || "",
        }

        yield put(
            updateAppointmentInfo({
                appointmentInfo: appointmentData,
                appointmentPreIntakeData: appointmentPreData
            })
        );

        yield put(
            getPreClinicalDataSuccessAction(data)
        );
        /*
         * End
         * */
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            getPreClinicalDataFailAction()
        );
    }
}

function* putPreClinicalDataSaga(payload: any): any {
    try {
        const details = payload.payload?.appointmentDetails
        const appointmentData = {
            appointment_id: details?.appointmentID,
            exam_consent: details?.verbalConsent || null,
            chief_complaint: details?.chiefComplaint || null,
            // exam_date: moment(details?.dateOfExam).format(usDateFormat),
            appt_type: details?.appointmentType,
            inst_steri_cycle: details?.instrumentCycle,
            social_note: details?.socialNotes,
            patient_preferences: details?.patientPreferences,
            profile_id: dentistProfileData?.id
        } as any

        if (details?.appointmentType === RE) {
            appointmentData.recall_cycle_period = details?.recallCyclePeriod
            appointmentData.last_date_of_examination = details?.lastDateOfExamination !== null ? moment(details?.lastDateOfExamination).format(usDateFormat) : null
        }

        const preIntakeData = payload?.payload?.preIntakeData

        const updatedPreIntakeData = {
            allergies_list: arrayToString(preIntakeData?.checkboxListTwo) || '',
            allergies_list_other: preIntakeData?.checkboxListTwoOtherInput || '',
            any_allergies: passTrueFalse(preIntakeData?.anyAllergies),
            antibiotic_premedication: preIntakeData?.antibioticPremeditation,
            antibiotic_premedication_require_prior_dental_appointments: passTrueFalse(
                preIntakeData?.doesYourPhysicianRecommendAntibiotic
            ),
            appearance_smile_scale_to_10: preIntakeData?.rateAppearanceOfYourSmile,
            currently_taking_any_medication: passTrueFalse(
                preIntakeData?.takingMedicationsOrSupplements
            ),
            dental_info: arrayToString(preIntakeData?.dentalInfo) || '',
            device: payload?.payload?.device,
            fatigue_daily_bases: passTrueFalse(preIntakeData?.feelDuringDay),
            height:
                (preIntakeData?.heightUnit === "ft" && preIntakeData?.heightFT
                    ?
                    `${preIntakeData?.heightFT}.${preIntakeData?.heightIN ? preIntakeData?.heightIN : 0}`
                    : preIntakeData?.heightCM || ''),
            height_unit: (preIntakeData?.heightFT || preIntakeData?.heightCM) ? preIntakeData?.heightUnit : '',
            weight: preIntakeData?.weight,
            weight_unit: preIntakeData?.weight ? preIntakeData?.weightUnit : '',
            history_of_smoking: preIntakeData?.historyOfSmoking,
            medical_conditions: arrayToString(preIntakeData?.medicalConditions) || '',
            medication_list:
            preIntakeData?.takingMedicationsOrSupplementsInput,
            pregnant: passTrueFalse(preIntakeData?.pregnant),
            serious_illness_or_operation_existance: passTrueFalse(
                preIntakeData?.seriousIllnessesOrOperations
            ),
            serious_illness_or_operation_name:
            preIntakeData?.seriousIllnessesOrOperationsInput,
            snack_3x_day: passTrueFalse(preIntakeData?.doYouSnackMoreThatDay),
        }

        const updatedAppointmentPayload = {
            appointment_id: payload?.payload?.appointmentID,
            profile_id: payload?.payload?.profile_id,
            appointment_details: appointmentData,
            pre_intake: updatedPreIntakeData
        }

        const response = yield API.put(`${ApiEndpointVersion}/exam/preclinical`, updatedAppointmentPayload);
        if (response) {
            yield put(
                putPreClinicalDataSuccessAction(),
            );
        }

        // successToaster(response?.data?.meta?.message)
        if (payload?.payload?.callback) {
            yield call(payload?.payload?.callback)
        }
    } catch (e: any) {
        errorToaster(e?.meta?.message)
        yield put(
            putPreClinicalDataFailAction()
        );
    }
}

function* preClinicalGetDataWatcher() {
    yield takeEvery(GET_PRE_CLINICAL_DATA_REQUEST, getPreClinicalDataSaga);
}

function* preClinicalPutDataWatcher() {
    yield takeEvery(PUT_PRE_CLINICAL_DATA_REQUEST, putPreClinicalDataSaga);
}

export default function* preClinicalSaga() {
    yield all([preClinicalGetDataWatcher(),
        preClinicalPutDataWatcher()
    ]);
}
