import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Furcation from "./PeriogramFindings/Furcation";
import BOP from "./PeriogramFindings/BOP";
import Plaque from "./PeriogramFindings/Plaque";
import ProbingDepth from "./PeriogramFindings/ProbingDepth";
import Recession from "./PeriogramFindings/Recession";
import Mobility from "./PeriogramFindings/Mobility";
import Implant from "./PeriogramFindings/Implant";
import TeethCrown from "./TeethCrown";
import TeethRoot from "./TeethRoot";
import NCCLs from './PeriogramFindings/Nccl';
import { removeRefFromJson, stringToArray } from '../../../../../../utils/helper';
import { procedureColors } from '../../../../../../utils/procedureHelper';
import abfraction from '../../../../../../assets/images/odontogramFindings/abfraction.svg'
import { examConstantInterface, findingData, findingTypes } from '../../../../../../utils/constant/examConstant';

const useStyles = makeStyles((theme: any) => ({
    odontogramTeeth: {
        height: '85px',
        flexDirection: 'column',
        zIndex: 1,
    },
    missingToothLine: {
        '&::before': {
            position: "absolute",
            content: '""',
            transform: 'rotate(25deg)',
            width: 2,
            height: 80,
            background: theme.palette.error.main,
            display: 'flex',
            zIndex: 1024,
            borderRadius: 10
        }
    },
    impactedTooth: {
        width: '46.52px',
        height: '90px',
        borderRadius: '50%',
    },
    rootContainer: {
        height: '46.28px !important',
        maxHeight: '46.28px !important',
        minHeight: '46.28px !important',
        overflow: 'hidden !important',
        width: '100%',
        position: 'relative',
    },
    crownContainer: {
        height: '32.5px !important',
        maxHeight: '32.5px !important',
        minHeight: '32.5px !important',
        overflow: 'hidden !important',
    },
}));

type Props = {
    tooth: number;
    lingual?: boolean;
};

const PeriogramTeeth = React.memo((props: Props) => {
    const { tooth, lingual } = props;
    const classes = useStyles();
    const {
        examFindingReducer: { examFindingData, examDataFromAPI },
        odontogram: { procedureFilters, showRejectedTreatmentsOnly },
        clinicalExamFilters: { riskFilters, findingType },
    } = useSelector((state: any) => state) as any;
    const bottomJaw = tooth > 28;

    const isImpactedTooth = useMemo(() => {
        let hasTooth = false;
        let impactedToothSubType = null;
        let color = null;
        const updatedFindingData = examDataFromAPI?.['occlusion_exam']?.['impacted_tooth'];
        if (updatedFindingData?.length === 1) {
            const impactedToothData = updatedFindingData[0];
            const isTooth = impactedToothData['tooth_number'];
            const isSubType = impactedToothData['subtype'];
            const procedure_status = impactedToothData['procedure_status'];
            const isRejected = impactedToothData['rejected'];
            if (
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${tooth}`) &&
                isSubType
            ) {
                hasTooth = true;
                impactedToothSubType = isSubType ? true : true;
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((impactedToothData: any) => {
                const isTooth = impactedToothData['tooth_number'];
                const isSubType = impactedToothData['subtype'];
                const procedure_status = impactedToothData['procedure_status'];
                const isRejected = impactedToothData['rejected'];
                if (
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${tooth}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    impactedToothSubType = isSubType ? true : true;
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            impactedToothSubType,
            color,
        };
    }, [tooth, examDataFromAPI, procedureFilters, showRejectedTreatmentsOnly]);
    const isImplant = useMemo(() => {
        let hasTooth = false;
        let implantSubType = null;
        let color = null;
        const updatedFindingData = examDataFromAPI?.['existing_treatment']?.['implant'];

        if (updatedFindingData?.length === 1) {
            const implantData = updatedFindingData[0];
            const isTooth = implantData['tooth_number'];
            const isSubType = implantData['subtype'];
            const procedure_status = implantData['procedure_status'];
            const isRejected = implantData['rejected'];
            if (
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${tooth}`) &&
                isSubType
            ) {
                hasTooth = true;
                implantSubType = isSubType !== 'Fixture';
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData?.forEach((implantData: any) => {
                const isTooth = implantData['tooth_number'];
                const isSubType = implantData['subtype'];
                const procedure_status = implantData['procedure_status'];
                const isRejected = implantData['rejected'];
                if (
                    (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                    (!showRejectedTreatmentsOnly || isRejected) &&
                    isTooth?.includes(`${tooth}`) &&
                    isSubType
                ) {
                    hasTooth = true;
                    implantSubType = isSubType !== 'Fixture';
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            implantSubType,
            color,
        };
    }, [tooth, examDataFromAPI, procedureFilters, showRejectedTreatmentsOnly]);
    const isNCCLs = useMemo(() => {
        let hasTooth = false;
        let color = null;
        let ncclsSubType = null;
        const updatedFindingData = examDataFromAPI?.['periodontal_exam']?.['non_carious_cervical_lesions'];
        if (updatedFindingData?.length === 1) {
            const ncclsSubTypeOption = updatedFindingData[0];
            const isTooth = ncclsSubTypeOption['tooth_number'];
            const procedure_status = ncclsSubTypeOption['procedure_status'];
            const isRejected = ncclsSubTypeOption['rejected'];
            if (
                (procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) &&
                (!showRejectedTreatmentsOnly || (showRejectedTreatmentsOnly && isRejected)) &&
                isTooth?.includes(`${tooth}`)
            ) {
                hasTooth = ncclsSubTypeOption;
                ncclsSubType = [
                    ncclsSubTypeOption['erosion'] ? 'erosion' : null,
                    ncclsSubTypeOption['attrition'] ? 'attrition' : null,
                    ncclsSubTypeOption['is_dentin_exposed'] ? 'is_dentin_exposed' : null,
                    ncclsSubTypeOption['abrasion'] ? 'abrasion' : null,
                    ncclsSubTypeOption['abfraction'] ? 'abfraction' : null,
                ];
                color = procedureColors[procedure_status]?.color;
            }
        } else if (updatedFindingData) {
            updatedFindingData.forEach((ncclsSubTypeOption: any) => {
                const isTooth = ncclsSubTypeOption['tooth_number'];
                const procedure_status = ncclsSubTypeOption['procedure_status'];
                const isRejected = ncclsSubTypeOption['rejected'];

                if ((procedureFilters?.includes(procedure_status) || procedureFilters?.length < 1) && (!showRejectedTreatmentsOnly
                    || isRejected) &&
                    isTooth?.includes(`${tooth}`)
                ) {
                    hasTooth = true;
                    ncclsSubType = [
                        (ncclsSubTypeOption['erosion'] === 'yes' || ncclsSubTypeOption['erosion'] === true) ? 'erosion' : null,
                        (ncclsSubTypeOption['attrition'] === 'yes' || ncclsSubTypeOption['attrition'] === true) ? 'attrition' : null,
                        (ncclsSubTypeOption['is_dentin_exposed'] === 'yes' || ncclsSubTypeOption['is_dentin_exposed'] === true) ? 'is_dentin_exposed' : null,
                        (ncclsSubTypeOption['abrasion'] === 'yes' || ncclsSubTypeOption['abrasion'] === true) ? 'abrasion' : null,
                        (ncclsSubTypeOption['abfraction'] === 'yes' || ncclsSubTypeOption['abfraction'] === true) ? 'abfraction' : null,
                    ];
                    color = procedureColors[procedure_status]?.color;
                }
            });
        }
        return {
            hasTooth,
            color,
            ncclsSubType,
        };
    }, [tooth, examDataFromAPI, procedureFilters, showRejectedTreatmentsOnly]);

    const getSite = (site: string) => {
        let newSite = '' as string
        if (site === 'center') {
            return lingual ? 'L' : 'B'
        }
        if ((tooth > 10 && tooth < 19) || (tooth > 30 && tooth < 39)) {
            newSite = (site === 'right') ? 'M' : (site === 'left') ? 'D' : ''
        } else {
            newSite = (site === 'right') ? 'D' : (site === 'left') ? 'M' : ''
        }
        if (newSite) {
            return `${newSite}${lingual ? 'L' : 'B'}`
        }
        return ''
    }
    const riskWiseFilteredData = useMemo(() => {
        const findingTypeWiseFilteredData = findingData?.filter((f: examConstantInterface) =>
            f?.findingTypes?.includes(findingTypes[findingType])
        ) as any;
        /*Filter the data only if there is at least one risk is selected else will show data without risk filters*/
        return riskFilters?.length
            ? (findingTypeWiseFilteredData?.filter((f: examConstantInterface) => f?.risks?.filter((ff: string) => riskFilters.includes(ff))?.length) as any)
            : findingData
        // eslint-disable-next-line
    }, [riskFilters, riskFilters?.length, findingData, findingType]);
    const isFindingPresent = (site: string, finding: string) => {
        const newSite = getSite(site)
        let value = '' as boolean | string | number | any
        const filteredFindingData = removeRefFromJson(riskWiseFilteredData) as any
        if (examFindingData && examFindingData?.periodontal_exam && examFindingData?.periodontal_exam[finding] && filteredFindingData) {
            filteredFindingData?.filter((findingKey: any) => findingKey?.key === finding)?.map((fk: any) => {
                examFindingData?.periodontal_exam[fk?.key]?.map((res: any) => {
                    if (res?.tooth_number === `${tooth}`) {
                        if ((typeof res?.tooth_site === 'string' ? stringToArray(res?.tooth_site) : res?.tooth_site)?.includes(newSite) && (finding === 'bleed_on_probing' || finding === 'plaque')) {
                            value = true
                        }
                        if (res?.grade && res?.tooth_number === `${tooth}` && finding === 'mobility') {
                            value = res?.grade
                        }
                        if (finding === 'furcation') {
                            let toothSite = lingual ? 'lingual_grade' : 'buccal_grade'
                            if (res[toothSite]) {
                                value = res[toothSite] || ''
                            }
                        }
                        if (finding === 'recession') {
                            let toothSite = lingual ? 'lingual_mm' : 'buccal_mm'
                            value = res[toothSite] || 0
                        }
                        if (finding === 'probing_depth') {
                            let toothSite: any
                            if (site === 'center') {
                                toothSite = lingual ? 'lingual_mm' : 'buccal_mm'
                            } else {
                                if ((tooth > 10 && tooth < 19) || (tooth > 30 && tooth < 39)) {
                                    toothSite = site === 'right' ? `mesial_${lingual ? 'lingual' : 'buccal'}_mm` : site === 'left' ? `distal_${lingual ? 'lingual' : 'buccal'}_mm` : ''
                                } else {
                                    toothSite = site === 'right' ? `distal_${lingual ? 'lingual' : 'buccal'}_mm` : site === 'left' ? `mesial_${lingual ? 'lingual' : 'buccal'}_mm` : ''
                                }
                            }
                            value = res[toothSite] || 0
                        }
                    }
                    return true
                })
                return true
            })
        }
        return value
    };
    const isMissingTooth = stringToArray(examDataFromAPI?.occlusion_exam?.missing_tooth_tooth_number || '').includes(`${tooth}`) && !isImplant?.hasTooth

    return (
        <Box
            sx={{
                transform: bottomJaw ? (lingual ? '' : 'rotate(180deg) scaleX(1)') : lingual ? 'rotate(180deg)' : '',
                opacity: isMissingTooth ? 0 : 1
            }}
            className={`${classes.odontogramTeeth} pos-relative d-flex-all-center ${isMissingTooth ? classes.missingToothLine : ''}`}
        >
            <Mobility isFindingPresent={isFindingPresent} lingual={lingual} />
            <BOP isFindingPresent={isFindingPresent} lingual={lingual} bottomJaw={bottomJaw} />
            <Box className={isImpactedTooth?.hasTooth ? classes.impactedTooth : ''}
                sx={{ border: isImpactedTooth?.hasTooth ? `1px solid ${isImpactedTooth?.color}` : 'none', }}>
                {/*Root*/}
                <Box className={`d-flex-all-center ${classes.rootContainer}`}
                    sx={{
                        transform: bottomJaw ? (lingual ? '' : ' scaleX(-1)') : '',
                        alignItems: !bottomJaw ? 'flex-end !important' : 'flex-end !important'
                    }}
                >
                    <Recession isFindingPresent={isFindingPresent} lingual={lingual} bottomJaw={bottomJaw} />
                    <ProbingDepth isFindingPresent={isFindingPresent} lingual={lingual} bottomJaw={bottomJaw} />
                    <Furcation isFindingPresent={isFindingPresent} lingual={lingual} bottomJaw={bottomJaw} />
                    {isImplant?.hasTooth ? (
                        <Implant isImplant={isImplant} />
                    ) : (
                        <TeethRoot lingual={lingual} bottomJaw={bottomJaw} tooth={tooth} />
                    )}
                </Box>
                {/*Crown*/}
                <Box className='pos-relative'>
                    <Box className={`d-flex-all-center pos-relative ${classes.crownContainer}`}
                        sx={{
                            transform: bottomJaw ? lingual ? 'rotate(180deg)' : 'rotate(180deg) scaleX(1)' : '',
                            alignItems: !bottomJaw ? 'flex-start !important' : 'flex-end !important'
                        }}>
                        <Plaque isFindingPresent={isFindingPresent} lingual={lingual} bottomJaw={bottomJaw} />
                        <Box className='d-flex'
                            sx={{ p: 0, m: 0, transform: lingual ? 'rotate(180deg)' : bottomJaw ? 'scaleX(-1)' : '' }}>
                            {isNCCLs?.hasTooth && <NCCLs isNCCLs={isNCCLs} bottomJaw={bottomJaw} lingual={lingual} />}
                            <TeethCrown tooth={tooth} isImplant={isImplant} lingual={lingual} />
                        </Box>
                    </Box>
                    {!lingual && isNCCLs?.hasTooth && isNCCLs?.ncclsSubType?.includes('abfraction') &&
                        <Box className='pos-absolute'
                            sx={{ top: bottomJaw ? '-10px' : '-11px', left: bottomJaw ? 0 : 3 }}>
                            <img src={abfraction} alt="abfraction" />
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
})

export default PeriogramTeeth;

