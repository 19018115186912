import moment from "moment";
import {removedDuplicatesData, usDateFormat} from "../../../utils/helper";
import {
    APPOINTMENT_DETAILS_REQUEST,
    APPOINTMENT_DETAILS_REQUEST_FAILURE,
    APPOINTMENT_DETAILS_REQUEST_SUCCESS,
    DASHBOARD_MEMBERS_CACHE_DATA_CLEAR_REQUEST,
    DASHBOARD_MEMBERS_REQUEST,
    DASHBOARD_MEMBERS_REQUEST_FAILURE,
    DASHBOARD_MEMBERS_REQUEST_SUCCESS,
    DENTIST_NEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
    DENTIST_NEW_APPOINTMENT_REQUEST,
    DENTIST_NEW_APPOINTMENT_REQUEST_FAILURE,
    DENTIST_NEW_APPOINTMENT_REQUEST_SUCCESS,
    SET_DATE_NEW_APPOINTMENT,
    UPDATE_APPOINTMENT_DETAILS,
    CHANGE_LOGIN_USER_PERMISSION_REQUEST,
    CHANGE_LOGIN_USER_PERMISSION_SUCCESS,
    CHANGE_LOGIN_USER_PERMISSION_FAILURE,
    UPDATE_APPOINTMENT_DATA_REQUEST,
    SET_DESCENDING_ASCENDING,
    SET_SEARCH_DATA_APPOINTMENT,
    SET_ROLE_APPOINTMENT,
    SET_EXAM_APPOINTMENT,
    SET_STATUS_APPOINTMENT,
    SET_TEAM_MEMBER_APPOINTMENT,
} from "../../actionTypes/dentist/appointment";
import {newAppointmentState,} from "../../types/dentist/appointment";

const initialState: newAppointmentState = {
    startDate: moment().format(usDateFormat),
    endDate: moment().format(usDateFormat),
    loader: false,
    newAppointment_data:  [],
    newAppointment_meta: null,
    error: null,
    memberLoader: false,
    members_data: null,
    members_meta: null,
    member_error: null,
    appointmentDetailsLoader: false,
    appointmentDetails: null,
    appointmentDetailsError: null,
    loginUserPermissionLoader: false,
    loginUserPermission: null,
    loginUserPermissionError: null,
    sortBy: '',
    desc: null,
    searchData: '',
    exam: null,
    role: null,
    status: ['upcoming', 'ongoing', 'finished', 'completed', 'no_show'],
    teamMember: null,
};

const appointmentsReducer = (
    state = initialState,
    action: any
) => {
    switch (action.type) {
        // new appointment
        case SET_DATE_NEW_APPOINTMENT:
            return {
                ...state,
                startDate: action?.payload.startDate,
                endDate: action?.payload.endDate,
            };
        case DENTIST_NEW_APPOINTMENT_REQUEST:
            return {
                ...state,
                loader: true,
            };
        case DENTIST_NEW_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                newAppointment_data: [],
            };

        case DENTIST_NEW_APPOINTMENT_REQUEST_SUCCESS:
            return {
                ...state,
                loader: false,
                newAppointment_data:removedDuplicatesData(state.newAppointment_data,action?.payload?.newAppointment_data,'appointmentID'),
                newAppointment_meta: action?.payload?.newAppointment_meta,
                error: null,
            };

        case DENTIST_NEW_APPOINTMENT_REQUEST_FAILURE:
            return {
                ...state,
                loader: false,
                newAppointment_data:  [],
                error: action.payload.error,
            };

        case DASHBOARD_MEMBERS_REQUEST:
            return {
                ...state,
                memberLoader: true,
                members_data:null
            };
        case DASHBOARD_MEMBERS_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                memberLoader: false,
                members_data: null,
            };

        case DASHBOARD_MEMBERS_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                members_data: action?.payload?.members_data,
                members_meta: action?.payload?.members_meta,
                error: null,
            };

        case DASHBOARD_MEMBERS_REQUEST_FAILURE:
            return {
                ...state,
                memberLoader: false,
                members_data: null,
                member_error: action.payload.member_error,
            };
        case APPOINTMENT_DETAILS_REQUEST:
            return {
                ...state,
                appointmentDetailsLoader: true,
                appointmentDetails: null
            };
        case APPOINTMENT_DETAILS_REQUEST_SUCCESS:
            return {
                ...state,
                appointmentDetailsLoader: false,
                appointmentDetails: action.payload?.appointmentDetails,
                appointmentDetailsError: null,
            };
        case UPDATE_APPOINTMENT_DETAILS:
            const {key, value} = action.payload as any
            return {
                ...state,
                appointmentDetails: {...state?.appointmentDetails, [key]: value},
            };
        case APPOINTMENT_DETAILS_REQUEST_FAILURE:
            return {
                ...state,
                appointmentDetailsLoader: false,
                appointmentDetails: null,
                appointmentDetailsError: action.payload,
            };
        case CHANGE_LOGIN_USER_PERMISSION_REQUEST:
            return {
                ...state,
                loginUserPermissionLoader: true,
            };
        case CHANGE_LOGIN_USER_PERMISSION_SUCCESS:
            return {
                ...state,
                loginUserPermissionLoader: false,
                loginUserPermission: action.payload,
                loginUserPermissionError: null,
            };
        case CHANGE_LOGIN_USER_PERMISSION_FAILURE:
            return {
                ...state,
                loginUserPermissionLoader: false,
                loginUserPermission: null,
                loginUserPermissionError: action.payload,
            };
        case UPDATE_APPOINTMENT_DATA_REQUEST:
            return {
                ...state,
                newAppointment_data: action?.payload,
            };
        case SET_DESCENDING_ASCENDING:
            return {
                ...state,
                desc: action?.payload?.desc,
                sortBy: action?.payload?.sortBy,
            }
        case SET_SEARCH_DATA_APPOINTMENT:
            return {
                ...state,
                searchData: action?.payload,
            };
        case SET_EXAM_APPOINTMENT:
            return {
                ...state,
                exam: action?.payload,
            };
        case SET_STATUS_APPOINTMENT:
            return {
                ...state,
                status: action?.payload,
            };
        case SET_ROLE_APPOINTMENT:
            return {
                ...state,
                role: action?.payload,
            };
        case SET_TEAM_MEMBER_APPOINTMENT:
            return {
                ...state,
                teamMember: action?.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default appointmentsReducer;