import React from 'react';
import theme from '../../theme';

type RequiredStarProps = {
  isPatient?: boolean;
};

const RequiredStar = ({ isPatient = false }: RequiredStarProps) => {

  return <span style={{ color: `${isPatient ? theme.palette.error.main : theme.palette.common.black50}`, fontSize: '14px' }}>*</span>;
};

export default RequiredStar;
