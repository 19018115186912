import {
    GET_RISK_PROFILE_REQUEST,
    GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST,
    GET_RISK_PROFILE_REQUEST_FAILURE,
    GET_RISK_PROFILE_REQUEST_SUCCESS,
} from "../../actionTypes/patient/riskProfile";
import {getRiskProfileState, RiskProfileActions} from "../../types/patient/patientRiskProfileActionTypes";

const initialState: getRiskProfileState = {
    pending: false,
    data: null,
    xRayData: null,
    error: null,
    get_risk_assessment_error: null,
    get_risk_assessment_loader: false,
};

const patientRiskProfileReducer = (
    state = initialState,
    action: RiskProfileActions
) => {
    switch (action.type) {
        case GET_RISK_PROFILE_REQUEST:
            return {
                ...state,
                get_risk_assessment_loader: true,
            };
        case GET_RISK_PROFILE_CACHE_DATA_CLEAR_REQUEST:
            return {
                ...state,
                data: null
            }
        case GET_RISK_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                get_risk_assessment_loader: false,
                data: action.payload.data,
                get_risk_assessment_error: null,
            };

        case GET_RISK_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                get_risk_assessment_loader: false,
                data: null,
                get_risk_assessment_error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};
export default patientRiskProfileReducer;
