import {
  CAME_ODONTOGRAM_FROM_TREATMENT,
  HANDLE_ODONTOGRAM_FORM_FINDING_SELECTION,
  OPEN_ODONTOGRAM_GROUP_FINDING_POPUP,
  PROCEDURE_FILTERS,
  SELECT_ODNTOGRAM_FINDING,
  SELECT_ODNTOGRAM_TOOTH,
  SET_EDIT_ODONTOGRAM_FINDING_DATA,
  SHOW_REJECTED_TREATMENT_FILTER
} from '../../../actionTypes/dentist/clinicalExam/exams/odontogram';

export const selectOdontrogramToothAction = (payload: number[]): any => ({
  type: SELECT_ODNTOGRAM_TOOTH,
  payload
});

export const selectOdontrogramFindingAction = (payload: string): any => ({
  type: SELECT_ODNTOGRAM_FINDING,
  payload
});
// TODO: for future use
// export const handleOdontrogramPopupAction = (payload: boolean): any => ({
//   type: HANDLE_ODNTOGRAM_POPUP,
//   payload
// });

export const setEditOdontogramFindingDataAction = (payload: any): any => ({
  type: SET_EDIT_ODONTOGRAM_FINDING_DATA,
  payload
});

export const handleOdontogramFormFindingSelectionAction = (payload: any): any => ({
  type: HANDLE_ODONTOGRAM_FORM_FINDING_SELECTION,
  payload
});

export const handleOdontogramComingAction = (payload: any): any => ({
  type: CAME_ODONTOGRAM_FROM_TREATMENT,
  payload
});

export const updateProcedureFiltersAction = (payload: any): any => ({
  type: PROCEDURE_FILTERS,
  payload
});

export const handleShowRejectedTreatmentAction = (payload: boolean): any => ({
  type: SHOW_REJECTED_TREATMENT_FILTER,
  payload
});
export const openOdontogramGroupFindingPopupAction = (payload: string | null): any => ({
  type: OPEN_ODONTOGRAM_GROUP_FINDING_POPUP,
  payload
});
