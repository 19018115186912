import {
  GET_RISK_CATEGORY_DATA_REQUEST,
  GET_RISK_CATEGORY_DATA_SUCCESS,
  GET_RISK_CATEGORY_DATA_FAIL,
} from '../../actionTypes/master/riskCategories';

// Get risk categories data api actions
export const getRiskCategoryDataAction = (payload: any): any => ({
  type: GET_RISK_CATEGORY_DATA_REQUEST,
  payload
});

export const getRiskCategoryDataSuccessAction = (payload: any): any => ({
  type: GET_RISK_CATEGORY_DATA_SUCCESS,
  payload
});

export const getRiskCategoryDataFailAction = (): any => ({
  type: GET_RISK_CATEGORY_DATA_FAIL
});

